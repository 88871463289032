import { useEffect, useState } from "react";
import useAuth from "../../../Hooks/useAuth";
import { useLocation } from "react-router-dom";
// Icon
import { useGetFirmByIDQuery } from "../../../store";
import Loader from "../../../Components/Utils/Loader";
import UnfindDataWarn from "../../../Components/Utils/UnfindDataWarn";
import { colors } from "../../../Config/Colors";

function InfoFirmPage() {
  const location = useLocation();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const id = location.state.id;

  const ResultMainFirm = useGetFirmByIDQuery({ id, token });
  const Data = ResultMainFirm?.data?.data?.firm || [];
  const [input, setInput] = useState({
    name: "",
    mainFirmID: "",
    mainFirmName: "",
    isActive: "",
    officialID: "",
    taxNumber: "",
    taxOffice: "",
    isCorporate: "",
    tel: "",
    email: "",
    address: { city: "", town: "", text: "" },
    playMakers: [],
  });

  useEffect(() => {
    if (ResultMainFirm.status === "fulfilled") setInput({ ...Data });
  }, [ResultMainFirm]);

  return (
    <div className="container">
      {ResultMainFirm.isLoading ? (
        <div className="flex w-full h-full justify-center items-center">
          <Loader
            Visible={true}
            Width={140}
            Height={140}
            Color={colors.primary.main}
          />
        </div>
      ) : ResultMainFirm.error || ResultMainFirm?.data?.results === 0 ? (
        <div className="fixed top-0 left-0 flex w-full h-fit justify-center items-center">
          <UnfindDataWarn Data={"Firma bulunamadı"} />
        </div>
      ) : (
        <div className="flex flex-col gap-8">
          {/* Ana Bilgiler Kartı */}
          <div
            className={`${colors.background.card} rounded-xl shadow-lg overflow-hidden`}
          >
            <div className="p-6">
              <div className="grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 gap-4">
                {input.taxNumber && (
                  <div className={`p-3 ${colors.background.light} rounded-lg`}>
                    <p className={`${colors.primary.text} text-xl mb-1`}>
                      Vergi Numarası
                    </p>
                    <p className={`${colors.text.light} text-xl font-bold`}>
                      {input.taxNumber}
                    </p>
                  </div>
                )}
                {input.taxOffice && (
                  <div className={`p-3 ${colors.background.light} rounded-lg`}>
                    <p className={`${colors.primary.text} text-xl mb-1`}>
                      Vergi Dairesi
                    </p>
                    <p className={`${colors.text.light} text-xl font-bold`}>
                      {input.taxOffice}
                    </p>
                  </div>
                )}
                {input.tel && (
                  <div className={`p-3 ${colors.background.light} rounded-lg`}>
                    <p className={`${colors.primary.text} text-xl mb-1`}>
                      Telefon
                    </p>
                    <p className={`${colors.text.light} text-xl font-bold`}>
                      {input.tel}
                    </p>
                  </div>
                )}
                {input.email && (
                  <div className={`p-3 ${colors.background.light} rounded-lg`}>
                    <p className={`${colors.primary.text} text-xl mb-1`}>
                      Email
                    </p>
                    <p className={`${colors.text.light} text-xl font-bold`}>
                      {input.email}
                    </p>
                  </div>
                )}
                {input.address?.city && (
                  <div className={`p-3 ${colors.background.light} rounded-lg`}>
                    <p className={`${colors.primary.text} text-xl mb-1`}>
                      Şehir
                    </p>
                    <p className={`${colors.text.light} text-xl font-bold`}>
                      {input.address.city}
                    </p>
                  </div>
                )}
                {input.address?.town && (
                  <div className={`p-3 ${colors.background.light} rounded-lg`}>
                    <p className={`${colors.primary.text} text-xl mb-1`}>
                      İlçe
                    </p>
                    <p className={`${colors.text.light} text-xl font-bold`}>
                      {input.address.town}
                    </p>
                  </div>
                )}
                {input.address?.text && (
                  <div
                    className={`p-3 ${colors.background.light} rounded-lg col-span-full`}
                  >
                    <p className={`${colors.primary.text} text-xl mb-1`}>
                      Açık Adres
                    </p>
                    <p className={`${colors.text.light} text-xl font-bold`}>
                      {input.address.text}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Plasiyerler Kartı */}
          {input?.playMakers?.length > 0 && (
            <div
              className={`${colors.background.card} rounded-xl shadow-lg overflow-hidden`}
            >
              <div
                className={`bg-gradient-to-r ${colors.primary.gradient} p-4`}
              >
                <h3
                  className={`${colors.text.white} text-3xl font-semibold text-center`}
                >
                  Plasiyerler
                </h3>
              </div>
              <div className="p-6">
                <div className="grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 gap-4">
                  {input.playMakers.map((item, index) => (
                    <div
                      key={index}
                      className={`p-3 ${colors.background.light} rounded-lg`}
                    >
                      <p
                        className={`${colors.text.light} text-xl font-bold text-center`}
                      >
                        {item.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* Not Kartı */}
          {input.note && (
            <div
              className={`${colors.background.card} rounded-xl shadow-lg overflow-hidden`}
            >
              <div
                className={`bg-gradient-to-r ${colors.primary.gradient} p-4`}
              >
                <h3
                  className={`${colors.text.white} text-3xl font-semibold text-center`}
                >
                  Not
                </h3>
              </div>
              <div className="p-6">
                <div className={`p-3 ${colors.background.light} rounded-lg`}>
                  <p className={`${colors.text.light} text-xl`}>{input.note}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default InfoFirmPage;
