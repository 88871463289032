import { Outlet } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { BsPersonPlusFill } from "react-icons/bs";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { colors } from "../../Config/Colors";
import TopBar from "../../Components/Utils/TopBar";

function CreateUserLayout() {
  const links = [
    {
      to: "",
      label: (
        <p className="text-2xl font-medium max-md:hidden">Kullanıcı Oluştur</p>
      ),
      icon: <BsPersonPlusFill className="w-10 h-10" />,
    },
  ];

  const backLink = {
    path: "/Anasayfa/Kullanıcı",
    icon: <MdOutlineKeyboardBackspace className="w-10 h-10" />,
  };

  return (
    <div className="min-h-screen px-16 max-md:px-0">
      {/* Top Bar */}
      <TopBar links={links} backLink={backLink} />

      {/* Main Content */}
      <div className="mb-40 mt-6">
        <Outlet />
      </div>

      {/* Bottom Info Bar */}
      <div
        className={`
          fixed bottom-0 left-0 right-0 
          ${colors.background.main} border-t ${colors.border.light}
          md:left-[20%] md:right-[20%] md:bottom-8 md:rounded-xl md:border
          shadow-xl backdrop-blur-sm bg-opacity-95
          transition-all duration-300
        `}
      >
        <div className="flex justify-evenly p-4">
          {/* Creation Info */}
          <div className="flex flex-col gap-2">
            <span className={`text-base font-medium ${colors.text.tertiary}`}>
              İşlem
            </span>
            <span
              className={`
                text-xl font-semibold 
                ${colors.background.light} 
                px-4 py-2 rounded-lg 
                ${colors.primary.text}
                flex items-center gap-2
              `}
            >
              <BsPersonPlusFill className="w-6 h-6" />
              Yeni Kullanıcı
            </span>
          </div>

          {/* System Info - Hidden on mobile */}
          <div className="max-md:hidden flex flex-col gap-2">
            <span className={`text-base font-medium ${colors.text.tertiary}`}>
              Sistem
            </span>
            <span
              className={`
                text-xl font-semibold 
                ${colors.background.light} 
                px-4 py-2 rounded-lg 
                ${colors.text.secondary}
              `}
            >
              Kullanıcı Yönetimi
            </span>
          </div>

          {/* Additional Info - Hidden on mobile */}
          <div className="max-md:hidden flex flex-col gap-2">
            <span className={`text-base font-medium ${colors.text.tertiary}`}>
              Durum
            </span>
            <span
              className={`
                text-xl font-semibold 
                ${colors.background.light} 
                px-4 py-2 rounded-lg 
                text-emerald-600
              `}
            >
              Yeni Kayıt
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateUserLayout;
