import { useEffect } from "react";
import { useGetDevicesMutation } from "../store/devicesApi";

export const useDevicesData = (token, setAlert, setMessage) => {
  const [
    getDevice,
    {
      data: deviceData,
      isLoading: deviceIsLoading,
      isError: deviceIsError,
      status: deviceStatus,
      error: deviceError,
    },
  ] = useGetDevicesMutation();

  useEffect(() => {
    getDevice({ token });
  }, []);

  const handleApiResponse = (
    isSuccess,
    isError,
    updateDeviceError,
    successMessage
  ) => {
    if (isError) {
      setAlert(2);
      setMessage(updateDeviceError.data.message);
    }
    if (isSuccess) {
      setAlert(1);
    }
  };

  useEffect(() => {
    handleApiResponse(false, deviceIsError, deviceError, "");
  }, [deviceIsError]);

  const refetch = () => {
    getDevice({ token });
  };

  return {
    deviceData: deviceData?.data?.devices || [],
    deviceIsLoading: deviceIsLoading,
    deviceError,
    setAlert,
    setMessage,
    refetch,
    getDevice,
  };
};
