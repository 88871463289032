import { useState } from "react";
import { useLocation } from "react-router-dom";
import useAuth from "../../../Hooks/useAuth";
import { useFirmReportData } from "../../../Hooks/useFirmReportData";
import { colors } from "../../../Config/Colors";
import { MagnifyingGlass } from "react-loader-spinner";
// Icon
import UnfindDataWarn from "../../../Components/Utils/UnfindDataWarn";

import FormatHourString from "../../../Components/Utils/Date/FormatHourString";
import FormatDateString from "../../../Components/Utils/Date/FormatDateString";
import CustomCalendar from "../../../Components/Utils/Date/CustomCalendar";
import SelectType from "../../../Components/Utils/SelectType";
import {
  formatToTurkeyDate,
  formatToTurkeyTime,
} from "../../../Components/Utils/FormatDateHourString";
import SidebarList from "../../../Components/Utils/SidebarList";
import ExportExcel from "../../../Components/Utils/ExportExcel";
import CustomReportTable from "../../../Components/Table/CustomReportTable";

function groupAndSumConsumptions(logs, filterType) {
  const grouped = {};

  logs.forEach((log) => {
    const date = new Date(log.createdInfo);
    let key;

    switch (filterType) {
      case "Day":
        key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}-${String(date.getDate()).padStart(2, "0")} ${String(
          `${String(date.getHours()).padStart(2, "0")}:${String(
            date.getMinutes()
          ).padStart(2, "0")}`
        )}`;
        break;
      case "Month":
        key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}`;
        break;
      case "Year":
        key = `${date.getFullYear()}`;
        break;
      default:
        key = "default";
        break;
    }

    if (!grouped[key]) grouped[key] = {};
    if (!grouped[key][log.productName]) {
      grouped[key][log.productName] = {
        consument: 0,
      };
    }
    grouped[key][log.productName].consument += log.consument;
  });

  return grouped;
}

function renderExcelData(filteredItems, filterType) {
  const groupedData = groupAndSumConsumptions(filteredItems.logs, filterType);

  // Yeni veri yapısı oluşturmak için `map` fonksiyonları ve içeride bir ternary operatör kullanılacak
  const excelData = Object.entries(groupedData).flatMap(
    ([dateTime, products]) =>
      Object.entries(products).map(([productName, { consument }]) =>
        filterType === "Day"
          ? {
              Ürün_İsmi: productName,
              Toplam_Tüketim: consument,
              Saat: dateTime.split(" ")[1],
              Tarih: dateTime.split(" ")[0],
            }
          : {
              Ürün_İsmi: productName,
              Toplam_Tüketim: consument,
              Tarih: dateTime,
            }
      )
  );

  return excelData;
}
function groupAndMergeLogs(data, filterType) {
  const grouped = {};

  data.forEach((item) => {
    if (!item.logs || item.logs.length === 0) return;

    const date = new Date(item.date);
    let key;

    switch (filterType) {
      case "Day":
        key = date.toISOString().split("T")[0]; // YYYY-MM-DD formatında bir anahtar oluşturur
        break;
      case "Month":
        key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}`; // YYYY-MM formatında bir anahtar oluşturur
        break;
      case "Year":
        key = `${date.getFullYear()}`; // YYYY formatında bir anahtar oluşturur
        break;
      default:
        // Eğer tanımlı bir filterType yoksa, işlem yapma
        return;
    }

    // Eğer bu anahtara sahip bir grup zaten varsa, logs'ları birleştir
    if (!grouped[key]) {
      grouped[key] = [...item.logs];
    } else {
      grouped[key] = [...grouped[key], ...item.logs];
    }
  });

  // Sonuç olarak oluşturulan gruplanmış ve birleştirilmiş logs'ları bir diziye çevir
  return Object.keys(grouped).map((key) => ({
    date: key,
    logs: grouped[key],
  }));
}

function ReportFirm() {
  const location = useLocation();
  const { auth } = useAuth();
  const {
    info,
    setInfo,
    input,
    setInput,
    filterType,
    date,
    setDate,
    dateSecond,
    setDateSecond,
    Datas,
    isFetching,
    isLoading,
    isError,
    selectedDate,
    setSelectedDate,
    handleGetLogData,
    handleFilterType,
  } = useFirmReportData(location.state.id, auth.accessToken);

  const ReportReturn = (item, date) => {
    let output = null;
    const fileName = {
      Consument: "Tüketim",
      UpdateInfo: "Bilgi Değişikliği",
      UpdateBill: "Fatura Hareketi",
    };

    const reportTypeColors = {
      Consument: "from-blue-500 to-blue-700",
      UpdateInfo: "from-emerald-500 to-emerald-700",
      UpdateBill: "from-violet-500 to-violet-700",
    };

    if (date === "" || item.length === 0) {
      return null;
    }

    const filteredItems = item.find((entry) => entry.date === date);

    if (!filteredItems) {
      return null;
    }

    let excelData = [];
    const tableHeaderClass = `px-6 py-4 text-sm font-semibold text-white bg-gradient-to-r ${
      reportTypeColors[input.type]
    }`;
    const tableCellClass = `px-6 py-4 text-sm ${colors.text.primary} border-b`;
    const tableRowHoverClass = `hover:bg-gray-50 transition-colors duration-150`;

    function renderOutput(filteredItems, filterType) {
      const groupedData = groupAndSumConsumptions(
        filteredItems.logs,
        filterType
      );

      return (
        <div className="overflow-x-auto">
          <table className="w-full border rounded-lg overflow-hidden">
            <thead>
              <tr>
                <th className={tableHeaderClass}>Tarih</th>
                {filterType === "Day" && (
                  <th className={tableHeaderClass}>Saat</th>
                )}
                <th className={tableHeaderClass}>Ürün</th>
                <th className={tableHeaderClass}>Tüketim</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(groupedData).map(([dateTime, products]) =>
                Object.entries(products).map(
                  ([productName, { consument }], index) => (
                    <tr key={index}>
                      <td className={tableCellClass}>
                        {dateTime.split(" ")[0]}
                      </td>
                      {filterType === "Day" && (
                        <td className={tableCellClass}>
                          {filterType === "Day" ? dateTime.split(" ")[1] : "—"}
                        </td>
                      )}
                      <td
                        className={`${tableCellClass} font-medium text-blue-600`}
                      >
                        {productName}
                      </td>
                      <td
                        className={`${tableCellClass} font-medium text-emerald-600`}
                      >
                        {consument}
                      </td>
                    </tr>
                  )
                )
              )}
            </tbody>
            {filterType === "Day" && (
              <tfoot className="bg-gray-50">
                <tr>
                  <td colSpan="2" className={`${tableCellClass} font-semibold`}>
                    Toplam Tüketimler:
                  </td>
                  <td colSpan="4" className={tableCellClass}>
                    <div className="grid grid-cols-2 gap-4">
                      {Object.entries(
                        filteredItems?.logs.reduce((acc, log) => {
                          if (acc[log.productName]) {
                            acc[log.productName] += log.consument;
                          } else {
                            acc[log.productName] = log.consument;
                          }
                          return acc;
                        }, {})
                      ).map(([product, total], index) => (
                        <div key={index} className="flex items-center gap-2">
                          <span className="font-medium text-blue-600">
                            {product}:
                          </span>
                          <span className="font-medium text-emerald-600">
                            {total}
                          </span>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      );
    }
    if (input.type === "Consument") {
      let productTotals = {};

      filteredItems?.logs.forEach((log) => {
        if (productTotals[log.productName]) {
          productTotals[log.productName] += log.consument;
        } else {
          productTotals[log.productName] = log.consument;
        }
      });
      console.log(filteredItems);

      output = renderOutput(filteredItems, filterType);
      excelData = renderExcelData(filteredItems, filterType);
    } else if (input.type === "UpdateInfo") {
      console.log(filteredItems);
      output = (
        <div className="overflow-x-auto">
          <table className="w-full border rounded-lg overflow-hidden">
            {" "}
            <thead>
              <tr>
                <th className={tableHeaderClass}>İsim</th>
                <th className={tableHeaderClass}>Soyisim</th>
                <th className={tableHeaderClass}>Bilgi</th>
                <th className={tableHeaderClass}>İlk Değer</th>
                <th className={tableHeaderClass}>Son Değer</th>
                <th className={tableHeaderClass}>Saat</th>
                <th className={tableHeaderClass}>Tarih</th>
              </tr>
            </thead>
            <tbody>
              {filteredItems?.logs.map((log, logIndex) =>
                log.info.map((info, infoIndex) => (
                  <tr
                    key={`${logIndex}-${infoIndex}`}
                    className={tableRowHoverClass}
                  >
                    <td className={tableCellClass}>{log.name}</td>
                    <td className={tableCellClass}>{log.lastName}</td>
                    <td className={tableCellClass}>{info.infoName}</td>
                    <td className={tableCellClass}>{info.valueFrom}</td>
                    <td className={tableCellClass}>{info.valueTo}</td>
                    <td className={tableCellClass}>
                      {FormatHourString(log.createdInfo)}
                    </td>
                    <td className={tableCellClass}>
                      {FormatDateString(log.createdInfo)}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      );
      excelData = filteredItems.logs.map((log) => ({
        İsim: log.name,
        Soyisim: log.lastName,
        Bilgi: log.info.infoName,
        İlk_Değer: log.info.valueFrom,
        Son_Değer: log.info.valueTo,
        Saat: FormatHourString(log.createdInfo),
        Tarih: FormatDateString(log.createdInfo),
      }));
    } else if (input.type === "UpdateBill") {
      output = (
        <div className="overflow-x-auto">
          <table className="w-full border rounded-lg overflow-hidden">
            <thead>
              <tr>
                <th className={tableHeaderClass}>İsim</th>
                <th className={tableHeaderClass}>Soyisim</th>
                <th className={tableHeaderClass}>İşlem</th>
                <th className={tableHeaderClass}>Fatura Numarası</th>
                <th className={tableHeaderClass}>Ürün Kodu</th>
                <th className={tableHeaderClass}>Ürün İsmi</th>
                <th className={tableHeaderClass}>Miktar</th>
                <th className={tableHeaderClass}>Gelir</th>
                <th className={tableHeaderClass}>Saat</th>
                <th className={tableHeaderClass}>Tarih</th>
              </tr>
            </thead>
            <tbody>
              {filteredItems?.logs.map((log, logIndex) => (
                <tr key={`${logIndex}`} className={tableRowHoverClass}>
                  <td className={tableCellClass}>{log.name}</td>
                  <td className={tableCellClass}>{log.lastName}</td>
                  <td className={` p-2 text-center `}>
                    <p
                      style={{
                        backgroundColor: `${
                          log.operation === "Added"
                            ? "#A6FF96"
                            : log.operation === "FreeUpload"
                            ? "#EBEF95"
                            : log.operation === "GetReturn"
                            ? "#FFC8C8"
                            : "#FF6969"
                        }`,
                      }}
                      className="  text-sm rounded-md shadow-md p-2 border-b"
                    >
                      {log.operation === "Added"
                        ? "Ekleme"
                        : log.operation === "FreeUpload"
                        ? "Bedelsiz Yükleme"
                        : log.operation === "GetReturn"
                        ? "İade"
                        : "Silme"}
                    </p>
                  </td>
                  <td className={tableCellClass}>{log.info.billNo}</td>
                  <td className={tableCellClass}>{log.info.productCode}</td>
                  <td className={tableCellClass}>{log.info.productName}</td>
                  <td className={tableCellClass}>{log.info.quota}</td>
                  <td className={tableCellClass}>{log.info.income}</td>
                  <td className={tableCellClass}>
                    {log.createdInfo.split(" ")[1]}
                  </td>
                  <td className={tableCellClass}>
                    {log.createdInfo.split(" ")[0]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
      excelData = filteredItems.logs.map((log) => ({
        İsim: log.name,
        Soyisim: log.lastName,
        İşlem:
          log.operation === "Added"
            ? "Ekleme"
            : log.operation === "FreeUpload"
            ? "Bedelsiz Yükleme"
            : log.operation === "GetReturn"
            ? "İade"
            : "Tanımsız",
        Fatura_Numarası: log.info.billNo,
        Ürün_Kodu: log.info.productCode,
        Ürün_İsmi: log.info.productName,
        Miktar: log.info.quota,
        Gelir: log.info.income,
        Saat: formatToTurkeyTime(log.createdInfo),
        Tarih: formatToTurkeyDate(log.createdInfo),
      }));
    }

    return (
      <div className="space-y-4">
        <div
          className={`bg-gradient-to-r ${
            reportTypeColors[input.type]
          } rounded-lg p-6`}
        >
          <h2 className="text-2xl font-bold text-white text-center">
            {date} Tarihli {fileName[input.type]} Raporu
          </h2>
          <p className="text-white/80 text-center mt-2">
            Toplam {filteredItems.logs.length} kayıt bulundu
          </p>
        </div>
        <div className="flex justify-center gap-4">
          <ExportExcel excelData={excelData} fileName={fileName[input.type]} />
        </div>
        <div className="  overflow-hidden border">{output}</div>
      </div>
    );
  };

  const [currentPage, setCurrentPage] = useState(1);

  return (
    <>
      <SidebarList
        info={info}
        onClick={() => {
          setInfo(false);
          setSelectedDate(null);
        }}
      >
        {ReportReturn(groupAndMergeLogs(Datas, filterType), selectedDate)}
      </SidebarList>
      <div
        className={`${colors.background.main} p-6 mb-6 rounded-xl shadow-md`}
      >
        <div className="flex flex-col gap-6">
          {/* Filtreleme Tipi Butonları */}
          <div className="flex flex-wrap items-center gap-4">
            <div
              className={`${colors.background.light} p-1 rounded-lg flex gap-1`}
            >
              {[
                { type: "Day", label: "Günlük" },
                { type: "Month", label: "Aylık" },
                { type: "Year", label: "Yıllık" },
              ].map((item) => (
                <button
                  key={item.type}
                  onClick={() => handleFilterType(item.type)}
                  className={`
                    px-4 py-2 rounded-lg transition-all duration-200
                    ${
                      filterType === item.type
                        ? `bg-gradient-to-r ${colors.primary.gradient} ${colors.text.white} shadow-sm`
                        : `${colors.background.main} ${colors.primary.hoverBgLight}`
                    }
                  `}
                >
                  <span className="text-sm font-medium">{item.label}</span>
                </button>
              ))}
            </div>
            <div className="w-48 relative">
              <label
                className={`
                    absolute top-2 left-10 px-2 
                    ${colors.primary.text} 
                    text-sm font-medium
                  `}
              >
                Başlangıç
              </label>
              <div
                className={`
                  ${colors.background.main} 
                  rounded-lg 
                  border ${colors.primary.border} 
                  p-1
                `}
              >
                <CustomCalendar setDate={setDate} date={date} />
              </div>
            </div>
            <div className="w-48 relative">
              <label
                className={`
                    absolute top-2 left-10 px-2 
                    ${colors.primary.text} 
                    text-sm font-medium
                  `}
              >
                Bitiş
              </label>
              <div
                className={`
                  ${colors.background.main} 
                  rounded-lg 
                  border ${colors.primary.border} 
                  p-1
                `}
              >
                <CustomCalendar setDate={setDateSecond} date={dateSecond} />
              </div>
            </div>

            {/* Rapor Tipi Seçimi */}
            <div className="flex-1 min-w-[200px]">
              <SelectType
                label="Rapor Tipi"
                options={[
                  { label: "Tüketim", value: "Consument" },
                  { label: "Bilgi Değişikliği", value: "UpdateInfo" },
                  { label: "Fatura Hareketi", value: "UpdateBill" },
                ]}
                selectedValue={input.type}
                onChange={(value) => {
                  setInput({ ...input, type: value });
                }}
              />
            </div>
            <button
              onClick={handleGetLogData}
              className={`
                px-6 py-2 rounded-lg ${colors.text.white} font-medium
                bg-gradient-to-r ${colors.primary.gradient}
                hover:shadow-lg transform hover:-translate-y-0.5
                transition-all duration-200
                disabled:opacity-50 disabled:cursor-not-allowed
                disabled:hover:shadow-none disabled:hover:transform-none
                h-[42px]
              `}
              disabled={!input.type || !date || !dateSecond}
            >
              <span className="flex items-center gap-2">
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
                Getir
              </span>
            </button>
          </div>
        </div>
      </div>
      {/* Results */}
      <div className="bg-white rounded-xl shadow-md overflow-hidden">
        {isFetching || isLoading ? (
          <div className="flex justify-center items-center p-8">
            <MagnifyingGlass
              visible={true}
              height="100"
              width="100"
              ariaLabel="loading"
              glassColor="#c0efff"
              color={colors.primary.hex}
            />
          </div>
        ) : isError || Datas?.length === 0 ? (
          <UnfindDataWarn Data={"Rapor bulunamadı"} />
        ) : (
          <CustomReportTable
            data={groupAndMergeLogs(Datas, filterType)}
            reportType={input.type}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            itemsPerPage={10}
            setSelectedDate={setSelectedDate}
            setInfo={setInfo}
          />
        )}
      </div>
    </>
  );
}

export default ReportFirm;
