import { useNavigate } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import Loader from "../../Components/Utils/Loader";
import UnfindDataWarn from "../../Components/Utils/UnfindDataWarn";
import TopSearchInfo from "../../Components/SearchBar/TopSearchInfo";
import { useFirmData } from "../../Hooks/useFirmData";
import { colors } from "../../Config/Colors";
import Pagination from "../../Components/Table/Pagination";
import CustomFirmList from "../../Components/Table/CustomFirmList";

function FirmPage() {
  const navigate = useNavigate();
  const { auth } = useAuth();

  const {
    firms,
    isLoading,
    isFetching,
    error,
    paginationNumber,
    setPaginationNumber,
    filteredData,
    isSearch,
    handleSearch,
  } = useFirmData(auth.accessToken);

  const inputFieldName = {
    firmName: "Firma İsmi",
    mainFirmName: "Ana Firma İsmi",
    bayserNo: "Bayser Numarası",
  };

  return (
    <div className="container px-4 mb-20">
      <TopSearchInfo
        Length={firms.length}
        TopInfo={"Firma Listesi"}
        AddButton={() => navigate("Oluştur")}
        SearchBarData={firms}
        HandleSearch={handleSearch}
        SearchInputFieldName={inputFieldName}
      />

      <div className="flex justify-end mb-4">
        <Pagination
          currentPage={paginationNumber}
          totalCount={firms.length}
          pageSize={10}
          onPageChange={(page) => setPaginationNumber(page)}
        />
      </div>

      {isLoading || isFetching ? (
        <div className="flex w-full h-[400px] justify-center items-center">
          <Loader
            Visible={true}
            Width={140}
            Height={140}
            Color={colors.primary.hex}
          />
        </div>
      ) : error ? (
        <div className="flex w-full h-[400px] justify-center items-center">
          <UnfindDataWarn Data={"Firma bulunamadı!"} />
        </div>
      ) : (
        <CustomFirmList
          data={isSearch ? filteredData : firms}
          paginationNumber={paginationNumber}
          setPaginationNumber={setPaginationNumber}
        />
      )}
    </div>
  );
}

export default FirmPage;
