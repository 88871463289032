import { useState, useEffect, useRef } from "react";

import useAuth from "../Hooks/useAuth";
import Alerts from "../Components/Utils/Alert";
import Loader from "../Components/Utils/Loader";
import UnfindDataWarn from "../Components/Utils/UnfindDataWarn";
import BillList from "../Components/Bill/BillList";
import BillTopSearchBar from "../Components/Bill/BillTopSearchBar";
import useBillOperationData from "../Hooks/useBillOperationData";

function BillPage() {
  const { auth } = useAuth();
  const token = auth.accessToken;
  const [searchBar, setSearchBar] = useState(true);
  const inputFieldName = {
    billNo: "Fatura Numarası",
    firmApellation: "Firma Ünvanı",
    bayserNo: "Bayser Numarası",
  };
  const hiddenFileInput = useRef(null);
  const [filteredData, setFilteredData] = useState("");
  const [isSearch, setIsSearch] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const {
    alert,
    message,
    state,
    setState,
    resultCreate,
    controll,
    resultControll,
    HandleUpload,
    sortData,
  } = useBillOperationData(token);

  useEffect(() => {
    setIsSearch(false);
  }, []);

  const hideSearchBar = () => {
    setSearchBar(searchBar === true ? false : true);
  };

  const handleSearch = (data, isSearch) => {
    setFilteredData(data);
    setIsSearch(isSearch);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="min-h-screen px-16 max-md:px-0">
      {alert !== 0 && (
        <div className="fixed z-10 left-1/2 top-0 -translate-x-1/2">
          <Alerts AlertChoice={alert} message={message} />
        </div>
      )}
      <div className="w-full inline-block align-middle">
        <BillTopSearchBar
          resultControll={resultControll}
          hiddenFileInput={hiddenFileInput}
          HandleUpload={HandleUpload}
          searchBar={searchBar}
          hideSearchBar={hideSearchBar}
          state={state}
          setState={setState}
          resultCreate={resultCreate}
          handleSearch={handleSearch}
          inputFieldName={inputFieldName}
          controll={controll}
          token={token}
        />

        {resultControll?.isLoading || resultCreate?.isLoading ? (
          <div className=" flex w-full h-full justify-center items-center">
            <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
          </div>
        ) : resultControll.data?.results === 0 || !resultControll?.isSuccess ? (
          <UnfindDataWarn Data={"Fatura Oluşturulmadı"} />
        ) : (
          <BillList
            sortData={sortData}
            isSearch={isSearch}
            filteredData={filteredData}
            resultCreate={resultCreate}
            resultControll={resultControll}
            sortConfig={{
              key: null,
              direction: "ascending",
            }}
            currentPage={currentPage}
            pageSize={pageSize}
            state={state}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
}

export default BillPage;
