import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useAuth from "../../../../Hooks/useAuth";
import DeviceSettingEditForm from "../../../../Forms/DeviceForm/DeviceSettingEditForm";
import SettingPageContainer from "./SettingPageContainer";
import { colors } from "../../../../Config/Colors";
import useDeviceSettingData from "../../../../Hooks/useDeviceSettingData";
import useUpdateDeviceSettingData from "../../../../Hooks/useUpdateDeviceSettingData";

const generateInitialFValues = (settings) => {
  return Object.keys(settings).reduce((acc, key) => {
    acc[key] = settings[key].value;
    return acc;
  }, {});
};

const secondGenerateInitialFValues = (settings) => {
  return Object.keys(settings).reduce((acc, key) => {
    if (key !== "index") {
      acc[key] = settings[key];
    }
    // Note: No need for an else block; just return acc directly
    return acc; // Always return the accumulator
  }, {}); // Initial value of the accumulator is an empty object
};

const getChangedValues = (original, initial, updated) => {
  const changes = { index: updated.index };
  const label = {};
  const lastValue = {};
  for (const key in initial) {
    if (updated.hasOwnProperty(key)) {
      if (initial[key] !== updated[key]) {
        if (original[key].type === "Number") {
          changes[key] = Number(updated[key]);
          lastValue[key] = Number(initial[key]);
        } else {
          changes[key] = updated[key];
          lastValue[key] = initial[key];
        }
        label[key] = original[key].label;
      }
    }
  }

  return { changes, label, lastValue };
};

export default function CupSetting() {
  const { auth } = useAuth();
  const token = auth.accessToken;
  const location = useLocation();
  const [CupSetting, setCupSetting] = useState({});
  const [CupIndex, setCupIndex] = useState(0);
  const [DesignedCupSetting, setDesignedCupSetting] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [alert, setAlert] = useState(0);
  const [message, setMessage] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [inputFieldName, setInputFieldName] = useState({});
  const [initialFValues, setInitialFValues] = useState({});

  const {
    getDeviceSetting,
    deviceSettingData,
    deviceSettingIsLoading,
    deviceSettingStatus,
    deviceSettingError,
  } = useDeviceSettingData({ setConfirm, setAlert, setMessage });

  const {
    updateDeviceSetting,
    updateDeviceSettingIsLoading,
    updateDeviceSettingIsSuccess,
    updateDeviceSettingIsError,
    updateDeviceSettingError,
  } = useUpdateDeviceSettingData({ setAlert, setMessage });

  useEffect(() => {
    if (updateDeviceSettingIsSuccess && confirm) {
      const tempVal = secondGenerateInitialFValues({
        ...DesignedCupSetting[CupIndex],
      });
      setInitialFValues(tempVal);
    }
  }, [updateDeviceSettingIsSuccess]);
  useEffect(() => {
    if (CupSetting.pots && CupSetting.pots.length > 0) {
      if (DesignedCupSetting[CupIndex]) {
        const tempVal = secondGenerateInitialFValues({
          ...DesignedCupSetting[CupIndex],
        });
        setInitialFValues(tempVal);
      }
    }
  }, [CupIndex]);

  useEffect(() => {
    getDeviceSetting({
      id: location.state.id,
      token: token,
      type: "CS",
    });
  }, []);

  const handleCloseModel = (boolean) => {
    if (boolean) {
      setConfirm(true);
      const changes = getChangedValues(
        CupSetting.pots[CupIndex],
        initialFValues,
        DesignedCupSetting[CupIndex]
      );
      updateDeviceSetting({
        id: location.state.id,
        token,
        key: "cupSetting",
        type: "CS",
        data: changes,
      });
    }
    setShowConfirmModal(false);
  };

  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        setAlert(0);
        setMessage("");
      }, 3000);
    }
  }, [alert]);

  useEffect(() => {
    if (deviceSettingStatus === "fulfilled") {
      const tempCupSetting = { ...deviceSettingData.data?.settings };
      setCupSetting(tempCupSetting);
      const tempSetting = [...DesignedCupSetting];
      console.log(tempCupSetting);
      tempCupSetting.pots.forEach((element, index) => {
        const tempVal = generateInitialFValues(element);
        const obj = element;
        const objvalues = Object.values(obj);
        tempSetting[index] = {
          ...tempVal,
          index: objvalues[0].index,
        };
        if (CupIndex === index) {
          setInitialFValues(tempVal);
        }
      });
      setDesignedCupSetting(tempSetting);
    }
  }, [deviceSettingStatus]);

  return (
    <SettingPageContainer
      isLoading={deviceSettingIsLoading || confirm || alert !== 0}
      isError={deviceSettingError}
      alert={{
        show: alert !== 0,
        type: alert,
        message: message,
      }}
      confirmProps={{
        input: DesignedCupSetting[CupIndex],
        inputFieldName: inputFieldName,
        infoText: "Bilgileri kaydetmek istediğinize emin misiniz?",
        handleCloseModel: handleCloseModel,
        showConfirmModal: showConfirmModal,
      }}
      onRefresh={() =>
        getDeviceSetting({
          id: location.state.id,
          token: token,
          type: "CS",
        })
      }
    >
      <div className="flex flex-col gap-8">
        {/* Göz Seçim Butonları */}
        <div className="flex flex-wrap gap-4">
          {CupSetting?.pots?.map((pot, index) => (
            <button
              key={index}
              onClick={() => setCupIndex(index)}
              className={`
                px-6 py-3 rounded-xl text-xl font-medium
                transition-all duration-300
                ${
                  CupIndex === index
                    ? `${colors.primary.bg} text-white shadow-lg`
                    : `${colors.background.light} ${colors.text.primary} hover:shadow-md`
                }
              `}
            >
              Göz {index + 1}
            </button>
          ))}
        </div>

        {/* Form Alanı */}
        {CupSetting.pots && CupSetting.pots.length > 0 && (
          <DeviceSettingEditForm
            Settings={CupSetting?.pots[CupIndex]}
            setDesignedInput={(values) => {
              const obj = CupSetting.pots[CupIndex];
              const objvalues = Object.values(obj);
              const tempSetting = [...DesignedCupSetting];
              tempSetting[CupIndex] = {
                ...DesignedCupSetting[CupIndex],
                ...values,
                index: objvalues[0].index,
              };
              setDesignedCupSetting(tempSetting);
            }}
            setInputFieldName={setInputFieldName}
            setShowConfirmModal={setShowConfirmModal}
            initialFValues={initialFValues}
          />
        )}
      </div>
    </SettingPageContainer>
  );
}
