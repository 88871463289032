import React from "react";
import Controls from "../../Components/Form/Controls";
import { useForm } from "../../Components/Form/useForm";
import { colors } from "../../Config/Colors";
import { IoReceiptOutline } from "react-icons/io5";
import { BiPackage } from "react-icons/bi";
import { MdOutlineNumbers } from "react-icons/md";
import { Modal, Backdrop, Fade, Box } from "@mui/material";

export default function BillQuotaDesignForm({
  HandleModal,
  initialFValues,
  type = "",
  ProductDatas = [],
  showModal = false,
  onClose,
}) {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    bgcolor: "background.paper",
    borderRadius: "12px",
    boxShadow: 24,
    outline: "none",
  };

  const ProductDataOptions = [
    ...ProductDatas?.map((option) => {
      return { quota: option.quota, title: option.productName };
    }),
  ];

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("productName" in fieldValues)
      temp.productName = fieldValues.productName ? "" : "Ürün seçiniz.";
    if ("quota" in fieldValues)
      temp.quota = fieldValues.quota ? "" : "Kota değerini girin.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      HandleModal(values);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  return (
    <Modal
      open={showModal}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            backdropFilter: "blur(4px)",
          },
        },
      }}
    >
      <Fade in={showModal}>
        <Box sx={modalStyle}>
          <div className="bg-white rounded-xl overflow-hidden">
            {/* Başlık */}
            <div
              className={`flex items-center gap-2 p-4 border-b ${colors.background.main}`}
            >
              <IoReceiptOutline className={`w-8 h-8 ${colors.primary.text}`} />
              <h3 className="text-xl font-semibold text-gray-800">{type}</h3>
            </div>

            {/* Form */}
            <form onSubmit={handleSubmit} className="flex flex-col p-6 gap-6">
              {/* Ürün Seçimi */}
              <div className={`${colors.background.light} p-4 rounded-xl`}>
                <div className="flex items-center gap-2 mb-2">
                  <BiPackage className={`${colors.text.tertiary} w-5 h-5`} />
                  <span className={`text-xl ${colors.text.tertiary}`}>
                    Ürün Seçimi
                  </span>
                </div>
                <Controls.SelectOptions
                  name="productName"
                  value={values.productName}
                  onChange={handleInputChange}
                  options={ProductDataOptions}
                  error={errors.productName}
                  className="text-xl"
                />
              </div>

              {/* Kota Miktarı */}
              <div className={`${colors.background.light} p-4 rounded-xl`}>
                <div className="flex items-center gap-2 mb-2">
                  <MdOutlineNumbers
                    className={`${colors.text.tertiary} w-5 h-5`}
                  />
                  <span className={`text-xl ${colors.text.tertiary}`}>
                    Kota Miktarı
                  </span>
                </div>
                <Controls.Number
                  name="quota"
                  value={values.quota}
                  onChange={handleInputChange}
                  error={errors.quota}
                  min={0}
                  max={10000}
                  className="text-xl"
                />
              </div>

              {/* Buton */}
              <button
                type="submit"
                className={`
                  ${colors.primary.bg} text-white
                  px-6 py-3 rounded-lg
                  text-xl font-medium
                  hover:shadow-lg transform hover:scale-[1.02]
                  transition-all duration-300
                  disabled:opacity-50 disabled:cursor-not-allowed
                  w-full
                `}
                disabled={!values.productName || !values.quota}
              >
                Faturayı Oluştur
              </button>
            </form>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
