import React from "react";
import { NavLink } from "react-router-dom";
import { BsFillPencilFill } from "react-icons/bs";
import { colors } from "../../Config/Colors";
import FormatDateHourString from "../Utils/FormatDateHourString";
import { FaClock } from "react-icons/fa";
import { MdNumbers } from "react-icons/md";
import { TbReportAnalytics } from "react-icons/tb";

export default function CustomDeviceList({ data }) {
  return (
    <div className="grid grid-cols-2 max-md:grid-cols-1 gap-4">
      {data.map((device) => (
        <div
          key={device._id}
          className="bg-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden"
        >
          {/* Başlık ve Aksiyon Butonları */}
          <div
            className={`flex justify-between items-center p-4 border-b ${colors.background.main}`}
          >
            <div className="flex items-center gap-3 flex-wrap">
              <h3 className="text-lg font-semibold text-gray-800">
                {device.deviceName}
              </h3>
              <div className="flex gap-2 items-center">
                <span
                  className={`px-3 py-1 rounded-full text-lg font-semibold
                  ${
                    device.isActive
                      ? `${colors.status.success.bg} ${colors.status.success.text}`
                      : `${colors.status.inactive.bg} ${colors.status.inactive.text}`
                  }`}
                >
                  {device.isActive ? "Aktif" : "Pasif"}
                </span>
                <span
                  className={`
                  px-3 py-1 rounded-full text-lg font-semibold
                  ${colors.background.light} ${colors.primary.text}
                `}
                >
                  {device.deviceStatusName}
                </span>
              </div>
            </div>

            <div className="flex gap-2">
              <NavLink
                to={`/Anasayfa/Cihaz/Düzenle/Bilgi`}
                state={{
                  id: device._id,
                  deviceName: device.deviceName,
                  deviceNo: device.deviceNo,
                }}
              >
                <button
                  className={`p-2 rounded-lg ${colors.primary.text} ${colors.primary.hover} hover:text-white transition-all duration-300`}
                >
                  <BsFillPencilFill className="w-6 h-6" />
                </button>
              </NavLink>

              <NavLink
                to={`/Anasayfa/Cihaz/Bilgi/Genel`}
                state={{
                  id: device._id,
                  serialNo: device.serialNo,
                  deviceName: device.deviceName,
                  createdInfo: device.createdInfo,
                  updatedInfo: device.updatedInfo,
                  isActive: device.isActive,
                }}
              >
                <button
                  className={`p-2 rounded-lg ${colors.primary.text} ${colors.primary.hover} hover:text-white transition-all duration-300`}
                >
                  <TbReportAnalytics className="w-6 h-6" />
                </button>
              </NavLink>
            </div>
          </div>

          {/* Cihaz Detayları */}
          <div className="p-4 grid grid-cols-2 gap-4">
            {/* Seri No ve Son Bağlantı */}
            <div>
              <div
                className={`${colors.background.light} p-4 rounded-lg h-full`}
              >
                <h4
                  className={`${colors.primary.text} font-medium mb-3 text-xl`}
                >
                  Cihaz Bilgileri
                </h4>
                <div className="grid grid-cols-1 gap-3">
                  <div
                    className={`${colors.background.main} p-3 rounded-lg shadow-sm border ${colors.border.light}`}
                  >
                    <div className="flex items-center gap-2">
                      <MdNumbers
                        className={`${colors.text.tertiary} w-5 h-5`}
                      />
                      <span className={`text-lg ${colors.text.tertiary}`}>
                        Seri No
                      </span>
                    </div>
                    <p
                      className={`text-lg mt-2 font-semibold ${colors.primary.text}`}
                    >
                      {device.serialNo}
                    </p>
                  </div>
                  <div
                    className={`${colors.background.main} p-3 rounded-lg shadow-sm border ${colors.border.light}`}
                  >
                    <div className="flex items-center gap-2">
                      <FaClock className={`${colors.text.tertiary} w-5 h-5`} />
                      <span className={`text-lg ${colors.text.tertiary}`}>
                        Son Bağlantı
                      </span>
                    </div>
                    <p
                      className={`text-lg mt-2 font-semibold ${colors.primary.text}`}
                    >
                      {FormatDateHourString(device.lastConnectionDate)}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Kota Bilgileri */}
            <div>
              <div
                className={`${colors.background.light} p-4 rounded-lg h-full`}
              >
                <h4
                  className={`${colors.primary.text} font-medium mb-3 text-xl`}
                >
                  Kota Bilgileri
                </h4>
                <div className="grid grid-cols-1 gap-3">
                  {device.productInfo.productInfo.map((item, index) => (
                    <div
                      key={index}
                      className={`${colors.background.main} p-3 rounded-lg shadow-sm border ${colors.border.light}`}
                    >
                      <div className="flex items-center gap-2">
                        <span className={`text-lg ${colors.text.tertiary}`}>
                          {item.productName}
                        </span>
                      </div>
                      <p
                        className={`text-lg mt-2 font-semibold ${colors.primary.text}`}
                      >
                        {item.quota}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
