import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// Icon
import { colors } from "../../../Config/Colors";
import { useGetDeviceMutation } from "../../../store/devicesApi";

import useAuth from "../../../Hooks/useAuth";
import DeviceInfoList from "../../../Components/Device/DeviceInfoList";
import DeviceProductCards from "../../../Components/Device/DeviceProductCards";
import UnfindDataWarn from "../../../Components/Utils/UnfindDataWarn";
import Loader from "../../../Components/Utils/Loader";

function InfoDevicePage() {
  const location = useLocation();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const id = location.state.id;

  const [
    getDevice,
    {
      data: deviceData,
      isLoading: deviceIsLoading,
      status: deviceStatus,
      error: deviceError,
    },
  ] = useGetDeviceMutation();

  const handleFetchDevice = async () => {
    try {
      await getDevice({ id, token }).unwrap();
    } catch (error) {
      // Hata durumunda yapılacak işlemler
    }
  };

  const [input, setInput] = useState({
    name: "",
    ip: "",
    gsmNo: "",
    serialNo: "",
    imei: "",
    userPassword: "",
    adminPassword: "",
    note: "",
    productInfo: [],
  });

  useEffect(() => {
    handleFetchDevice(); // Sayfa açıldığında otomatik olarak cihaz verilerini al
  }, []); // Boş bağımlılık dizisi ile sadece bir kez çalışır

  useEffect(() => {
    if (deviceStatus === "fulfilled" && deviceData) {
      const Data = deviceData.data?.device || [];
      setInput({ ...Data });
    }
  }, [deviceStatus, deviceData]);

  return (
    <div className="container">
      {deviceIsLoading ? (
        <div className="flex w-full h-full justify-center items-center">
          <Loader
            Visible={true}
            Width={140}
            Height={140}
            Color={colors.primary.main}
          />
        </div>
      ) : deviceError || (deviceData && deviceData.data?.results === 0) ? (
        <div className="fixed top-40 left-0 flex w-full h-fit justify-center items-center">
          <UnfindDataWarn Data={"Cihaz bulunamadı"} />
        </div>
      ) : (
        <div className="flex flex-col gap-8">
          <DeviceInfoList input={input} />
          <DeviceProductCards input={input} />
        </div>
      )}
    </div>
  );
}

export default InfoDevicePage;
