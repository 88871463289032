import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import URL from "../url";
import { createRequestData } from "./utils";

const deviceLogApi = createApi({
  reducerPath: "deviceLog",
  baseQuery: fetchBaseQuery({
    baseUrl: `${URL.url}`,
    fetchFn: async (...args) => {
      // REMOVE FOR PRODUCTION
      return fetch(...args);
    },
  }),
  tagTypes: ["DeviceLog"],
  endpoints(builder) {
    return {
      getDeviceConsumentLogReport: builder.mutation({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/deviceLog/report/consument`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
      }),
      getDeviceLogReport: builder.mutation({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });

          return {
            url: `/deviceLog/allReport`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
      }),
    };
  },
});

export const {
  useGetDeviceConsumentLogReportMutation,
  useGetDeviceLogReportMutation,
} = deviceLogApi;
export { deviceLogApi };
