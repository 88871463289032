import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { colors } from "../../Config/Colors";

export default function SelectType({
  label = "Age",
  options = [],
  selectedValue,
  onChange,
}) {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel
        id="select-label"
        sx={{
          fontSize: "18px",
          color: "#64748B", // colors.text.secondary
          "&.Mui-focused": {
            color: "#93C6E7", // colors.primary.hex
          },
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId="select-label"
        id="select-id"
        value={selectedValue}
        label={label}
        onChange={handleChange}
        sx={{
          backgroundColor: "white",
          fontSize: "18px",
          fontWeight: 500,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#E2E8F0", // colors.border.light
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#93C6E7", // colors.primary.hex
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#93C6E7", // colors.primary.hex
          },
          "& .MuiSelect-select": {
            color: "#334155", // colors.text.primary
          },
          "& .MuiSvgIcon-root": {
            color: "#64748B", // colors.text.secondary
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option.value}
            sx={{
              fontSize: "18px",
              color: "#334155", // colors.text.primary
              "&:hover": {
                backgroundColor: "#F8FAFC", // colors.background.lighter
              },
              "&.Mui-selected": {
                backgroundColor: "#93C6E740", // colors.primary.hex with opacity
                "&:hover": {
                  backgroundColor: "#93C6E720", // colors.primary.hex with less opacity
                },
              },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
