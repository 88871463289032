import { useState, useCallback } from "react";
import useAuth from "../../../Hooks/useAuth";

// Icon
import { useGetFirmProductsQuery } from "../../../store";
import { useLocation } from "react-router-dom";
import Alerts from "../../../Components/Utils/Alert";
import Confirm from "../../../Components/Utils/Confirm";
import UnfindDataWarn from "../../../Components/Utils/UnfindDataWarn";
import Loader from "../../../Components/Utils/Loader";
import DataSearchBar from "../../../Components/SearchBar/DataSearchBar";
import BillQuotaDesignForm from "../../../Forms/FirmForm/BillQuotaDesignForm";
import { useFirmBillsData } from "../../../Hooks/useFirmBillsData";
import CustomBillList from "../../../Components/Table/CustomBillList";
import { colors } from "../../../Config/Colors";
import { IoAddCircleOutline } from "react-icons/io5";
import { TbFileExport } from "react-icons/tb";
import { Tooltip } from "react-tooltip";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

function UpdateBill() {
  const location = useLocation();
  const { auth } = useAuth();
  const id = location.state.id;

  const [giveQuota, setQuotaInfo] = useState({ quota: 0, productTypeName: "" });
  const [deleteInput, setDeleteInput] = useState("");
  const [inputFieldName, setInputFieldName] = useState({
    billNo: "Fatura Numarası",
  });
  const [infoText, setInfoText] = useState("");
  const [giveQuotaDesignModal, setGiveQuotaDesignModal] = useState(false);
  const [takeBackQuotaDesignModal, setTakeBackQuotaDesignModal] =
    useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [action, setAction] = useState(0);
  const [paginationNumber, setPaginationNumber] = useState(1);
  const pageSize = 4;

  const {
    billsData,
    billsIsLoading,
    billsIsFetching,
    billsError,
    productDatas,
    productsIsLoading,
    deleteIsLoading,
    takebackIsLoading,
    giveQuotaIsLoading,
    setIsSearch,
    handleSearch,
    alert,
    message,
    deleteBill,
    updateGiveQuota,
    updateTakebackQuota,
  } = useFirmBillsData({ id, token: auth.accessToken });

  const responseProducts = useGetFirmProductsQuery({
    id,
    token: auth.accessToken,
  });
  const ProductDatas = responseProducts.data?.data || [];

  // İşlem sırasında loading durumunu kontrol et
  const isProcessing =
    deleteIsLoading || takebackIsLoading || giveQuotaIsLoading;
  const isLoading = billsIsLoading || billsIsFetching || productsIsLoading;

  const handleCloseModel = useCallback(
    (boolean) => {
      if (boolean) {
        setGiveQuotaDesignModal(false);
        setTakeBackQuotaDesignModal(false);

        if (action === 1) {
          updateGiveQuota(giveQuota);
        } else if (action === 2) {
          deleteBill({ id: deleteInput.id, token: auth.accessToken });
        } else if (action === 3) {
          updateTakebackQuota(giveQuota);
        }
      }
      setShowConfirmModal(false);
      setAction(0);
      setIsSearch(false);
    },
    [
      action,
      giveQuota,
      deleteInput?.id,
      auth.accessToken,
      updateGiveQuota,
      updateTakebackQuota,
      deleteBill,
      setIsSearch,
    ]
  );

  const handleDelete = (bill) => {
    setAction(2);
    setDeleteInput({
      id: bill._id,
      token: auth.accessToken,
      billNo: bill.billNo,
      message: "Faturayı silmek isteğinize emin misiniz ?",
    });
    setShowConfirmModal(true);
  };

  // Faturaları tarihine göre sıralama
  const sortedBillsData = [...billsData].sort(
    (a, b) => new Date(b.createdInfo) - new Date(a.createdInfo)
  );

  // Gösterilecek faturaları belirleme
  const startIndex = (paginationNumber - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const displayedBills = sortedBillsData.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedBillsData.length / pageSize);

  const getPageNumbers = (paginationNumber) => {
    const pageNumbers = [];
    let startPage = Math.max(1, paginationNumber - 1);
    let endPage = Math.min(totalPages, startPage + 2);

    if (startPage === 1) {
      endPage = Math.min(totalPages, 3);
    }

    if (totalPages < 3) {
      startPage = 1;
      endPage = totalPages;
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <div className="grid w-full">
      {alert !== 0 && (
        <div className="fixed z-50 left-1/2 transform -translate-x-1/2 top-12">
          <Alerts AlertChoice={alert} message={message} />
        </div>
      )}

      {/* İşlem sırasında loading overlay */}
      {isProcessing && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      )}

      <Confirm
        input={action === 1 ? giveQuota : {}}
        inputFieldName={inputFieldName}
        infoText={infoText}
        handleCloseModel={handleCloseModel}
        showConfirmModal={showConfirmModal}
        isLoading={isProcessing}
      />

      <div className="fixed z-30 right-6 top-1/2 -translate-y-1/2 flex flex-col gap-4">
        <div>
          <button
            data-tooltip-id="add-quota"
            data-tooltip-content="Kota Bedelsiz Yükle"
            data-tooltip-place="left"
            onClick={() => setGiveQuotaDesignModal(true)}
            className={`
              p-3 rounded-full shadow-lg
              ${colors.primary.bg} text-white
              hover:shadow-xl transform hover:scale-110
              transition-all duration-300
              flex items-center justify-center
            `}
          >
            <IoAddCircleOutline className="w-8 h-8" />
          </button>
          <Tooltip
            id="add-quota"
            className={`
              !bg-white !text-gray-800
              !px-4 !py-2 !rounded-lg !shadow-lg
              !font-medium !text-xl
              !border ${colors.border.light}
            `}
          />
        </div>

        <div>
          <button
            data-tooltip-id="return-quota"
            data-tooltip-content="Kota İade Al"
            data-tooltip-place="left"
            onClick={() => setTakeBackQuotaDesignModal(true)}
            className={`
              p-3 rounded-full shadow-lg
              bg-white ${colors.primary.text}
              hover:shadow-xl transform hover:scale-110
              transition-all duration-300
              flex items-center justify-center
              border ${colors.border.light}
            `}
          >
            <TbFileExport className="w-8 h-8" />
          </button>
          <Tooltip
            id="return-quota"
            className={`
              !bg-white !text-gray-800
              !px-4 !py-2 !rounded-lg !shadow-lg
              !font-medium !text-xl
              !border ${colors.border.light}
            `}
          />
        </div>
      </div>

      {isLoading ? (
        <div className="flex w-full h-screen justify-center items-center">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : billsError || sortedBillsData.length === 0 ? (
        <div className="fixed top-0 left-0 flex w-full h-full justify-center items-center -z-10">
          <UnfindDataWarn Data={"Fatura bulunamadı !"} />
        </div>
      ) : (
        <>
          <div className="bg-white w-full rounded-xl shadow-xl transition-all duration-300 mb-4 mt-10 block p-10">
            <DataSearchBar
              Data={sortedBillsData}
              handleSearch={handleSearch}
              inputFieldName={inputFieldName}
            />
            <div className="flex flex-wrap gap-4 mt-6">
              {productDatas?.map((product) => (
                <div
                  key={product._id}
                  className={`${colors.primary.bgLight} ${colors.primary.text} p-4 rounded-lg shadow-sm`}
                >
                  <span className=" text-2xl font-medium">
                    {product.productName} :{" "}
                  </span>
                  <span className="ml-2 text-2xl font-bold">
                    {product.quota}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="flex bg-white rounded-xl shadow-xl justify-center items-center mb-4 p-4">
            {/* İlk Sayfa */}
            <button
              onClick={() => setPaginationNumber(1)}
              disabled={paginationNumber === 1}
              className={`flex items-center justify-center p-2 rounded-lg transition duration-300 ${
                paginationNumber === 1
                  ? "text-gray-400 cursor-not-allowed"
                  : `${colors.primary.text} hover:${colors.primary.bg} hover:text-white`
              }`}
            >
              <RxDoubleArrowLeft className="w-5 h-5" />
            </button>

            {/* Önceki Sayfa */}
            <button
              onClick={() =>
                setPaginationNumber((prev) => Math.max(prev - 1, 1))
              }
              disabled={paginationNumber === 1}
              className={`flex items-center justify-center p-2 rounded-lg transition duration-300 ${
                paginationNumber === 1
                  ? "text-gray-400 cursor-not-allowed"
                  : `${colors.primary.text} hover:${colors.primary.bg} hover:text-white`
              }`}
            >
              <IoIosArrowBack className="w-5 h-5" />
            </button>

            {/* Sayfa Numaraları */}
            {getPageNumbers(paginationNumber).map((pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => setPaginationNumber(pageNumber)}
                className={`min-w-[40px] h-10 rounded-lg font-medium transition duration-300 ${
                  pageNumber === paginationNumber
                    ? `${colors.primary.bg} text-white`
                    : `${colors.primary.text} hover:${colors.primary.bg} hover:text-white`
                }`}
              >
                {pageNumber}
              </button>
            ))}

            {/* Sonraki Sayfa */}
            <button
              onClick={() =>
                setPaginationNumber((prev) => Math.min(prev + 1, totalPages))
              }
              disabled={paginationNumber === totalPages}
              className={`flex items-center justify-center p-2 rounded-lg transition duration-300 ${
                paginationNumber === totalPages
                  ? "text-gray-400 cursor-not-allowed"
                  : `${colors.primary.text} hover:${colors.primary.bg} hover:text-white`
              }`}
            >
              <IoIosArrowForward className="w-5 h-5" />
            </button>

            {/* Son Sayfa */}
            <button
              onClick={() => setPaginationNumber(totalPages)}
              disabled={paginationNumber === totalPages}
              className={`flex items-center justify-center p-2 rounded-lg transition duration-300 ${
                paginationNumber === totalPages
                  ? "text-gray-400 cursor-not-allowed"
                  : `${colors.primary.text} hover:${colors.primary.bg} hover:text-white`
              }`}
            >
              <RxDoubleArrowRight className="w-5 h-5" />
            </button>
          </div>
          <div className="mb-20">
            <CustomBillList
              data={displayedBills}
              onDelete={handleDelete}
              currentPage={paginationNumber}
              totalCount={sortedBillsData.length}
              pageSize={pageSize}
              onPageChange={setPaginationNumber}
            />
          </div>
        </>
      )}

      <BillQuotaDesignForm
        HandleModal={(input) => {
          setQuotaInfo(input);
          setShowConfirmModal(true);
          setAction(giveQuotaDesignModal ? 1 : 3);
          setGiveQuotaDesignModal(false);
          setTakeBackQuotaDesignModal(false);
          setInputFieldName({
            productName: "Ürün İsmi",
            quota: giveQuotaDesignModal
              ? "Yüklenecek Kota"
              : "Düşürülecek Kota",
          });
          setInfoText(
            giveQuotaDesignModal
              ? "Bu kota firmaya yüklenecek bundan emin misiniz ?"
              : "Bu kota firmadan düşürülecek bundan emin misiniz ?"
          );
        }}
        initialFValues={{ productName: "", quota: 0 }}
        Token={auth.accessToken}
        id={id}
        type={giveQuotaDesignModal ? "Kota Yükleme" : "Kota İade"}
        ProductDatas={ProductDatas}
        showModal={giveQuotaDesignModal || takeBackQuotaDesignModal}
        onClose={() => {
          setShowConfirmModal(false);
          setGiveQuotaDesignModal(false);
          setTakeBackQuotaDesignModal(false);
          setAction(0);
        }}
      />
    </div>
  );
}

export default UpdateBill;
