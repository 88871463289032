import { colors } from "../../Config/Colors";

export default function FilterButton({ button, handleClick, active }) {
  const baseStyle = "transition-all duration-300";
  const activeStyle = `bg-white ${colors.primary.text} border-${colors.primary.main}`;
  const passiveStyle = `${colors.primary.bg} text-white border-white hover:bg-white hover:${colors.primary.text}`;

  const buttons = [
    { id: "today", label: "Bugün" },
    { id: "yesterday", label: "Dün" },
    { id: "thisweek", label: "Haftalık" },
    { id: "thismonth", label: "Aylık" },
    { id: "thisyear", label: "Yıllık" },
  ];

  return (
    <div className="flex gap-2 flex-wrap justify-end">
      {buttons.map(({ id, label }) => (
        <button
          key={id}
          onClick={() => handleClick(id)}
          className={`
            ${baseStyle}
            ${button}
            ${active === id ? activeStyle : passiveStyle}
            border
          `}
        >
          {label}
        </button>
      ))}
    </div>
  );
}
