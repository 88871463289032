import { useState } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { BiPlusMedical } from "react-icons/bi";
import { IoFilterSharp } from "react-icons/io5";
import DataSearchBar from "./DataSearchBar";
import { colors } from "../../Config/Colors";

export default function TopSearchInfo({
  Length,
  TopInfo,
  AddButton,
  SearchBarData,
  HandleSearch,
  SearchInputFieldName,
}) {
  const [searchBar, setSearchBar] = useState(true);

  return (
    <div className="w-full flex flex-col gap-4">
      {/* Header Section */}
      <div className="flex items-center justify-between bg-white p-4 rounded-xl shadow-md">
        {/* Left Side - Title and Count */}
        <div className="flex items-center gap-3">
          <h2 className="text-2xl font-semibold text-gray-800">{TopInfo}</h2>
          <span
            className={`
            px-3 py-1 rounded-full text-xl font-semibold
            ${colors.primary.bg} text-white
          `}
          >
            {Length}
          </span>
        </div>

        {/* Right Side - Buttons */}
        <div className="flex items-center gap-3">
          {/* Add Button */}
          <button
            onClick={AddButton}
            className={`
              flex items-center gap-2 px-4 py-2
              ${colors.primary.bg} text-white
              rounded-lg font-medium
              transition-all duration-300
              hover:shadow-lg transform hover:scale-[1.02]
              active:scale-95
            `}
          >
            <span>Ekle</span>
            <BiPlusMedical className="w-5 h-5" />
          </button>

          {/* Filter Button */}
          <button
            onClick={() => setSearchBar(!searchBar)}
            className={`
              flex items-center gap-2 px-4 py-2
              ${colors.primary.bg} text-white
              rounded-lg font-medium
              transition-all duration-300
              hover:shadow-lg transform hover:scale-[1.02]
              active:scale-95
              max-md:hidden
            `}
          >
            <span>Filtrele</span>
            {searchBar ? (
              <IoIosArrowUp className="w-5 h-5 transition-transform duration-300" />
            ) : (
              <IoIosArrowDown className="w-5 h-5 transition-transform duration-300" />
            )}
          </button>
        </div>
      </div>

      {/* Search Bar Section */}
      <div
        className={`
        bg-white rounded-xl shadow-md
        transition-all duration-300 ease-in-out
        ${
          searchBar
            ? "max-h-[500px] opacity-100 p-6"
            : "max-h-0 opacity-0 overflow-hidden"
        }
      `}
      >
        <DataSearchBar
          Data={SearchBarData}
          handleSearch={HandleSearch}
          inputFieldName={SearchInputFieldName}
        />
      </div>
    </div>
  );
}
