import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import URL from "../url";

const reportsApi = createApi({
  reducerPath: "reports",
  baseQuery: fetchBaseQuery({
    baseUrl: `${URL.url}`,
    fetchFn: async (...args) => {
      // REMOVE FOR PRODUCTION
      return fetch(...args);
    },
  }),
  endpoints(builder) {
    return {
      getConsumentReport: builder.query({
        query: ({ token, startDate, endDate, timeZone }) => {
          return {
            url: `/reports/general/generalConsument?startDate=${startDate}&endDate=${endDate}&timeZone=${timeZone}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
      }),
      getFirmConsumentDailyReport: builder.query({
        query: ({
          token,
          playMakerId,
          productName,
          firmId,
          year,
          month,
          timeZone,
        }) => {
          return {
            url: `/reports/firm/dailyConsument?playMakerId=${playMakerId}&productName=${productName}&firmId=${firmId}&year=${year}&month=${month}&timeZone=${timeZone}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
      }),
      getFirmConsumentMonthlyReport: builder.query({
        query: ({
          token,
          playMakerId,
          productName,
          firmId,
          year,
          timeZone,
        }) => {
          return {
            url: `/reports/firm/monthlyConsument?playMakerId=${playMakerId}&productName=${productName}&firmId=${firmId}&year=${year}&timeZone=${timeZone}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
      }),
      getFirmRemainderReport: builder.query({
        query: ({
          token,
          playMakerId,
          productName,
          lowerLimit,
          upperLimit,
          firmId,
        }) => {
          return {
            url: `/reports/firm/firmRemainder?playMakerId=${playMakerId}&productName=${productName}&lowerLimit=${lowerLimit}&upperLimit=${upperLimit}&firmId=${firmId}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
      }),
      getFirmTotalConsumentReport: builder.query({
        query: ({
          token,
          startDate,
          endDate,
          timeZone,
          productName,
          firmId,
          playMakerId,
        }) => {
          return {
            url: `/reports/firm/totalConsument?startDate=${startDate}&endDate=${endDate}&timeZone=${timeZone}&playMakerId=${playMakerId}&productName=${productName}&firmId=${firmId}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
      }),
      getNewFirmsBetweenDatesReport: builder.query({
        query: ({ token, startDate, endDate, timeZone }) => {
          return {
            url: `/reports/firm/newFirmsBetweenDates?startDate=${startDate}&endDate=${endDate}&timeZone=${timeZone}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
      }),
      getFirmsListBasedLocation: builder.query({
        query: ({ token, city, town, activity, playMakerId }) => {
          return {
            url: `/reports/firm/locationList?city=${city}&town=${town}&activity=${activity}&playMakerId=${playMakerId}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
      }),
      getFirmBillUploadOperationReport: builder.query({
        query: ({
          token,
          startDate,
          endDate,
          billNo,
          firmName,
          productTypeName,
          billType,
        }) => {
          return {
            url: `/reports/firm/billUploadOperation?startDate=${startDate}&endDate=${endDate}&billNo=${billNo}&firmName=${firmName}&productTypeName=${productTypeName}&billType=${billType}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
      }),
    };
  },
});

export const {
  useGetFirmBillUploadOperationReportQuery,
  useGetFirmsListBasedLocationQuery,
  useGetNewFirmsBetweenDatesReportQuery,
  useGetFirmTotalConsumentReportQuery,
  useGetFirmConsumentDailyReportQuery,
  useGetFirmConsumentMonthlyReportQuery,
  useGetConsumentReportQuery,
  useGetFirmRemainderReportQuery,
} = reportsApi;

export { reportsApi };
