import { useState, useCallback, useMemo, useEffect } from "react";
import { useGetBelowFirmsByIDQuery } from "../store";

export function useBelowFirmsData({ id, token }) {
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [filteredData, setFilteredData] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  const {
    data: belowFirmsData,
    isLoading: belowFirmsIsLoading,
    isFetching: belowFirmsIsFetching,
    error: belowFirmsError,
  } = useGetBelowFirmsByIDQuery(
    { id, token },
    {
      refetchOnMountOrArgChange: true,
      skip: !token || !id,
    }
  );

  const belowFirms = useMemo(
    () => belowFirmsData?.data?.belowFirms || [],
    [belowFirmsData]
  );

  const handleSearch = useCallback((data, isSearch) => {
    setPaginationNumber(1);
    setFilteredData(data);
    setIsSearch(isSearch);
  }, []);

  // Sayfa yüklendiğinde search'ü sıfırla
  useEffect(() => {
    setIsSearch(false);
  }, []);

  return {
    belowFirms,
    belowFirmsIsLoading,
    belowFirmsIsFetching,
    belowFirmsError,
    paginationNumber,
    setPaginationNumber,
    filteredData,
    isSearch,
    setIsSearch,
    handleSearch,
  };
}
