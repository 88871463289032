import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";

//Icons
import { AiFillDelete } from "react-icons/ai";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

import { HiOutlinePencilAlt } from "react-icons/hi";
import styles from "../../CustomStyles";
import FormatDateHourString from "../../Components/Utils/FormatDateHourString";
import TopBar from "../../Components/Utils/TopBar";

function EditUserPage() {
  const location = useLocation();
  const { name, lastName, createdInfo, updatedInfo } = location.state;

  const links = [
    {
      to: "Bilgi",
      state: {
        id: location.state.id,
        name: location.state.name,
        lastName: location.state.lastName,
        createdInfo: location.state.createdInfo,
        updatedInfo: location.state.updatedInfo,
      },
      label: (
        <p className={`${styles.PageHeader} max-md:hidden`}>
          Kullanıcı Düzenleme
        </p>
      ),
      icon: <HiOutlinePencilAlt className={`${styles.PageHeaderIcon}`} />,
    },
    {
      to: "Sil",
      state: {
        id: location.state.id,
        name: location.state.name,
        lastName: location.state.lastName,
        createdInfo: location.state.createdInfo,
        updatedInfo: location.state.updatedInfo,
      },
      label: (
        <p className={`${styles.PageHeader} max-md:hidden`}>Kullanıcı Sil</p>
      ),
      icon: <AiFillDelete className={`${styles.PageHeaderIcon}`} />,
    },
  ];
  const backLink = {
    path: "/Anasayfa/Kullanıcı",
    icon: <MdOutlineKeyboardBackspace className={`${styles.PageHeaderIcon}`} />,
  };

  return (
    <div>
      {/* Device Top Information Close Button */}
      <TopBar links={links} backLink={backLink} />
      <div
        className={`flex w-full 
          mt-[2rem] flex-col justify-between gap-4 
          rounded-xl text-black`}
      >
        {" "}
        <Outlet />
      </div>
      <div className=" text-gray-400 fixed bottom-5 flex gap-4 items-center w-fit ">
        <p className={`${styles.textTitle}`}>{name}</p>
        <p className={`${styles.textTitle} `}>{lastName}</p>
        <p className={`${styles.textTitle}`}>
          Oluşturulma({FormatDateHourString(createdInfo)})
        </p>
        <p className={`${styles.textTitle}   `}>
          Son Güncelleme({FormatDateHourString(updatedInfo)})
        </p>
      </div>
    </div>
  );
}

export default EditUserPage;
