import React from "react";
import { colors } from "../../Config/Colors";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";

const Pagination = ({ currentPage, totalCount, pageSize, onPageChange }) => {
  const totalPages = Math.ceil(totalCount / pageSize);

  if (totalPages <= 0) return null;

  const getPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, startPage + 4);

    if (endPage - startPage < 4) {
      startPage = Math.max(1, endPage - 4);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50">
      <div className="flex items-center gap-2 bg-white p-2 rounded-lg shadow-lg">
        {/* İlk Sayfa */}
        <button
          onClick={() => onPageChange(1)}
          disabled={currentPage === 1}
          className={`
            p-2 rounded-lg transition-all duration-300
            ${
              currentPage === 1
                ? "text-gray-400 cursor-not-allowed"
                : `${colors.primary.text} hover:${colors.primary.bg} hover:text-white`
            }
          `}
        >
          <RxDoubleArrowLeft className="w-8 h-8" />
        </button>

        {/* Önceki Sayfa */}
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`
            p-2 rounded-lg transition-all duration-300
            ${
              currentPage === 1
                ? "text-gray-400 cursor-not-allowed"
                : `${colors.primary.text} hover:${colors.primary.bg} hover:text-white`
            }
          `}
        >
          <IoIosArrowBack className="w-8 h-8" />
        </button>

        {/* Sayfa Numaraları */}
        {getPageNumbers().map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => onPageChange(pageNumber)}
            className={`
              min-w-[40px] h-10 rounded-lg font-medium
              transition-all duration-300
              text-xl
              ${
                pageNumber === currentPage
                  ? `${colors.primary.bg} text-white shadow-md`
                  : `${colors.primary.text} hover:${colors.primary.bg} hover:text-white`
              }
            `}
          >
            {pageNumber}
          </button>
        ))}

        {/* Sonraki Sayfa */}
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`
            p-2 rounded-lg transition-all duration-300
            ${
              currentPage === totalPages
                ? "text-gray-400 cursor-not-allowed"
                : `${colors.primary.text} hover:${colors.primary.bg} hover:text-white`
            }
          `}
        >
          <IoIosArrowForward className="w-8 h-8" />
        </button>

        {/* Son Sayfa */}
        <button
          onClick={() => onPageChange(totalPages)}
          disabled={currentPage === totalPages}
          className={`
            p-2 rounded-lg transition-all duration-300
            ${
              currentPage === totalPages
                ? "text-gray-400 cursor-not-allowed"
                : `${colors.primary.text} hover:${colors.primary.bg} hover:text-white`
            }
          `}
        >
          <RxDoubleArrowRight className="w-8 h-8" />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
