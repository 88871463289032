import React from "react";
import { colors } from "../../Config/Colors";
import SelectType from "../Utils/SelectType";
import CustomCalendar from "../Utils/Date/CustomCalendar";

const ReportSearchBar = ({
  filterType,
  handleFilterType,
  date,
  setDate,
  dateSecond,
  setDateSecond,
  input,
  setInput,
  handleGetLogData,
  reportTypeOptions,
}) => {
  return (
    <div className={`${colors.background.main} p-6 mb-6 rounded-xl shadow-md`}>
      <div className="flex flex-col gap-6">
        <div className="flex flex-wrap items-center gap-4">
          {/* Filtreleme Tipi Butonları */}
          <div
            className={`${colors.background.light} p-1 rounded-lg flex gap-1`}
          >
            {[
              { type: "Day", label: "Günlük" },
              { type: "Month", label: "Aylık" },
              { type: "Year", label: "Yıllık" },
            ].map((item) => (
              <button
                key={item.type}
                onClick={() => handleFilterType(item.type)}
                className={`
                  px-4 py-2 rounded-lg  transition-all duration-200
                  ${
                    filterType === item.type
                      ? `bg-gradient-to-r ${colors.primary.gradient} ${colors.text.white} shadow-sm`
                      : `${colors.background.main} ${colors.primary.hoverBgLight}`
                  }
                `}
              >
                <span className="text-xl tracking-widest">{item.label}</span>
              </button>
            ))}
          </div>

          {/* Tarih Seçiciler */}
          <div className="w-48 relative">
            <label
              className={`
              absolute top-3 left-12 px-2 
              ${colors.primary.text} 
              text-xl
            `}
            >
              Başlangıç
            </label>

            <div
              className={`
                ${colors.background.main} 
                rounded-lg 
                border ${colors.primary.border} 
                p-3 
              `}
            >
              <CustomCalendar setDate={setDate} date={date} />
            </div>
          </div>

          <div className="w-48 relative">
            <label
              className={`
                absolute top-3 left-12 px-2 
                ${colors.primary.text} 
                text-xl 
              `}
            >
              Bitiş
            </label>
            <div
              className={`
                ${colors.background.main} 
                rounded-lg 
                border ${colors.primary.border} 
                p-3
              `}
            >
              <CustomCalendar setDate={setDateSecond} date={dateSecond} />
            </div>
          </div>

          {/* Rapor Tipi Seçimi */}
          <div className="flex-1 text-xl min-w-[200px]">
            <SelectType
              label="Rapor Tipi"
              options={reportTypeOptions}
              selectedValue={input.type}
              onChange={(value) => {
                setInput({ ...input, type: value });
              }}
              className="text-[18px] p-2" // Metin boyutunu 18px ve padding ekledik
            />
          </div>

          {/* Getir Butonu */}
          <button
            onClick={handleGetLogData}
            className={`
              px-6 py-2 rounded-lg ${colors.text.white} font-medium
              bg-gradient-to-r ${colors.primary.gradient}
              hover:shadow-lg transform hover:-translate-y-0.5
              transition-all duration-200
              disabled:opacity-50 disabled:cursor-not-allowed
              disabled:hover:shadow-none disabled:hover:transform-none
              text-xl
            `}
            disabled={!input.type || !date || !dateSecond}
          >
            <span className="flex items-center gap-2">
              <svg
                className="w-8 h-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
              Getir
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportSearchBar;
