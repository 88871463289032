import React from "react";
import { NavLink } from "react-router-dom";
import { BsFillPencilFill, BsFillLaptopFill } from "react-icons/bs";
import { TbReportAnalytics } from "react-icons/tb";
import { BiSolidInfoCircle } from "react-icons/bi";
import { MdUpdate, MdPower, MdBuildCircle } from "react-icons/md";
import { FaUserTie, FaWarehouse, FaBuilding } from "react-icons/fa";
import { colors } from "../../Config/Colors";
import FormatDateHourString from "../Utils/FormatDateHourString";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function CustomDeviceList2({ data, handleOperationSelect }) {
  return (
    <div className="grid grid-cols-1 gap-4">
      {data.map((device) => (
        <div
          key={device._id}
          className="bg-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden"
        >
          {/* Header */}
          <div
            className={`flex justify-between items-center p-4 border-b ${colors.border.light}`}
          >
            <div className="flex items-center gap-3">
              <h3 className={`text-xl font-medium ${colors.text.primary}`}>
                {device.deviceName}
              </h3>
              <div className="flex gap-2">
                <span
                  className={`px-3 py-1 rounded-full text-lg font-medium
                  ${
                    device.isActive
                      ? `${colors.status.success.bg} ${colors.status.success.text}`
                      : `${colors.status.inactive.bg} ${colors.status.inactive.text}`
                  }`}
                >
                  {device.isActive ? "Aktif" : "Pasif"}
                </span>
                <span
                  className={`px-3 py-1 rounded-full text-lg font-medium
                  ${colors.background.light} ${colors.primary.text}`}
                >
                  {device.deviceStatusName}
                </span>
              </div>
            </div>

            <div className="flex items-center gap-3">
              <div className="flex items-center gap-2">
                {/* Aktiflik Butonu */}
                <button
                  onClick={() =>
                    handleOperationSelect(
                      { target: { value: "Aktiflik" } },
                      device
                    )
                  }
                  className={`
                    flex items-center text-md gap-2 px-4 py-2 rounded-lg
                    ${
                      device.isActive
                        ? "bg-red-100 text-red-600 hover:bg-red-200"
                        : "bg-green-100 text-green-600 hover:bg-green-200"
                    }
                    transition-all duration-200 font-medium
                  `}
                >
                  <MdPower className="w-5 h-5" />
                  Makineyi {device.isActive ? "Kapat" : "Aç"}
                </button>

                {/* Servis Butonu */}
                {device.deviceStatusName !== "Servis" && (
                  <button
                    onClick={() =>
                      handleOperationSelect(
                        { target: { value: "Servis" } },
                        device
                      )
                    }
                    className="flex items-center text-md gap-2 px-4 py-2 rounded-lg bg-yellow-100 text-yellow-600 hover:bg-yellow-200 transition-all duration-200 font-medium"
                  >
                    <MdBuildCircle className="w-5 h-5" />
                    Servise Al
                  </button>
                )}

                {/* Plasiyer Butonu */}
                <button
                  onClick={() =>
                    handleOperationSelect(
                      { target: { value: "Plasiyer" } },
                      device
                    )
                  }
                  className="flex items-center text-md gap-2 px-4 py-2 rounded-lg bg-blue-100 text-blue-600 hover:bg-blue-200 transition-all duration-200 font-medium"
                >
                  <FaUserTie className="w-5 h-5" />
                  Plasiyere Ver
                </button>

                {/* Depo Butonu */}
                <button
                  onClick={() =>
                    handleOperationSelect({ target: { value: "Depo" } }, device)
                  }
                  className="flex items-center text-md gap-2 px-4 py-2 rounded-lg bg-purple-100 text-purple-600 hover:bg-purple-200 transition-all duration-200 font-medium"
                >
                  <FaWarehouse className="w-5 h-5" />
                  Depoya Gönder
                </button>

                {/* Firma Butonu */}
                <button
                  onClick={() =>
                    handleOperationSelect(
                      { target: { value: "Firma" } },
                      device
                    )
                  }
                  className="flex items-center text-md gap-2 px-4 py-2 rounded-lg bg-gray-100 text-gray-600 hover:bg-gray-200 transition-all duration-200 font-medium"
                >
                  <FaBuilding className="w-5 h-5" />
                  Firmaya Al
                </button>
              </div>

              {/* Action Buttons */}
              <div className="flex gap-2">
                <NavLink
                  to={`/Anasayfa/Cihaz/Düzenle/Bilgi`}
                  state={{
                    id: device._id,
                    serialNo: device.serialNo,
                    deviceName: device.deviceName,
                    createdInfo: device.createdInfo,
                    updatedInfo: device.updatedInfo,
                    isActive: device.isActive,
                  }}
                >
                  <button
                    className={`p-2 rounded-lg ${colors.primary.text} hover:${colors.primary.bg} hover:text-white transition-all duration-200`}
                  >
                    <BsFillPencilFill className="w-8 h-8" />
                  </button>
                </NavLink>
                <NavLink
                  to={`/Anasayfa/Cihaz/Bilgi/Genel`}
                  state={{
                    id: device._id,
                    serialNo: device.serialNo,
                    deviceName: device.deviceName,
                    createdInfo: device.createdInfo,
                    updatedInfo: device.updatedInfo,
                    isActive: device.isActive,
                  }}
                >
                  <button
                    className={`p-2 rounded-lg ${colors.primary.text} hover:${colors.primary.bg} hover:text-white transition-all duration-200`}
                  >
                    <TbReportAnalytics className="w-8 h-8" />
                  </button>
                </NavLink>
              </div>
            </div>
          </div>

          {/* Device Details */}
          <div className="grid md:grid-cols-3 gap-4 p-4">
            {/* Basic Info */}
            <div
              className={`
              ${colors.background.light} 
              p-4 rounded-lg
              border-l-4 ${colors.primary.border}
              hover:shadow-md transition-all duration-300
            `}
            >
              <h4
                className={`
                ${colors.text.primary} 
                font-semibold 
                mb-3 
                text-lg
                flex items-center gap-2
              `}
              >
                <span
                  className={`p-2 rounded-lg ${colors.primary.bg} shadow-lg`}
                >
                  <BiSolidInfoCircle className="w-8 h-8 text-white" />
                </span>
                Temel Bilgiler
              </h4>
              <div className="space-y-3">
                <p
                  className={`
                  flex justify-between items-center
                  p-2 rounded-lg
                  ${colors.background.main}
                  hover:${colors.background.dark}
                  transition-colors duration-200
                  text-md
                `}
                >
                  <span className={`${colors.text.secondary}  font-medium`}>
                    Firma :
                  </span>
                  <span className={`${colors.text.primary} font-semibold`}>
                    {device.firm.firmName}
                  </span>
                </p>
                <p
                  className={`
                  flex justify-between items-center
                  p-2 rounded-lg
                  ${colors.background.main}
                  hover:${colors.background.dark}
                  transition-colors duration-200
                                    text-md
                `}
                >
                  <span className={`${colors.text.secondary} font-medium`}>
                    Seri No:
                  </span>
                  <span className={`${colors.text.primary} font-semibold`}>
                    {device.serialNo}
                  </span>
                </p>
                <p
                  className={`
                  flex justify-between items-center
                  p-2 rounded-lg
                  ${colors.background.main}
                  hover:${colors.background.dark}
                  transition-colors duration-200
                                    text-md
                `}
                >
                  <span className={`${colors.text.secondary} font-medium`}>
                    Son Bağlantı:
                  </span>
                  <span className={`${colors.text.primary} font-semibold`}>
                    {FormatDateHourString(device.lastConnectionDate)}
                  </span>
                </p>
              </div>
            </div>

            {/* Device Info */}
            <div
              className={`
              ${colors.background.light} 
              p-4 rounded-lg
              border-l-4 ${colors.status.success.border}
              hover:shadow-md transition-all duration-300
            `}
            >
              <h4
                className={`
                ${colors.text.primary} 
                font-semibold 
                mb-3
                flex items-center gap-2
                text-lg
              `}
              >
                <span className={`p-2 rounded-lg bg-orange-600 shadow-lg`}>
                  <BsFillLaptopFill className="w-5 h-5 text-white" />
                </span>
                Cihaz Bilgileri
              </h4>
              <div className="space-y-3">
                {[
                  { label: "GSM:", value: device.deviceInfo?.gsmNumber },
                  { label: "IMEI:", value: device.deviceInfo?.imei },
                  { label: "Operatör:", value: device.deviceInfo?.carrier },
                ].map((item, index) => (
                  <p
                    key={index}
                    className={`
                    flex justify-between items-center
                    p-2 rounded-lg
                    ${colors.background.main}
                    hover:${colors.background.dark}
                    transition-colors duration-200
                                      text-md
                  `}
                  >
                    <span className={`${colors.text.secondary} font-medium`}>
                      {item.label}
                    </span>
                    <span className={`${colors.text.primary} font-semibold`}>
                      {item.value}
                    </span>
                  </p>
                ))}
              </div>
            </div>

            {/* Version Info */}
            <div
              className={`
              ${colors.background.light} 
              p-4 rounded-lg
              border-l-4 ${colors.status.warning.border}
              hover:shadow-md transition-all duration-300
            `}
            >
              <h4
                className={`
                ${colors.text.primary} 
                font-semibold 
                mb-3
                flex items-center gap-2
                text-lg
              `}
              >
                <span className={`p-2 rounded-lg bg-green-600 shadow-lg`}>
                  <MdUpdate className="w-5 h-5 text-white" />
                </span>
                Versiyon Bilgileri
              </h4>
              <div className="space-y-3">
                <p
                  className={`
                    flex justify-between items-center
                    p-2 rounded-lg
                    ${colors.background.main}
                    hover:${colors.background.dark}
                    transition-colors duration-200
                                      text-md
                  `}
                >
                  <span className={colors.text.secondary}>Ekran Sürüm:</span>
                  <span>{device.deviceInfo?.appVersion}</span>
                </p>
                <p
                  className={`
                    flex justify-between items-center
                    p-2 rounded-lg
                    ${colors.background.main}
                    hover:${colors.background.dark}
                    transition-colors duration-200
                                      text-md
                  `}
                >
                  <span className={colors.text.secondary}>Kart Sürüm:</span>
                  <span>{device.deviceInfo?.hwVersion}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
