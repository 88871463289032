import { useEffect, useState } from "react";
import { MdArrowForwardIos, MdOutlineArrowBackIos } from "react-icons/md";
import { HiHomeModern } from "react-icons/hi2";
import { RiCupFill } from "react-icons/ri";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ImConnection } from "react-icons/im";
import { FiActivity } from "react-icons/fi";
import { GiPositionMarker } from "react-icons/gi";
import { NavLink } from "react-router-dom";
import { BsFillPencilFill } from "react-icons/bs";
import { TbReportAnalytics } from "react-icons/tb";
import { colors } from "../../../Config/Colors";

// Zaman hesaplama yardımcı fonksiyonu
const timeAgo = (date) => {
  const now = new Date();
  const past = new Date(date);
  const diffInSeconds = Math.floor((now - past) / 1000);

  if (diffInSeconds < 60) {
    return "Az önce";
  }

  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) {
    return `${diffInMinutes} dakika önce`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours} saat önce`;
  }

  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays < 28) {
    return `${diffInDays} gün önce`;
  }

  const diffInMonths = Math.floor(diffInDays / 30);
  if (diffInMonths < 12) {
    return `${diffInMonths} ay önce`;
  }

  const diffInYears = Math.floor(diffInMonths / 12);
  return `${diffInYears} yıl önce`;
};

// Tarih formatlama yardımcı fonksiyonu
const formatDate = (date) => {
  return new Date(date).toLocaleDateString("tr-TR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

export default function DeviceTable({ data, filterSerialNo, expanded }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const [open, setOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState({
    deviceName: "",
    serialNo: "",
    firm: { firmName: "" },
    lastConnectionDate: "",
    deviceStatusName: "",
    isActive: "",
    productInfo: [],
  });

  const handleOpen = (index) => {
    setSelectedDevice(data[index]);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentData = data.slice(firstIndex, lastIndex);

  const onPageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };

  // Durum renklerini belirleyelim
  const getStatusColor = (status) => {
    switch (status) {
      case "Aktif":
        return "text-green-600 bg-green-50";
      case "Pasif":
        return "text-red-600 bg-red-50";
      default:
        return "text-gray-600 bg-gray-50";
    }
  };

  const getDeviceStatusColor = (status) => {
    switch (status) {
      case "Firma":
        return "text-blue-600 bg-blue-50";
      case "Plasiyer":
        return "text-purple-600 bg-purple-50";
      case "Depo":
        return "text-orange-600 bg-orange-50";
      case "Servis":
        return "text-yellow-600 bg-yellow-50";
      case "Üretim":
        return "text-pink-600 bg-pink-50";
      default:
        return "text-gray-600 bg-gray-50";
    }
  };

  return (
    <div className="flex flex-col h-[450px] justify-between w-full">
      <div className="flex-1 overflow-y-auto">
        {currentData.map((device, index) => (
          <div
            key={device.serialNo}
            onClick={() => handleOpen((currentPage - 1) * itemsPerPage + index)}
            className={`
              flex items-center p-4 cursor-pointer
              ${
                index !== currentData.length - 1
                  ? `border-b ${colors.border.light}`
                  : ""
              }
              hover:bg-gray-50 transition-all duration-300
            `}
          >
            {/* Cihaz Bilgileri */}
            <div className="w-1/4 flex flex-col">
              <span className={`text-base font-medium ${colors.text.primary}`}>
                {device.deviceName}
              </span>
              <span
                className={`text-sm ${colors.text.secondary} bg-gray-100 px-2 py-0.5 rounded-full w-fit mt-1`}
              >
                {device.serialNo}
              </span>
            </div>

            {/* Firma ve Durum */}
            <div className="w-1/4 flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <HiHomeModern className="w-4 h-4 text-blue-500" />
                <span className={`text-sm ${colors.text.primary}`}>
                  {device.firm.firmName}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <GiPositionMarker className="w-4 h-4 text-gray-500" />
                <span
                  className={`text-xs px-2 py-1 rounded-full font-medium ${getDeviceStatusColor(
                    device.deviceStatusName
                  )}`}
                >
                  {device.deviceStatusName}
                </span>
              </div>
            </div>

            {/* Bağlantı Durumu */}
            <div className="w-1/4 flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <ImConnection
                  className={`w-4 h-4 ${
                    timeAgo(device.lastConnectionDate).includes("gün")
                      ? "text-red-500"
                      : "text-green-500"
                  }`}
                />
                <span className={`text-sm ${colors.text.primary}`}>
                  {timeAgo(device.lastConnectionDate)}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <FiActivity
                  className={`w-4 h-4 ${
                    device.isActive ? "text-green-500" : "text-red-500"
                  }`}
                />
                <span
                  className={`text-xs px-2 py-1 rounded-full font-medium ${getStatusColor(
                    device.isActive ? "Aktif" : "Pasif"
                  )}`}
                >
                  {device.isActive ? "Aktif" : "Pasif"}
                </span>
              </div>
            </div>

            {/* Ürün Bilgileri */}
            <div className="w-1/4 flex flex-col gap-2">
              {device.productInfo.productInfo.map((product) => (
                <div
                  key={product.productName}
                  className="flex items-center gap-2 bg-gray-50 p-2 rounded-lg"
                >
                  <RiCupFill className="w-4 h-4 text-indigo-500" />
                  <span className={`text-sm ${colors.text.primary}`}>
                    {product.productName}
                  </span>
                  <span
                    className={`text-xs px-2 py-0.5 rounded-full ${
                      product.quota <= 0
                        ? "bg-red-50 text-red-600"
                        : "bg-green-50 text-green-600"
                    }`}
                  >
                    {product.quota}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box
          className={`
          absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
          bg-white rounded-xl shadow-xl p-6 w-96
          ${colors.border.light}
        `}
        >
          <div className="flex justify-end gap-2 mb-4">
            <NavLink
              to={`/Anasayfa/Cihaz/Düzenle/Bilgi`}
              state={{
                id: selectedDevice._id,
                serialNo: selectedDevice.serialNo,
                deviceName: selectedDevice.deviceName,
                createdInfo: selectedDevice.createdInfo,
                updatedInfo: selectedDevice.updatedInfo,
              }}
            >
              <button
                className={`p-2 rounded-lg ${colors.primary.bg} text-white hover:opacity-90`}
              >
                <BsFillPencilFill className="w-5 h-5" />
              </button>
            </NavLink>
            <NavLink
              to={`/Anasayfa/Cihaz/Bilgi/Genel`}
              state={{
                id: selectedDevice._id,
                serialNo: selectedDevice.serialNo,
                deviceName: selectedDevice.deviceName,
                createdInfo: selectedDevice.createdInfo,
                updatedInfo: selectedDevice.updatedInfo,
                isActive: selectedDevice.isActive,
              }}
            >
              <button
                className={`p-2 rounded-lg ${colors.primary.bg} text-white hover:opacity-90`}
              >
                <TbReportAnalytics className="w-5 h-5" />
              </button>
            </NavLink>
          </div>

          <DetailRow label="Makine" value={selectedDevice.deviceName} />
          <DetailRow label="Seri No" value={selectedDevice.serialNo} />
          <DetailRow label="Firma" value={selectedDevice.firm.firmName} />
          <DetailRow
            label="Cihaz Durumu"
            value={selectedDevice.deviceStatusName}
          />
          <DetailRow
            label="Durum"
            value={selectedDevice.isActive ? "Aktif" : "Pasif"}
          />
          <DetailRow
            label="Ürünler"
            value={selectedDevice?.productInfo?.productInfo
              ?.map((product) => `${product.productName} (${product.quota})`)
              .join(", ")}
          />
          <DetailRow
            label="Son Bağlantı"
            value={timeAgo(selectedDevice.lastConnectionDate)}
          />
        </Box>
      </Modal>

      {/* Pagination */}
      <div className="flex items-center justify-between mt-4 px-2">
        <div className={`text-sm ${colors.text.secondary}`}>
          Toplam {data.length} cihaz
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`
              p-1.5 rounded-lg transition-all duration-300
              ${
                currentPage === 1
                  ? "text-gray-400 cursor-not-allowed"
                  : `${colors.primary.text} hover:${colors.primary.bg} hover:text-white`
              }
            `}
          >
            <MdOutlineArrowBackIos className="w-4 h-4" />
          </button>

          <span className={`text-sm ${colors.text.secondary}`}>
            {currentPage} / {totalPages}
          </span>

          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`
              p-1.5 rounded-lg transition-all duration-300
              ${
                currentPage === totalPages
                  ? "text-gray-400 cursor-not-allowed"
                  : `${colors.primary.text} hover:${colors.primary.bg} hover:text-white`
              }
            `}
          >
            <MdArrowForwardIos className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );
}

// Modal için yardımcı bileşen
const DetailRow = ({ label, value }) => (
  <div className="flex justify-between items-center mb-3">
    <span className={`font-medium ${colors.text.secondary}`}>{label}:</span>
    <span className={colors.text.primary}>{value}</span>
  </div>
);
