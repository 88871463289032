import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import URL from "../url";

const DashBoardApi = createApi({
  reducerPath: "dashBoardDevice",
  baseQuery: fetchBaseQuery({
    baseUrl: `${URL.url}`,
    fetchFn: async (...args) => {
      // REMOVE FOR PRODUCTION
      return fetch(...args);
    },
  }),
  tagTypes: "DashBoardDevice",
  endpoints(builder) {
    return {
      getDashBoardConsuments: builder.query({
        query: (input) => {
          return {
            url: `/dashBoard/consuments/${input.section}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
          };
        },
        providesTags: ["DashBoardDevice"],
      }),
      getDashBoardBills: builder.query({
        query: (input) => {
          return {
            url: `/dashBoard/bills`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
          };
        },
        providesTags: ["DashBoardDevice"],
      }),
      getDashBoardDevices: builder.query({
        query: (input) => {
          return {
            url: `/dashBoard/devices`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
          };
        },
        providesTags: ["DashBoardDevice"],
      }),
    };
  },
});

export const {
  useGetDashBoardBillsQuery,
  useGetDashBoardConsumentsQuery,
  useGetDashBoardDevicesQuery,
} = DashBoardApi;
export { DashBoardApi };
