import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CustomDeviceList2 from "../../Components/Device/CustomDeviceList2";
import Loader from "../../Components/Utils/Loader";
import UnfindDataWarn from "../../Components/Utils/UnfindDataWarn";
import DeviceTopSearchBar from "../../Components/SearchBar/DeviceTopSearchBar";
import Alerts from "../../Components/Utils/Alert";
import Pagination from "../../Components/Table/Pagination";
import DeviceOperationModal from "../../Components/Device/DeviceOperationModal";

import { useDeviceOperationData } from "../../Hooks/useDeviceOpreationData";
import useAuth from "../../Hooks/useAuth";
import { useDevicesData } from "../../Hooks/useDevicesData";

function DevicePage() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [alert, setAlert] = useState(0);
  const [message, setMessage] = useState("");
  const [confirm, setConfirm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isSearch, setIsSearch] = useState(false);

  const { deviceData, deviceIsLoading, deviceError, getDevice } =
    useDevicesData(auth.accessToken, setAlert, setMessage);

  const {
    operationDetail,
    deviceOperationIsLoading,
    setOperationDetail,
    handleOpen,
    handleClose,
    makeDeviceOperation,
  } = useDeviceOperationData(
    auth.accessToken,
    getDevice,
    setConfirm,
    setMessage,
    setAlert
  );

  const handleSearch = (data, isSearch) => {
    setFilteredData(data);
    setIsSearch(isSearch);
  };

  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        setAlert(0);
        setMessage("");
      }, 1000);
    }
  }, [alert]);

  const getCurrentPageData = () => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return isSearch
      ? filteredData.slice(firstPageIndex, lastPageIndex)
      : deviceData.slice(firstPageIndex, lastPageIndex);
  };

  const handleOperationSelect = (event, device) => {
    setOperationDetail({
      _id: device._id,
      serialNo: device.serialNo,
      deviceStatusName: device.deviceStatusName,
      isActive: device.isActive,
      operation: event.target.value,
      firmName: device.firm?.firmName,
    });
    handleOpen();
  };

  return (
    <div className="space-y-6 mb-20">
      {alert !== 0 && (
        <div className="fixed z-50 left-1/2 top-16 -translate-x-1/2">
          <Alerts AlertChoice={alert} message={message} />
        </div>
      )}
      <DeviceOperationModal
        open={confirm}
        onClose={handleClose}
        operationDetail={operationDetail}
        auth={auth}
        makeDeviceOperation={makeDeviceOperation}
      />
      <DeviceTopSearchBar
        Length={deviceData.length}
        TopInfo={"Cihaz Listesi"}
        AddButton={() => {
          navigate("Oluştur");
        }}
        SearchBarData={deviceData}
        HandleSearch={handleSearch}
        SearchInputFieldName={{
          deviceName: "Cihaz İsmi",
          serialNo: "Seri Numarası",
        }}
      />
      {deviceIsLoading || deviceOperationIsLoading || alert !== 0 ? (
        <div className="flex w-full h-full pt-24 justify-center">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : deviceError || !deviceData.length ? (
        <UnfindDataWarn Data={"Cihaz bulunamadı"} />
      ) : (
        <>
          <CustomDeviceList2
            data={getCurrentPageData()}
            handleOperationSelect={handleOperationSelect}
          />
          <Pagination
            currentPage={currentPage}
            totalCount={isSearch ? filteredData.length : deviceData.length}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </>
      )}
    </div>
  );
}

export default DevicePage;
