import { useGetConsumentReportQuery } from "../../../store/reportsApi";
import useAuth from "../../../Hooks/useAuth";
import { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, Typography, Grid, Button } from "@mui/material";
import trLocale from "date-fns/locale/tr";
import { format, parseISO } from "date-fns";
import ReportLayout from "../../../Layout/Reports/ReportLayout";

export default function ReportGeneralConsumentsPage() {
  const { auth } = useAuth();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [parsedData, setParsedData] = useState(null);
  const [productNames, setProductNames] = useState([]);
  const token = auth.accessToken;

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const startDateUTC = startDate ? startDate.toISOString() : null;
  const endDateUTC = endDate ? endDate.toISOString() : null;

  const {
    data: ResultReportGeneralConsuments,
    isLoading,
    error,
  } = useGetConsumentReportQuery(
    { token, startDate: startDateUTC, endDate: endDateUTC, timeZone },
    { skip: !shouldFetch }
  );

  useEffect(() => {
    if (ResultReportGeneralConsuments && ResultReportGeneralConsuments.data) {
      const allProductNames = new Set();
      const parsed = ResultReportGeneralConsuments.data.map((item) => {
        const productData = {};
        item.products.forEach((product) => {
          productData[product.productName] = product.consumption;
          allProductNames.add(product.productName);
        });
        return {
          date: item.date,
          ...productData,
        };
      });
      setParsedData(parsed);
      setProductNames(Array.from(allProductNames));
      setShouldFetch(false);
    }
  }, [ResultReportGeneralConsuments]);

  const handleSearch = () => {
    if (startDate && endDate) {
      setShouldFetch(true);
    }
  };

  const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return format(date, "d MMMM yyyy", { locale: trLocale });
  };

  if (isLoading) return <div>Yükleniyor...</div>;
  if (error) return <div>Hata: {error.message}</div>;

  return (
    <ReportLayout title="Genel Tüketim Raporu">
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <Grid container spacing={3} sx={{ maxWidth: 600 }}>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Başlangıç Tarihi"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              format="dd/MM/yyyy"
              views={["year", "month", "day"]}
              fullWidth
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: 2,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Bitiş Tarihi"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              format="dd/MM/yyyy"
              views={["year", "month", "day"]}
              fullWidth
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: 2,
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <Button
          variant="contained"
          onClick={handleSearch}
          sx={{ minWidth: 200, borderRadius: 2 }}
          disabled={!startDate || !endDate}
        >
          Raporu Getir
        </Button>
      </Box>
      {isLoading && <Typography align="center">Yükleniyor...</Typography>}
      {error && (
        <Typography align="center" color="error">
          Hata: {error.message}
        </Typography>
      )}
      {parsedData && (
        <Box sx={{ overflowX: "auto" }}>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>Tarih</th>
                {productNames.map((productName) => (
                  <th key={productName} style={tableHeaderStyle}>
                    {productName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {parsedData.map((item, index) => (
                <tr key={index}>
                  <td style={tableCellStyle}>{formatDate(item.date)}</td>
                  {productNames.map((productName) => (
                    <td key={productName} style={tableCellStyle}>
                      {item[productName] || 0}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      )}
    </ReportLayout>
  );
}

// Tablo stilleri
const tableHeaderStyle = {
  backgroundColor: "#222831", // main rengi
  color: "white",
  padding: "12px",
  textAlign: "center",
  fontWeight: "bold",
  borderBottom: "2px solid #ddd",
};

const tableCellStyle = {
  padding: "12px",
  borderBottom: "1px solid #ddd",
  textAlign: "center",
};
