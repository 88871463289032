import { useState, useEffect } from "react";
import Alerts from "../../../Components/Utils/Alert";
import {
  useUpdateTownMutation,
  useDeleteTownMutation,
  useAddTownMutation,
  useFetchTownQuery,
} from "../../../store";
import useAuth from "../../../Hooks/useAuth";
import styles from "../../../CustomStyles";
import Confirm from "../../../Components/Utils/Confirm";
import TopSearchInfo from "../../../Components/SearchBar/TopSearchInfo";
import ListInfo from "../../../Components/Table/ListInfo";
import { BsFillPencilFill, BsTrash } from "react-icons/bs";
import DesignModal from "../../../Components/Utils/DesignModal";
import UnfindDataWarn from "../../../Components/Utils/UnfindDataWarn";
import Loader from "../../../Components/Utils/Loader";
import TownDesignTag from "./TownDesignTag";

const TownPage = function () {
  const { auth } = useAuth();
  const token = auth.accessToken;
  const [isSearch, setIsSearch] = useState(false);
  const [input, setInput] = useState("");
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [filteredData, setFilteredData] = useState("");
  const inputFieldName = {
    townName: "İlçe İsmi",
    cityName: "Bağlı Olduğu Şehir",
  };
  const [infoText, setInfoText] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [designModal, setDesignModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [action, setAction] = useState(0);
  const [alert, setAlert] = useState(0);
  const [message, setMessage] = useState("");

  const TownResult = useFetchTownQuery(token);

  const [Update, resultUpdate] = useUpdateTownMutation();
  const [Delete, resultDelete] = useDeleteTownMutation();
  const [Add, resultAdd] = useAddTownMutation();

  const DataTown = TownResult?.data?.data?.towns || [];

  useEffect(() => {
    setIsSearch(false);
  }, []);

  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        setAlert(0);
        setMessage("");
      }, 5000);
    }
  }, [alert]);

  const handleApiResponse = (apiResponse, successMessage) => {
    setConfirm(false);

    if (apiResponse.isError) {
      setAlert(2);
      setMessage("İşlem başarısız tekrar deneyin !");
      setInput((prevInput) => ({
        ...prevInput,
        token: token,
      }));
    }
    if (apiResponse.isSuccess) {
      setAlert(1);
      setMessage(successMessage);
    }
  };

  useEffect(() => {
    handleApiResponse(resultDelete, "Silme başarılı !");
  }, [resultDelete.isSuccess, resultDelete.isError]);
  useEffect(() => {
    handleApiResponse(resultAdd, "Ekleme başarılı !");
  }, [resultAdd.isSuccess, resultAdd.isError]);

  useEffect(() => {
    handleApiResponse(resultUpdate, "Güncelleme başarılı !");
  }, [resultUpdate.isSuccess, resultUpdate.isError]);

  const HandleModal = ({ e, townName, cityName }) => {
    e.preventDefault();
    setInput({ ...input, cityName, townName });

    setShowConfirmModal(true);
    setDesignModal(false);
  };

  const handleCloseModel = (boolean) => {
    if (boolean) {
      setConfirm(true);

      if (action === 1) {
        Add(input);
        setIsSearch(false);
      } else if (action === 2) {
        Update(input);
        setIsSearch(false);
      } else if (action === 3) {
        Delete(input);
        setIsSearch(false);
      }
      setAction(0);
      setInput("");
      setIsSearch(false);
      setShowConfirmModal(false);
    } else {
      setShowConfirmModal(false);
      setIsSearch(false);
      if (action === 3) setAction(0);
    }
  };

  const handleSearch = (data, isSearch) => {
    setPaginationNumber(1);
    setFilteredData(data);
    setIsSearch(isSearch);
  };

  const config = [
    {
      class: "w-4",
      label: "Düzenle",
      render: (DataTown) => (
        <div className="flex flex-row justify-center">
          <button
            onClick={() => {
              setInfoText(
                "Değişiklikleri kaydetmek istediğinize emin misiniz ?"
              );
              setInput({
                ...input,
                townName: DataTown.townName,
                cityName: DataTown.cityName,
                id: DataTown._id,
                token: token,
              });
              setDesignModal(true);
              setAction(2);
            }}
            className={`${styles.tableButton}`}
          >
            <BsFillPencilFill className={`${styles.buttonIcon}`} />
          </button>
          <button
            className={`${styles.tableButton}`}
            onClick={() => {
              setInput({
                ...input,
                townName: DataTown.townName,
                cityName: DataTown.cityName,
                id: DataTown._id,
                token: token,
              });
              setInfoText("Silmek istediğinize emin misiniz ?");
              setShowConfirmModal(true);
              setAction(3);
            }}
          >
            <BsTrash className={`${styles.buttonIcon}`} />
          </button>
        </div>
      ),
    },
    {
      label: "İlçe",
      render: (DataTown) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">İlçe</div>
          {DataTown.townName}
        </>
      ),
      sortValue: (DataTown) => DataTown.townName,
    },
    {
      label: "Bağlı Olduğu Şehir",
      render: (DataTown) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">
            Bağlı Olduğu Şehir:
          </div>
          {DataTown.city.cityName}
        </>
      ),
      sortValue: (DataTown) => DataTown.cityName,
    },
  ];

  const DesignTag = () => {
    return (
      <TownDesignTag
        HandleModal={HandleModal}
        setInput={setInput}
        input={input}
        Token={token}
      />
    );
  };

  return (
    <div className={` mb-20 flex flex-col relative`}>
      {alert !== 0 && (
        <div
          className="fixed z-10 left-1/2 top-0
    -translate-x-1/2"
        >
          <Alerts AlertChoice={alert} message={message} />
        </div>
      )}
      <Confirm
        input={input}
        inputFieldName={inputFieldName}
        infoText={infoText}
        handleCloseModel={handleCloseModel}
        showConfirmModal={showConfirmModal}
      />
      <TopSearchInfo
        Length={DataTown.length}
        TopInfo={"İlçe Listesi"}
        AddButton={() => {
          setAction(1);
          setInput({
            ...input,
            token: token,
            townName: "",
            cityName: "",
          });
          setDesignModal(true);
          setInfoText("Oluşturma işlemini onaylıyor musunuz ?");
        }}
        SearchBarData={DataTown}
        HandleSearch={handleSearch}
        SearchInputFieldName={{
          townName: "İlçe İsmi",
          cityName: "Şehir İsmi",
        }}
      />

      {TownResult.isFetching || confirm ? (
        <div className=" flex w-full h-full justify-center items-center">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : TownResult.error || DataTown?.length === 0 ? (
        <UnfindDataWarn Data={"İlçe bulunamadı"} />
      ) : (
        <ListInfo
          Config={config}
          Key={"_id"}
          SetPaginationNumber={setPaginationNumber}
          PaginationNumber={paginationNumber}
          IsSearch={isSearch}
          FilteredData={filteredData}
          Data={DataTown}
        />
      )}

      <DesignModal
        onClose={() => {
          setInput("");
          setShowConfirmModal(false);
          setDesignModal(false);
          setAction(0);
        }}
        designModal={designModal}
        Tag={DesignTag}
      />
    </div>
  );
};

export default TownPage;
