import { useState } from "react";
import { colors } from "../Config/Colors";
import { useProfileForm } from "../Hooks/useProfileForm";
import { Modal, Box, TextField } from "@mui/material";
import avatar from "../Assets/icons/avatar.png";
import { HiOutlineMail } from "react-icons/hi";
import { RiLockPasswordLine } from "react-icons/ri";
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoMdSave } from "react-icons/io";
import Alerts from "../Components/Utils/Alert";

export default function ProfilePage() {
  const {
    updateMe,
    updateMyPassword,
    alert,
    message,
    userInfo,
    input,
    setInput,
    resultUpdateMe,
    resultUpdateMyPassword,
  } = useProfileForm();

  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  const handleOpenProfileModal = () => {
    setInput((prev) => ({
      ...prev,
      name: userInfo.name,
      lastName: userInfo.lastName,
      email: userInfo.email,
      tel: userInfo.tel,
    }));
    setOpenProfileModal(true);
  };

  const handleProfileSubmit = (e) => {
    e.preventDefault();
    updateMe(input);
    setOpenProfileModal(false);
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    updateMyPassword(input);
    setOpenPasswordModal(false);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {alert !== 0 && (
        <div className="fixed z-50 left-1/2 top-6 -translate-x-1/2">
          <Alerts AlertChoice={alert} message={message} />
        </div>
      )}

      <div className="bg-white rounded-xl shadow-lg p-6">
        {/* Profil Başlığı ve Avatar */}
        <div className="flex items-center gap-6 mb-8">
          <img src={avatar} alt="Profile" className="w-24 h-24 rounded-full" />
          <div>
            <h2 className={`text-2xl font-semibold ${colors.text.primary}`}>
              {userInfo.name} {userInfo.lastName}
            </h2>
            <p className={`${colors.text.secondary}`}>{userInfo.email}</p>
          </div>
        </div>

        {/* Profil Bilgileri */}
        <div className="grid gap-6 mb-8">
          <div className="flex items-center gap-4">
            <BsFillTelephoneFill className={`w-5 h-5 ${colors.primary.text}`} />
            <div>
              <p className={`text-sm ${colors.text.secondary}`}>Telefon</p>
              <p className={`${colors.text.primary}`}>{userInfo.tel}</p>
            </div>
          </div>

          <div className="flex items-center gap-4">
            <HiOutlineMail className={`w-5 h-5 ${colors.primary.text}`} />
            <div>
              <p className={`text-sm ${colors.text.secondary}`}>Email</p>
              <p className={`${colors.text.primary}`}>{userInfo.email}</p>
            </div>
          </div>

          <div className="flex items-center gap-4">
            <RiLockPasswordLine className={`w-5 h-5 ${colors.primary.text}`} />
            <div>
              <p className={`text-sm ${colors.text.secondary}`}>Şifre</p>
              <p className={`${colors.text.primary}`}>********</p>
            </div>
          </div>
        </div>

        {/* Aksiyon Butonları */}
        <div className="flex gap-4">
          <button
            onClick={handleOpenProfileModal}
            className={`
              flex items-center gap-2 px-4 py-2 rounded-lg
              ${colors.primary.bg} text-white
              hover:opacity-90 transition-all
            `}
          >
            <IoMdSave className="w-5 h-5" />
            Profili Düzenle
          </button>

          <button
            onClick={() => setOpenPasswordModal(true)}
            className={`
              flex items-center gap-2 px-4 py-2 rounded-lg
              border ${colors.primary.border} ${colors.primary.text}
              hover:${colors.primary.bg} hover:text-white transition-all
            `}
          >
            <RiLockPasswordLine className="w-5 h-5" />
            Şifre Değiştir
          </button>
        </div>
      </div>

      {/* Profil Düzenleme Modal */}
      <Modal open={openProfileModal} onClose={() => setOpenProfileModal(false)}>
        <Box
          className={`
          absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
          bg-white rounded-xl shadow-xl p-6 w-96
        `}
        >
          <h2 className={`text-xl font-semibold mb-6 ${colors.text.primary}`}>
            Profil Bilgilerini Güncelle
          </h2>
          <form onSubmit={handleProfileSubmit} className="space-y-4">
            <TextField
              fullWidth
              label="İsim"
              value={input.name}
              onChange={(e) => setInput({ ...input, name: e.target.value })}
            />
            <TextField
              fullWidth
              label="Soyisim"
              value={input.lastName}
              onChange={(e) => setInput({ ...input, lastName: e.target.value })}
            />
            <TextField
              fullWidth
              label="Telefon"
              value={input.tel}
              onChange={(e) => setInput({ ...input, tel: e.target.value })}
            />
            <button
              type="submit"
              className={`
                w-full py-2 rounded-lg
                ${colors.primary.bg} text-white
                hover:opacity-90 transition-all
              `}
            >
              Kaydet
            </button>
          </form>
        </Box>
      </Modal>

      {/* Şifre Değiştirme Modal */}
      <Modal
        open={openPasswordModal}
        onClose={() => setOpenPasswordModal(false)}
      >
        <Box
          className={`
          absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
          bg-white rounded-xl shadow-xl p-6 w-96
        `}
        >
          <h2 className={`text-xl font-semibold mb-6 ${colors.text.primary}`}>
            Şifre Değiştir
          </h2>
          <form onSubmit={handlePasswordSubmit} className="space-y-4">
            <TextField
              fullWidth
              type="password"
              label="Mevcut Şifre"
              value={input.passwordCurrent}
              onChange={(e) =>
                setInput({ ...input, passwordCurrent: e.target.value })
              }
            />
            <TextField
              fullWidth
              type="password"
              label="Yeni Şifre"
              value={input.password}
              onChange={(e) => setInput({ ...input, password: e.target.value })}
            />
            <TextField
              fullWidth
              type="password"
              label="Yeni Şifre Tekrar"
              value={input.passwordConfirm}
              onChange={(e) =>
                setInput({ ...input, passwordConfirm: e.target.value })
              }
            />
            <button
              type="submit"
              className={`
                w-full py-2 rounded-lg
                ${colors.primary.bg} text-white
                hover:opacity-90 transition-all
              `}
            >
              Şifreyi Güncelle
            </button>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
