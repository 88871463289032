import { useEffect } from "react";
import { useUpdateSettingMutation } from "../store/devicesApi";

const useUpdateDeviceSettingData = ({ setAlert, setMessage }) => {
  const [
    updateDeviceSetting,
    {
      data: updateDeviceSettingData,
      isLoading: updateDeviceSettingIsLoading,
      isSuccess: updateDeviceSettingIsSuccess,
      isError: updateDeviceSettingIsError,
      error: updateDeviceSettingError,
    },
  ] = useUpdateSettingMutation();

  const handleApiResponse = (
    isSuccess,
    isError,
    updateError,
    successMessage
  ) => {
    if (isError) {
      setAlert(2);
      setMessage(updateError.data.message);
    }
    if (isSuccess) {
      setAlert(1);
      setMessage(successMessage);
    }
  };

  useEffect(() => {
    handleApiResponse(
      updateDeviceSettingIsSuccess,
      updateDeviceSettingIsError,
      updateDeviceSettingError,
      updateDeviceSettingData?.message
    );
  }, [updateDeviceSettingIsSuccess, updateDeviceSettingIsError]);

  return {
    updateDeviceSetting,
    updateDeviceSettingIsLoading,
    updateDeviceSettingIsSuccess,
    updateDeviceSettingIsError,
    updateDeviceSettingError,
    updateDeviceSettingData,
  };
};

export default useUpdateDeviceSettingData;
