import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { colors } from "../../Config/Colors";
import coffeeBean from "../../Assets/icons/coffee_bean.png";
import teaLeaf from "../../Assets/icons/tea_leaf.png";
import UnfindDataWarn from "../../Components/Utils/UnfindDataWarn";

const defaultProducts = [
  { name: "Çay", icon: teaLeaf, color: "#5F8D4E" },
  { name: "Türk Kahvesi", icon: coffeeBean, color: "#6d4a3a" },
  { name: "Filtre Kahve", icon: coffeeBean, color: "#322110" },
];

const calculatePercentages = (consumption) => {
  if (!Array.isArray(consumption)) return [];

  const totalConsumption = consumption.reduce(
    (total, item) => total + (item.consument || 0),
    0
  );

  const dataMap = new Map(
    defaultProducts.map((product) => [product.name, { ...product, amount: 0 }])
  );

  consumption.forEach((item) => {
    if (dataMap.has(item.productName)) {
      dataMap.get(item.productName).amount = item.consument || 0;
    }
  });

  return Array.from(dataMap.values())
    .filter((item) => item.amount > 0)
    .map((item) => ({
      ...item,
      percentage: Math.round((item.amount / totalConsumption) * 100),
    }));
};

export default function ProductConsumentCard({
  consumption = [],
  warn,
  dashboardType,
  dashboardConsumentIsLoading,
  dashboardConsumentIsFetching,
}) {
  const [consumptionData, setConsumptionData] = useState([]);

  useEffect(() => {
    setConsumptionData([]);
  }, [dashboardType]);

  useEffect(() => {
    setConsumptionData(calculatePercentages(consumption));
  }, [consumption]);

  if (!Array.isArray(consumption)) {
    return <UnfindDataWarn Data={"Veri yüklenirken bir sorun oluştu!"} />;
  }

  if (dashboardConsumentIsLoading || dashboardConsumentIsFetching) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <ThreeDots
          visible={true}
          height="100"
          width="100"
          color={colors.primary.hex}
          ariaLabel="loading-indicator"
        />
      </div>
    );
  }

  if (consumption.length === 0) {
    return <UnfindDataWarn Data={warn} />;
  }

  return (
    <div className="  w-full h-[485px] overflow-auto">
      <div className="grid md:grid-cols-2 gap-4">
        {consumptionData.map((product, index) => (
          <div
            key={index}
            className={`flex items-center justify-between p-4 rounded-xl bg-[#EEEEEE] shadow-sm hover:shadow-md transition-shadow`}
          >
            <div className="flex items-center gap-4">
              <div>
                <h3 className={`text-lg font-medium ${colors.text.primary}`}>
                  {product.name}
                </h3>
                <div
                  className={`text-2xl font-bold mt-1`}
                  style={{ color: product.color }}
                >
                  %{product.percentage}
                </div>
                <div className="w-24 h-2 rounded-full bg-gray-100 mt-2">
                  <div
                    className="h-full rounded-full transition-all duration-500"
                    style={{
                      width: `${product.percentage}%`,
                      backgroundColor: product.color,
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="flex gap-2 justify-center items-center">
              <p
                className="text-3xl font-Bold"
                style={{ color: product.color }}
              >
                {product.amount}
              </p>
              <img
                src={product.icon}
                alt={product.name}
                className="w-10 h-10 object-contain"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
