import React from "react";
import { colors } from "../../../../Config/Colors";
import Loader from "../../../../Components/Utils/Loader";
import UnfindDataWarn from "../../../../Components/Utils/UnfindDataWarn";
import Alerts from "../../../../Components/Utils/Alert";
import Confirm from "../../../../Components/Utils/Confirm";
import { IoMdRefresh } from "react-icons/io";

export default function SettingPageContainer({
  isLoading,
  isError,
  children,
  alert,
  confirmProps,
  onRefresh,
}) {
  return (
    <>
      {/* Alert */}
      {alert?.show && (
        <div className="fixed z-50 left-1/2 top-4 -translate-x-1/2">
          <Alerts AlertChoice={alert.type} message={alert.message} />
        </div>
      )}

      {/* Confirm Modal */}
      {confirmProps && <Confirm {...confirmProps} />}

      {/* Loading State */}
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black/20 backdrop-blur-sm">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      )}

      {!isLoading && (
        <div className="fixed right-0 top-1/2 -translate-y-1/2 mr-4">
          <button
            onClick={onRefresh}
            className={`
                group relative
                w-14 h-14
                flex items-center justify-center
                ${colors.primary.bg} text-white 
                rounded-full shadow-lg
                hover:scale-110
                transition-all duration-300
              `}
            title="Ayarları İndir"
          >
            <IoMdRefresh className="w-8 h-8" />

            {/* Tooltip */}
            <span
              className="
                absolute right-full mr-2 
                bg-gray-800 text-white 
                px-3 py-1 rounded-lg
                text-sm whitespace-nowrap
                opacity-0 group-hover:opacity-100
                transition-opacity duration-300
              "
            >
              Ayarları İndir
            </span>
          </button>
        </div>
      )}
      {/* Error State */}
      {isError && !isLoading ? (
        <div className="fixed inset-0 -z-10 flex items-center justify-center">
          <UnfindDataWarn Data={"Cihaz bağlantısı yok!"} />

          {/* Refresh Button */}
        </div>
      ) : !isLoading ? (
        // Main Content
        <div
          className={`bg-white rounded-xl shadow-md p-6 ${colors.border.light}`}
        >
          {children}
        </div>
      ) : (
        ""
      )}
    </>
  );
}
