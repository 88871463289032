import React from "react";
import { colors } from "../../Config/Colors";

const StatisticDeviceFilterButton = ({ active, setActive, setFilterType }) => {
  const baseStyle = "transition-all duration-300 h-10";
  const activeStyle = `bg-white ${colors.primary.text} border-${colors.primary.main}`;
  const passiveStyle = `${colors.primary.bg} text-white border-white hover:bg-white hover:${colors.primary.text}`;

  const buttons = [
    { type: "today", label: "Bugün" },
    { type: "yesterday", label: "Dün" },
    { type: "week", label: "Haftalık" },
    { type: "thismonth", label: "Aylık" },
  ];

  return (
    <div className="flex gap-2 flex-wrap justify-end">
      {buttons.map((button, index) => (
        <button
          key={index}
          onClick={() => {
            setFilterType(button.type);
            setActive(index);
          }}
          className={`
            ${baseStyle}
            px-4 rounded-lg font-medium
            ${active === index ? activeStyle : passiveStyle}
            border text-xl
          `}
        >
          {button.label}
        </button>
      ))}
    </div>
  );
};

export default StatisticDeviceFilterButton;
