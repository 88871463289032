import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { devicesApi } from "./devicesApi";
import { deviceLogApi } from "./deviceLogApi";
import { firmLogApi } from "./firmLogApi";

import { usersApi } from "./usersApi";
import { cityApi } from "./cityApi";
import { townApi } from "./townApi";
import { firmApi } from "./firmApi";
import { productInfoApi } from "./productInfoApi";
import { productTypeApi } from "./productTypeApi";
import { authApi } from "./authApi";
import { acountApi } from "./acountApi";
import { StockApi } from "./stockApi";
import { BillsApi } from "./billsApi";
import { DashBoardApi } from "./dashboardApi";
import { reportsApi } from "./reportsApi";

export const store = configureStore({
  reducer: {
    [devicesApi.reducerPath]: devicesApi.reducer,
    [deviceLogApi.reducerPath]: deviceLogApi.reducer,
    [firmApi.reducerPath]: firmApi.reducer,
    [firmLogApi.reducerPath]: firmLogApi.reducer,
    [DashBoardApi.reducerPath]: DashBoardApi.reducer,
    [BillsApi.reducerPath]: BillsApi.reducer,
    [StockApi.reducerPath]: StockApi.reducer,
    [productInfoApi.reducerPath]: productInfoApi.reducer,
    [productTypeApi.reducerPath]: productTypeApi.reducer,
    [townApi.reducerPath]: townApi.reducer,
    [cityApi.reducerPath]: cityApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [acountApi.reducerPath]: acountApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(devicesApi.middleware)
      .concat(deviceLogApi.middleware)
      .concat(firmApi.middleware)
      .concat(firmLogApi.middleware)
      .concat(DashBoardApi.middleware)
      .concat(BillsApi.middleware)
      .concat(StockApi.middleware)
      .concat(productInfoApi.middleware)
      .concat(productTypeApi.middleware)
      .concat(townApi.middleware)
      .concat(cityApi.middleware)
      .concat(authApi.middleware)
      .concat(acountApi.middleware)
      .concat(usersApi.middleware)
      .concat(reportsApi.middleware);
  },
});

setupListeners(store.dispatch);

export { useGetConsumentReportQuery } from "./reportsApi";
export {
  useDeleteDeviceMutation,
  useUpdateDeviceInformationMutation,
  useUpdateSettingMutation,
  useGetQuotaToLoadedCodeQuery,
  useUpdateDeviceQuotaMutation,
  useUploadManualQRMutation,
} from "./devicesApi";

export {
  useAddFirmMutation,
  useGetFirmByIDQuery,
  useGetBelowFirmsByIDQuery,
  useUpdateFirmInfoMutation,
  useUpdateFirmSyncMutation,
  useUpdateTakeBackFirmProductQuotaMutation,
  useUpdateGiveFirmProductQuotaMutation,
  useDivideQuotaMutation,
  useDeleteFirmByIDMutation,
  useGetBelongUsersByFirmIDQuery,
  useGetDeviceByFirmIDQuery,
  useGetSyncByFirmIDQuery,
  useGetFirmQuotaByIDQuery,
  useGetOtherFirmsQuery,
  useGetFirmProductsQuery,
  useUpdateFirmProductQuotaMutation,
} from "./firmApi";

export {
  useGetFirmLogStatisticQuery,
  useGetFirmLogReportQuery,
  useGetFirmConsumentLogReportQuery,
  useLazyGetFirmLogReportQuery,
} from "./firmLogApi";

export {
  useGetDashBoardConsumentsQuery,
  useGetDashBoardBillsQuery,
  useGetDashBoardDevicesQuery,
} from "./dashboardApi";

export {
  useFetchBillsQuery,
  useCreateBillsMutation,
  useGetBillsQuery,
  useControllBillsMutation,
  useDeleteBillMutation,
} from "./billsApi";

export { useFetchStockQuery, useUpdateStockMutation } from "./stockApi";

export {
  useFetchProductInfoQuery,
  useAddProductInfoMutation,
  useUpdateProductInfoMutation,
  useDeleteProductInfoMutation,
} from "./productInfoApi";

export {
  useFetchProductTypeQuery,
  useAddProductTypeMutation,
  useUpdateProductTypeMutation,
  useDeleteProductTypeMutation,
} from "./productTypeApi";

export {
  useFetchCityQuery,
  useAddCityMutation,
  useUpdateCityMutation,
  useDeleteCityMutation,
} from "./cityApi";
export {
  useFetchTownQuery,
  useFetchTownWithyCityQuery,
  useAddTownMutation,
  useUpdateTownMutation,
  useDeleteTownMutation,
} from "./townApi";

export {
  useForgotPasswordMutation,
  useLoginMutation,
  useLogoutQuery,
} from "./authApi";

export {
  useGetMeQuery,
  useUpdateMeMutation,
  useUpdateMyPasswordMutation,
} from "./acountApi";

export {
  useFetchUserQuery,
  useFetchPlayMakerQuery,
  useAddUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useDeleteUserByIDMutation,
} from "./usersApi";
