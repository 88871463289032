import { useMemo, useState } from "react";
import useAuth from "../../../Hooks/useAuth";
import { NavLink, useLocation } from "react-router-dom";
import { BsFillPencilFill } from "react-icons/bs";
import { TbReportAnalytics } from "react-icons/tb";
import styles from "../../../CustomStyles";
import Loader from "../../../Components/Utils/Loader";
import UnfindDataWarn from "../../../Components/Utils/UnfindDataWarn";
import DataSearchBar from "../../../Components/SearchBar/DataSearchBar";
import FormatDateHourString from "../../../Components/Utils/FormatDateHourString";
import Syncronization from "./Syncronization";
import { useFirmDevicesData } from "../../../Hooks/useFirmDevicesData";
import CustomDeviceList from "../../../Components/Table/CustomDeviceList";
import { IoSettingsOutline } from "react-icons/io5";

function ControlDevice() {
  const location = useLocation();
  const { auth } = useAuth();
  const id = location.state.id;

  const {
    devices,
    isLoading,
    hasNoData,
    paginationNumber,
    setPaginationNumber,
    filteredData,
    isSearch,
    handleSearch,
    choice,
    setChoice,
  } = useFirmDevicesData({ id, token: auth.accessToken });

  const inputFieldName = {
    serialNo: "Seri Numarası",
    deviceName: "Cihaz İsmi",
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="grid w-full">
      <div
        className={`
        fixed 
        top-1/2 max-md:top-auto max-md:bottom-6
        -translate-y-1/2 max-md:translate-y-0
        right-6 max-md:right-1/2 max-md:translate-x-1/2
        z-50
      `}
      >
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className={`
            p-3 rounded-lg shadow-lg
            hover:bg-white
            hover:text-[#29A19C] bg-[#29A19C] text-white
            transition-all duration-300 relative
          `}
        >
          <IoSettingsOutline
            className={`
            w-7 h-7 
            transition-transform duration-500
            ${isMenuOpen ? "rotate-180" : ""}
          `}
          />
        </button>

        <div
          className={`
          absolute 
          right-0 max-md:right-1/2 max-md:translate-x-1/2
          max-md:bottom-full max-md:mb-2
          w-56 
          bg-white rounded-lg shadow-lg 
          border border-gray-100
          transition-all duration-300 
          origin-top max-md:origin-bottom
          ${
            isMenuOpen
              ? "opacity-100 scale-100 translate-y-0"
              : "opacity-0 scale-95 max-md:translate-y-2 -translate-y-2 pointer-events-none"
          }
        `}
        >
          <div className="p-1 space-y-1">
            <button
              onClick={() => {
                setChoice(0);
                setIsMenuOpen(false);
              }}
              className={`
                w-full px-4 py-3 rounded-md
                text-gray-800
                hover:bg-[#29A19C] hover:text-white
                transition-all duration-300
                flex items-center justify-start
              `}
            >
              Cihazlar
            </button>
            <button
              onClick={() => {
                setChoice(1);
                setIsMenuOpen(false);
              }}
              className={`
                w-full px-4 py-3 rounded-md
                text-gray-800
                hover:bg-[#29A19C] hover:text-white
                transition-all duration-300
                flex items-center justify-start
              `}
            >
              Senkronizasyon
            </button>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="flex w-full h-screen justify-center items-center">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : hasNoData ? (
        <div className="fixed top-0 left-0 flex w-full h-full justify-center items-center -z-10">
          <UnfindDataWarn Data={"Cihaz bulunamadı !"} />
        </div>
      ) : (
        <>
          <div className={`mb-12 mt-6 ${choice === 0 ? "" : "hidden"}`}>
            <div className="bg-white w-full p-10 rounded-xl shadow-xl transition-all duration-300 mb-4">
              <DataSearchBar
                Data={devices}
                handleSearch={handleSearch}
                inputFieldName={inputFieldName}
              />
            </div>
            <div className="mb-20">
              <CustomDeviceList
                data={
                  isSearch
                    ? filteredData
                    : devices.slice(
                        (paginationNumber - 1) * 10,
                        paginationNumber * 10
                      )
                }
              />
            </div>
          </div>
          <div className={`mb-20 mt-12 ${choice === 1 ? "" : "hidden"}`}>
            <Syncronization />
          </div>
        </>
      )}
    </div>
  );
}

export default ControlDevice;
