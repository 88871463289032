import { useGetNewFirmsBetweenDatesReportQuery } from "../../../store/reportsApi";
import useAuth from "../../../Hooks/useAuth";
import { useState, useEffect } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import ReportLayout from "../../../Layout/Reports/ReportLayout";
import Loader from "../../../Components/Utils/Loader";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";

export default function ReportNewFirmsBetweenDatesPage() {
  const { auth } = useAuth();
  const [startDate, setStartDate] = useState(getFirstDayOfMonth());
  const [endDate, setEndDate] = useState(getLastDayOfMonth());
  const [shouldFetch, setShouldFetch] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const token = auth.accessToken;

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  function getFirstDayOfMonth() {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
  }

  function getLastDayOfMonth() {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0);
  }

  const {
    data: newFirmsReport,
    isLoading,
    error,
  } = useGetNewFirmsBetweenDatesReportQuery(
    {
      token,
      startDate: startDate ? startDate.toISOString() : "undefined",
      endDate: endDate ? endDate.toISOString() : "undefined",
      timeZone: timeZone || "UTC",
    },
    { skip: !shouldFetch }
  );

  useEffect(() => {
    if (newFirmsReport && newFirmsReport.reports) {
      setParsedData(newFirmsReport.reports);
      setShouldFetch(false);
    }
  }, [newFirmsReport]);

  const handleSearch = () => {
    if (!startDate || !endDate) {
      return;
    }
    setShouldFetch(true);
  };

  // Dosya adı oluşturma
  const fileName = `Aramizda_Katilan_Firmalar_${
    startDate ? startDate.toISOString().split("T")[0] : ""
  }_${endDate ? endDate.toISOString().split("T")[0] : ""}.xlsx`;

  // Excel verilerini hazırlama
  const excelData = parsedData.map((item) => ({
    "Firma Adı": item.firmName,
    "Ana Firma": item.mainFirm,
    Şehir: item.city,
    İlçe: item.town,
    Telefon: item.tel,
    "E-posta": item.email,
    "Oluşturulma Tarihi": item.createdInfo,
  }));

  return (
    <ReportLayout
      title="Aramızda Katılan Firmalar Raporu"
      fileName={fileName}
      excelData={excelData}
    >
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <Grid container spacing={3} sx={{ maxWidth: 800 }}>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Başlangıç Tarihi"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
              format="dd/MM/yyyy"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Bitiş Tarihi"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
              format="dd/MM/yyyy"
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <Button
          variant="contained"
          onClick={handleSearch}
          sx={{ minWidth: 200, borderRadius: 2 }}
          disabled={!startDate || !endDate}
        >
          Raporu Getir
        </Button>
      </Box>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            marginTop: 4,
          }}
        >
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </Box>
      )}
      {error && (
        <Typography align="center" color="error">
          Hata: {error.message}
        </Typography>
      )}
      {(!isLoading || !error) && parsedData.length > 0 && (
        <Box sx={{ overflowX: "auto" }}>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>Firma Adı</th>
                <th style={tableHeaderStyle}>Ana Firma</th>
                <th style={tableHeaderStyle}>Şehir</th>
                <th style={tableHeaderStyle}>İlçe</th>
                <th style={tableHeaderStyle}>Telefon</th>
                <th style={tableHeaderStyle}>E-posta</th>
                <th style={tableHeaderStyle}>Oluşturulma Tarihi</th>
              </tr>
            </thead>
            <tbody>
              {parsedData.map((item, index) => (
                <tr
                  key={index}
                  style={index % 2 === 0 ? {} : alternateRowStyle}
                >
                  <td style={tableCellStyle}>{item.firmName}</td>
                  <td style={tableCellStyle}>{item.mainFirm}</td>
                  <td style={tableCellStyle}>{item.city}</td>
                  <td style={tableCellStyle}>{item.town}</td>
                  <td style={tableCellStyle}>{item.tel}</td>
                  <td style={tableCellStyle}>{item.email}</td>
                  <td style={tableCellStyle}>{item.createdInfo}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      )}
    </ReportLayout>
  );
}

const tableHeaderStyle = {
  backgroundColor: "#222831",
  color: "white",
  padding: "12px",
  textAlign: "center",
  fontWeight: "bold",
  borderBottom: "2px solid #ddd",
};

const tableCellStyle = {
  padding: "12px",
  borderBottom: "1px solid #ddd",
  textAlign: "center",
};

const alternateRowStyle = {
  backgroundColor: "#f0f0f0",
};
