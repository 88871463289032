export const colors = {
  primary: {
    main: "#29A19C",
    light: "#2DC8C3",
    dark: "#1F7A77",
    gradient: "from-[#29A19C] to-[#2DC8C3]",
    gradientLight: "from-[#29A19C]/5 to-transparent",
    border: "border-[#29A19C]",
    text: "text-[#29A19C]",
    hover: "hover:bg-[#29A19C]",
    bg: "bg-[#29A19C]",
    bgLight: "bg-[#29A19C]/10",
    hoverBgLight: "hover:bg-[#29A19C]/10",
    hoverText: "hover:text-[#29A19C]",
  },
  status: {
    success: {
      text: "text-green-600",
      bg: "bg-green-100",
    },
    error: {
      text: "text-red-600",
      bg: "bg-red-100",
    },
    warning: {
      text: "text-orange-500",
      bg: "bg-orange-100",
    },
    inactive: {
      text: "text-gray-600",
      bg: "bg-gray-100",
    },
  },
  text: {
    primary: "text-gray-800",
    secondary: "text-gray-700",
    tertiary: "text-gray-600",
    light: "text-gray-500",
    white: "text-white",
    whiteAlpha: "text-white/80",
  },
  background: {
    main: "bg-white",
    lighter: "bg-gray-50",
    light: "bg-gray-100",
    modal: "bg-gray-300",
    card: "bg-white",
  },
  border: {
    light: "border-gray-100",
    main: "border-gray-200",
  },
};
