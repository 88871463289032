import { ThreeDots } from "react-loader-spinner";
export default function Loader({ Visible, Height, Width, Color }) {
  return (
    <ThreeDots
      visible={Visible}
      height={Height}
      width={Width}
      color={"#29A19C"}
      ariaLabel="blocks-loading"
      wrapperStyle={{}}
      wrapperClass="blocks-wrapper"
    />
  );
}
