import { Outlet, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
//Icons
import { TbListDetails } from "react-icons/tb";
import { MdOutlineKeyboardBackspace, MdCoffeeMaker } from "react-icons/md";
import { SlGraph } from "react-icons/sl";

import styles from "../../CustomStyles";
import FormatDateHourString from "../../Components/Utils/FormatDateHourString";
import TopBar from "../../Components/Utils/TopBar";
import useAuth from "../../Hooks/useAuth";
import { colors } from "../../Config/Colors";

function InfoDevicePage() {
  const location = useLocation();
  const { deviceName, serialNo, createdInfo, updatedInfo, isActive } =
    location.state;
  const { auth } = useAuth();

  const links = [
    {
      to: "Genel",
      state: {
        id: location.state.id,
        serialNo: location.state.serialNo,
        deviceName: location.state.deviceName,
        createdInfo: location.state.createdInfo,
        updatedInfo: location.state.updatedInfo,
        isActive: location.state.isActive,
      },
      label: <p className={` text-2xl max-md:hidden`}>Genel Bilgiler</p>,
      icon: <MdCoffeeMaker className={`w-8 h-8`} />,
    },
    ...(auth.role === "admin" || auth.role === "management"
      ? [
          {
            to: "İstatistik",
            state: {
              id: location.state.id,
              serialNo: location.state.serialNo,
              deviceName: location.state.deviceName,
              createdInfo: location.state.createdInfo,
              updatedInfo: location.state.updatedInfo,
              isActive: location.state.isActive,
            },
            label: <p className={`text-2xl max-md:hidden`}>Tüketim</p>,
            icon: <SlGraph className={`w-8 h-8`} />,
          },
          {
            to: "Rapor",
            state: {
              id: location.state.id,
              serialNo: location.state.serialNo,
              deviceName: location.state.deviceName,
              createdInfo: location.state.createdInfo,
              updatedInfo: location.state.updatedInfo,
              isActive: location.state.isActive,
            },
            label: <p className={`text-2xl max-md:hidden`}>Rapor</p>,
            icon: <TbListDetails className={`w-8 h-8`} />,
          },
        ]
      : []),
  ];

  const backLink = {
    path: "/Anasayfa/Cihaz",
    icon: <MdOutlineKeyboardBackspace className={`w-8 h-8`} />,
  };

  return (
    <div className="min-h-screen px-16 max-md:px-0">
      {/* Top Bar */}
      <TopBar links={links} backLink={backLink} />

      {/* Main Content */}
      <div className="pb-64 pt-4">
        <Outlet />
      </div>

      {/* Bottom Info Bar */}
      <div
        className={`
          fixed bottom-0 left-0 right-0 
          ${colors.background.main} border-t ${colors.border.light}
          md:left-[20%] md:right-[20%] md:bottom-8 md:rounded-xl md:border
          shadow-xl backdrop-blur-sm bg-opacity-95
          transition-all duration-300
        `}
      >
        <div className="flex justify-evenly p-4">
          {/* Device Name */}
          <div className="flex flex-col gap-2">
            <span className={` text-lg font-medium ${colors.text.tertiary}`}>
              Cihaz
            </span>
            <span
              className={`text-xl font-semibold ${colors.background.light} px-4 py-2 rounded-lg ${colors.text.primary}`}
            >
              {deviceName}
            </span>
          </div>

          {/* Serial Number */}
          <div className="flex flex-col gap-2">
            <span className={`text-lg font-medium ${colors.text.tertiary}`}>
              Seri No
            </span>
            <span
              className={`text-xl font-semibold ${colors.background.light} px-4 py-2 rounded-lg ${colors.text.secondary}`}
            >
              {serialNo}
            </span>
          </div>

          {/* Creation Date - Hidden on mobile */}
          <div className="max-md:hidden flex flex-col gap-2">
            <span className={`text-lg font-medium ${colors.text.tertiary}`}>
              Oluşturulma
            </span>
            <span
              className={`text-xl font-semibold ${colors.background.light} px-4 py-2 rounded-lg ${colors.text.secondary}`}
            >
              {FormatDateHourString(createdInfo)}
            </span>
          </div>

          {/* Last Update - Hidden on mobile */}
          <div className="max-md:hidden flex flex-col gap-2">
            <span className={`text-lg font-medium ${colors.text.tertiary}`}>
              Son Güncelleme
            </span>
            <span
              className={`text-xl font-semibold ${colors.background.light} px-4 py-2 rounded-lg ${colors.text.secondary}`}
            >
              {FormatDateHourString(updatedInfo)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoDevicePage;
