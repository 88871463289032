import { useState } from "react";
import { MdArrowForwardIos, MdOutlineArrowBackIos } from "react-icons/md";
// Sayfa numaralarını hesaplayan yardımcı fonksiyon
const calculatePageNumbers = (currentPage, totalPages) => {
  const maxVisiblePages = 5;
  let pageNumbers = [];

  if (totalPages <= maxVisiblePages) {
    // Toplam sayfa sayısı max görünür sayfa sayısından azsa tüm sayfaları göster
    pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  } else {
    // Başlangıç ve bitiş sayfalarını hesapla
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = startPage + maxVisiblePages - 1;

    // Bitiş sayfası toplam sayfa sayısını aşıyorsa düzelt
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    pageNumbers = Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
  }

  return pageNumbers;
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = calculatePageNumbers(currentPage, totalPages);

  return (
    <div className="flex items-center justify-center gap-1">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={`
          p-2 rounded-lg transition-all duration-300
          ${
            currentPage === 1
              ? "text-white/40 cursor-not-allowed"
              : "text-white hover:bg-white/20"
          }
        `}
      >
        <MdOutlineArrowBackIos className="w-4 h-4" />
      </button>

      {pageNumbers.map((number) => (
        <button
          key={number}
          onClick={() => onPageChange(number)}
          className={`
            w-8 h-8 rounded-lg transition-all duration-300
            ${
              currentPage === number
                ? "bg-white/20 text-white"
                : "text-white hover:bg-white/10"
            }
          `}
        >
          {number}
        </button>
      ))}

      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`
          p-2 rounded-lg transition-all duration-300
          ${
            currentPage === totalPages
              ? "text-white/40 cursor-not-allowed"
              : "text-white hover:bg-white/20"
          }
        `}
      >
        <MdArrowForwardIos className="w-4 h-4" />
      </button>
    </div>
  );
};

const ItemNumber = ({ length }) => {
  return <div className="text-sm text-white/80">Toplam {length} firma</div>;
};

export default function FirmTable({ data }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 2; // Her sayfada 2 firma göster
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const currentData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="flex flex-col h-60 justify-between w-full">
      <div className="flex flex-col gap-2 overflow-auto h-48">
        {currentData.map((firm, index) => (
          <div
            key={index}
            className={`
              flex flex-row gap-4 w-full h-20 items-center justify-between px-6
              ${
                index !== currentData.length - 1
                  ? "border-b border-white/10"
                  : ""
              }
              hover:bg-white/5 transition-colors duration-300
              shadow-[0_2px_8px_rgba(0,0,0,0.15)]
              bg-white/5
              backdrop-blur-sm
              rounded-lg
              mb-2
            `}
          >
            {/* Tarih Bilgisi */}
            <div className="flex items-center justify-center">
              <p className="text-xl text-white/90">
                {firm.lastOrderDate
                  ? `${firm.daysSinceLastOrder} gün önce`
                  : "Henüz fatura kesilmemiş."}
              </p>
            </div>

            {/* Firma Adı */}
            <div className="flex items-center justify-center">
              <p className="text-lg text-white font-medium">
                {firm.firmApellation}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="flex items-center justify-between mt-4">
          <ItemNumber length={data.length} />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
}
