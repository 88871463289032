import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { colors } from "../../Config/Colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function DataSearchBar({ Data, handleSearch, inputFieldName }) {
  const [input, setInput] = useState({});
  const [clean, setClean] = useState(false);

  let filteredData = [];

  // MUI tema özelleştirmesi - renk kodu düzeltildi
  const theme = createTheme({
    palette: {
      primary: {
        main: "#29A19C", // Doğrudan hex kodu olarak kullanıyoruz
      },
    },
    typography: {
      fontFamily: "inherit",
    },
  });

  const cleanBar = () => {
    setClean(true);
    setInput({});
  };

  useEffect(() => {
    if (clean === false) {
      filteredData = Data?.filter((Data) => {
        let include = true;
        Object.entries(input).forEach(([key, value]) => {
          if (
            value &&
            !Data[key]?.toString().toLowerCase().includes(value.toLowerCase())
          ) {
            include = false;
          }
        });
        return include;
      });

      if (clean === false && Object.keys(input).length > 0) {
        handleSearch(filteredData, true);
      }
    }
    if (clean === true) {
      handleSearch("", false);
    }
    setClean(false);
  }, [input, Data]);

  return (
    <ThemeProvider theme={theme}>
      <div className="flex gap-6 flex-col w-full">
        {/* Input Alanları */}
        <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4">
          {Object.entries(inputFieldName).map(([key, value]) => (
            <TextField
              key={key}
              id="component-outlined"
              maxLength="40"
              label={value}
              value={input[key] ?? ""}
              onChange={(e) => {
                var newValue = e.target.value;
                setInput((prevInput) => ({ ...prevInput, [key]: newValue }));
              }}
              variant="outlined"
              size="medium"
              InputProps={{
                style: { fontSize: 16 }, // Font boyutu 16px olarak ayarlandı
              }}
              InputLabelProps={{
                style: { fontSize: 16 }, // Label font boyutu 16px olarak ayarlandı
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#29A19C",
                  },
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "#29A19C",
                },
              }}
            />
          ))}
        </div>

        {/* Temizle Butonu */}
        <div className="flex justify-end">
          <button
            onClick={cleanBar}
            className={`
              px-6 py-2.5 rounded-lg font-medium 
              ${colors.primary.text} ${colors.background.main} 
              border-2 ${colors.primary.border} 
              ${colors.primary.hover} hover:text-white 
              transition-all duration-300
              shadow-sm hover:shadow-md
              text-lg
            `}
          >
            Temizle
          </button>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default DataSearchBar;
