import { useEffect, useState } from "react";
import { MdArrowForwardIos, MdOutlineArrowBackIos } from "react-icons/md";
import addedBill from "../../../Assets/icons/added_bill.png";
import deletedBill from "../../../Assets/icons/deleted_bill.png";
import uploadedBill from "../../../Assets/icons/uploaded_bill.png";
import getreturnBill from "../../../Assets/icons/getreturn_bill.png";
import { HiHomeModern } from "react-icons/hi2";
import { GrBasket } from "react-icons/gr";
import { RiCupFill } from "react-icons/ri";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { colors } from "../../../Config/Colors";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className="flex items-center justify-center gap-1">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={`
          p-1.5 rounded-lg transition-all duration-300
          ${
            currentPage === 1
              ? "text-gray-400 cursor-not-allowed"
              : `${colors.primary.text} hover:${colors.primary.bg} hover:text-white`
          }
        `}
      >
        <MdOutlineArrowBackIos className="w-3.5 h-3.5" />
      </button>

      <div className={`text-sm ${colors.text.secondary}`}>
        {currentPage} / {totalPages}
      </div>

      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`
          p-1.5 rounded-lg transition-all duration-300
          ${
            currentPage === totalPages
              ? "text-gray-400 cursor-not-allowed"
              : `${colors.primary.text} hover:${colors.primary.bg} hover:text-white`
          }
        `}
      >
        <MdArrowForwardIos className="w-3.5 h-3.5" />
      </button>
    </div>
  );
};

const ItemNumber = ({ length }) => {
  return (
    <div className={`text-xs ${colors.text.secondary}`}>
      Toplam {length} fatura
    </div>
  );
};

function timeAgo(createdInfo) {
  const now = new Date();
  const createdTime = new Date(createdInfo);
  const diffInMilliseconds = now - createdTime;

  const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
  const diffInWeeks = Math.floor(diffInDays / 7);
  const diffInMonths = Math.floor(diffInDays / 30); // Approximate

  if (diffInMinutes < 60) {
    return `${diffInMinutes} dakika önce`;
  } else if (diffInHours < 24) {
    return `${diffInHours} saat önce`;
  } else if (diffInDays < 7) {
    return `${diffInDays} gün önce`;
  } else if (diffInWeeks < 4) {
    return `${diffInWeeks} hafta önce`;
  } else {
    return `${diffInMonths} ay önce`;
  }
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

function parseProductName(productName) {
  return productName.slice(0, productName.indexOf("-"));
}

export default function BillTable({ data }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [open, setOpen] = useState(false);
  const [selectedBill, setSelectedBill] = useState({
    billType: "",
    billerName: "",
    createdInfo: "",
    billNo: "",
    firmApellation: "",
    productName: "",
    price: 0,
    income: 0,
    quota: 0,
  });

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentData = data.slice(firstIndex, lastIndex);

  const handleOpen = (index) => {
    setSelectedBill(data[index]);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const onPageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };

  return (
    <div className="flex flex-col h-full justify-between w-full">
      <div className="flex-1 overflow-y-auto">
        {currentData.map((bill, index) => (
          <div
            key={index}
            onClick={() => handleOpen((currentPage - 1) * itemsPerPage + index)}
            className={`
              flex items-cente cursor-pointer h-24 mt-4 items-center
              ${
                index !== currentData.length - 1
                  ? `border-b ${colors.border.light}`
                  : ""
              }
              hover:bg-gray-50 transition-all duration-300 gap-6 
            `}
          >
            {/* Tarih Bilgisi */}
            <div className="w-24 flex flex-col ">
              <span className={`text-xs ${colors.text.secondary}`}>
                {timeAgo(bill.createdInfo)}
              </span>
              <span className={`text-xs ${colors.text.tertiary}`}>
                {formatDate(bill.createdInfo)}
              </span>
            </div>

            {/* İkon */}
            <div className="flex justify-center">
              <img
                className="w-10 h-10"
                src={
                  bill.billType === "Bill"
                    ? addedBill
                    : bill.billType === "FreeUpload"
                    ? uploadedBill
                    : bill.billType === "GetReturn"
                    ? getreturnBill
                    : deletedBill
                }
                alt="Bill type icon"
              />
            </div>

            {/* İşlem Tipi, Firma ve Ürün Bilgileri - Alt Alta */}
            <div className="flex-1">
              <div className="flex flex-col gap-1">
                <span className={`text-sm font-medium ${colors.text.primary}`}>
                  {bill.billType === "Bill"
                    ? "Fatura Eklendi"
                    : bill.billType === "FreeUpload"
                    ? "Bedelsiz Yükleme"
                    : bill.billType === "GetReturn"
                    ? "İade Alındı"
                    : "Fatura Silindi"}
                </span>
                <div className="flex items-center gap-2">
                  <HiHomeModern className="w-3.5 h-3.5 text-blue-500" />
                  <span className={`text-xs ${colors.text.secondary}`}>
                    {bill.firmApellation}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <GrBasket className="w-3.5 h-3.5 text-gray-500" />
                  <span className={`text-xs ${colors.text.secondary}`}>
                    {parseProductName(bill.productName)}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <RiCupFill className="w-3.5 h-3.5 text-indigo-500" />
                  <span className={`text-xs ${colors.text.secondary}`}>
                    {bill.quota}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="bill-details-modal"
      >
        <Box
          className={`
          absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
          bg-white rounded-xl shadow-xl p-6 w-96
          ${colors.border.light}
        `}
        >
          <h2 className={`text-xl font-semibold mb-4 ${colors.text.primary}`}>
            {selectedBill.billType === "Bill"
              ? "Yüklenen Fatura Detayları"
              : selectedBill.billType === "FreeUpload"
              ? "Bedelsiz Yükleme Detayları"
              : selectedBill.billType === "GetReturn"
              ? "İade Detayları"
              : "Silinen Fatura Detayları"}
          </h2>

          <div className="space-y-3">
            <DetailRow label="İşlemi Yapan" value={selectedBill.billerName} />
            <DetailRow
              label="İşlem Tarihi"
              value={formatDate(selectedBill.createdInfo)}
            />
            <DetailRow label="Fatura No" value={selectedBill.billNo} />
            <DetailRow label="Firma" value={selectedBill.firmApellation} />
            <DetailRow label="Ürün" value={selectedBill.productName} />
            <DetailRow label="Miktar" value={selectedBill.quota} />

            {!(
              selectedBill.billType === "FreeUpload" ||
              selectedBill.billType === "GetReturn"
            ) && (
              <>
                <DetailRow
                  label="Birim Fiyat"
                  value={`${selectedBill.price.toFixed(2)} ₺`}
                />
                <DetailRow label="Gelir" value={`${selectedBill.income} ₺`} />
              </>
            )}
          </div>
        </Box>
      </Modal>

      <div className="flex items-center justify-between mt-2 px-2">
        <div className={`text-sm ${colors.text.secondary}`}>
          Toplam {data.length} fatura
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`
              p-1.5 rounded-lg transition-all duration-300
              ${
                currentPage === 1
                  ? "text-gray-400 cursor-not-allowed"
                  : `${colors.primary.text} hover:${colors.primary.bg} hover:text-white`
              }
            `}
          >
            <MdOutlineArrowBackIos className="w-4 h-4" />
          </button>

          <span className={`text-sm ${colors.text.secondary}`}>
            {currentPage} / {totalPages}
          </span>

          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`
              p-1.5 rounded-lg transition-all duration-300
              ${
                currentPage === totalPages
                  ? "text-gray-400 cursor-not-allowed"
                  : `${colors.primary.text} hover:${colors.primary.bg} hover:text-white`
              }
            `}
          >
            <MdArrowForwardIos className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );
}

// Modal için yardımcı bileşen
const DetailRow = ({ label, value }) => (
  <div className="flex justify-between items-center">
    <span className={`font-medium ${colors.text.secondary}`}>{label}:</span>
    <span className={colors.text.primary}>{value}</span>
  </div>
);
