import React from "react";
import FormatDateHourString from "../../Components/Utils/FormatDateHourString";
import { colors } from "../../Config/Colors";

const DeviceInfoList = ({ input }) => {
  return (
    <div
      className={`${colors.background.card} rounded-xl shadow-lg overflow-hidden`}
    >
      <div className="p-6">
        <div className="grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 gap-4">
          {input.firm?.firmName && (
            <div className={`p-3 ${colors.background.light} rounded-lg`}>
              <p className={`${colors.primary.text} text-xl mb-1`}>Firma</p>
              <p className={`${colors.text.light} text-lg font-bold`}>
                {input.firm.firmName}
              </p>
            </div>
          )}
          {input.serialNo && (
            <div className={`p-3 ${colors.background.light} rounded-lg`}>
              <p className={`${colors.primary.text} text-xl mb-1`}>Seri No</p>
              <p className={`${colors.text.light} text-lg font-bold`}>
                {input.serialNo}
              </p>
            </div>
          )}
          {input.cupNumber && (
            <div className={`p-3 ${colors.background.light} rounded-lg`}>
              <p className={`${colors.primary.text} text-xl mb-1`}>
                Göz Sayısı
              </p>
              <p className={`${colors.text.light} text-lg font-bold`}>
                {input.cupNumber}
              </p>
            </div>
          )}
          {input.lastConnectionDate && (
            <div className={`p-3 ${colors.background.light} rounded-lg`}>
              <p className={`${colors.primary.text} text-xl mb-1`}>
                Son Bağlantı
              </p>
              <p className={`${colors.text.light} text-lg font-bold`}>
                {FormatDateHourString(input.lastConnectionDate)}
              </p>
            </div>
          )}
          {input.note && (
            <div className={`p-3 ${colors.background.light} rounded-lg`}>
              <p className={`${colors.primary.text} text-xl mb-1`}>Not</p>
              <p className={`${colors.text.light} text-lg font-bold`}>
                {input.note}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeviceInfoList;
