import { AiOutlineCloudUpload } from "react-icons/ai";
import { TbReportAnalytics } from "react-icons/tb";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import DataSearchBar from "../SearchBar/DataSearchBar";
import * as XLSX from "xlsx";

export default function BillTopSearchBar({
  resultControll,
  hiddenFileInput,
  HandleUpload,
  searchBar,
  hideSearchBar,
  state,
  resultCreate,
  handleSearch,
  inputFieldName,
  setState,
  token,
  controll,
}) {
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        // Verileri dizi dizisi olarak almak için header: 1 kullanın
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      // İlk satırı başlık olarak kabul edin ve manuel olarak işleyin
      const headers = data[0];
      const rows = data.slice(1);
      console.log(headers);
      const Datas = rows.map((row) => {
        // Sütun başlıklarına göre verileri eşleştirin
        const obj = {};
        headers.forEach((header, index) => {
          obj[header] = row[index];
        });
        return {
          bayserNo: obj["BAYSER NO"],
          firmApellation: obj["CARİ HESAP ÜNVANI"],
          billNo: obj["FİŞ NUMARASI"],
          createdInfo: obj["TARİHİ"],
          shippingInfo: obj["SEVK AÇIKLAMA"],
          productName: obj["MALZEME AÇIKLAMASI"],
          productCode: obj["MALZEME KODU"],
          price: obj["SATIR TOPLAMI"] / obj["SATIR MİKTARI"],
          quota: obj["SATIR MİKTARI"],
          income: obj["SATIR TOPLAMI"],
        };
      });
      const Object = { bills: Datas, token: token };
      setState(1);
      controll(Object);
    });
  };

  return (
    <>
      <div className="flex my-3 gap-4 md:flex-row flex-col md:items-center">
        <div className="flex gap-3 items-center text-main xl:text-xl text-BASE">
          <div className="text-2xl text-gray-800">Fatura Listesi</div>
          <div className="bg-slate-800 text-2xl text-white px-2 rounded-full">
            {state === 0
              ? 0
              : state === 2
              ? resultCreate.data?.results || []
              : state === 1
              ? resultControll.data?.results
              : 0}
          </div>
        </div>

        <div className="flex gap-4">
          <button
            className="flex items-center gap-2 text-xl bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-300"
            onClick={() => {
              console.log("1-Clicked....");
              setState(0);
              hiddenFileInput.current.click();
            }}
          >
            <AiOutlineCloudUpload className="w-8 h-8" />
            <p>Fatura Yükle</p>
          </button>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={(e) => {
              const file = e.target.files[0];
              readExcel(file);
            }}
            accept=".xlsx, .xls"
            style={{ display: "none" }}
          />
        </div>

        <div>
          <button
            onClick={HandleUpload}
            className="flex items-center text-xl gap-2 bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition duration-300"
          >
            <TbReportAnalytics className="w-8 h-8" />
            <p>Fatura Kaydet</p>
          </button>
        </div>

        <button
          className="flex items-center gap-2 text-xl bg-gray-300 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-400 transition duration-300"
          onClick={hideSearchBar}
        >
          <p>FİLTRELE</p>
          <IoIosArrowUp
            className={`${
              searchBar ? "flex" : "hidden"
            } w-8 h-8 transition-all duration-500`}
          />
          <IoIosArrowDown
            className={`${
              searchBar ? "hidden" : "flex"
            } w-8 h-8 transition-all duration-500`}
          />
        </button>
      </div>
      <div
        className={`bg-white rounded-xl shadow transition-all duration-300 mb-4 ${
          searchBar ? "block p-10" : " overflow-hidden h-0"
        }`}
      >
        <DataSearchBar
          Data={
            state === 2 ? resultCreate.data?.bills : resultControll.data?.bills
          }
          handleSearch={handleSearch}
          inputFieldName={inputFieldName}
        />
      </div>
    </>
  );
}
