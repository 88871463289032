import { useState, useEffect } from "react";
import { useCreateBillsMutation, useControllBillsMutation } from "../store";

export default function useBillOperationData(token) {
  const [alert, setAlert] = useState(0);
  const [message, setMessage] = useState("");
  const [state, setState] = useState(0); // 0-empty 1-control 2-create

  const [create, resultCreate] = useCreateBillsMutation();
  const [controll, resultControll] = useControllBillsMutation();

  const handleApiResponse = (apiResponse, successMessage) => {
    if (apiResponse.isError) {
      setAlert(2);
      setMessage(apiResponse.error.data.message);
    }
    if (apiResponse.isSuccess) {
      setAlert(1);
      setMessage(successMessage);
    }
  };

  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        setAlert(0);
        setMessage("");
      }, 5000);
    }
  }, [alert]);

  useEffect(() => {
    console.log(resultCreate);
    handleApiResponse(resultCreate, "Faturalar Tanımlandı !");
  }, [resultCreate.isSuccess, resultCreate.isError]);

  useEffect(() => {
    handleApiResponse(resultControll, "Fatura Kontrolü Yapıldı !");
  }, [resultControll.isSuccess, resultControll.isError]);

  const HandleUpload = () => {
    const Object = {
      bills: resultControll.data?.bills.filter((item) => item.status),
      token: token,
    };
    setState(2);
    create(Object);
  };

  const sortData = (data, sortConfig) => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  };

  return {
    alert,
    message,
    state,
    setState,
    create,
    resultCreate,
    controll,
    resultControll,
    HandleUpload,
    sortData,
  };
}
