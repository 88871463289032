import React from "react";
import { colors } from "../../Config/Colors";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { BsFileEarmarkBarGraph } from "react-icons/bs";
import styles from "../../CustomStyles";

const CustomReportTable = ({
  data,
  reportType,
  itemsPerPage = 10,
  currentPage,
  setCurrentPage,
  onDetailClick,
  setInfo,
  setSelectedDate,
}) => {
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data.slice(startIndex, endIndex);

  // Rapor tipine göre tablo başlıkları
  const getHeaders = () => {
    switch (reportType) {
      case "Consument":
        return [
          { key: "date", label: "Tarih" },
          { key: "count", label: "Tüketim Kayıt Sayısı" },
          { key: "action", label: "İncele" },
        ];
      case "UpdateBill":
        return [
          { key: "date", label: "Tarih" },
          { key: "count", label: "Fatura Kayıt Sayısı" },
          { key: "action", label: "İncele" },
        ];
      case "UpdateInfo":
        return [
          { key: "date", label: "Tarih" },
          { key: "count", label: "Değişiklik Kayıt Sayısı" },
          { key: "action", label: "İncele" },
        ];
      default:
        return [];
    }
  };

  const handleDetailClick = (date) => {
    setSelectedDate(date);
    setInfo(true);
  };

  return (
    <div className="w-full">
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className={`border-b ${colors.border.light}`}>
              {getHeaders().map((header, index) => (
                <th
                  key={index}
                  className={`
                    px-6 py-4 
                    text-left text-sm font-semibold 
                    ${colors.text.primary}
                    whitespace-nowrap
                    bg-white
                    ${header.key === "action" ? "w-24 text-center" : ""}
                  `}
                >
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200">
            {currentData.map((item, rowIndex) => (
              <tr
                key={rowIndex}
                className={`
                  hover:${colors.background.lighter}
                  transition-colors duration-150
                `}
              >
                <td
                  className={`
                  px-6 py-4
                  text-sm
                  ${colors.text.primary}
                  whitespace-nowrap
                  bg-white
                `}
                >
                  {item.date}
                </td>
                <td
                  className={`
                  px-6 py-4
                  text-sm
                  ${colors.text.primary}
                  whitespace-nowrap
                  bg-white
                `}
                >
                  {item.logs?.length || 0} kayıt
                </td>
                <td
                  className={`
                  px-6 py-4
                  text-sm
                  whitespace-nowrap
                  bg-white
                  text-center
                `}
                >
                  <button
                    onClick={() => handleDetailClick(item.date)}
                    className={`${styles.button} w-fit mx-auto`}
                  >
                    <BsFileEarmarkBarGraph className={styles.buttonIcon} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex items-center justify-between px-6 py-4 bg-white border-t">
        <div className={`text-sm ${colors.text.secondary}`}>
          <span className="hidden sm:inline">
            Toplam {data.length} kayıttan{" "}
          </span>
          <span>
            {startIndex + 1}-{Math.min(endIndex, data.length)} arası
            gösteriliyor
          </span>
        </div>

        <div className="flex items-center gap-2">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(1, prev - 1))}
            disabled={currentPage === 1}
            className={`
              p-2 rounded-lg
              ${
                currentPage === 1
                  ? "opacity-50 cursor-not-allowed"
                  : `hover:${colors.background.lighter} cursor-pointer`
              }
              transition-all duration-200
            `}
          >
            <IoChevronBack className={`w-5 h-5 ${colors.text.primary}`} />
          </button>

          <span className="text-sm font-medium px-4">
            Sayfa {currentPage} / {totalPages}
          </span>

          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(totalPages, prev + 1))
            }
            disabled={currentPage === totalPages}
            className={`
              p-2 rounded-lg
              ${
                currentPage === totalPages
                  ? "opacity-50 cursor-not-allowed"
                  : `hover:${colors.background.lighter} cursor-pointer`
              }
              transition-all duration-200
            `}
          >
            <IoChevronForward className={`w-5 h-5 ${colors.text.primary}`} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomReportTable;
