import { useState, useCallback, useMemo } from "react";
import { useGetFirmQuery } from "../store/firmApi";

export function useFirmData(token) {
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [filteredData, setFilteredData] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  const {
    data: firmData,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useGetFirmQuery(
    { token },
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    }
  );

  const firms = useMemo(() => firmData?.data.firms || [], [firmData]);

  const handleSearch = useCallback((data, isSearch) => {
    setPaginationNumber(1);
    setFilteredData(data);
    setIsSearch(isSearch);
  }, []);

  return {
    firms,
    isLoading,
    isFetching,
    error,
    refetch,
    paginationNumber,
    setPaginationNumber,
    filteredData,
    isSearch,
    handleSearch,
  };
}
