import { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { colors } from "../../Config/Colors";

export function monthName(monthIndex) {
  const months = {
    1: "Ocak",
    2: "Şubat",
    3: "Mart",
    4: "Nisan",
    5: "Mayıs",
    6: "Haziran",
    7: "Temmuz",
    8: "Ağustos",
    9: "Eylül",
    10: "Ekim",
    11: "Kasım",
    12: "Aralık",
  };
  return months[monthIndex] || "";
}

const CHART_COLORS = {
  Çay: "#5F8D4E",
  "Türk Kahvesi": "#6d4a3a",
  "Filtre Kahve": "#322110",
};

export default function ConsumptionGraph({
  monthlyConsumption = [],
  dashboardType,
  dashboardConsumentIsLoading,
  warn,
  dashboardConsumentIsFetching,
}) {
  const [data, setData] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [visibleMonths, setVisibleMonths] = useState(4);

  const GRAPH_HEIGHT = 425; // Sabit graph yüksekliği
  const BOTTOM_PADDING = 40; // Ay isimleri için alt padding
  const GRAPH_AREA_HEIGHT = GRAPH_HEIGHT - BOTTOM_PADDING; // Gerçek graph alanı yüksekliği

  useEffect(() => {
    setData([]);
  }, [dashboardType]);

  // Y ekseni değerlerini hesapla
  const calculateYAxisValues = (maxValue) => {
    // Yuvarlanmış maksimum değer (bir üst 1000'e yuvarla)
    const roundedMax = Math.floor(maxValue / 1000) * 1000;

    // 5 eşit parçaya böl
    const interval = roundedMax / 5;

    return [
      0,
      Math.round(interval),
      Math.round(interval * 2),
      Math.round(interval * 3),
      Math.round(interval * 4),
      roundedMax,
    ];
  };

  useEffect(() => {
    const processedData = monthlyConsumption.map((log) => {
      const monthData = {
        month: monthName(log.monthIndex),
        values: {
          Çay: 0,
          "Türk Kahvesi": 0,
          "Filtre Kahve": 0,
        },
      };

      log.productConsument.forEach((product) => {
        if (monthData.values.hasOwnProperty(product.productName)) {
          monthData.values[product.productName] = product.consument;
        }
      });

      return monthData;
    });

    // Maksimum değeri bul
    const max = Math.max(
      ...processedData.flatMap((item) => Object.values(item.values))
    );
    setMaxValue(max);
    setData(processedData);
  }, [monthlyConsumption]);

  const yAxisValues = calculateYAxisValues(maxValue);

  // Ekran genişliğini izle - daha detaylı breakpoint'ler
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 480) {
        // xs
        setVisibleMonths(1);
      } else if (width < 640) {
        // sm
        setVisibleMonths(2);
      } else if (width < 768) {
        // md
        setVisibleMonths(3);
      } else if (width < 1024) {
        // lg
        setVisibleMonths(4);
      } else if (width < 1280) {
        // xl
        setVisibleMonths(4);
      } else {
        setVisibleMonths(4);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Son n ayı al
  const visibleData = data.slice(-visibleMonths);

  if (dashboardConsumentIsLoading || dashboardConsumentIsFetching) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <ThreeDots
          visible={true}
          height="100"
          width="100"
          color={colors.primary.hex}
          ariaLabel="loading-indicator"
        />
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className="flex w-full h-full items-center justify-center font-medium tracking-wide">
        <p className={`text-xl ${colors.text.primary} p-4 rounded-md`}>
          {warn}
        </p>
      </div>
    );
  }

  return (
    <div className="w-full h-full overflow-hidden py-4">
      <div className="relative" style={{ height: `${GRAPH_HEIGHT}px` }}>
        {/* Y Ekseni ve Grid Çizgileri */}
        <div
          className="absolute flex flex-col justify-between inset-0"
          style={{ height: `${GRAPH_AREA_HEIGHT + 10}px` }}
        >
          {yAxisValues.reverse().map((value, index) => (
            <div key={index} className="flex items-center w-full">
              <span className="w-16 text-right pr-4 text-lg text-gray-500">
                {value}
              </span>
              <div className="flex-1 h-[1px] bg-gray-100" />
            </div>
          ))}
        </div>

        {/* Barlar */}
        <div
          className="absolute inset-0 pl-20 flex items-end"
          style={{ height: `${GRAPH_AREA_HEIGHT}px` }}
        >
          {visibleData.map((month, monthIndex) => (
            <div
              key={monthIndex}
              className="flex-1 flex flex-col items-center group relative"
            >
              {/* Tooltip - Tüm Ürün Bilgileri */}
              <div className="  opacity-0 group-hover:opacity-100 absolute -left-20 bottom-36 bg-gray-800 text-white p-2 rounded text-xs whitespace-nowrap transition-opacity z-50">
                <div className=" text-xl font-medium mb-1">{month.month}</div>
                {Object.entries(month.values).map(([key, value], i) => (
                  <div key={i} className="flex items-center gap-2">
                    <div
                      className="w-2 h-2 rounded-full"
                      style={{ backgroundColor: CHART_COLORS[key] }}
                    />
                    <span className=" text-lg font-medium">{key}:</span>
                    <span className=" text-lg font-medium">{value}</span>
                  </div>
                ))}
              </div>

              {/* Bar Grupları */}
              <div className="w-full flex justify-center items-end gap-1 h-full group">
                {Object.entries(month.values).map(([key, value], i) => {
                  const heightPercentage = (value / maxValue) * 100;
                  const barHeight =
                    (heightPercentage * GRAPH_AREA_HEIGHT) / 100;

                  return (
                    <div
                      key={i}
                      className="relative"
                      style={{ height: barHeight }}
                    >
                      <div
                        className="w-5 h-full transition-all duration-300 hover:opacity-80"
                        style={{
                          backgroundColor: CHART_COLORS[key],
                          borderRadius: "2px 2px 0 0",
                        }}
                      />
                    </div>
                  );
                })}
              </div>

              {/* Ay İsmi */}
              <div
                className="absolute bottom-0 text-center text-lg text-gray-500 whitespace-nowrap"
                style={{ transform: "translateY(30px)" }}
              >
                {month.month}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Lejant */}
      <div className="flex flex-wrap justify-center gap-4 sm:gap-8 mt-2">
        {Object.entries(CHART_COLORS).map(([key, color]) => (
          <div key={key} className="flex items-center gap-2">
            <div
              className="w-6 h-6 rounded"
              style={{ backgroundColor: color }}
            />
            <span className="text-xl text-gray-600 whitespace-nowrap">
              {key}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
