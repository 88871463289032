import { useState, useEffect } from "react";
import { useLazyGetFirmLogReportQuery } from "../store";
import { GenerateFormattedDate } from "../Components/Utils/Date/GenerateFormattedDate";

export const useFirmReportData = (id, token) => {
  const [info, setInfo] = useState(false);
  const [input, setInput] = useState({
    id,
    token,
    type: "",
    createdInfo: "",
    createdInfoSecond: "",
  });
  const [filterType, setFilterType] = useState("Day");
  const [date, setDate] = useState(new Date());
  const [dateSecond, setDateSecond] = useState(new Date());
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [getReport, { data, isFetching, isLoading, isError }] =
    useLazyGetFirmLogReportQuery();
  const [Datas, setDatas] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [prevInputType, setPrevInputType] = useState(input.type);

  useEffect(() => {
    if (input.type !== prevInputType) {
      setInfo(false);
      setDatas([]);
    } else {
      setDatas(
        data?.data?.filter((item) => item.logs && item.logs.length > 0) || []
      );
    }
    setPrevInputType(input.type);
  }, [data, input.type]);

  useEffect(() => {
    setInput((prev) => ({ ...prev, createdInfo: GenerateFormattedDate(date) }));
  }, [date]);

  useEffect(() => {
    setInput((prev) => ({
      ...prev,
      createdInfoSecond: GenerateFormattedDate(dateSecond),
    }));
  }, [dateSecond]);

  const handleGetLogData = () => {
    if (input.createdInfo && input.type) {
      getReport(input);
    }
  };

  const handleFilterType = (type) => {
    setFilterType(type);
  };

  return {
    info,
    setInfo,
    input,
    setInput,
    filterType,
    setFilterType,
    date,
    setDate,
    dateSecond,
    setDateSecond,
    paginationNumber,
    setPaginationNumber,
    Datas,
    isFetching,
    isLoading,
    isError,
    selectedDate,
    setSelectedDate,
    handleGetLogData,
    handleFilterType,
  };
};
