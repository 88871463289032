import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import DeviceInfoEditForm from "../../../Forms/DeviceForm/DeviceInfoEditForm";
import { colors } from "../../../Config/Colors";

import useDeviceData from "../../../Hooks/useDeviceData";
import useUpdateDeviceData from "../../../Hooks/useUpdateDeviceData";

import useAuth from "../../../Hooks/useAuth";
import Syncronization from "../../../Components/Device/Syncronization";
import UnfindDataWarn from "../../../Components/Utils/UnfindDataWarn";
import Alerts from "../../../Components/Utils/Alert";
import Confirm from "../../../Components/Utils/Confirm";
import Loader from "../../../Components/Utils/Loader";

function UpdateInfo() {
  const location = useLocation();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const id = location.state.id;
  const [confirm, setConfirm] = useState(false);
  const [alert, setAlert] = useState(0);
  const [message, setMessage] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [designedInput, setDesignedInput] = useState({});
  const [device, setDevice] = useState();

  const { getDevice, deviceData, deviceIsLoading, deviceError } = useDeviceData(
    { token, id, setAlert, setConfirm, setMessage }
  );
  const { updateDevice, updateDeviceIsLoading } = useUpdateDeviceData({
    getDevice,
    token,
    id,
    setAlert,
    setConfirm,
    setMessage,
  });

  useEffect(() => {
    getDevice({ token, id });
  }, [getDevice, token, id]);

  useEffect(() => {
    if (deviceData) {
      setDevice(deviceData?.data?.device);
    }
  }, [deviceData]);

  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        setAlert(0);
        setMessage("");
      }, 3000);
    }
  }, [alert]);

  const handleCloseModel = (boolean) => {
    if (boolean) {
      setConfirm(true);
      updateDevice({ ...designedInput, id, token });
    }
    setShowConfirmModal(false);
  };

  if (deviceIsLoading || confirm || updateDeviceIsLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-white/50">
        <Loader
          Visible={true}
          Width={140}
          Height={140}
          Color={colors.primary.main}
        />
      </div>
    );
  }

  if (deviceError) {
    return (
      <div className="p-4">
        <UnfindDataWarn Data={"Cihaz bulunamadı"} />
      </div>
    );
  }

  return (
    <div className="container">
      {/* Alert */}
      {alert !== 0 && (
        <div className="fixed z-50 left-1/2 top-4 -translate-x-1/2">
          <Alerts AlertChoice={alert} message={message} />
        </div>
      )}

      {/* Confirm Modal */}
      <Confirm
        input={designedInput}
        infoText={"Bilgileri kaydetmek istediğinize emin misiniz?"}
        handleCloseModel={handleCloseModel}
        showConfirmModal={showConfirmModal}
      />

      {/* Main Content */}
      <div className="flex flex-col gap-8">
        <div
          className={`bg-white rounded-xl shadow-md p-6 ${colors.border.light}`}
        >
          <DeviceInfoEditForm
            input={{ ...device }}
            setDesignedInput={setDesignedInput}
            setShowConfirmModal={setShowConfirmModal}
            token={token}
            initialFValues={{
              note: device?.note,
            }}
          />
        </div>

        <Syncronization />
      </div>
    </div>
  );
}

export default UpdateInfo;
