import React from "react";
import {
  FormControl,
  FormControlLabel,
  Checkbox as MuiCheckbox,
} from "@mui/material";
import { colors } from "../../Config/Colors";

export default function Checkbox(props) {
  const { name, label, value, onChange, disabled = false } = props;

  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });

  return (
    <FormControl>
      <FormControlLabel
        control={
          <MuiCheckbox
            name={name}
            disabled={disabled}
            checked={Boolean(value)}
            onChange={(e) =>
              onChange(convertToDefEventPara(name, e.target.checked))
            }
            sx={{
              color: colors.text.tertiary,
              padding: "12px",
              fontSize: "16px",
              "&.Mui-checked": {
                color: "#29A19C",
              },
              "& .MuiSvgIcon-root": {
                fontSize: 32,
              },
            }}
          />
        }
        label={label}
        sx={{
          "& .MuiFormControlLabel-label": {
            color: "black",
            fontSize: "1.1rem",
            fontWeight: "500",
          },
        }}
      />
    </FormControl>
  );
}
