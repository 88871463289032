import { useState, useEffect } from "react";
import Alerts from "../../../../Components/Utils/Alert";
import useAuth from "../../../../Hooks/useAuth";
import styles from "../../../../CustomStyles";
import Confirm from "../../../../Components/Utils/Confirm";
import TopSearchInfo from "../../../../Components/SearchBar/TopSearchInfo";
import ListInfo from "../../../../Components/Table/ListInfo";
import { BsFillPencilFill, BsTrash } from "react-icons/bs";
import DesignModal from "../../../../Components/Utils/DesignModal";
import UnfindDataWarn from "../../../../Components/Utils/UnfindDataWarn";
import Loader from "../../../../Components/Utils/Loader";
import {
  useAddProductTypeMutation,
  useDeleteProductTypeMutation,
  useFetchProductTypeQuery,
  useUpdateProductTypeMutation,
} from "../../../../store";
import ProductTypeDesignTag from "./ProductTypeDesignTag";
import FormatDateHourString from "../../../../Components/Utils/FormatDateHourString";

export default function ProductTypePage() {
  const { auth } = useAuth();
  const token = auth.accessToken;
  const [isSearch, setIsSearch] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [input, setInput] = useState("");
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [filteredData, setFilteredData] = useState("");
  const [inputFieldName, setInputFieldName] = useState({});

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [designModal, setDesignModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [action, setAction] = useState(0);
  const [alert, setAlert] = useState(0);
  const [message, setMessage] = useState("");

  const ProductType = useFetchProductTypeQuery(token);
  const [Update, resultUpdate] = useUpdateProductTypeMutation();
  const [Delete, resultDelete] = useDeleteProductTypeMutation();
  const [Add, resultAdd] = useAddProductTypeMutation();
  const DataProductType = ProductType?.data?.data?.productTypes || [];

  useEffect(() => {
    setIsSearch(false);
  }, []);
  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        setAlert(0);
        setMessage("");
      }, 5000);
    }
  }, [alert]);

  const handleApiResponse = (apiResponse, successMessage) => {
    setConfirm(false);
    if (apiResponse.isError) {
      setAlert(2);
      setMessage("İşlem başarısız tekrar deneyin !");
      setInput((prevInput) => ({
        ...prevInput,
        token: token,
      }));
    }
    if (apiResponse.isSuccess) {
      setAlert(1);
      setMessage(successMessage);
    }
  };

  useEffect(() => {
    handleApiResponse(resultDelete, "Silme başarılı !");
  }, [resultDelete.isSuccess, resultAdd.isError]);
  useEffect(() => {
    handleApiResponse(resultAdd, "Ekleme başarılı !");
  }, [resultAdd.isSuccess, resultAdd.isError]);

  useEffect(() => {
    handleApiResponse(resultUpdate, "Güncelleme başarılı !");
  }, [resultUpdate.isSuccess, resultAdd.isError]);

  const HandleModal = ({ e, productTypeName }) => {
    e.preventDefault();
    setInput({ ...input, productTypeName });
    setInputFieldName({
      productTypeName: "Ürün Tipi",
    });

    setShowConfirmModal(true);
    setDesignModal(false);
  };

  const handleCloseModel = (boolean) => {
    if (boolean) {
      setConfirm(true);

      if (action === 1) {
        Add(input);
        setIsSearch(false);
      } else if (action === 2) {
        Update(input);
        setIsSearch(false);
      } else if (action === 3) {
        Delete(input);
        setIsSearch(false);
      }
      setAction(0);
      setInput("");
      setIsSearch(false);
      setShowConfirmModal(false);
    } else {
      setShowConfirmModal(false);
      setIsSearch(false);
      if (action === 3) setAction(0);
    }
  };

  const handleSearch = (data, isSearch) => {
    setPaginationNumber(1);
    setFilteredData(data);
    setIsSearch(isSearch);
  };

  const config = [
    {
      class: "w-4",
      label: "Düzenle",
      render: (DataProductType) => (
        <div className="flex flex-row justify-center">
          <button
            onClick={() => {
              setInput({
                ...input,
                productTypeName: DataProductType.productTypeName,
                id: DataProductType._id,
                token: token,
              });
              setInfoText(
                "Değişiklikleri kaydetmek istediğinize emin misiniz ?"
              );
              setDesignModal(true);
              setAction(2);
            }}
            className={`${styles.tableButton}`}
          >
            <BsFillPencilFill className={`${styles.buttonIcon}`} />
          </button>
          <button
            className={`${styles.tableButton}`}
            onClick={() => {
              setInput({
                ...input,
                productTypeName: DataProductType.productTypeName,
                id: DataProductType._id,
                token: token,
              });
              setInfoText("Silmek istediğinize emin misiniz ?");
              setShowConfirmModal(true);
              setAction(3);
            }}
          >
            <BsTrash className={`${styles.buttonIcon}`} />
          </button>
        </div>
      ),
    },
    {
      label: "Ürün Tipi",
      render: (DataProductType) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Ürün Tipi</div>
          {DataProductType.productTypeName}
        </>
      ),
      sortValue: (DataProductType) => DataProductType.productTypeName,
    },
    {
      label: "Değiştirilme Tarihi",
      render: (DataProductType) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">
            Değiştirilme Tarihi
          </div>
          {FormatDateHourString(DataProductType.updatedInfo)}
        </>
      ),
      sortValue: (DataProductType) => DataProductType.updatedInfo,
    },
  ];

  const DesignTag = () => {
    return <ProductTypeDesignTag HandleModal={HandleModal} input={input} />;
  };

  return (
    <div className={` mb-20 flex flex-col md:mr-10 md:ml-0 mx-10 relative`}>
      {alert !== 0 && (
        <div
          className="fixed z-10 left-1/2 top-0
    -translate-x-1/2"
        >
          <Alerts AlertChoice={alert} message={message} />
        </div>
      )}

      <Confirm
        input={input}
        inputFieldName={inputFieldName}
        infoText={infoText}
        handleCloseModel={handleCloseModel}
        showConfirmModal={showConfirmModal}
      />

      <TopSearchInfo
        Length={DataProductType.length}
        TopInfo={"Ürün Tipi Listesi"}
        AddButton={() => {
          setAction(1);
          setInput({
            ...input,
            token: token,
            productTypeName: "",
          });
          setInfoText("Oluşturma işlemini onaylıyor musunuz ?");
          setDesignModal(true);
        }}
        SearchBarData={DataProductType}
        HandleSearch={handleSearch}
        SearchInputFieldName={{
          productTypeName: "Ürün Tipi",
        }}
      />

      {ProductType.isFetching || confirm ? (
        <div className=" flex w-full h-full justify-center items-center">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : ProductType.error ||
        DataProductType?.length === 0 ||
        (filteredData.length === 0 && isSearch) ? (
        <UnfindDataWarn Data={"Ürün bulunamadı"} />
      ) : (
        <ListInfo
          Config={config}
          Key={"id"}
          SetPaginationNumber={setPaginationNumber}
          PaginationNumber={paginationNumber}
          IsSearch={isSearch}
          FilteredData={filteredData}
          Data={DataProductType}
        />
      )}

      <DesignModal
        onClose={() => {
          setInput("");
          setShowConfirmModal(false);
          setDesignModal(false);
          setAction(0);
        }}
        designModal={designModal}
        Tag={DesignTag}
      />
    </div>
  );
}
