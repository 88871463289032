import React from "react";
import { MuiTelInput } from "mui-tel-input";
import { colors } from "../../Config/Colors";

export default function TelInput(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    disabled = false,
  } = props;

  return (
    <MuiTelInput
      disabled={disabled}
      variant="outlined"
      label={label}
      name={name}
      value={value}
      defaultCountry="TR"
      onChange={(value) => {
        const target = { name, value };
        const e = { target: target };
        onChange(e);
      }}
      {...(error && { error: true, helperText: error })}
      sx={{
        width: "100%",
        "& .MuiOutlinedInput-root": {
          backgroundColor: "white",
          borderRadius: "12px",
          borderWidth: 2,
          borderColor: "#29A19C",
        },
        "& .MuiInputLabel-root": {
          color: "white",
          backgroundColor: "#29A19C",
          borderRadius: "12px",
          paddingX: 2,
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: "white",
          backgroundColor: "#29A19C",
          borderRadius: "12px",
          paddingX: 2,
        },
      }}
    />
  );
}
