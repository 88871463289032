import { useState } from "react";
import styles from "../../../../CustomStyles";
import TextField from "@mui/material/TextField";

export default function ProductTypeDesignTag({ HandleModal, input }) {
  const [productTypeName, setProductTypeName] = useState(input.productTypeName);

  return (
    <form className=" flex flex-col min-w-max gap-4 ">
      <div
        className={`${styles.cardTitle} text-center w-full p-4 rounded-t-xl bg-main text-white`}
      >
        Ürün Tipi Bilgileri
      </div>
      <div className="flex flex-col gap-6 min-w-max px-8">
        <TextField
          label="Yeni Ürün Tipi"
          id="outlined-size-normal"
          value={productTypeName}
          onChange={(event) => {
            setProductTypeName(event.target.value);
          }}
        />
      </div>
      <button
        onClick={(e) => {
          HandleModal({ e, productTypeName });
        }}
        className={`${styles.buttonSearch}  
    px-6 py-3 w-fit self-center mb-2`}
      >
        Kaydet
      </button>
    </form>
  );
}
