import { ThreeDots } from "react-loader-spinner";
import BillTable from "./BillTable";
import { colors } from "../../../Config/Colors";

export default function BillInfoCard({ warn, bills, isLoading, isFetching }) {
  return (
    <div
      className={`
      flex flex-col p-6 shadow-lg bg-white 
      w-2/6 max-xl:w-full h-[full] rounded-2xl
      ${colors.border.light}
    `}
    >
      {isFetching || isLoading ? (
        <div className="flex-1 flex items-center justify-center">
          <ThreeDots
            visible={true}
            height="100"
            width="100"
            color={colors.primary.hex}
            ariaLabel="loading-indicator"
          />
        </div>
      ) : bills.length === 0 ? (
        <div className="flex w-full h-full items-center justify-center">
          <p
            className={`text-xl font-medium ${colors.text.primary} p-4 rounded-md`}
          >
            {warn}
          </p>
        </div>
      ) : (
        <>
          <div className="flex items-center mb-6">
            <h2 className={`text-2xl font-semibold ${colors.text.primary}`}>
              Fatura Hareketleri
            </h2>
          </div>
          <BillTable data={bills} />
        </>
      )}
    </div>
  );
}
