import { ThreeDots } from "react-loader-spinner";
import { colors } from "../../../Config/Colors";
import FirmTable from "./FirmTable";
import { GiReceiveMoney } from "react-icons/gi";

export default function FirmInfoCard({ warn, firms, isLoading, isFetching }) {
  return (
    <div
      className={`
        bg-gradient-to-r from-[#347928] via-[#3D8931] to-[#459937]
        rounded-2xl overflow-hidden
        flex flex-col p-6 w-full  h-[350px]  relative shadow-lg shadow-[#459937]
        ${colors.border.light}
      `}
    >
      <div className="absolute right-0 bottom-0 opacity-5 pointer-events-none">
        <GiReceiveMoney className="w-48 h-48" />
      </div>

      {isFetching || isLoading ? (
        <div className="flex-1 flex items-center justify-center">
          <ThreeDots
            visible={true}
            height="100"
            width="100"
            color="white"
            ariaLabel="loading-indicator"
          />
        </div>
      ) : firms.length === 0 ? (
        <div className="flex w-full h-full items-center justify-center">
          <p className="text-xl font-medium text-white p-4 rounded-md">
            {warn}
          </p>
        </div>
      ) : (
        <>
          <div className="relative flex flex-col gap-2 mb-4">
            <h2 className="text-2xl tracking-widest font-semibold text-white">
              Sipariş Vermeyen Firmalar
            </h2>
          </div>
          <FirmTable data={firms} />
        </>
      )}
    </div>
  );
}
