import useUsersContext from "../../Hooks/use-user-context";
import useAuth from "../../Hooks/useAuth";
import { BsChevronDown } from "react-icons/bs";
import { useState } from "react";
import { colors } from "../../Config/Colors";
import { useLocation } from "react-router-dom";

export default function Sidebarlink({
  link,
  isHover,
  roles,
  linkInfo,
  handleClose,
}) {
  const [open, setOpen] = useState(false);

  const { LogOut } = useUsersContext();
  const { auth, setAuth } = useAuth();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      const token = auth.accessToken;
      localStorage.clear();
      setAuth(null);
      await LogOut(token);
    } catch (err) {}
  };

  const isActive = (path) => {
    if (!path) return false;
    return location.pathname.includes(path);
  };

  if (link.roles?.includes(auth?.role) !== false) {
    if (link.childrens) {
      return (
        <div className="flex flex-col w-full">
          <button
            onClick={() => setOpen(!open)}
            className={`
              py-5 px-4 justify-start items-center flex gap-9 w-full
              rounded-full transition-all duration-300 cursor-pointer
              ${
                isActive(link.path) ||
                link.childrens?.some((child) => isActive(child.path))
                  ? `${colors.primary.bgLight} ${colors.primary.text}`
                  : `${colors.primary.hoverBgLight} ${colors.primary.hoverText}`
              }
            `}
          >
            {link.icon}
            <div className="flex items-center gap-4">
              <p className={`transition-all ${linkInfo}`}>{link.label}</p>
              <BsChevronDown
                className={`transition-transform duration-300 ${
                  open ? "rotate-180" : ""
                }`}
              />
            </div>
          </button>

          <div
            className={`
            ${open ? "flex flex-col pl-4" : "hidden"}
            ${colors.background.light}
          `}
          >
            {link?.childrens?.map((child, index) => (
              <div className=" mt-4" key={index}>
                <Sidebarlink
                  link={child}
                  isHover={isHover}
                  roles={roles}
                  linkInfo={linkInfo}
                  handleClose={handleClose}
                />
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div className="w-full flex">
          <div
            onClick={() => {
              if (link.label === "Çıkış") {
                handleLogout();
              } else {
                if (typeof handleClose === "function") {
                  handleClose(false);
                }
                window.location.href = "/#" + link.path;
              }
            }}
            className={`
              flex gap-10 w-full h-12 items-center py-5 px-4
              rounded-full transition-all duration-300 cursor-pointer
              ${
                link.label === "Çıkış"
                  ? "text-red-500 hover:bg-red-50"
                  : isActive(link.path)
                  ? `${colors.primary.bgLight} ${colors.primary.text}`
                  : `${colors.primary.hoverBgLight} ${colors.primary.hoverText}`
              }
            `}
          >
            {link.icon}
            <p
              className={`flex transition-all ${linkInfo} ${
                link.label === "Çıkış"
                  ? "text-red-500"
                  : isActive(link.path)
                  ? colors.primary.text
                  : ""
              }`}
            >
              {link.label}
            </p>
          </div>
        </div>
      );
    }
  }
  return null;
}
