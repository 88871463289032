import React, { useState } from "react";
import { colors } from "../../Config/Colors";
import {
  MdEmail,
  MdPerson,
  MdBusinessCenter,
  MdVerified,
} from "react-icons/md";
import { BsTelephone, BsFillPencilFill } from "react-icons/bs";
import { FaUserTag, FaUserCog } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import Pagination from "./Pagination";

const CustomUserList2 = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;

  const getRoleInfo = (role) => {
    const roles = {
      admin: {
        title: "Sistem Yöneticisi",
        color: "from-indigo-400 to-indigo-600",
        icon: <FaUserCog className="w-5 h-5" />,
      },
      management: {
        title: "Yönetici",
        color: "from-sky-400 to-sky-600",
        icon: <MdVerified className="w-5 h-5" />,
      },
      playmaker: {
        title: "Plasiyer",
        color: "from-emerald-400 to-emerald-600",
        icon: <MdBusinessCenter className="w-5 h-5" />,
      },
      accounting: {
        title: "Muhasebe",
        color: "from-amber-400 to-amber-600",
        icon: <MdBusinessCenter className="w-5 h-5" />,
      },
      manufacturer: {
        title: "Üretim",
        color: "from-orange-400 to-orange-600",
        icon: <MdBusinessCenter className="w-5 h-5" />,
      },
      customer: {
        title: "Müşteri",
        color: "from-teal-400 to-teal-600",
        icon: <MdPerson className="w-5 h-5" />,
      },
      default: {
        title: "Kullanıcı",
        color: colors.primary.gradient,
        icon: <MdPerson className="w-5 h-5" />,
      },
    };

    return roles[role] || roles.default;
  };

  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div className="grid grid-cols-1 gap-6 mb-16 mt-6">
        {currentItems.map((user) => {
          const roleInfo = getRoleInfo(user.role);

          return (
            <div
              key={user._id}
              className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300"
            >
              <div
                className={`bg-gradient-to-r ${roleInfo.color} p-4 rounded-t-xl`}
              >
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-3">
                    <div className="bg-white/20 p-2 rounded-lg">
                      {roleInfo.icon}
                    </div>
                    <h3 className="text-white font-semibold text-lg">
                      {roleInfo.title}
                    </h3>
                  </div>
                  <NavLink
                    to={`Düzenle/${user._id}/Bilgi`}
                    state={{
                      id: user._id,
                      name: user.name,
                      lastName: user.lastName,
                      createdInfo: user.createdInfo,
                      updatedInfo: user.updatedInfo,
                    }}
                  >
                    <button className="bg-white/20 p-2 rounded-lg hover:bg-white/30 transition-colors">
                      <BsFillPencilFill className="w-4 h-4 text-white" />
                    </button>
                  </NavLink>
                </div>
              </div>

              <div className="p-4">
                <div className="grid md:grid-cols-3 gap-4">
                  {/* Kişisel Bilgiler */}
                  <div className={`bg-gray-50/50 p-4 rounded-xl`}>
                    <div className="flex items-center gap-2 mb-3">
                      <MdPerson className={`${colors.primary.text} w-5 h-5`} />
                      <h4 className={`${colors.primary.text} font-medium`}>
                        Kişisel Bilgiler
                      </h4>
                    </div>
                    <div className="space-y-2">
                      <div className="flex items-center gap-2">
                        <span className="text-gray-500">İsim:</span>
                        <span className="text-gray-700 font-medium">
                          {user.name}
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="text-gray-500">Soyisim:</span>
                        <span className="text-gray-700 font-medium">
                          {user.lastName}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* İletişim Bilgileri */}
                  <div className={`bg-gray-50/50 p-4 rounded-xl`}>
                    <div className="flex items-center gap-2 mb-3">
                      <MdEmail className={`${colors.primary.text} w-5 h-5`} />
                      <h4 className={`${colors.primary.text} font-medium`}>
                        İletişim Bilgileri
                      </h4>
                    </div>
                    <div className="space-y-2">
                      <div className="flex items-center gap-2">
                        <span className="text-gray-500">Email:</span>
                        <span className="text-gray-700 font-medium">
                          {user.email}
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="text-gray-500">Telefon:</span>
                        <span className="text-gray-700 font-medium">
                          {user.tel}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Firma Bilgileri */}
                  <div className={`bg-gray-50/50 p-4 rounded-xl`}>
                    <div className="flex items-center gap-2 mb-3">
                      <MdBusinessCenter
                        className={`${colors.primary.text} w-5 h-5`}
                      />
                      <h4 className={`${colors.primary.text} font-medium`}>
                        Firma Bilgileri
                      </h4>
                    </div>
                    <div className="space-y-2">
                      <div className="flex items-center gap-2">
                        <span className="text-gray-500">Firma:</span>
                        <span className="text-gray-700 font-medium">
                          {user.firm?.firmName || "-"}
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="text-gray-500">Durum:</span>
                        <span
                          className={`px-3 py-1 rounded-full text-sm font-medium ${
                            user.isActive
                              ? "bg-emerald-50 text-emerald-600"
                              : "bg-red-50 text-red-600"
                          }`}
                        >
                          {user.isActive ? "Aktif" : "Pasif"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <Pagination
        currentPage={currentPage}
        totalCount={data.length}
        pageSize={pageSize}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default CustomUserList2;
