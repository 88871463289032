import React from "react";
import Controls from "../Components/Form/Controls";
import { useForm } from "../Components/Form/useForm";
import { colors } from "../Config/Colors";
import { AiFillDelete } from "react-icons/ai";
import { IoWarningOutline } from "react-icons/io5";

export default function DeleteForm({ handleOpenModal, initialFValues }) {
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("password" in fieldValues)
      temp.password = fieldValues.password ? "" : "Şifreyi girin.";
    if ("passwordConfirm" in fieldValues)
      temp.passwordConfirm = fieldValues.passwordConfirm
        ? ""
        : "Şifreyi onaylamalısınız.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      handleOpenModal(values);
    }
  };

  return (
    <div className="max-w-md mx-auto w-full">
      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        {/* Başlık */}
        <div className="bg-red-50 p-6 flex items-center gap-3 border-b border-red-100">
          <div className="w-12 h-12 rounded-full bg-red-100 flex items-center justify-center">
            <IoWarningOutline className="w-6 h-6 text-red-600" />
          </div>
          <div>
            <h3 className="text-lg font-semibold text-red-600">
              Firma Silme İşlemi
            </h3>
            <p className="text-red-500 text-sm">
              Bu işlem geri alınamaz. Lütfen şifrenizi girerek onaylayın.
            </p>
          </div>
        </div>

        {/* Form */}
        <form onSubmit={handleSubmit} className="p-6 space-y-4">
          <div className="space-y-4">
            <Controls.Input
              label="Şifre"
              name="password"
              type="password"
              value={values.password}
              onChange={handleInputChange}
              error={errors.password}
            />
            <Controls.Input
              label="Şifre Onay"
              name="passwordConfirm"
              type="password"
              value={values.passwordConfirm}
              onChange={handleInputChange}
              error={errors.passwordConfirm}
            />
          </div>

          {/* Silme Butonu */}
          <button
            type="submit"
            className={`
              w-full flex items-center justify-center gap-2
              bg-red-600 hover:bg-red-700
              text-white font-medium
              px-6 py-3 rounded-lg
              transition-all duration-300
              hover:shadow-lg transform hover:scale-[1.02]
            `}
          >
            <AiFillDelete className="w-5 h-5" />
            Firmayı Sil
          </button>
        </form>
      </div>
    </div>
  );
}
