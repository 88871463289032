import { useState } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { colors } from "../../Config/Colors";
import DataSearchBar from "./DataSearchBar";
import ManualQuota from "../Device/ManualQuota";

export default function DeviceTopSearchBar({
  Length,
  TopInfo,
  AddButton,
  SearchBarData,
  HandleSearch,
  SearchInputFieldName,
}) {
  const [searchBar, setSearchBar] = useState(true);
  const [modalIsOpen, setIsOpen] = useState(false);

  return (
    <div className="w-full flex flex-col gap-4">
      {/* Header Section */}
      <div className="flex items-center text-2xl justify-between bg-white p-4 rounded-xl shadow-md">
        {/* Left Side - Title and Count */}
        <div className="flex items-center gap-3 ">
          <h2 className=" font-semibold text-gray-800"> {TopInfo}</h2>
          <span
            className={`
              px-3 py-1 rounded-lg font-semibold
              ${colors.primary.bg} text-white
            `}
          >
            {Length}
          </span>
        </div>

        {/* Right Side - Buttons */}
        <div className="flex items-center gap-3">
          {/* Manual Load Button */}
          <button
            onClick={() => setIsOpen(true)}
            className={`
                flex items-center gap-2 px-4 h-12
              ${colors.primary.bg} text-white
              rounded-lg font-medium
              transition-all duration-300
              hover:shadow-lg transform hover:scale-[1.02]
              active:scale-95
              max-md:hidden
              text-lg
            `}
          >
            <span className="max-md:hidden">MANUEL YÜKLEME</span>
          </button>
          {/* Filter Button */}
          <button
            onClick={() => setSearchBar(!searchBar)}
            className={`
              flex items-center gap-2 px-4 h-12
              ${colors.primary.bg} text-white
              rounded-lg font-medium
              transition-all duration-300
              hover:shadow-lg transform hover:scale-[1.02]
              active:scale-95
              max-md:hidden
              text-lg
            `}
          >
            <span className="max-md:hidden">FİLTRELE</span>
            {searchBar ? (
              <IoIosArrowUp className="w-8 h-8" />
            ) : (
              <IoIosArrowDown className="w-8 h-8" />
            )}
          </button>
        </div>
      </div>

      {/* Search Bar Section */}
      <div
        className={`
          bg-white rounded-xl shadow-md
          transition-all duration-300 ease-in-out
          ${
            searchBar
              ? "max-h-[500px] opacity-100 p-6"
              : "max-h-0 opacity-0 overflow-hidden"
          }
        `}
      >
        <DataSearchBar
          Data={SearchBarData}
          handleSearch={HandleSearch}
          inputFieldName={SearchInputFieldName}
        />
      </div>

      {/* Manual Quota Modal */}
      <ManualQuota setIsOpen={setIsOpen} isOpen={modalIsOpen} />
    </div>
  );
}
