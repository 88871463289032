import React from "react";
import { Box, Typography, Paper, IconButton } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import trLocale from "date-fns/locale/tr";
import { useNavigate } from "react-router-dom";
import ExportExcel from "../../Components/Utils/ExportExcel";

const theme = createTheme({
  palette: {
    primary: {
      main: "#222831", // main rengi
    },
  },
});

const ReportLayout = ({ title, excelData, fileName, children }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/Anasayfa/Rapor/Menu");
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={trLocale}
      >
        <Paper
          elevation={3}
          sx={{ p: 3, borderRadius: 2, bgcolor: "background.default" }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <IconButton onClick={handleBack} sx={{ mr: 2 }}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="body2" color="text.secondary">
              Geri Dön
            </Typography>
          </Box>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ mb: 4, textAlign: "center", color: "primary.main" }}
          >
            {title}
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ mb: 4, textAlign: "center", color: "primary.main" }}
          >
            <ExportExcel excelData={excelData} fileName={fileName} />
          </Typography>
          {children}
        </Paper>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default ReportLayout;
