import { Calendar } from "react-calendar";
import styled from "styled-components";
import { colors } from "../../../Config/Colors";

const StyledCalendarContainer = styled.div`
  .react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    border-radius: 12px;
    font-family: inherit;
  }

  .react-calendar__navigation {
    display: flex;
    margin-bottom: 1rem;

    .react-calendar__navigation__label {
      font-weight: 600;
      color: #334155;
    }

    button {
      min-width: 44px;
      background: none;
      font-size: 16px;
      padding: 8px;
      border: none;
      color: #334155;
      border-radius: 8px;
      transition: all 0.3s ease;

      &:enabled:hover {
        background-color: #f8fafc;
        color: #93c6e7;
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.75em;
    color: #64748b;
    padding: 8px 0;
  }

  .react-calendar__month-view__days__day {
    padding: 12px;
    background: none;
    border: none;
    color: #334155;
    font-weight: 500;
    border-radius: 8px;
    transition: all 0.2s ease;

    &:enabled:hover {
      background: #f8fafc;
      color: #93c6e7;
    }
  }

  .react-calendar__tile--now {
    background: #f8fafc;
    color: #93c6e7;
    font-weight: 600;
  }

  .react-calendar__tile--active,
  .react-calendar__tile--active:enabled:hover {
    background: #93c6e7 !important;
    color: white !important;
    font-weight: 600;
    box-shadow: 0 2px 4px rgba(147, 198, 231, 0.4);
  }

  .react-calendar__tile--hasActive {
    background: rgba(147, 198, 231, 0.2);
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #94a3b8;
  }

  .react-calendar__year-view__months,
  .react-calendar__decade-view__years,
  .react-calendar__century-view__decades {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    padding: 8px;

    .react-calendar__tile {
      padding: 16px 8px;
      border-radius: 8px;
      font-weight: 500;

      &:enabled:hover {
        background: #f8fafc;
        color: #93c6e7;
      }

      &.react-calendar__tile--active,
      &.react-calendar__tile--active:enabled:hover {
        background: #93c6e7;
        color: white;
        font-weight: 600;
      }
    }
  }

  @media (max-width: 768px) {
    .react-calendar {
      width: 300px;
    }

    .react-calendar__month-view__days__day {
      padding: 8px;
    }
  }
`;

export default function CalendarContainer({ onChange, value }) {
  return (
    <StyledCalendarContainer>
      <Calendar
        locale="tr-TR"
        onChange={onChange}
        value={value}
        prevLabel="←"
        nextLabel="→"
        prev2Label="«"
        next2Label="»"
      />
    </StyledCalendarContainer>
  );
}
