import React from "react";
import { colors } from "../../Config/Colors";

const DeviceProductCards = ({ input }) => {
  return (
    <div className="grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 gap-8">
      {input.productInfo?.productInfo?.map((item, index) => (
        <div
          key={index}
          className={`bg-gradient-to-r ${colors.primary.gradient} rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden`}
        >
          <div className={`p-4`}>
            <h3
              className={`${colors.text.white} text-2xl font-semibold text-center`}
            >
              {item.productName}
            </h3>
          </div>
          <div className="p-6">
            <div className="space-y-4">
              <div
                className={`flex justify-between items-center p-3 ${colors.background.light} rounded-lg`}
              >
                <span className={`${colors.primary.text} text-xl font-medium`}>
                  Kota
                </span>
                <span className={`${colors.text.tertiary} text-xl font-bold`}>
                  {item.quota}
                </span>
              </div>
              <div
                className={`flex justify-between items-center p-3 ${colors.background.light} rounded-lg`}
              >
                <span className={`${colors.primary.text} text-xl font-medium`}>
                  Sayaç
                </span>
                <span className={`${colors.text.tertiary} text-xl font-bold`}>
                  {item.counter}
                </span>
              </div>
              <div
                className={`flex justify-between items-center p-3 ${colors.background.light} rounded-lg`}
              >
                <span
                  className={`${colors.status.warning.text} text-xl font-medium`}
                >
                  Kritik Seviye
                </span>
                <span className={`${colors.text.tertiary} text-xl font-bold`}>
                  {item.quotaWarning}
                </span>
              </div>
              <div
                className={`flex justify-between items-center p-3 ${colors.background.light} rounded-lg`}
              >
                <span className={`${colors.primary.text} text-xl font-medium`}>
                  Senkronizasyon
                </span>
                <span className={`${colors.text.tertiary} text-xl font-bold`}>
                  {item.syncLevel}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DeviceProductCards;
