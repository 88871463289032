import React from "react";
import Controls from "../../Components/Form/Controls";
import { useForm } from "../../Components/Form/useForm";
import { colors } from "../../Config/Colors";
import { HiOutlinePencilAlt } from "react-icons/hi";

export default function DeviceInfoEditForm({
  setDesignedInput,
  setShowConfirmModal,
  initialFValues,
}) {
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    setErrors({
      ...temp,
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setShowConfirmModal(true);
      setDesignedInput({
        note: values.note,
      });
    }
  };

  return (
    <div className=" rounded-xl overflow-hidden ">
      {/* Form */}
      <form onSubmit={handleSubmit} className="flex flex-col gap-6">
        <div className="grid gap-6">
          {/* Açıklama Input */}
          <div className={`p-4 rounded-xl`}>
            <Controls.Input
              label="Açıklama"
              name="note"
              value={values.note}
              onChange={handleInputChange}
              error={errors.note}
            />
          </div>
        </div>

        {/* Kaydet Butonu */}
        <div className="flex justify-end">
          <button
            type="submit"
            className={`
              ${colors.primary.bg} text-white
              px-6 py-3 rounded-lg
              text-xl font-medium
              hover:shadow-lg transform hover:scale-[1.02]
              transition-all duration-300
              flex items-center gap-2
            `}
          >
            <HiOutlinePencilAlt className="w-6 h-6" />
            <span>Kaydet</span>
          </button>
        </div>
      </form>
    </div>
  );
}
