import { useState, useEffect } from "react";
import { GenerateFormattedDate } from "../Components/Utils/Date/GenerateFormattedDate";
import { useGetDeviceLogReportMutation } from "../store/deviceLogApi";

export const useDeviceReportData = (id, token) => {
  const [info, setInfo] = useState(false);
  const [input, setInput] = useState({
    id: id,
    token: token,
    type: "",
    createdInfo: "",
    createdInfoSecond: "",
  });
  const [filterType, setFilterType] = useState("Day");
  const [date, setDate] = useState(new Date());
  const [dateSecond, setDateSecond] = useState(new Date());
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [getReport, { data, isFetching, isLoading, isError }] =
    useGetDeviceLogReportMutation();
  const [Datas, setDatas] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [prevInputType, setPrevInputType] = useState(input.type);

  useEffect(() => {
    if (input.type !== prevInputType) {
      setInfo(false);
      setDatas([]);
    } else {
      setDatas(
        data?.data?.filter((item) => item.logs && item.logs.length > 0) || []
      );
    }
    setPrevInputType(input.type);
  }, [data, input.type]);

  useEffect(() => {
    setInput({ ...input, createdInfo: GenerateFormattedDate(date) });
  }, [date]);

  useEffect(() => {
    setInput({
      ...input,
      createdInfoSecond: GenerateFormattedDate(dateSecond),
    });
  }, [dateSecond]);

  const handleFilterType = (type) => {
    setFilterType(type);
  };

  const handleGetLogData = () => {
    if (input.createdInfo && input.type) {
      getReport(input);
    }
  };

  return {
    info,
    setInfo,
    input,
    setInput,
    filterType,
    handleFilterType,
    date,
    setDate,
    dateSecond,
    setDateSecond,
    paginationNumber,
    setPaginationNumber,
    isFetching,
    isLoading,
    isError,
    Datas,
    selectedDate,
    setSelectedDate,
    handleGetLogData,
  };
};
