import React from "react";
import Controls from "../../Components/Form/Controls";
import { useForm } from "../../Components/Form/useForm";
import styles from "../../CustomStyles";
import { colors } from "../../Config/Colors";
import { FaPowerOff } from "react-icons/fa";

export default function DeviceCloseForm({ setDesignedInput, initialFValues }) {
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("description" in fieldValues)
      temp.description = fieldValues.description
        ? ""
        : !initialFValues.isActive
        ? "Makineyi açma sebebini yazınız."
        : "Makineyi kapatma sebebini yazınız.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setDesignedInput({
        isActive: !values.isActive,
        description: values.description,
        connect: values.connect,
      });
    }
  };

  return (
    <form className="grid gap-4 w-full " onSubmit={handleSubmit}>
      <div
        className={`flex items-center gap-2 p-4 border-b ${colors.background.main}`}
      >
        <FaPowerOff
          className={`w-8 h-8 ${
            initialFValues.isActive ? "text-red-400" : "text-green-400"
          }`}
        />
        <h3 className="text-xl font-semibold text-gray-800">
          Makineyi {initialFValues.isActive ? "Kapat" : "Aç"}
        </h3>
      </div>
      <div className=" px-6 grid gap-4">
        <Controls.Input
          label="Açıklama"
          name="description"
          value={values.description}
          onChange={handleInputChange}
          error={errors.description}
        />
        {initialFValues.isActive && (
          <Controls.Checkbox
            label="Makine bağlantısı kurulsun mu ?"
            name="connect"
            value={values.connect}
            onChange={handleInputChange}
          />
        )}
      </div>

      <button
        className={` mb-4 z-20 md:rounded-md rounded-full w-fit justify-self-center
         ${styles.button}`}
      >
        <p>Kaydet</p>
      </button>
    </form>
  );
}
