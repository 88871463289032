import { FormHelperText } from "@mui/material";
import React from "react";

export default function Number(props) {
  const {
    name,
    label,
    error = null,
    value,
    onChange,
    disabled = false,
    min = 0,
    max,
  } = props;

  return (
    <div
      className={`relative flex flex-col gap-1 w-full ${
        disabled ? "opacity-40 pointer-events-none" : ""
      }`}
    >
      <label
        className={`
          absolute -top-2 left-3 px-2  bg-white font-medium text-lg
          ${error ? "text-red-500" : "text-[#29A19C]"}
        `}
      >
        {label}
      </label>
      <input
        className={`
          text-lg py-4 px-4 text-center
          border-2 rounded-xl bg-white
          transition-all duration-200
          ${
            error
              ? "border-red-400 focus:border-red-500"
              : `border-[#29A19C] focus:border-[#29A19C]`
          }
        `}
        type="number"
        value={value}
        name={name}
        step="0.01"
        onChange={onChange}
        aria-label="Quantity Input"
        min={min}
        max={max}
      />
      {error && (
        <FormHelperText error={true} className="text-sm ml-2">
          {error}
        </FormHelperText>
      )}
    </div>
  );
}
