import { useState, useEffect } from "react";
import { useUpdateMeMutation, useUpdateMyPasswordMutation } from "../store";
import useAuth from "./useAuth";

export const useProfileForm = () => {
  const [updateMe, resultUpdateMe] = useUpdateMeMutation();
  const [updateMyPassword, resultUpdateMyPassword] =
    useUpdateMyPasswordMutation();
  const { auth, setAuth } = useAuth();

  const [alert, setAlert] = useState(0);
  const [message, setMessage] = useState("");
  const [userInfo, setUserInfo] = useState({
    name: auth?.name || "",
    lastName: auth?.lastName || "",
    email: auth?.email || "",
    tel: auth?.tel || "",
  });

  const [input, setInput] = useState({
    name: "",
    lastName: "",
    email: "",
    tel: "",
    password: "",
    passwordCurrent: "",
    passwordConfirm: "",
    id: "",
    token: "",
  });

  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        setAlert(0);
        setMessage("");
      }, 2000);
    }
  }, [alert]);

  useEffect(() => {
    if (resultUpdateMe.isError) {
      setAlert(2);
      setMessage("İşlem başarısız tekrar deneyin!");
    }
    if (resultUpdateMe.isSuccess) {
      setAlert(1);
      setMessage("Profil başarı ile güncellendi!");
      const user = resultUpdateMe?.data?.data?.user;
      setAuth({ ...user, accessToken: auth.accessToken });
      setUserInfo({
        name: user.name,
        lastName: user.lastName,
        email: user.email,
        tel: user.tel,
      });
    }
  }, [resultUpdateMe.isSuccess, resultUpdateMe.isError]);

  useEffect(() => {
    if (resultUpdateMyPassword.isError) {
      setAlert(2);
      setMessage("İşlem başarısız tekrar deneyin!");
    }
    if (resultUpdateMyPassword.isSuccess) {
      setAlert(1);
      setMessage("Şifre başarı ile güncellendi!");
      const accessToken = resultUpdateMyPassword?.data?.token;
      setAuth({ ...auth, accessToken });
    }
  }, [resultUpdateMyPassword.isSuccess, resultUpdateMyPassword.isError]);

  return {
    updateMe,
    updateMyPassword,
    alert,
    setAlert,
    message,
    setMessage,
    userInfo,
    setUserInfo,
    input,
    setInput,
    resultUpdateMe,
    resultUpdateMyPassword,
  };
};
