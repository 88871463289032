export const groupAndSumConsumptionsReport = (logs, filterType) => {
  const grouped = {};

  logs.forEach((log) => {
    const date = new Date(log.createdInfo);
    let key;

    switch (filterType) {
      case "Day":
        key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}-${String(date.getDate()).padStart(2, "0")} ${String(
          `${String(date.getHours()).padStart(2, "0")}:${String(
            date.getMinutes()
          ).padStart(2, "0")}`
        )}`;
        break;
      case "Month":
        key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}`;
        break;
      case "Year":
        key = `${date.getFullYear()}`;
        break;
      default:
        key = "default";
        break;
    }

    if (!grouped[key]) {
      grouped[key] = {};
    }

    if (!grouped[key][log.productName]) {
      grouped[key][log.productName] = {
        consument: 0,
      };
    }

    grouped[key][log.productName].consument += log.consument;
  });

  return grouped;
};
