import React from "react";
import {
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
} from "@mui/material";
import { colors } from "../../Config/Colors";

export default function SelectOptions(props) {
  const {
    name,
    label,
    error = null,
    value,
    onChange,
    options,
    disabled = false,
  } = props;

  return (
    <FormControl
      className="w-full"
      key={name}
      variant="outlined"
      {...(error && { error: true })}
    >
      <InputLabel
        sx={{
          color: "#29A19C",
          backgroundColor: "white",
          paddingX: 2,
          marginLeft: -0.5,
          fontSize: "18px",
        }}
      >
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        sx={{
          backgroundColor: "white",
          borderRadius: "12px",
          fontSize: "16px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: error ? "red" : "#29A19C",
            borderWidth: "2px",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#29A19C",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#29A19C",
            borderWidth: "2px",
          },
          "& .MuiSelect-select": {
            padding: "16px 14px",
          },
        }}
      >
        {options.map((item) => (
          <MenuItem
            key={item.title}
            value={item.title}
            sx={{
              fontSize: "1.1rem",
              padding: "12px 16px",
              "&:hover": {
                backgroundColor: colors.background.light,
              },
              "&.Mui-selected": {
                backgroundColor: "#29A19C",
                color: "white",
              },
            }}
          >
            {item.title}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
