import { useEffect } from "react";
import { useUpdateDeviceSyncMutation } from "../store/devicesApi";

const useUpdateDeviceSyncData = ({
  getDeviceSyncByID,
  token,
  id,
  setAlert,
  setMessage,
}) => {
  const [
    updateDeviceSync,
    {
      data: updateDeviceSyncData,
      isLoading: updateDeviceSyncIsLoading,
      isSuccess: updateDeviceSyncIsSuccess,
      isError: updateDeviceSyncIsError,
      error: updateDeviceSyncError,
    },
  ] = useUpdateDeviceSyncMutation();

  const handleApiResponse = (
    isSuccess,
    isError,
    updateError,
    successMessage
  ) => {
    if (isError) {
      getDeviceSyncByID({ token, id });
      setAlert(2);
      setMessage(updateError.data.message);
    }
    if (isSuccess) {
      getDeviceSyncByID({ token, id });
      setAlert(1);
      setMessage(successMessage);
    }
  };

  useEffect(() => {
    handleApiResponse(
      updateDeviceSyncIsSuccess,
      updateDeviceSyncIsError,
      updateDeviceSyncError,
      updateDeviceSyncData?.message
    );
  }, [updateDeviceSyncIsSuccess, updateDeviceSyncIsError]);

  return {
    updateDeviceSync,
    updateDeviceSyncIsLoading,
    updateDeviceSyncIsSuccess,
    updateDeviceSyncIsError,
    updateDeviceSyncError,
    updateDeviceSyncData,
  };
};

export default useUpdateDeviceSyncData;
