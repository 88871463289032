import React from "react";
import Controls from "../../Components/Form/Controls";
import { useForm } from "../../Components/Form/useForm";
import { colors } from "../../Config/Colors";
import { useFetchCityQuery, useFetchTownQuery } from "../../store";
import { useFirmData } from "../../Hooks/useFirmData";
import { IoBusinessOutline } from "react-icons/io5";

const isValidEmail = (email) => {
  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return emailPattern.test(email);
};

export default function CreateFirmForm({
  setDesignedInput,
  setInputFieldName,
  setShowConfirmModal,
  token,
  initialFValues,
}) {
  const { firms, isLoading, isFetching, error } = useFirmData(token);
  const responseCity = useFetchCityQuery(token);
  const responseTown = useFetchTownQuery(token);
  const Cities = responseCity.data?.data?.cities || [];
  const Towns = responseTown.data?.data?.towns || [];

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("firmName" in fieldValues)
      temp.firmName = fieldValues.firmName ? "" : "Firma seçiniz.";
    if ("orderFrequencyDays" in fieldValues) {
      temp.orderFrequencyDays =
        fieldValues.orderFrequencyDays >= 1 &&
        fieldValues.orderFrequencyDays <= 360
          ? ""
          : "Fatura kontrol sıklığı 1 ile 360 arasında olmalıdır.";
    }
    if ("email" in fieldValues)
      temp.email = isValidEmail(fieldValues.email)
        ? ""
        : "Doğru bir email adresi girin.";

    if ("officialNumber" in fieldValues)
      temp.officialNumber = fieldValues.officialNumber
        ? ""
        : "Tc numarası giriniz .";
    if ("taxNumber" in fieldValues)
      temp.taxNumber = fieldValues.taxNumber ? "" : "Vergi numarası giriniz .";
    if ("taxOffice" in fieldValues)
      temp.taxOffice = fieldValues.taxOffice ? "" : "Vergi dairesini giriniz .";
    if ("taxNumber" in fieldValues)
      temp.taxNumber = fieldValues.taxNumber ? "" : "Vergi numarası giriniz .";
    if ("tel" in fieldValues)
      temp.tel = fieldValues.tel ? "" : "Telefon girin.";
    if ("town" in fieldValues)
      temp.town = fieldValues.town ? "" : "İlçe seçin.";
    if ("city" in fieldValues)
      temp.city = fieldValues.city ? "" : "Şehir seçin.";
    if ("text" in fieldValues)
      temp.text = fieldValues.text ? "" : "Adres girin.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const FirmsOptions = [
    ...firms?.map((option) => {
      return { title: option.firmName };
    }),
  ];

  const CityOptions = [
    ...Cities?.map((option) => {
      return { title: option.cityName };
    }).sort((a, b) => a.title.localeCompare(b.title)),
  ];

  const TownOptions =
    CityOptions.length > 0 && values?.city
      ? Towns?.filter((option) => option.city.cityName === values.city)
          ?.map((option) => ({
            title: option.townName,
          }))
          .sort((a, b) => a.title.localeCompare(b.title))
      : []; // Şehir seçilmemişse null

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setInputFieldName({
        firmName: "Firma İsmi",
        email: "Firma Email",
        tel: "Firma Telefon",
      });
      setShowConfirmModal(true);
      setDesignedInput({
        isActive: values.isActive,
        orderFrequencyDays: values.orderFrequencyDays,
        firmName: values.firmName,
        isCorporate: values.isCorporate,
        mainFirmName: values.mainFirmName,
        officialNumber: values.officialNumber,
        taxNumber: values.taxNumber,
        taxOffice: values.taxOffice,
        email: values.email,
        tel: values.tel,
        note: values.note,
        address: {
          city: values.city,
          town: values.town,
          text: values.text,
        },
        token: token,
      });
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden">
      {/* Başlık */}
      <div
        className={`flex items-center gap-2 p-4 border-b ${colors.background.main}`}
      >
        <IoBusinessOutline className={`w-8 h-8 ${colors.primary.text}`} />
        <h3 className="text-xl font-semibold text-gray-800">Firma Bilgileri</h3>
      </div>

      <form onSubmit={handleSubmit} className="p-6 space-y-6">
        {/* Temel Bilgiler */}
        <div className={`${colors.background.lighter} p-4 rounded-xl`}>
          <h4 className={`text-lg font-medium ${colors.text.tertiary} mb-4`}>
            Temel Bilgiler
          </h4>
          <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-4">
            <Controls.Checkbox
              label="Aktiflik"
              name="isActive"
              value={values.isActive}
              onChange={handleInputChange}
            />
            <Controls.Checkbox
              label="Ana Firma Yok"
              name="isCorporate"
              value={values.isCorporate}
              onChange={handleInputChange}
              error={errors.isCorporate}
            />
            <Controls.Number
              label="Fatura Kontrol Sıklığı (Gün)"
              name="orderFrequencyDays"
              value={values.orderFrequencyDays}
              onChange={handleInputChange}
              error={errors.orderFrequencyDays}
            />
          </div>
        </div>

        {/* Firma Detayları */}
        <div className={`${colors.background.lighter} p-4 rounded-xl`}>
          <h4 className={`text-lg font-medium ${colors.text.tertiary} mb-4`}>
            Firma Detayları
          </h4>
          <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-4">
            {!values.isCorporate && (
              <Controls.SelectOptions
                label="Ana Firma"
                name="mainFirmName"
                value={values.mainFirmName}
                onChange={handleInputChange}
                options={FirmsOptions}
                error={errors.mainFirmName}
              />
            )}
            <Controls.Input
              label="Firma İsmi"
              name="firmName"
              value={values.firmName}
              onChange={handleInputChange}
              error={errors.firmName}
            />
            <Controls.Input
              label="Tc No"
              name="officialNumber"
              value={values.officialNumber}
              onChange={handleInputChange}
              error={errors.officialNumber}
            />
            <Controls.Input
              label="Vergi Numarası"
              name="taxNumber"
              value={values.taxNumber}
              onChange={handleInputChange}
              error={errors.taxNumber}
            />
            <Controls.Input
              label="Vergi Dairesi"
              name="taxOffice"
              value={values.taxOffice}
              onChange={handleInputChange}
              error={errors.taxOffice}
            />
          </div>
        </div>

        {/* İletişim Bilgileri */}
        <div className={`${colors.background.lighter} p-4 rounded-xl`}>
          <h4 className={`text-lg font-medium ${colors.text.tertiary} mb-4`}>
            İletişim Bilgileri
          </h4>
          <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-4">
            <Controls.TelInput
              label="Telefon Numarası"
              name="tel"
              value={values.tel}
              onChange={handleInputChange}
              error={errors.tel}
            />
            <Controls.Input
              label="Email"
              name="email"
              value={values.email}
              onChange={handleInputChange}
              error={errors.email}
            />
          </div>
        </div>

        {/* Adres Bilgileri */}
        <div className={`${colors.background.lighter} p-4 rounded-xl`}>
          <h4 className={`text-lg font-medium ${colors.text.tertiary} mb-4`}>
            Adres Bilgileri
          </h4>
          <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-4">
            <Controls.Input
              label="Adres"
              name="text"
              value={values.text}
              onChange={handleInputChange}
              error={errors.text}
            />
            <Controls.SelectOptions
              label="Şehir"
              name="city"
              value={values.city}
              onChange={handleInputChange}
              options={CityOptions}
              error={errors.city}
            />
            <Controls.SelectOptions
              label="İlçe"
              name="town"
              value={values.town}
              onChange={handleInputChange}
              options={TownOptions}
              error={errors.town}
            />
            <Controls.Input
              label="Açıklama"
              name="note"
              value={values.note}
              onChange={handleInputChange}
              error={errors.note}
            />
          </div>
        </div>

        {/* Kaydet Butonu */}
        <button
          type="submit"
          className={`
            ${colors.primary.bg} text-white
            px-6 py-3 rounded-lg
            text-xl font-medium
            hover:shadow-lg transform 
            transition-all duration-300
            fixed bottom-8 left-1/2 -translate-x-1/2 md:relative md:bottom-auto md:left-auto md:transform-none
            
          `}
        >
          Kaydet
        </button>
      </form>
    </div>
  );
}
