import React from "react";
import { colors } from "../../Config/Colors";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { BsFileEarmarkBarGraph } from "react-icons/bs";

const CustomDeviceReportTable = ({
  data,
  reportType,
  currentPage,
  setCurrentPage,
  setInfo,
  setSelectedDate,
  itemsPerPage = 10,
}) => {
  const totalPages = Math.ceil(data.length / itemsPerPage);
  console.log(data);
  console.log(data.length);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data.slice(startIndex, endIndex);

  const getHeaders = () => {
    switch (reportType) {
      case "Consument":
        return [
          { key: "date", label: "Tarih" },
          { key: "count", label: "Tüketim Kayıt Sayısı" },
          { key: "action", label: "İncele" },
        ];
      case "UpdateSetting":
      case "UpdateInfo":
        return [
          { key: "date", label: "Tarih" },
          { key: "count", label: "Değişiklik Sayısı" },
          { key: "action", label: "İncele" },
        ];
      case "UpdateManualQuota":
        return [
          { key: "date", label: "Tarih" },
          { key: "count", label: "Yükleme Sayısı" },
          { key: "action", label: "İncele" },
        ];
      default:
        return [];
    }
  };

  return (
    <div className="w-full space-y-4">
      {/* Tablo */}
      <div className="overflow-x-auto">
        <table className="w-full border rounded-lg overflow-hidden">
          <thead>
            <tr className={`bg-gradient-to-r ${colors.primary.gradient}`}>
              {getHeaders().map((header) => (
                <th
                  key={header.key}
                  className={`px-6 py-4 text-xl font-semibold ${colors.text.white}`}
                >
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {currentData.map((item, index) => (
              <tr
                key={index}
                className={`hover:${colors.background.lighter}  transition-colors duration-150`}
              >
                <td
                  className={`px-6 py-4 text-lg text-center ${colors.text.primary}`}
                >
                  {item.date}
                </td>
                <td
                  className={`px-6 py-4 text-lg  text-center ${colors.text.primary}`}
                >
                  {item.logs.length}
                </td>
                <td
                  className={`px-6 py-4 text-lg flex items-center justify-center`}
                >
                  <button
                    onClick={() => {
                      setInfo(true);
                      setSelectedDate(item.date);
                    }}
                    className={`
                      flex items-center gap-2 px-4 py-2 rounded-lg
                      ${colors.primary.bgLight} ${colors.primary.text}
                      hover:bg-opacity-80 transition-colors duration-150
                    `}
                  >
                    <BsFileEarmarkBarGraph className="w-8 h-8" />
                    <span className="font-medium">Detay</span>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Sayfalama */}
      <div className="flex items-center justify-between px-4">
        <span className={`text-lg ${colors.text.secondary}`}>
          Toplam {data.length} kayıt
        </span>

        <div className="flex items-center gap-4">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(1, prev - 1))}
            disabled={currentPage === 1}
            className={`
              p-2 rounded-lg
              ${
                currentPage === 1
                  ? "opacity-50 cursor-not-allowed"
                  : `hover:${colors.background.lighter} cursor-pointer`
              }
              transition-all duration-200
            `}
          >
            <IoChevronBack className={`w-5 h-5 ${colors.text.primary}`} />
          </button>

          <span className={`text-lg font-medium ${colors.text.primary}`}>
            Sayfa {currentPage} / {totalPages}
          </span>

          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(totalPages, prev + 1))
            }
            disabled={currentPage === totalPages}
            className={`
              p-2 rounded-lg
              ${
                currentPage === totalPages
                  ? "opacity-50 cursor-not-allowed"
                  : `hover:${colors.background.lighter} cursor-pointer`
              }
              transition-all duration-200
            `}
          >
            <IoChevronForward className={`w-5 h-5 ${colors.text.primary}`} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomDeviceReportTable;
