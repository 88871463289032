export default function DesignModal({ onClose, designModal, Tag }) {
  return (
    <>
      <div
        onClick={() => {
          onClose();
        }}
        className={` ${
          designModal
            ? "bg-black opacity-80 "
            : " bg-transparent pointer-events-none "
        } fixed inset-0 z-40 `}
      ></div>
      <div
        className={` ${
          designModal ? "" : " opacity-0 pointer-events-none "
        } fixed z-50 top-1/2 -translate-x-1/2 left-1/2 -translate-y-1/2 overflow-y-scroll no-scrollbar rounded-xl 
            w-fit max-h-[41rem] bg-white
          `}
      >
        <Tag />
      </div>
    </>
  );
}
