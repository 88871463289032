import { useState, useEffect } from "react";
import { useMakeDeviceOperationMutation } from "../store/devicesApi";

export const useDeviceOperationData = (
  token,
  getDevice,
  setConfirm,
  setMessage,
  setAlert
) => {
  const [operationDetail, setOperationDetail] = useState({});

  const [makeDeviceOperation, resultMakeDeviceOperation] =
    useMakeDeviceOperationMutation();

  // API Response Handler
  const handleApiResponse = (apiResponse, successMessage) => {
    if (apiResponse.isError) {
      setAlert(2);
      setMessage(apiResponse.error?.data?.message || "Bir hata oluştu");
    }
    if (apiResponse.isSuccess) {
      getDevice({ token });
      setAlert(1);
      setMessage(successMessage);
    }
  };

  useEffect(() => {
    handleApiResponse(resultMakeDeviceOperation, "Cihaz güncellendi!");
  }, [resultMakeDeviceOperation.isSuccess, resultMakeDeviceOperation.isError]);

  // Modal Handlers
  const handleOpen = () => setConfirm(true);

  const handleClose = () => {
    setOperationDetail({});
    setConfirm(false);
  };

  return {
    operationDetail,
    deviceOperationIsLoading: resultMakeDeviceOperation.isLoading,
    setAlert,
    setMessage,
    setOperationDetail,
    handleOpen,
    handleClose,
    makeDeviceOperation,
    resultMakeDeviceOperation,
    getDevice,
  };
};
