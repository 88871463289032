import { useLocation } from "react-router-dom";
import useAuth from "../../../Hooks/useAuth";
import Loader from "../../../Components/Utils/Loader";
import UnfindDataWarn from "../../../Components/Utils/UnfindDataWarn";
import { useFirmUsersData } from "../../../Hooks/useFirmUsersData";
import CustomUserList from "../../../Components/Table/CustomUserList";

function ControlUsers() {
  const location = useLocation();
  const { auth } = useAuth();
  const id = location.state.id;

  const { users, isLoading, hasNoData, paginationNumber, setPaginationNumber } =
    useFirmUsersData({ id, token: auth.accessToken });

  return (
    <div className="grid w-full">
      {isLoading ? (
        <div className="flex w-full h-full justify-center items-center">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : hasNoData ? (
        <div className="fixed top-0 left-0 flex w-full h-full justify-center items-center -z-10">
          <UnfindDataWarn Data={"Bu firmaya bağlı kullanıcı bulunamadı !"} />
        </div>
      ) : (
        <div className="mb-20 mt-6">
          <CustomUserList
            data={users.slice(
              (paginationNumber - 1) * 10,
              paginationNumber * 10
            )}
          />
        </div>
      )}
    </div>
  );
}

export default ControlUsers;
