import React from "react";
import Controls from "../../Components/Form/Controls";
import { useForm } from "../../Components/Form/useForm";
import { Modal, Backdrop, Fade, Box } from "@mui/material";
import { colors } from "../../Config/Colors";
import { IoSettingsOutline } from "react-icons/io5";

export default function DeviceSyncronizationForm({
  handleModal,
  initialFValues,
  showModal,
  onClose,
}) {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    maxHeight: "90vh",
    overflow: "auto",
    bgcolor: "background.paper",
    borderRadius: "12px",
    boxShadow: 24,
    outline: "none",
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("quotaWarning" in fieldValues)
      temp.quotaWarning = fieldValues.quotaWarning
        ? ""
        : "Kota uyarı seviyesi girin.";
    if ("quotaMax" in fieldValues)
      temp.quotaMax = fieldValues.quotaMax
        ? ""
        : "Maximum kota seviyesi girin.";
    if ("syncLevel" in fieldValues)
      temp.syncLevel = fieldValues.syncLevel
        ? ""
        : "Senkronizasyon seviyesi girin.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      handleModal({
        quotaWarning: values.quotaWarning,
        quotaMax: values.quotaMax,
        syncLevel: values.syncLevel,
        productName: values.productName,
        quotaDistribution: values.quotaDistribution,
      });
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  return (
    <Modal
      open={showModal}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            backdropFilter: "blur(4px)",
          },
        },
      }}
    >
      <Fade in={showModal}>
        <Box sx={modalStyle}>
          <div className="bg-white rounded-xl overflow-hidden">
            {/* Başlık */}
            <div
              className={`flex items-center gap-2 p-4 border-b ${colors.background.main}`}
            >
              <IoSettingsOutline className={`w-8 h-8 ${colors.primary.text}`} />
              <h3 className="text-xl font-semibold text-gray-800">
                Senkronizasyon Ayarları
              </h3>
            </div>

            {/* Form */}
            <form onSubmit={handleSubmit} className="flex flex-col p-6 gap-6">
              <div className="grid gap-6">
                {/* Ürün İsmi */}
                <div className={`${colors.background.light} p-4 rounded-xl`}>
                  <Controls.Input
                    label="Ürün İsmi"
                    name="productName"
                    disabled={true}
                    value={values.productName}
                    onChange={handleInputChange}
                    error={errors.productName}
                  />
                </div>

                {/* Maximum Kota */}
                <div className={`${colors.background.light} p-4 rounded-xl`}>
                  <Controls.Number
                    label="Maximum Kota"
                    name="quotaMax"
                    min="500"
                    max="20000"
                    value={values.quotaMax}
                    onChange={handleInputChange}
                    error={errors.quotaMax}
                  />
                </div>

                {/* Kota Uyarı Seviyesi */}
                <div className={`${colors.background.light} p-4 rounded-xl`}>
                  <Controls.Number
                    label="Kota Uyarı Seviyesi"
                    name="quotaWarning"
                    min="100"
                    max="5000"
                    value={values.quotaWarning}
                    onChange={handleInputChange}
                    error={errors.quotaWarning}
                  />
                </div>

                {/* Senkronizasyon Seviyesi */}
                <div className={`${colors.background.light} p-4 rounded-xl`}>
                  <Controls.Number
                    label="Senkronizasyon Seviyesi"
                    name="syncLevel"
                    min="100"
                    max="5000"
                    value={values.syncLevel}
                    onChange={handleInputChange}
                    error={errors.syncLevel}
                  />
                </div>

                {/* Otomatik Dağıtım */}
                <div className={`${colors.background.light} p-4 rounded-xl`}>
                  <Controls.Checkbox
                    label="Otomatik Kota Dağıtımı"
                    name="quotaDistribution"
                    value={values.quotaDistribution}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {/* Kaydet Butonu */}
              <button
                type="submit"
                className={`
                  ${colors.primary.bg} text-white
                  px-6 py-3 rounded-lg
                  text-xl font-medium
                  hover:shadow-lg transform hover:scale-[1.02]
                  transition-all duration-300
                `}
              >
                Kaydet
              </button>
            </form>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
