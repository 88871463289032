import { useEffect } from "react";
import { useGetDeviceSyncByIDMutation } from "../store/devicesApi";

const useDeviceSyncData = ({ setConfirm, setAlert, setMessage }) => {
  const [
    getDeviceSyncByID,
    {
      data: deviceSyncData,
      isLoading: deviceSyncIsLoading,
      isError: deviceSyncIsError,
      status: deviceSyncStatus,
      error: deviceSyncError,
    },
  ] = useGetDeviceSyncByIDMutation();

  const handleApiResponse = (
    isSuccess,
    isError,
    updateDeviceError,
    successMessage
  ) => {
    if (isError) {
      setConfirm(false);
      setAlert(2);
      setMessage(updateDeviceError);
    }
    if (isSuccess) {
      setConfirm(false);
    }
  };

  useEffect(() => {
    handleApiResponse(false, deviceSyncIsError, deviceSyncError, "");
  }, [deviceSyncIsError]);

  return {
    getDeviceSyncByID,
    deviceSyncData,
    deviceSyncIsLoading,
    deviceSyncStatus,
    deviceSyncError,
  };
};

export default useDeviceSyncData;
