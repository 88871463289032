import { useEffect } from "react";
import { useUpdateDeviceInformationMutation } from "../store";

const useUpdateDeviceData = ({
  getDevice,
  token,
  id,
  setConfirm,
  setAlert,
  setMessage,
}) => {
  const [
    updateDevice,
    {
      data: updateDeviceData,
      isLoading: updateDeviceIsLoading,
      isSuccess: updateDeviceIsSuccess,
      isError: updateDeviceIsError,
      error: updateDeviceError,
    },
  ] = useUpdateDeviceInformationMutation();

  const handleApiResponse = (
    isSuccess,
    isError,
    updateError,
    successMessage
  ) => {
    if (isError) {
      setConfirm(false);
      setAlert(2);
      setMessage(updateError.data.message);
    }
    if (isSuccess) {
      getDevice({ token, id });
      setConfirm(false);
      setAlert(1);
      setMessage(successMessage);
    }
  };

  useEffect(() => {
    handleApiResponse(
      updateDeviceIsSuccess,
      updateDeviceIsError,
      updateDeviceError,
      updateDeviceData?.message
    );
  }, [updateDeviceIsSuccess, updateDeviceIsError]);

  return {
    updateDevice,
    updateDeviceIsLoading,
    updateDeviceIsSuccess,
    updateDeviceIsError,
    updateDeviceError,
    updateDeviceData,
  };
};

export default useUpdateDeviceData;
