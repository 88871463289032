import { useEffect, useState } from "react";
import useAuth from "../../../Hooks/useAuth";
import { useLocation } from "react-router-dom";
import Loader from "../../../Components/Utils/Loader";
import UnfindDataWarn from "../../../Components/Utils/UnfindDataWarn";
import Graph from "../../../Components/Graph/DashboardBar";
import { colors } from "../../../Config/Colors";
import StatisticDeviceFilterButton from "../../../Components/Device/StatisticDeviceFilterButton";
import { useGetDeviceConsumentLogReportMutation } from "../../../store/deviceLogApi";

function StatisticDevice() {
  const location = useLocation();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const id = location.state.id;
  const [active, setActive] = useState(0);
  const [filterType, setFilterType] = useState("today");
  const colorOfProduct = {
    Çay: "#C70039",
    "Filtre Kahve": "#6d4a3a",
    "Türk Kahvesi": "#322110",
  };

  const handleFetchDevice = async () => {
    try {
      await getDeviceConsumentLogReport({
        id,
        token,
        type: filterType,
      }).unwrap();
    } catch (error) {
      // Hata durumunda yapılacak işlemler
    }
  };

  useEffect(() => {
    handleFetchDevice(); // Sayfa açıldığında otomatik olarak cihaz verilerini al
  }, []); // Boş bağımlılık dizisi ile sadece bir kez çalışır

  const [
    getDeviceConsumentLogReport,
    {
      data: deviceConsumentLogData,
      isLoading: deviceConsumentLogIsLoading,
      status: deviceConsumentLogStatus,
      error: deviceConsumentLogError,
    },
  ] = useGetDeviceConsumentLogReportMutation();

  const sortedHourlyConsumption = deviceConsumentLogData?.data;
  const productTotals = deviceConsumentLogData?.productTotals;

  function createProductDataAndBars(sortedHourlyConsumption) {
    if (!sortedHourlyConsumption) {
      return { productData: [], productBars: [] };
    }

    let ProductBars = new Set();
    let ProductData = [];

    for (let i = 0; i < 24; i++) {
      ProductData[i] = { name: i };
    }

    for (const log of sortedHourlyConsumption) {
      const hourIndex = parseInt(log.createdInfo, 10);
      const productDataItem = { name: log.createdInfo };

      log.consumentInfo.forEach((product) => {
        productDataItem[product.productName] = product.consument;
        ProductBars.add(product.productName);
      });

      ProductData[hourIndex] = productDataItem;
    }

    const productBarsArray = Array.from(ProductBars).map((dataKey) => ({
      dataKey,
      fill: colorOfProduct[dataKey],
    }));

    ProductBars = productBarsArray;

    return { ProductData, ProductBars };
  }

  const { ProductData, ProductBars } = createProductDataAndBars(
    sortedHourlyConsumption
  );

  return (
    <div className={`bg-white rounded-xl shadow-md p-6 ${colors.border.light}`}>
      <div className="flex flex-col lg:flex-row justify-between gap-6 mb-8">
        <div className="space-y-4">
          <h2
            className={`text-xl font-SemiBold tracking-widest ${colors.text.primary}`}
          >
            Tüketim Grafiği
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {productTotals?.map((item, index) => (
              <div
                key={index}
                className={`${colors.background.lighter} p-3 rounded-lg`}
              >
                <span className={`text-lg ${colors.text.secondary}`}>
                  {item.productName}
                </span>
                <p
                  className={`text-xl font-semibold mt-1`}
                  style={{ color: colorOfProduct[item.productName] }}
                >
                  {item.total}
                </p>
              </div>
            ))}
          </div>
        </div>

        <StatisticDeviceFilterButton
          active={active}
          setActive={setActive}
          setFilterType={setFilterType}
        />
      </div>

      <div className="h-[32rem] w-full">
        {deviceConsumentLogIsLoading ? (
          <div className="flex items-center justify-center h-full">
            <Loader
              Visible={true}
              Width={140}
              Height={140}
              Color={colors.primary.hex}
            />
          </div>
        ) : deviceConsumentLogData?.results === 0 || deviceConsumentLogError ? (
          <div className="flex items-center justify-center h-full">
            <UnfindDataWarn Data={"Tüketim verisi bulunamadı!"} />
          </div>
        ) : (
          <Graph data={ProductData || []} bars={ProductBars || []} />
        )}
      </div>
    </div>
  );
}

export default StatisticDevice;
