import { IoCheckmarkCircle, IoWarning, IoClose } from "react-icons/io5";

const Alerts = function ({ AlertChoice, message }) {
  const getAlertStyles = (choice) => {
    switch (choice) {
      case 1: // Başarılı
        return {
          bg: "bg-emerald-100",
          border: "border-emerald-500",
          text: "text-emerald-800",
          icon: <IoCheckmarkCircle className="w-12 h-12 text-emerald-500" />,
          title: "Başarılı",
        };
      case 2: // Hata
        return {
          bg: "bg-red-100",
          border: "border-red-500",
          text: "text-red-800",
          icon: <IoClose className="w-12 h-12 text-red-500" />,
          title: "Hata",
        };
      case 3: // Uyarı
        return {
          bg: "bg-amber-100",
          border: "border-amber-500",
          text: "text-amber-800",
          icon: <IoWarning className="w-12 h-12 text-amber-500" />,
          title: "Uyarı",
        };
      default:
        return null;
    }
  };

  const alertStyle = getAlertStyles(AlertChoice);

  if (!alertStyle) return null;

  return (
    <div
      className={`
      flex items-start gap-3 p-4 rounded-xl
      ${alertStyle.bg}
      border-l-4 ${alertStyle.border}
      shadow-lg
      animate-slideIn
    `}
    >
      {/* İkon */}
      <div className="mt-1">{alertStyle.icon}</div>

      {/* İçerik */}
      <div className="flex flex-col">
        <h3 className={`text-2xl font-semibold ${alertStyle.text}`}>
          {alertStyle.title}
        </h3>
        <p className={`text-2xl ${alertStyle.text}`}>{message}</p>
      </div>
    </div>
  );
};

export default Alerts;
