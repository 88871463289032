import { Fragment } from "react";

function Table({ data, config, keyFn }) {
  const textStyle = "text-xl px-2 py-3";

  const renderedHeaders = config.map((column, index) => {
    if (column.header) {
      return <Fragment key={index}>{column.header()}</Fragment>;
    }

    return <th key={index}>{column.label}</th>;
  });

  const renderedRows = data?.map((rowData, index) => {
    const renderedCells = config.map((column) => {
      return (
        <td
          className={` ${textStyle} font-medium  max-md:grid md:text-center `}
          key={column.label}
        >
          {column.render(rowData)}
        </td>
      );
    });
    return (
      <tr
        className="odd:bg-[#DDDDDD] shadow-2xl text-black bg-transparent md:shadow-lg"
        key={keyFn(rowData)}
      >
        {renderedCells}
      </tr>
    );
  });

  return (
    <table className=" w-full h-full divide-y ">
      <thead
        className={` text-2xl px-2 py-3 text-center md:border-b-2 max-md:hidden `}
      >
        <tr>{renderedHeaders}</tr>
      </thead>
      <tbody className=" relative max-md:flex max-md:flex-col rounded-xl gap-4">
        {renderedRows}
      </tbody>
    </table>
  );
}

export default Table;
