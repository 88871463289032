import { ThreeDots } from "react-loader-spinner";
import { MdAttachMoney } from "react-icons/md";
import { useEffect, useState } from "react";
import FilterIncome from "./FilterIncome";
import { colors } from "../../../Config/Colors";
import coffeeBean from "../../../Assets/icons/coffee_bean.png";
import teaLeaf from "../../../Assets/icons/tea_leaf.png";

// Ürün ikonları mapping'i
const PRODUCT_ICONS = {
  Çay: teaLeaf,
  "Türk Kahvesi": coffeeBean,
  "Filtre Kahve": coffeeBean,
};

export default function IncomeInfoCard({
  warn,
  incomes,
  isLoading,
  isFetching,
}) {
  const [consumption, setConsumption] = useState([]);
  const [dashboardType, setDashboardType] = useState("thisyear");

  useEffect(() => {
    if (incomes && dashboardType === "today") {
      const object = Object.keys(incomes.dailyRevenue).map((key) => ({
        productName: key,
        consument: incomes.dailyRevenue[key],
      }));
      setConsumption(object);
    } else if (incomes && dashboardType === "thisweek") {
      const object = Object.keys(incomes.weeklyRevenue).map((key) => ({
        productName: key,
        consument: incomes.weeklyRevenue[key],
      }));
      setConsumption(object);
    } else if (incomes && dashboardType === "thismonth") {
      const object = Object.keys(incomes.monthlyRevenue).map((key) => ({
        productName: key,
        consument: incomes.monthlyRevenue[key],
      }));
      setConsumption(object);
    } else if (incomes && dashboardType === "thisyear") {
      const object = Object.keys(incomes.yearlyRevenue).map((key) => ({
        productName: key,
        consument: incomes.yearlyRevenue[key],
      }));
      setConsumption(object);
    }
  }, [incomes, dashboardType]);

  // Toplam geliri hesapla
  const totalIncome = consumption.reduce(
    (total, item) => total + item.consument,
    0
  );

  return (
    <div
      className={`
  relative flex flex-col p-6 max-xl:h-full
   bg-gradient-to-l from-[#1A1A19] to-[#33372C]
  text-white h-[350px] shadow-lg shadow-[#1A1A19]
  rounded-2xl 
    `}
    >
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-white">Mali Tablo</h2>
        <FilterIncome
          button={`
            px-4 py-2 rounded-lg text-sm font-medium 
            transition-all duration-300 border border-white/20
            text-white hover:bg-white/10
          `}
          handleClick={setDashboardType}
          active={dashboardType}
        />
      </div>

      {/* Background Icon */}
      <div className="absolute right-32 top-24 opacity-5">
        <MdAttachMoney className="w-48 h-48" />
      </div>

      {/* Content */}
      {isFetching || isLoading ? (
        <div className="flex-1 flex items-center justify-center">
          <ThreeDots
            visible={true}
            height="100"
            width="100"
            color={colors.primary.hex}
            ariaLabel="loading-indicator"
          />
        </div>
      ) : consumption.length === 0 ? (
        <div className="flex-1 flex items-center justify-center">
          <p className={`text-xl font-medium ${colors.text.primary}`}>{warn}</p>
        </div>
      ) : (
        <div className="flex-1 flex flex-col">
          {/* Ürün Listesi */}
          <div className="flex flex-col gap-2 overflow-auto h-44">
            {consumption.map((item, index) => (
              <div
                key={index}
                className={`
                  flex justify-between items-center p-3
                  rounded-lg transition-all duration-300
                  hover:bg-white/10 bg-white/5
                `}
              >
                <div className="flex items-center gap-3">
                  {/* Ürün İkonu */}
                  <img
                    src={PRODUCT_ICONS[item.productName]}
                    alt={item.productName}
                    className="w-6 h-6 object-contain"
                  />
                  <span className="text-lg font-medium text-white">
                    {item.productName}
                  </span>
                </div>
                <span className="text-xl text-white">
                  {item.consument.toLocaleString("tr-TR")} ₺
                </span>
              </div>
            ))}
          </div>

          {/* Toplam */}
          <div className="mt-4 pt-2 border-t border-white/20 flex justify-between items-center">
            <span className="text-xl font-semibold text-white">Toplam</span>
            <span className="text-2xl font-semibold text-white">
              {totalIncome.toLocaleString("tr-TR")} ₺
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
