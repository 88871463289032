import styles from "../../CustomStyles";
import { useState } from "react";
import { useFetchStockQuery } from "../../store";
import useAuth from "../../Hooks/useAuth";
import Loader from "../../Components/Utils/Loader";
import StockGraph from "./StockGraph";
import StockList from "./StockList";

export default function StockPage() {
  const { auth } = useAuth();
  const token = auth.accessToken;

  const responseData = useFetchStockQuery(token);
  const DataStock = responseData.data?.data?.stocks;

  const [active, setActive] = useState(1);

  return (
    <div className="">
      <div className=" w-full h-full inline-block align-middle">
        <div className="flex my-6 gap-3 items-center text-main xl:text-xl text-BASE">
          <button
            className={`${
              styles.PageHeader
            } border-2 border-main rounded-md transition-all duration-500 p-4  ${
              active === 1 ? "text-white bg-main rounded-md" : ""
            }`}
            onClick={() => {
              setActive(1);
            }}
          >
            Stok Grafiği
          </button>
          <div className={`${styles.PageHeader}`}>-</div>

          <button
            onClick={() => {
              setActive(2);
            }}
            className={`${
              styles.PageHeader
            } border-2 border-main rounded-md transition-all duration-500 p-4 ${
              active === 2 ? "text-white bg-main  rounded-md" : ""
            }`}
          >
            Stok Yönetimi
          </button>
        </div>

        <div className="flex flex-col w-full h-full items-center gap-4 rounded-md mb-10 ">
          {responseData.isFetching ? (
            <div className=" flex w-full h-full justify-center items-center">
              <Loader
                Visible={true}
                Width={140}
                Height={140}
                Color={"#93C6E7"}
              />
            </div>
          ) : responseData.isError || responseData.data?.results === 0 ? (
            <div className=" text-SemiBold">Stock bulunamadı</div>
          ) : (
            <>
              {active === 1 && <StockGraph DataStock={DataStock} />}
              {active === 2 && (
                <StockList
                  token={token}
                  DataStock={DataStock}
                  active={active}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
