import PropTypes from "prop-types";
import { colors } from "../../Config/Colors";
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
} from "react-icons/io5";
import { Modal, Backdrop, Fade, Box } from "@mui/material";

function Confirm({
  input = {},
  inputFieldName = {},
  infoText = "",
  handleCloseModel,
  showConfirmModal = false,
}) {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    bgcolor: "background.paper",
    borderRadius: "12px",
    boxShadow: 24,
    outline: "none",
  };

  return (
    <Modal
      open={showConfirmModal}
      onClose={() => handleCloseModel(false)}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            backdropFilter: "blur(4px)",
          },
        },
      }}
    >
      <Fade in={showConfirmModal}>
        <Box sx={modalStyle}>
          <div className="bg-white rounded-xl overflow-hidden">
            {/* Başlık */}
            <div className={`p-4 border-b ${colors.background.main}`}>
              <h3 className="text-xl font-semibold text-gray-800">Onay</h3>
            </div>

            {/* İçerik */}
            <div className="p-6 space-y-4">
              {/* Input Alanları */}
              {Object.keys(input).map((key, index) => {
                if (inputFieldName && inputFieldName.hasOwnProperty(key)) {
                  return (
                    <div
                      key={index}
                      className={`${colors.background.light} p-4 rounded-xl`}
                    >
                      <span className={`text-lg ${colors.text.tertiary}`}>
                        {inputFieldName[key]}
                      </span>
                      <p
                        className={`text-xl mt-1 font-semibold ${colors.primary.text}`}
                      >
                        {input[key]}
                      </p>
                    </div>
                  );
                }
                return null;
              })}

              {/* Bilgi Metni */}
              {infoText && (
                <div className={`${colors.background.light} p-4 rounded-xl`}>
                  <p className={`text-lg ${colors.text.secondary}`}>
                    {infoText}
                  </p>
                </div>
              )}

              {/* Butonlar */}
              <div className="flex gap-3 pt-4">
                <button
                  onClick={() => handleCloseModel(true)}
                  className={`
                    flex-1 flex items-center justify-center gap-2
                    ${colors.primary.bg} text-white
                    px-6 py-3 rounded-lg
                    text-lg font-medium
                    hover:shadow-lg transform hover:scale-[1.02]
                    transition-all duration-300
                  `}
                >
                  <IoCheckmarkCircleOutline className="w-6 h-6" />
                  Onay
                </button>
                <button
                  onClick={() => handleCloseModel(false)}
                  className={`
                    flex-1 flex items-center justify-center gap-2
                    bg-white ${colors.primary.text}
                    px-6 py-3 rounded-lg
                    text-lg font-medium
                    border ${colors.border.light}
                    hover:shadow-lg transform hover:scale-[1.02]
                    transition-all duration-300
                  `}
                >
                  <IoCloseCircleOutline className="w-6 h-6" />
                  İptal
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

Confirm.propTypes = {
  input: PropTypes.object,
  inputFieldName: PropTypes.object,
  infoText: PropTypes.string,
  handleCloseModel: PropTypes.func.isRequired,
  showConfirmModal: PropTypes.bool,
};

export default Confirm;
