import { Fragment, useState, useEffect } from "react";
import styles from "../../../../CustomStyles";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useFetchProductTypeQuery } from "../../../../store";

export default function ProductInfoDesignTag({ HandleModal, input, Token }) {
  const [productTypeName, setProductTypeName] = useState(input.productTypeName);
  const [productName, setProductName] = useState(input.productName);
  const [productCode, setProductCode] = useState(input.productCode);
  const [startFetch, setStartFetch] = useState(false);
  const { data, error, isLoading, isSuccess } = useFetchProductTypeQuery(
    Token,
    {
      skip: !startFetch,
    }
  );
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let active = true;

    if (!isSuccess) {
      return undefined;
    }

    (async () => {
      if (active) {
        setOptions([...data.data.productTypes]);
      }
    })();
    return () => {
      active = false;
    };
  }, [isSuccess]);

  return (
    <form className=" flex flex-col min-w-max gap-4 ">
      <div
        className={`${styles.cardTitle} text-center w-full p-4 rounded-t-xl bg-main text-white`}
      >
        Ürün Bilgileri
      </div>
      <div className="flex flex-col gap-6 min-w-max px-8">
        <TextField
          label="Yeni Ürün"
          id="outlined-size-normal"
          value={productName}
          onChange={(event) => {
            setProductName(event.target.value);
          }}
        />
      </div>
      <div className="flex flex-col gap-6 min-w-max px-8">
        <TextField
          label="Yeni Ürün Kodu"
          id="outlined-size-normal"
          value={productCode}
          onChange={(event) => {
            setProductCode(event.target.value);
          }}
        />
        <Autocomplete
          id="asynchronous-demo"
          sx={{ width: 300 }}
          open={open}
          onOpen={() => {
            setStartFetch(true);
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option, value) =>
            option.productTypeName === value.productTypeName
          }
          onChange={(event, newValue) => {
            setProductTypeName(newValue ? newValue.productTypeName : "");
          }}
          getOptionLabel={(option) => option.productTypeName}
          options={options}
          loading={isLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Ürün Tipi"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </Fragment>
                ),
              }}
            />
          )}
        />
      </div>

      <button
        onClick={(e) => {
          HandleModal({ e, productTypeName, productName, productCode });
        }}
        className={`${styles.buttonSearch}  
    px-6 py-3 w-fit self-center mb-2`}
      >
        Kaydet
      </button>
    </form>
  );
}
