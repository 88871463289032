import Graph from "../../Components/Graph/DashboardBar";

export default function StockGraph({ DataStock }) {
  const Product = {};
  const randomColor = () =>
    "#" + Math.floor(Math.random() * 16777215).toString(16);

  DataStock?.forEach((data) => {
    Product[data.product.productName] = data.quota;
  });

  const ProductData = [{ ...Product }];

  const ProductBars = DataStock?.map((data) => {
    return { dataKey: data.product.productName, fill: randomColor() };
  });

  return (
    <div className=" w-full md:h-[36rem] h-[32rem] mb-10">
      <Graph data={ProductData} bars={ProductBars} />
    </div>
  );
}
