import { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";

import useAuth from "../../../Hooks/useAuth";
import Alerts from "../../../Components/Utils/Alert";
import styles from "../../../CustomStyles";
import { useGetUserQuery, useUpdateUserMutation } from "../../../store";

import Confirm from "../../../Components/Utils/Confirm";
import Loader from "../../../Components/Utils/Loader";
import UserForm from "../../../Forms/UserForm";

function UpdateInfo() {
  const { auth } = useAuth();
  const token = auth.accessToken;
  const { id } = useParams();

  const ResultUser = useGetUserQuery({ id, token });
  const [update, resultUpdate] = useUpdateUserMutation();

  const [alert, setAlert] = useState(0); // 1- success 2-error 3-warning
  const [message, setMessage] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [confirm, setConfirm] = useState(false);
  const inputFieldName = {
    role: "Rol",
    firmName: "Firma İsmi",
    name: "İsim",
    lastName: "Soyisim",
    email: "Email",
    tel: "Telefon",
  };

  const [input, setInput] = useState({
    role: "",
    firmID: "",
    firmName: "",
    name: "",
    lastName: "",
    email: "",
    tel: "",
    token: token,
  });

  useEffect(() => {
    if (ResultUser.status === "fulfilled") {
      setInput({
        ...ResultUser?.data?.data?.user,
        role:
          ResultUser?.data?.data?.user.role === "playmaker"
            ? "Plasiyer"
            : ResultUser?.data?.data?.user.role === "management"
            ? "Yönetici"
            : ResultUser?.data?.data?.user.role === "accounting"
            ? "Muhasebe"
            : ResultUser?.data?.data?.user.role === "manufacturer"
            ? "Üretim"
            : ResultUser?.data?.data?.user.role === "customer"
            ? "Müşteri"
            : "",
      });
    }
  }, [ResultUser]);
  console.log(ResultUser?.data?.data?.user);
  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        setAlert(0);
        setMessage("");
      }, 2000);
    }
  }, [alert]);

  useEffect(() => {
    setConfirm(false);
    if (resultUpdate.isError === true) {
      setAlert(2);
      setMessage("İşlem başarısız tekrar deneyin !");
    }
    if (resultUpdate.isSuccess === true) {
      setAlert(1);
      setMessage("İşlem başarı ile gerçekleşti !");
    }
  }, [resultUpdate.isSuccess, resultUpdate.isError]);

  const handleCloseModel = (bool) => {
    if (bool) {
      update({
        input: {
          ...input,
          role:
            input.role === "Plasiyer"
              ? "playmaker"
              : input.role === "Yönetici"
              ? "management"
              : input.role === "Muhasebe"
              ? "accounting"
              : input.role === "Üretim"
              ? "manufacturer"
              : input.role === "Müşteri"
              ? "customer"
              : "",
        },
        token,
      });
      setConfirm(true);
      setShowConfirmModal(false);
    } else {
      setShowConfirmModal(false);
    }
  };

  return (
    <div>
      {/*
        Confirm Modal
      */}
      {alert !== 0 && (
        <div
          className="fixed z-50 left-1/2 top-0
    -translate-x-1/2"
        >
          <Alerts AlertChoice={alert} message={message} />
        </div>
      )}
      <Confirm
        input={input}
        inputFieldName={inputFieldName}
        infoText={infoText}
        handleCloseModel={handleCloseModel}
        showConfirmModal={showConfirmModal}
      />
      {/*
      Device information.
      */}
      {resultUpdate.isLoading ||
      ResultUser.status !== "fulfilled" ||
      confirm ? (
        <div
          className={` fixed top-0 left-0 flex w-full h-full justify-center items-center`}
        >
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : (
        <UserForm
          handleOpenModal={(values) => {
            setInfoText("Kullanıcıyı oluşturmak istediğinize emin misiniz ?");
            setInput(values);
            setShowConfirmModal(true);
          }}
          initialFValues={{
            ...input,
            firmName: input.firm?.firmName || "",
          }}
          token={token}
        />
      )}
    </div>
  );
}

export default UpdateInfo;
