import React, { useState, useEffect } from "react";
import { HiOutlineMail } from "react-icons/hi";
import { RiLockPasswordLine } from "react-icons/ri";
import useAuth from "../../Hooks/useAuth";
import { useNavigate } from "react-router-dom";
import LogoImage from "../../img/logo2.png";
import { useForgotPasswordMutation, useLoginMutation } from "../../store";
import Alerts from "../../Components/Utils/Alert";
import { Blocks, ThreeDots } from "react-loader-spinner";
import { colors } from "../../Config/Colors";
import BackgroundImage from "../../Assets/background/background.jpg";

export default function LoginPage() {
  const [forgotPassword, resultForgotPassword] = useForgotPasswordMutation();
  const [Login, resultLogin] = useLoginMutation();
  const { setAuth } = useAuth();
  const [pwd, setPwd] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [login, setLogin] = useState(true);

  const [alert, setAlert] = useState(0); // 1- success 2-error 3-warning
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        setAlert(0);
        setMessage("");
      }, 2000);
    }
  }, [alert]);

  useEffect(() => {
    if (resultForgotPassword.isError === true) {
      setAlert(2);
      setMessage("İşlem başarısız tekrar deneyin !");
    }
    if (resultForgotPassword.isSuccess === true) {
      setAlert(1);
      setMessage("Şifreniz başarı ile oluşturuldu !");
    }
  }, [resultForgotPassword.isSuccess, resultForgotPassword.isError]);

  useEffect(() => {
    if (resultLogin.isError === true) {
      setAlert(2);
      setMessage("İşlem başarısız tekrar deneyin !");
    }
    if (resultLogin.isSuccess === true) {
      setAlert(1);
      setMessage("Giriş başarılı !");
      const accessToken = resultLogin?.data?.token;
      const user = resultLogin?.data?.data?.user;

      setAuth({ ...user, accessToken });
      console.log(user);
      if (user?.role === "management" || user?.role === "admin")
        navigate("/Anasayfa/Dashboard");
      else if (user?.role === "accounting") navigate("/Anasayfa/Firma");
      else if (user?.role === "manufacturer") navigate("/Anasayfa/Stok");
      else if (user?.role === "playmaker") navigate("/Anasayfa/Profil");
    }
  }, [resultLogin.isSuccess, resultLogin.isError]);

  const handleForgetPassword = (e) => {
    e.preventDefault();
    forgotPassword(email);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    Login({ password: pwd, email: email });
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="absolute inset-0 z-0">
        <img
          src={BackgroundImage}
          alt="background"
          className="w-full h-full object-cover opacity-20"
        />
      </div>
      {alert !== 0 && (
        <div className="fixed z-50 left-1/2 top-6 -translate-x-1/2">
          <Alerts AlertChoice={alert} message={message} />
        </div>
      )}

      {resultForgotPassword.isLoading || resultLogin.isLoading ? (
        <div className="flex justify-center items-center">
          <ThreeDots
            visible={true}
            height="200"
            width="200"
            color={colors.primary.hex}
            ariaLabel="loading-indicator"
          />
        </div>
      ) : (
        <div className="w-full max-w-md p-8">
          <div className="bg-gradient-to-br from-gray-900/90 to-gray-800/90 backdrop-blur-lg rounded-2xl shadow-2xl p-8 border border-white/20">
            {/* Logo */}
            <div className="flex justify-center ">
              <img src={LogoImage} alt="logo" className="h-28" />
            </div>

            {/* Login Form */}
            <form
              className={`${login ? "block" : "hidden"} space-y-6`}
              onSubmit={handleSubmit}
            >
              <div className="space-y-2">
                <label className="block text-white/80 text-sm font-medium">
                  Email
                </label>
                <div className="relative">
                  <HiOutlineMail className="absolute left-3 top-1/2 -translate-y-1/2 text-white/40 h-5 w-5" />
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="w-full bg-white/5 border border-white/10 rounded-lg py-2.5 pl-10 pr-4 text-white placeholder-white/30 focus:outline-none focus:ring-2 focus:ring-blue-500/40 focus:border-transparent transition-all"
                    placeholder="mail@example.com"
                  />
                </div>
              </div>

              <div className="space-y-2">
                <label className="block text-white/80 text-sm font-medium">
                  Şifre
                </label>
                <div className="relative">
                  <RiLockPasswordLine className="absolute left-3 top-1/2 -translate-y-1/2 text-white/40 h-5 w-5" />
                  <input
                    type="password"
                    value={pwd}
                    onChange={(e) => setPwd(e.target.value)}
                    required
                    className="w-full bg-white/5 border border-white/10 rounded-lg py-2.5 pl-10 pr-4 text-white placeholder-white/30 focus:outline-none focus:ring-2 focus:ring-blue-500/40 focus:border-transparent transition-all"
                    placeholder="••••••••"
                  />
                </div>
              </div>

              <div className="space-y-4">
                <button
                  type="submit"
                  className={`
                    w-full py-2.5 px-4 rounded-lg 
                    bg-gradient-to-r ${colors.primary.gradient}
                    text-white font-medium
                    hover:opacity-90 transition-all
                    focus:outline-none focus:ring-2 focus:ring-blue-500/40
                  `}
                >
                  Giriş Yap
                </button>

                <button
                  type="button"
                  onClick={() => {
                    setLogin(false);
                    setEmail("");
                    setPwd("");
                  }}
                  className="w-full text-sm text-white/60 hover:text-white transition-colors"
                >
                  Şifremi Unuttum
                </button>
              </div>
            </form>

            {/* Forgot Password Form */}
            <form
              className={`${login ? "hidden" : "block"} space-y-6`}
              onSubmit={handleForgetPassword}
            >
              <div className="space-y-2">
                <label className="block text-white/80 text-sm font-medium">
                  Email
                </label>
                <div className="relative">
                  <HiOutlineMail className="absolute left-3 top-1/2 -translate-y-1/2 text-white/40 h-5 w-5" />
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="w-full bg-white/5 border border-white/10 rounded-lg py-2.5 pl-10 pr-4 text-white placeholder-white/30 focus:outline-none focus:ring-2 focus:ring-blue-500/40 focus:border-transparent transition-all"
                    placeholder="mail@example.com"
                  />
                </div>
              </div>

              <div className="space-y-4">
                <button
                  type="submit"
                  className={`
                    w-full py-2.5 px-4 rounded-lg 
                    bg-gradient-to-r ${colors.primary.gradient}
                    text-white font-medium
                    hover:opacity-90 transition-all
                    focus:outline-none focus:ring-2 focus:ring-blue-500/40
                  `}
                >
                  Şifre Sıfırla
                </button>

                <button
                  type="button"
                  onClick={() => {
                    setLogin(true);
                    setEmail("");
                  }}
                  className="w-full text-sm text-white/60 hover:text-white transition-colors"
                >
                  Giriş Sayfasına Dön
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
