import Input from "./Input";
import SelectOptions from "./Select";
import Checkbox from "./Checkbox";
import Button from "./Button";
import TelInput from "./TelInput";
import Number from "./Number";
const Controls = {
  Input,
  SelectOptions,
  Checkbox,
  Button,
  TelInput,
  Number,
};

export default Controls;
