import React, { useEffect } from "react";
import Controls from "../Components/Form/Controls";
import { useForm } from "../Components/Form/useForm";
import { colors } from "../Config/Colors";
import { IoMdAddCircle } from "react-icons/io";
import { MdPerson, MdEmail, MdBusiness } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import { FaUserTag } from "react-icons/fa";
import { useFirmData } from "../Hooks/useFirmData";

const isValidEmail = (email) => {
  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  return emailPattern.test(email);
};

export default function UserForm({ handleOpenModal, token, initialFValues }) {
  const { firms, isLoading, isFetching, error } = useFirmData(token);

  const firmOptions = [
    ...firms?.map((option) => {
      return { id: option._id, title: option.firmName };
    }),
  ];

  const roleOptions = [
    { id: "1", title: "Yönetici" },
    { id: "2", title: "Muhasebe" },
    { id: "3", title: "Üretim" },
    { id: "4", title: "Plasiyer" },
    { id: "5", title: "Müşteri" },
  ];

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("role" in fieldValues)
      temp.role = fieldValues.role ? "" : "Kullanıcı rolü seçilmedi.";
    if ("firmName" in fieldValues)
      temp.firmName = fieldValues.firmName ? "" : "Firma seçilmedi.";
    if ("email" in fieldValues)
      temp.email = isValidEmail(fieldValues.email)
        ? ""
        : "Doğru bir email adresi girin.";
    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "İsim girin.";
    if ("lastName" in fieldValues)
      temp.lastName = fieldValues.lastName ? "" : "Soyisim girin.";
    if ("tel" in fieldValues)
      temp.tel = fieldValues.tel ? "" : "Telefon girin.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  useEffect(() => {
    if (values.firmName === "Bayiner") {
      if (values.role === "Müşteri") {
        setValues({ ...values, role: "" });
      }
    }
  }, [values.role, values.firmName]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      handleOpenModal(values);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="mx-auto rounded-xl shadow-sm  bg-white"
    >
      <div className="  overflow-hidden">
        <div className="p-6 space-y-6">
          {/* Rol ve Firma Seçimi */}
          <div className="grid md:grid-cols-2 gap-6">
            <div className="space-y-2">
              <div className="flex items-center gap-2 mb-2">
                <FaUserTag className={`${colors.primary.text} w-5 h-5`} />
                <label className={`font-medium ${colors.primary.text}`}>
                  Kullanıcı Rolü
                </label>
              </div>
              <Controls.SelectOptions
                name="role"
                value={values.role}
                onChange={handleInputChange}
                options={roleOptions}
                error={errors.role}
                className="w-full"
              />
            </div>

            <div className="space-y-2">
              <div className="flex items-center gap-2 mb-2">
                <MdBusiness className={`${colors.primary.text} w-5 h-5`} />
                <label className={`font-medium ${colors.primary.text}`}>
                  Firma Seçimi
                </label>
              </div>
              <Controls.SelectOptions
                name="firmName"
                value={values.firmName}
                onChange={handleInputChange}
                options={firmOptions}
                error={errors.firmName}
                className="w-full"
              />
            </div>
          </div>

          {/* Kişisel Bilgiler */}
          <div className="space-y-4">
            <div className="flex items-center gap-2">
              <MdPerson className={`${colors.primary.text} w-5 h-5`} />
              <h3 className={`font-medium ${colors.primary.text}`}>
                Kişisel Bilgiler
              </h3>
            </div>
            <div className="grid md:grid-cols-2 gap-6">
              <Controls.Input
                label="İsim"
                name="name"
                value={values.name}
                onChange={handleInputChange}
                error={errors.name}
              />
              <Controls.Input
                label="Soyisim"
                name="lastName"
                value={values.lastName}
                onChange={handleInputChange}
                error={errors.lastName}
              />
            </div>
          </div>

          {/* İletişim Bilgileri */}
          <div className="space-y-4">
            <div className="flex items-center gap-2">
              <BsTelephone className={`${colors.primary.text} w-5 h-5`} />
              <h3 className={`font-medium ${colors.primary.text}`}>
                İletişim Bilgileri
              </h3>
            </div>
            <div className="grid md:grid-cols-2 gap-6">
              <Controls.Input
                label="Email"
                name="email"
                value={values.email}
                onChange={handleInputChange}
                error={errors.email}
              />
              <Controls.TelInput
                label="Telefon"
                name="tel"
                value={values.tel}
                onChange={handleInputChange}
                error={errors.tel}
              />
            </div>
          </div>
        </div>

        {/* Form Footer */}
        <div className="px-6 py-4  flex justify-end">
          <button
            type="submit"
            className={`
              flex items-center gap-2 px-6 py-2 rounded-lg
              bg-gradient-to-r ${colors.primary.gradient}
              text-white font-medium
              hover:shadow-md transform hover:-translate-y-0.5
              transition-all duration-200
            `}
          >
            <IoMdAddCircle className="w-5 h-5" />
            <span>Kaydet</span>
          </button>
        </div>
      </div>
    </form>
  );
}
