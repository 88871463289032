import { useState, ChangeEvent, useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import styles from "../../../CustomStyles";
import criticQuotaDevice from "../../../Assets/icons/criticquota_device.png";
import unQuotaDevice from "../../../Assets/icons/unquota_device.png";
import unConnectedDevice from "../../../Assets/icons/unconnected_device.png";
import firmDevice from "../../../Assets/icons/firm_device.png";
import storageDevice from "../../../Assets/icons/storage_device.png";
import playmakerDevice from "../../../Assets/icons/playmaker_device.png";
import serviceDevice from "../../../Assets/icons/service_device.png";

import addedDevice from "../../../Assets/icons/added_device.png";

import { TextField } from "@mui/material";
import DeviceTable from "./DeviceTable";
import { colors } from "../../../Config/Colors";

function isThreeDaysApart(date1, date2) {
  // Tarihleri milisaniyeye çevirin
  const timeDifference = Math.abs(new Date(date1) - new Date(date2));

  // Farkı gün cinsine çevirin
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  // 3 güne eşit olup olmadığını kontrol edin
  return daysDifference >= 3;
}

export default function DeviceInfoCard({
  warn,
  devices,
  isLoading,
  isFetching,
}) {
  const [expanded, setExpanded] = useState("criticQuota");
  const [hovered, setHovered] = useState(null);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [filterSerialNo, setFilterSerialNo] = useState("");
  const [filterCount, setFilterCount] = useState({
    playmaker: 0,
    firm: 0,
    storage: 0,
    service: 0,
    unConnected: 0,
    unQuota: 0,
    criticQuota: 0,
  });

  useEffect(() => {
    if (filterSerialNo === "" && devices) {
      setFilteredDevices(devices);
    } else if (devices) {
      setFilteredDevices(
        devices.filter((device) => device.serialNo.includes(filterSerialNo))
      );
    }
  }, [filterSerialNo]);
  useEffect(() => {
    if (devices) {
      const filterCount = {
        playmaker: 0,
        firm: 0,
        storage: 0,
        unConnected: 0,
        unQuota: 0,
        criticQuota: 0,
        service: 0,
        added: 0,
      };
      const now = new Date();
      devices.forEach((device) => {
        switch (device.deviceStatusName) {
          case "Plasiyer":
            filterCount.playmaker += 1;
            break;
          case "Firma":
            filterCount.firm += 1;
            break;
          case "Depo":
            filterCount.storage += 1;
            break;
          case "Servis":
            filterCount.service += 1;
            break;
          case "Üretim":
            filterCount.added += 1;
            break;
        }

        if (
          isThreeDaysApart(device.lastConnectionDate, now) &&
          device.deviceStatusName === "Firma"
        ) {
          filterCount.unConnected += 1;
        }
        device.productInfo?.productInfo?.some((product) => {
          let flag = 0;
          if (product.quota <= 0 && device.deviceStatusName === "Firma") {
            filterCount.unQuota += 1;
            flag = 1;
          }
          if (
            product.quota <= product.quotaWarning &&
            device.deviceStatusName === "Firma" &&
            product.quota !== 0
          ) {
            filterCount.criticQuota += 1;
            flag = 1;
          }

          if (flag === 1) return true;
        });
      });

      setFilterCount(filterCount);
    }
  }, [devices]);

  useEffect(() => {
    if (expanded === "playmaker") {
      setFilteredDevices(
        devices?.filter((device) => device.deviceStatusName === "Plasiyer")
      );
    } else if (expanded === "firm") {
      setFilteredDevices(
        devices?.filter((device) => device.deviceStatusName === "Firma")
      );
    } else if (expanded === "storage") {
      setFilteredDevices(
        devices?.filter((device) => device.deviceStatusName === "Depo")
      );
    } else if (expanded === "service") {
      setFilteredDevices(
        devices?.filter((device) => device.deviceStatusName === "Servis")
      );
    } else if (expanded === "added") {
      setFilteredDevices(
        devices?.filter((device) => device.deviceStatusName === "Üretim")
      );
    } else if (expanded === "unQuota") {
      const temp = devices.filter((device) => {
        return device.productInfo.productInfo.some((product) => {
          return product.quota <= 0 && device.deviceStatusName === "Firma";
        });
      });
      setFilteredDevices(temp);
    } else if (expanded === "criticQuota") {
      const temp = devices?.filter((device) => {
        return device?.productInfo.productInfo.some((product) => {
          return (
            product.quota <= product.quotaWarning &&
            device.deviceStatusName === "Firma" &&
            product.quota !== 0
          );
        });
      });
      setFilteredDevices(temp);
    } else if (expanded === "unConnected") {
      const now = new Date();
      setFilteredDevices(
        devices?.filter((device) => {
          if (device.deviceStatusName === "Firma")
            return isThreeDaysApart(device.lastConnectionDate, now);
          else return false;
        })
      );
    } else {
      setFilteredDevices(devices);
    }
  }, [expanded]);

  const handleExpand = (type) => {
    setExpanded((prev) => (prev === type ? null : type));
  };

  const handleMouseEnter = (type) => {
    setHovered(type);
  };

  const handleMouseLeave = () => {
    setHovered(null);
  };

  return (
    <div
      className={`
      flex flex-col gap-6 p-6 shadow-lg bg-white  w-4/6
      max-xl:w-full  h-[720px] rounded-2xl
      ${colors.border.light}
    `}
    >
      {/* Üst Kısım - Filtreler */}
      <div className="flex flex-col gap-4">
        <h2 className={`text-2xl font-semibold ${colors.text.primary}`}>
          Cihaz Durumları
        </h2>

        <div className="flex flex-wrap gap-3">
          {/* Her bir filtre butonu için */}
          {[
            {
              id: "criticQuota",
              label: "Kritik Kota",
              color: "bg-red-100 text-red-600",
              icon: criticQuotaDevice,
              count: filterCount.criticQuota,
            },
            {
              id: "unQuota",
              label: "Kotasız",
              color: "bg-orange-100 text-orange-600",
              icon: unQuotaDevice,
              count: filterCount.unQuota,
            },
            {
              id: "unConnected",
              label: "Bağlanmayan",
              color: "bg-yellow-100 text-yellow-600",
              icon: unConnectedDevice,
              count: filterCount.unConnected,
            },
            {
              id: "firm",
              label: "Firma",
              color: "bg-green-100 text-green-600",
              icon: firmDevice,
              count: filterCount.firm,
            },
            {
              id: "storage",
              label: "Depo",
              color: "bg-blue-100 text-blue-600",
              icon: storageDevice,
              count: filterCount.storage,
            },
            {
              id: "playmaker",
              label: "Plasiyer",
              color: "bg-indigo-100 text-indigo-600",
              icon: playmakerDevice,
              count: filterCount.playmaker,
            },
            {
              id: "service",
              label: "Servis",
              color: "bg-purple-100 text-purple-600",
              icon: serviceDevice,
              count: filterCount.service,
            },
            {
              id: "added",
              label: "Üretim",
              color: "bg-pink-100 text-pink-600",
              icon: addedDevice,
              count: filterCount.added,
            },
          ].map((filter) => (
            <button
              key={filter.id}
              onClick={() => handleExpand(filter.id)}
              onMouseEnter={() => handleMouseEnter(filter.id)}
              onMouseLeave={handleMouseLeave}
              className={`
                flex items-center gap-2 px-3 py-2 rounded-lg
                ${filter.color}
                transition-all duration-300
                hover:opacity-90
                ${
                  expanded === filter.id
                    ? `ring-2 ring-offset-2 ring-${colors.primary.main}`
                    : ""
                }
              `}
            >
              <img src={filter.icon} alt={filter.label} className="w-6 h-6" />
              <span className="font-medium">{filter.label}</span>
              <span className="font-bold">{filter.count}</span>
            </button>
          ))}
        </div>

        {/* Seri No Arama */}
        <TextField
          label="Seri No"
          variant="outlined"
          size="small"
          value={filterSerialNo}
          onChange={(e) => setFilterSerialNo(e.target.value)}
          className="w-full max-w-xs"
          InputProps={{
            className: "bg-gray-50",
          }}
        />
      </div>

      {/* Alt Kısım - Tablo */}
      {isFetching || isLoading ? (
        <div className="flex-1 flex items-center justify-center">
          <ThreeDots
            visible={true}
            height="80"
            width="80"
            color={colors.primary.hex}
            ariaLabel="loading"
          />
        </div>
      ) : !filteredDevices || filteredDevices?.length === 0 ? (
        <div className="flex-1 flex items-center justify-center">
          <p
            className={`text-xl font-medium ${colors.text.primary} p-4 rounded-md`}
          >
            {warn}
          </p>
        </div>
      ) : (
        <DeviceTable
          filterSerialNo={filterSerialNo}
          expanded={expanded}
          data={filteredDevices}
        />
      )}
    </div>
  );
}
