import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { NavigationProvider } from "./context/navigation";
import { UsersProvider } from "./context/users";
import { Provider } from "react-redux";
import { BrowserRouter, HashRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider";
import { store } from "./store";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { trTR } from "@mui/material/locale";

const el = document.getElementById("root");
const root = ReactDOM.createRoot(el);
const theme = createTheme({}, trTR);

root.render(
  <React.StrictMode>
    <HashRouter>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <UsersProvider>
            <NavigationProvider>
              <AuthProvider>
                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>
              </AuthProvider>
            </NavigationProvider>
          </UsersProvider>
        </Provider>
      </ThemeProvider>
    </HashRouter>
  </React.StrictMode>
);
