import React from "react";
import { Modal, Backdrop, Fade, Box } from "@mui/material";
import DeviceCloseForm from "../../Forms/DeviceForm/DeviceCloseForm";
import DevicePositionPlaymaker from "../../Forms/DeviceForm/DevicePositionPlaymaker";
import DevicePositionStorage from "../../Forms/DeviceForm/DevicePositionStorage";
import DevicePositionService from "../../Forms/DeviceForm/DevicePositionService";
import DevicePositionFirm from "../../Forms/DeviceForm/DevicePositionFirm";

const DeviceOperationModal = ({
  open,
  onClose,
  operationDetail,
  auth,
  makeDeviceOperation,
}) => {
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    bgcolor: "background.paper",
    borderRadius: "12px",
    boxShadow: 24,
    outline: "none",
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            backdropFilter: "blur(4px)",
          },
        },
      }}
    >
      <Fade in={open}>
        <Box sx={modalStyle}>
          <div className="bg-white rounded-xl overflow-hidden">
            {operationDetail.operation === "Aktiflik" && (
              <DeviceCloseForm
                initialFValues={{
                  isActive: operationDetail.isActive,
                  connect: true,
                  description: "",
                }}
                setDesignedInput={(values) => {
                  makeDeviceOperation({
                    operation: "Activity",
                    id: operationDetail._id,
                    ...values,
                    token: auth.accessToken,
                    deviceStatusName: operationDetail.deviceStatusName,
                    firmName: operationDetail.firmName,
                    userID: "",
                  });
                  onClose();
                }}
              />
            )}
            {operationDetail.operation === "Plasiyer" && (
              <DevicePositionPlaymaker
                initialFValues={{
                  playmaker: "",
                  connect: true,
                  description: "",
                }}
                token={auth.accessToken}
                setDesignedInput={(values) => {
                  makeDeviceOperation({
                    operation: "DeviceStatusName",
                    id: operationDetail._id,
                    ...values,
                    token: auth.accessToken,
                    deviceStatusName: "Plasiyer",
                    isActive: operationDetail.isActive,
                    firmName: operationDetail.firmName,
                  });
                  onClose();
                }}
              />
            )}
            {operationDetail.operation === "Depo" && (
              <DevicePositionStorage
                initialFValues={{
                  firmName: "",
                  connect: true,
                  description: "",
                }}
                token={auth.accessToken}
                setDesignedInput={(values) => {
                  makeDeviceOperation({
                    operation: "DeviceStatusName",
                    id: operationDetail._id,
                    ...values,
                    token: auth.accessToken,
                    deviceStatusName: "Depo",
                    isActive: operationDetail.isActive,
                    userID: "",
                  });
                  onClose();
                }}
              />
            )}
            {operationDetail.operation === "Servis" && (
              <DevicePositionService
                initialFValues={{
                  connect: true,
                  description: "",
                }}
                token={auth.accessToken}
                setDesignedInput={(values) => {
                  makeDeviceOperation({
                    operation: "DeviceStatusName",
                    id: operationDetail._id,
                    ...values,
                    token: auth.accessToken,
                    deviceStatusName: "Servis",
                    isActive: operationDetail.isActive,
                    firmName: operationDetail.firmName,
                    userID: "",
                  });
                  onClose();
                }}
              />
            )}
            {operationDetail.operation === "Firma" && (
              <DevicePositionFirm
                initialFValues={{
                  firmName: operationDetail.firmName,
                  connect: true,
                  description: "",
                }}
                token={auth.accessToken}
                setDesignedInput={(values) => {
                  makeDeviceOperation({
                    operation: "DeviceStatusName",
                    id: operationDetail._id,
                    ...values,
                    token: auth.accessToken,
                    deviceStatusName: "Firma",
                    isActive: operationDetail.isActive,
                    userID: "",
                  });
                  onClose();
                }}
              />
            )}
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default DeviceOperationModal;
