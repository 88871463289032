import { colors } from "../../../Config/Colors";

const buttons = [
  { id: "today", label: "Bugün" },
  { id: "thisweek", label: "Haftalık" },
  { id: "thismonth", label: "Aylık" },
  { id: "thisyear", label: "Yıllık" },
];

export default function FilterIncome({
  button,
  handleClick,
  active = "thisyear",
}) {
  const baseStyle = "transition-all duration-300";
  const activeStyle = `bg-white/20 text-white border-white/30`;
  const passiveStyle = `bg-transparent text-white/70 border-white/20 hover:bg-white/10 hover:text-white`;

  return (
    <div className="flex flex-wrap justify-end gap-2">
      {buttons.map(({ id, label }) => (
        <button
          key={id}
          onClick={() => handleClick(id)}
          className={`
            ${baseStyle}
            ${button}
            ${active === id ? activeStyle : passiveStyle}
            border px-3 py-1.5 rounded-lg text-xl font-medium
            whitespace-nowrap
          `}
        >
          {label}
        </button>
      ))}
    </div>
  );
}
