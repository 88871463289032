import { useState, useMemo } from "react";
import { useGetDeviceByFirmIDQuery } from "../store";

export function useFirmDevicesData({ id, token }) {
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [filteredData, setFilteredData] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [choice, setChoice] = useState(0);

  const {
    data: devicesData,
    isLoading: devicesIsLoading,
    isFetching: devicesIsFetching,
    error: devicesError,
  } = useGetDeviceByFirmIDQuery(
    { id, token },
    {
      refetchOnMountOrArgChange: true,
      skip: !token || !id,
    }
  );

  const devices = useMemo(
    () => devicesData?.data?.devices || [],
    [devicesData]
  );

  const handleSearch = (data, isSearch) => {
    setPaginationNumber(1);
    setFilteredData(data);
    setIsSearch(isSearch);
  };

  const isLoading = devicesIsLoading || devicesIsFetching;
  const hasNoData = devicesError || devices.length === 0;

  return {
    devices,
    isLoading,
    hasNoData,
    devicesError,
    paginationNumber,
    setPaginationNumber,
    filteredData,
    isSearch,
    setIsSearch,
    handleSearch,
    choice,
    setChoice,
  };
}
