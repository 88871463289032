import { Outlet, useLocation } from "react-router-dom";
import { SlGraph } from "react-icons/sl";
import { TbListDetails } from "react-icons/tb";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { BsInfoLg } from "react-icons/bs";
import styles from "../../CustomStyles";
import FormatDateHourString from "../../Components/Utils/FormatDateHourString";
import TopBar from "../../Components/Utils/TopBar";
import useAuth from "../../Hooks/useAuth";
import { colors } from "../../Config/Colors";

function InfoFirmPage() {
  const location = useLocation();
  const { bayserNo, firmName, isActive, createdInfo, updatedInfo } =
    location.state;
  const { auth } = useAuth();

  const links = [
    {
      to: "Genel",
      state: { ...location.state },
      label: (
        <p className={`${styles.PageHeader} max-md:hidden`}>Genel Bilgiler</p>
      ),
      icon: <BsInfoLg className={`${styles.PageHeaderIcon}`} />,
    },
    ...(auth.role === "admin" || auth.role === "management"
      ? [
          {
            to: "İstatistik",
            state: { ...location.state },
            label: (
              <p className={`${styles.PageHeader} max-md:hidden`}>İstatistik</p>
            ),
            icon: (
              <SlGraph className={`${styles.PageHeaderIcon} max-md:ml-4`} />
            ),
          },
          {
            to: "Rapor",
            state: { ...location.state },
            label: (
              <p className={`${styles.PageHeader} max-md:hidden`}>Rapor</p>
            ),
            icon: (
              <TbListDetails
                className={`${styles.PageHeaderIcon} max-md:ml-4`}
              />
            ),
          },
        ]
      : []),
  ];

  const backLink = {
    path: "/Anasayfa/Firma",
    icon: <MdOutlineKeyboardBackspace className={`${styles.PageHeaderIcon}`} />,
  };

  return (
    <div className="min-h-screen px-16 max-md:px-0">
      {/* Top Bar */}
      <TopBar links={links} backLink={backLink} />

      {/* Main Content */}
      <div className="mb-40 mt-4">
        <Outlet />
      </div>

      {/* Bottom Info Bar */}
      <div
        className={`
          fixed bottom-0 left-0 right-0 
          ${colors.background.main} border-t ${colors.border.light}
          md:left-[20%] md:right-[20%] md:bottom-8 md:rounded-xl md:border
          shadow-xl backdrop-blur-sm bg-opacity-95
          transition-all duration-300
        `}
      >
        <div className="flex justify-evenly p-4">
          {/* Bayser No */}
          <div className="flex flex-col gap-2">
            <span className={`text-base font-medium ${colors.text.tertiary}`}>
              Bayser No
            </span>
            <span
              className={`text-xl font-semibold ${colors.background.light} px-4 py-2 rounded-lg ${colors.text.secondary}`}
            >
              {bayserNo}
            </span>
          </div>

          {/* Firm Name */}
          <div className="flex flex-col gap-2">
            <span className={`text-base font-medium ${colors.text.tertiary}`}>
              Firma
            </span>
            <span
              className={`text-xl font-semibold ${colors.background.light} px-4 py-2 rounded-lg ${colors.text.primary}`}
            >
              {firmName}
            </span>
          </div>

          {/* Creation Date - Hidden on mobile */}
          <div className="max-md:hidden flex flex-col gap-2">
            <span className={`text-base font-medium ${colors.text.tertiary}`}>
              Oluşturulma
            </span>
            <span
              className={`text-xl font-semibold ${colors.background.light} px-4 py-2 rounded-lg ${colors.text.secondary}`}
            >
              {FormatDateHourString(createdInfo)}
            </span>
          </div>

          {/* Last Update - Hidden on mobile */}
          <div className="max-md:hidden flex flex-col gap-2">
            <span className={`text-base font-medium ${colors.text.tertiary}`}>
              Son Güncelleme
            </span>
            <span
              className={`text-xl font-semibold ${colors.background.light} px-4 py-2 rounded-lg ${colors.text.secondary}`}
            >
              {FormatDateHourString(updatedInfo)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InfoFirmPage;
