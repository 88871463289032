import { useMemo } from "react";
import useAuth from "../../../Hooks/useAuth";
import { NavLink, useLocation } from "react-router-dom";
import { BsFillPencilFill } from "react-icons/bs";
import { TbReportAnalytics } from "react-icons/tb";
import UnfindDataWarn from "../../../Components/Utils/UnfindDataWarn";
import Loader from "../../../Components/Utils/Loader";
import DataSearchBar from "../../../Components/SearchBar/DataSearchBar";
import { useBelowFirmsData } from "../../../Hooks/useBelowFirmsData";
import CustomFirmList from "../../../Components/Table/CustomFirmList";

function ControlFirm() {
  const location = useLocation();
  const { auth } = useAuth();
  const id = location.state.id;

  const buttonIcon = "xl:w-8 xl:h-8 md:w-7 md:h-7 w-6 h-6";
  const tableButton = " text-main hover:text-gray-400";

  const {
    belowFirms,
    belowFirmsIsLoading,
    belowFirmsIsFetching,
    belowFirmsError,
    paginationNumber,
    setPaginationNumber,
    filteredData,
    isSearch,
    handleSearch,
  } = useBelowFirmsData({ id, token: auth.accessToken });

  const inputFieldName = {
    firmName: "Firma İsmi",
    bayserNo: "Bayser Numarası",
  };

  const config = useMemo(
    () => [
      {
        class: "w-4",
        label: "Detay",
        render: (data) => (
          <div className="flex flex-row justify-center">
            <NavLink
              state={{
                id: data._id,
                firmName: data.firmName,
                bayserNo: data.bayserNo,
                createdInfo: data.createdInfo,
                updatedInfo: data.updatedInfo,
              }}
              to={`/Anasayfa/Firma/Düzenle/Bilgi`}
            >
              <button
                className={`flex items-center justify-center py-2 pl-4 pr-3 rounded-full transition duration-500 ${tableButton}`}
              >
                <BsFillPencilFill
                  className={`2xl:w-10 2xl:h-10 w-7 h-7 ${buttonIcon}`}
                />
              </button>
            </NavLink>

            <NavLink
              state={{
                id: data._id,
                firmName: data.firmName,
                bayserNo: data.bayserNo,
                isActive: data.isActive,
                createdInfo: data.createdInfo,
                updatedInfo: data.updatedInfo,
              }}
              to={`/Anasayfa/Firma/Bilgi/Genel`}
            >
              <button
                className={`flex items-center justify-center py-2 pl-4 pr-3 rounded-full transition duration-500 ${tableButton}`}
              >
                <TbReportAnalytics
                  className={`2xl:w-10 2xl:h-10 w-7 h-7 ${buttonIcon}`}
                />
              </button>
            </NavLink>
          </div>
        ),
      },
      {
        label: " Bayser No",
        render: (data) => (
          <>
            <div className=" md:hidden opacity-40 font-Bold">Bayser No:</div>
            {data.bayserNo}
          </>
        ),
        sortValue: (data) => data.bayserNo,
      },
      {
        label: " Firma İsmi",
        render: (data) => (
          <>
            <div className=" md:hidden opacity-40 font-Bold">Firma:</div>
            {data.firmName}
          </>
        ),
        sortValue: (data) => data.firmName,
      },
      {
        label: "İletişim",
        render: (data) => (
          <>
            <div className=" md:hidden opacity-40 font-Bold">İletişim:</div>
            <div className=" flex flex-col">
              <div>
                <p>Email: </p>
                {data.email}
              </div>
              <div>
                <p>Telefon: </p>
                {data.tel}
              </div>
            </div>
          </>
        ),
      },
      {
        label: "Adres",
        render: (data) => (
          <>
            <div className=" md:hidden opacity-40 font-Bold">Adres:</div>

            <div className=" flex flex-col">
              <div>
                <p>İl: </p>
                {data.address.city}
              </div>
              <div>
                <p>İlçe: </p>
                {data.address.town}
              </div>
            </div>
          </>
        ),
      },
      {
        label: "Durum",
        render: (data) => (
          <>
            <div className=" md:hidden opacity-40 font-Bold">Aktivite:</div>
            {data.isActive ? "Aktif" : "Pasif"}
          </>
        ),
        sortValue: (data) => data.isActive,
      },
    ],
    [buttonIcon, tableButton]
  );

  const isLoading = belowFirmsIsLoading || belowFirmsIsFetching;

  return (
    <div className="grid w-full">
      {isLoading ? (
        <div className="flex w-full h-screen justify-center items-center">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : belowFirmsError || belowFirms.length === 0 ? (
        <div className="fixed top-0 left-0 flex w-full h-full justify-center items-center -z-10">
          <UnfindDataWarn Data={"Alt firma bulunamadı !"} />
        </div>
      ) : (
        <>
          <div className="bg-white w-full rounded-xl shadow-xl transition-all duration-300 mb-4 mt-10 block p-10">
            <DataSearchBar
              Data={belowFirms}
              handleSearch={handleSearch}
              inputFieldName={inputFieldName}
            />
          </div>
          <div className="mb-20">
            <CustomFirmList
              data={
                isSearch
                  ? filteredData
                  : belowFirms.slice(
                      (paginationNumber - 1) * 10,
                      paginationNumber * 10
                    )
              }
            />
          </div>
        </>
      )}
    </div>
  );
}

export default ControlFirm;
