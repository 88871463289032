import { useState, useEffect } from "react";
import { MdAddBusiness, MdOutlineKeyboardBackspace } from "react-icons/md";
import useAuth from "../../../Hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useAddFirmMutation } from "../../../store";
import Alerts from "../../../Components/Utils/Alert";
import Confirm from "../../../Components/Utils/Confirm";
import Loader from "../../../Components/Utils/Loader";
import CreateFirmForm from "../../../Forms/FirmForm/FirmForm";
import TopBar from "../../../Components/Utils/TopBar";
import { colors } from "../../../Config/Colors";

function CreateFirmPage() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const [inputFieldName, setInputFieldName] = useState({
    firmName: "Firma İsmi",
    mainFirmName: "Ana Firma İsmi",
    email: "Firma Email",
    tel: "Firma Telefon",
  });
  const [infoText, setInfoText] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [add, resultAdd] = useAddFirmMutation();
  const [alert, setAlert] = useState(0);
  const [message, setMessage] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [input, setInput] = useState({
    firmName: "",
    orderFrequencyDays: 30,
    isActive: true,
    mainFirmName: "",
    isCorporate: false,
    officialID: "",
    taxNumber: "",
    taxOffice: "",
    email: "",
    tel: "",
    note: "",
    address: { text: "", city: "", town: "" },
    token: token,
  });

  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        if (alert === 1) {
          setAlert(0);
          setMessage("");
          navigate("/Anasayfa/Firma");
        }
        setAlert(0);
        setMessage("");
      }, 2000);
    }
  }, [alert]);

  useEffect(() => {
    setConfirm(false);
    if (resultAdd.isError === true) {
      setAlert(2);
      setMessage("İşlem başarısız tekrar deneyin !");
    }
    if (resultAdd.isSuccess === true) {
      setAlert(1);
      setMessage("İşlem başarı ile gerçekleşti !");
    }
  }, [resultAdd.isSuccess, resultAdd.isError]);

  const handleCloseModel = (bool) => {
    if (bool) {
      add(input);
      setConfirm(true);
      setInfoText("Eklemek istediğinize emin misiniz ?");
      setShowConfirmModal(false);
    } else {
      setShowConfirmModal(false);
    }
  };

  // TopBar için gerekli linkler
  const links = [
    {
      to: "",
      label: (
        <p className="text-2xl font-medium max-md:hidden">Firma Oluştur</p>
      ),
      icon: <MdAddBusiness className="w-10 h-10" />,
    },
  ];

  const backLink = {
    path: "/Anasayfa/Firma",
    icon: <MdOutlineKeyboardBackspace className="w-10 h-10" />,
  };

  return (
    <div className="min-h-screen px-16 mb-48 max-md:px-0">
      {/* Alerts */}
      {alert !== 0 && (
        <div className="fixed z-50 left-1/2 top-0 -translate-x-1/2">
          <Alerts AlertChoice={alert} message={message} />
        </div>
      )}

      {/* Confirm Modal */}
      <Confirm
        input={input}
        inputFieldName={inputFieldName}
        infoText={infoText}
        handleCloseModel={handleCloseModel}
        showConfirmModal={showConfirmModal}
      />

      {/* Top Bar */}
      <TopBar links={links} backLink={backLink} />

      {/* Main Content */}
      <div className=" mt-6">
        {resultAdd.isLoading || confirm ? (
          <div className="flex w-full h-full justify-center items-center">
            <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
          </div>
        ) : (
          <div className="flex w-full flex-col justify-between gap-4">
            <CreateFirmForm
              setDesignedInput={setInput}
              setInputFieldName={setInputFieldName}
              setShowConfirmModal={setShowConfirmModal}
              initialFValues={{
                isCorporate: false,
                isActive: true,
                orderFrequencyDays: 30,
                firmName: "",
                mainFirmName: "",
                officialNumber: "",
                taxNumber: "",
                taxOffice: "",
                email: "",
                tel: "",
                note: "",
                city: "",
                town: "",
                text: "",
              }}
              token={token}
            />
          </div>
        )}
      </div>

      {/* Bottom Info Bar */}
      <div
        className={`
          fixed bottom-0 left-0 right-0 
          ${colors.background.main} border-t ${colors.border.light}
          md:left-[20%] md:right-[20%] md:bottom-8 md:rounded-xl md:border
          shadow-xl backdrop-blur-sm bg-opacity-95
          transition-all duration-300
        `}
      >
        <div className="flex justify-evenly p-4">
          {/* İşlem Bilgisi */}
          <div className="flex flex-col gap-2">
            <span className={`text-base font-medium ${colors.text.tertiary}`}>
              İşlem
            </span>
            <span
              className={`
                text-xl font-semibold 
                ${colors.background.light} 
                px-4 py-2 rounded-lg 
                ${colors.primary.text}
                flex items-center gap-2
              `}
            >
              <MdAddBusiness className="w-6 h-6" />
              Yeni Firma
            </span>
          </div>

          {/* Sistem Bilgisi */}
          <div className="max-md:hidden flex flex-col gap-2">
            <span className={`text-base font-medium ${colors.text.tertiary}`}>
              Sistem
            </span>
            <span
              className={`
                text-xl font-semibold 
                ${colors.background.light} 
                px-4 py-2 rounded-lg 
                ${colors.text.secondary}
              `}
            >
              Firma Yönetimi
            </span>
          </div>

          {/* Durum Bilgisi */}
          <div className="max-md:hidden flex flex-col gap-2">
            <span className={`text-base font-medium ${colors.text.tertiary}`}>
              Durum
            </span>
            <span
              className={`
                text-xl font-semibold 
                ${colors.background.light} 
                px-4 py-2 rounded-lg 
                text-emerald-600
              `}
            >
              Yeni Kayıt
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateFirmPage;
