import React from "react";
import Button from "@mui/material/Button";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import Tooltip from "@mui/material/Tooltip";
import { RiFileExcel2Fill } from "react-icons/ri";
import { colors } from "../../Config/Colors";

const ExportExcel = ({ excelData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Tooltip title="Excel'e Aktar">
      <Button
        variant="contained"
        onClick={exportToExcel}
        sx={{
          backgroundColor: "#219B9D", // colors.primary.hex
          "&:hover": {
            backgroundColor: "#8ABFA3", // colors.primary.hex darker
          },
          padding: "8px 16px",
          gap: "8px",
          fontSize: "18px",
          fontWeight: 500,
          textTransform: "none",
          boxShadow: "0 2px 4px rgba(147, 198, 231, 0.4)", // colors.primary.hex with opacity
        }}
      >
        <RiFileExcel2Fill className="w-8 h-8" />
        Excel İndir
      </Button>
    </Tooltip>
  );
};

export default ExportExcel;
