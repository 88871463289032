import { useGetFirmBillUploadOperationReportQuery } from "../../../store/reportsApi";
import useAuth from "../../../Hooks/useAuth";
import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ReportLayout from "../../../Layout/Reports/ReportLayout";
import Loader from "../../../Components/Utils/Loader";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const billTypeOptions = [
  { value: "", label: "Tümü" },
  { value: "Bill", label: "Fatura" },
  { value: "FreeUpload", label: "Serbest Yükleme" },
  { value: "GetReturn", label: "İade" },
];

export default function ReportFirmUploadedBillPage() {
  const { auth } = useAuth();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [billNo, setBillNo] = useState("");
  const [firmName, setFirmName] = useState("");
  const [productTypeName, setProductTypeName] = useState("");
  const [billType, setBillType] = useState("");
  const [shouldFetch, setShouldFetch] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const token = auth.accessToken;

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const {
    data: billReport,
    isLoading,
    error,
  } = useGetFirmBillUploadOperationReportQuery(
    {
      token,
      startDate: startDate ? startDate.toISOString() : "undefined",
      endDate: endDate ? endDate.toISOString() : "undefined",
      billNo: billNo || "undefined",
      firmName: firmName || "undefined",
      productTypeName: productTypeName || "undefined",
      billType: billType || "undefined",
      timeZone: timeZone || "UTC",
    },
    { skip: !shouldFetch }
  );

  useEffect(() => {
    if (billReport && billReport.data) {
      setParsedData(billReport.data);
      setShouldFetch(false);
    }
  }, [billReport]);

  const handleSearch = () => {
    if (!startDate || !endDate) {
      return;
    }
    setShouldFetch(true);
  };

  // Dosya adı oluşturma
  const fileName = `Yuklenen_Faturalar_${
    startDate ? startDate.toISOString().split("T")[0] : ""
  }_${endDate ? endDate.toISOString().split("T")[0] : ""}.xlsx`;

  const getBillTypeLabel = (value) => {
    return (
      billTypeOptions.find((option) => option.value === value)?.label || value
    );
  };

  // Excel verilerini hazırlama
  const excelData = parsedData.map((item) => ({
    "Fatura Numarası": item.invoiceNumber,
    "Fatura Tipi": getBillTypeLabel(item.invoiceType),
    "Firma Adı": item.firmName,
    "Ürün Tipi": item.productType,
    "Ürün Adı": item.productName,
    Kota: item.quota,
    Tarih: item.date,
  }));

  return (
    <ReportLayout
      title="Yüklenen Faturalar Raporu"
      fileName={fileName}
      excelData={excelData}
    >
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <Grid container spacing={3} sx={{ maxWidth: 800 }}>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Başlangıç Tarihi"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
              format="dd/MM/yyyy"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Bitiş Tarihi"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
              format="dd/MM/yyyy"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Fatura Numarası"
              value={billNo}
              onChange={(e) => setBillNo(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Firma Adı"
              value={firmName}
              onChange={(e) => setFirmName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Ürün Tipi"
              value={productTypeName}
              onChange={(e) => setProductTypeName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="bill-type-select-label">Fatura Tipi</InputLabel>
              <Select
                labelId="bill-type-select-label"
                id="bill-type-select"
                value={billType}
                onChange={(e) => setBillType(e.target.value)}
                label="Fatura Tipi"
              >
                {billTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <Button
          variant="contained"
          onClick={handleSearch}
          sx={{ minWidth: 200, borderRadius: 2 }}
          disabled={!startDate || !endDate}
        >
          Raporu Getir
        </Button>
      </Box>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            marginTop: 4,
          }}
        >
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </Box>
      )}
      {error && (
        <Typography align="center" color="error">
          Hata: {error.message}
        </Typography>
      )}
      {(!isLoading || !error) && parsedData.length > 0 && (
        <Box sx={{ overflowX: "auto" }}>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>Fatura Numarası</th>
                <th style={tableHeaderStyle}>Fatura Tipi</th>
                <th style={tableHeaderStyle}>Firma Adı</th>
                <th style={tableHeaderStyle}>Ürün Tipi</th>
                <th style={tableHeaderStyle}>Ürün Adı</th>
                <th style={tableHeaderStyle}>Kota</th>
                <th style={tableHeaderStyle}>Tarih</th>
              </tr>
            </thead>
            <tbody>
              {parsedData.map((item, index) => (
                <tr
                  key={index}
                  style={index % 2 === 0 ? {} : alternateRowStyle}
                >
                  <td style={tableCellStyle}>{item.invoiceNumber}</td>
                  <td style={tableCellStyle}>
                    {getBillTypeLabel(item.invoiceType)}
                  </td>
                  <td style={tableCellStyle}>{item.firmName}</td>
                  <td style={tableCellStyle}>{item.productType}</td>
                  <td style={tableCellStyle}>{item.productName}</td>
                  <td style={tableCellStyle}>{item.quota}</td>
                  <td style={tableCellStyle}>{item.date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      )}
    </ReportLayout>
  );
}

const tableHeaderStyle = {
  backgroundColor: "#222831",
  color: "white",
  padding: "12px",
  textAlign: "center",
  fontWeight: "bold",
  borderBottom: "2px solid #ddd",
};

const tableCellStyle = {
  padding: "12px",
  borderBottom: "1px solid #ddd",
  textAlign: "center",
};

const alternateRowStyle = {
  backgroundColor: "#f0f0f0",
};
