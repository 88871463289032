import React from "react";
import { colors } from "../../Config/Colors";
import { MdEmail, MdPerson } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import { FaUserTag } from "react-icons/fa";

const CustomUserList = ({ data }) => {
  return (
    <div className="grid grid-cols-2 max-lg:grid-cols-1 gap-4">
      {data.map((user) => (
        <div
          key={user._id}
          className="bg-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden"
        >
          {/* Başlık */}
          <div
            className={`flex justify-between items-center p-4 border-b ${colors.background.main}`}
          >
            <div className="flex items-center gap-3 flex-wrap">
              <h3 className="text-xl font-semibold text-gray-800">
                {user.role === "admin"
                  ? "Sistem Yöneticisi"
                  : user.role === "management"
                  ? "Yönetici"
                  : user.role === "playmaker"
                  ? "Plasiyer"
                  : "Kullanıcı"}
              </h3>
            </div>
          </div>

          {/* Kullanıcı Detayları */}
          <div className="p-4 grid grid-cols-2 gap-4">
            {/* Kişisel Bilgiler */}
            <div>
              <div
                className={`${colors.background.light} p-4 rounded-lg h-full`}
              >
                <h4
                  className={`${colors.primary.text} font-medium mb-3 text-xl`}
                >
                  Kişisel Bilgiler
                </h4>
                <div className="grid grid-cols-1 gap-3">
                  <div
                    className={`${colors.background.main} p-3 rounded-lg shadow-sm border ${colors.border.light}`}
                  >
                    <div className="flex items-center gap-2">
                      <MdPerson className={`${colors.text.tertiary} w-5 h-5`} />
                      <span className={`text-lg ${colors.text.tertiary}`}>
                        İsim
                      </span>
                    </div>
                    <p
                      className={`text-lg mt-2 font-semibold ${colors.primary.text}`}
                    >
                      {user.name}
                    </p>
                  </div>
                  <div
                    className={`${colors.background.main} p-3 rounded-lg shadow-sm border ${colors.border.light}`}
                  >
                    <div className="flex items-center gap-2">
                      <FaUserTag
                        className={`${colors.text.tertiary} w-5 h-5`}
                      />
                      <span className={`text-lg ${colors.text.tertiary}`}>
                        Soyisim
                      </span>
                    </div>
                    <p
                      className={`text-lg mt-2 font-semibold ${colors.primary.text}`}
                    >
                      {user.lastName}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* İletişim Bilgileri */}
            <div>
              <div
                className={`${colors.background.light} p-4 rounded-lg h-full`}
              >
                <h4
                  className={`${colors.primary.text} font-medium mb-3 text-xl`}
                >
                  İletişim Bilgileri
                </h4>
                <div className="grid grid-cols-1 gap-3">
                  <div
                    className={`${colors.background.main} p-3 rounded-lg shadow-sm border ${colors.border.light}`}
                  >
                    <div className="flex items-center gap-2">
                      <MdEmail className={`${colors.text.tertiary} w-5 h-5`} />
                      <span className={`text-lg ${colors.text.tertiary}`}>
                        Email
                      </span>
                    </div>
                    <p
                      className={`text-lg mt-2 font-semibold ${colors.primary.text}`}
                    >
                      {user.email}
                    </p>
                  </div>
                  <div
                    className={`${colors.background.main} p-3 rounded-lg shadow-sm border ${colors.border.light}`}
                  >
                    <div className="flex items-center gap-2">
                      <BsTelephone
                        className={`${colors.text.tertiary} w-5 h-5`}
                      />
                      <span className={`text-lg ${colors.text.tertiary}`}>
                        Telefon
                      </span>
                    </div>
                    <p
                      className={`text-lg mt-2 font-semibold ${colors.primary.text}`}
                    >
                      {user.tel}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CustomUserList;
