import { TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { useUpdateStockMutation } from "../../store";
import styles from "../../CustomStyles";
import { BsFillPencilFill } from "react-icons/bs";
import Alerts from "../../Components/Utils/Alert";
import Confirm from "../../Components/Utils/Confirm";
import DataSearchBar from "../../Components/SearchBar/DataSearchBar";
import ListInfo from "../../Components/Table/ListInfo";
import FormatDateHourString from "../../Components/Utils/FormatDateHourString";
import StockForm from "../../Forms/StockForm";

export default function StockList({ active, token, DataStock }) {
  const [Update, resultUpdate] = useUpdateStockMutation();
  const [input, setInput] = useState("");

  const inputFieldName = {
    productName: "Ürün İsmi",
    productCode: "Ürün Kodu",
  };
  const inputField = {
    quota: "Yüklenecek Stok",
    productName: "Ürün İsmi",
  };
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [designModal, setDesignModal] = useState(false);
  const [action, setAction] = useState(0);
  const [alert, setAlert] = useState(0);
  const [message, setMessage] = useState("");
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [isSearch, setIsSearch] = useState(false);
  const [filteredData, setFilteredData] = useState("");
  const [infoText, setInfoText] = useState("");
  const [quota, setQuota] = useState(0);

  useEffect(() => {
    setIsSearch(false);
  }, []);

  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        setAlert(0);
        setMessage("");
      }, 5000);
    }
  }, [alert]);

  const handleModel = (values) => {
    setShowConfirmModal(true);
    setInput({ ...input, quota: values.quota });
    setDesignModal(false);
  };

  const handleApiResponse = (apiResponse, successMessage) => {
    if (apiResponse.isError) {
      setAlert(2);
      setMessage("İşlem başarısız tekrar deneyin !");
      setInput((prevInput) => ({
        ...prevInput,
        token: token,
      }));
    }
    if (apiResponse.isSuccess) {
      setAlert(1);
      setMessage(successMessage);
    }
  };
  useEffect(() => {
    handleApiResponse(resultUpdate, "Güncelleme başarılı !");
  }, [resultUpdate.isSuccess, resultUpdate.isError]);

  const handleCloseModel = (boolean) => {
    if (boolean) {
      if (action === 2) {
        Update(input);
        setIsSearch(false);
      }
      setAction(0);
      setShowConfirmModal(false);
    } else {
      setShowConfirmModal(false);
      if (action === 3) setAction(0);
    }
  };

  const handleSearch = (data, isSearch) => {
    setPaginationNumber(1);
    setFilteredData(data);
    setIsSearch(isSearch);
  };

  const config = [
    {
      class: "w-4",
      label: "Düzenle",
      render: (DataStock) => (
        <div className="flex flex-row justify-center">
          <button
            onClick={() => {
              setInput({
                ...input,
                quota: DataStock.quota,
                productCode: DataStock.product.productCode,
                productName: DataStock.product.productName,
                productTypeName:
                  DataStock.product?.productType?.productTypeName,
                _id: DataStock._id,
                token: token,
              });
              setInfoText(
                "Değişiklikleri kaydetmek istediğinize emin misiniz ?"
              );
              setDesignModal(true);
              setAction(2);
            }}
            className={`${styles.tableButton}`}
          >
            <BsFillPencilFill className={`${styles.buttonIcon}`} />
          </button>
        </div>
      ),
    },
    {
      label: "Ürün İsmi",
      render: (DataStock) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Ürün İsmi</div>
          {DataStock.product.productName}
        </>
      ),
      sortValue: (DataStock) => DataStock.product.productName,
    },
    {
      label: "Ürün Tipi",
      render: (DataStock) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Ürün Tipi</div>
          {DataStock.product?.productType?.productTypeName}
        </>
      ),
      sortValue: (DataStock) => DataStock.product?.productType?.productTypeName,
    },
    {
      label: "Ürün Kota",
      render: (DataStock) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Ürün Kota</div>
          {DataStock.quota}
        </>
      ),
      sortValue: (DataStock) => DataStock.quota,
    },
    {
      label: "Değiştirilme Tarihi",
      render: (DataStock) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">
            Değiştirilme Tarihi
          </div>
          {FormatDateHourString(DataStock.updatedInfo)}
        </>
      ),
      sortValue: (DataStock) => DataStock.updatedInfo,
    },
  ];

  return (
    <>
      {active === 2 && (
        <>
          {alert !== 0 && (
            <div
              className="fixed z-10 left-1/2 top-0
    -translate-x-1/2"
            >
              <Alerts AlertChoice={alert} message={message} />
            </div>
          )}
          <Confirm
            input={input}
            inputFieldName={inputField}
            infoText={infoText}
            handleCloseModel={handleCloseModel}
            showConfirmModal={showConfirmModal}
          />
          <div className=" w-full bg-white rounded-md shadow-md p-4">
            <DataSearchBar
              Data={DataStock}
              handleSearch={handleSearch}
              token={token}
              inputFieldName={inputFieldName}
            />{" "}
          </div>

          <ListInfo
            Config={config}
            Key={"_id"}
            SetPaginationNumber={setPaginationNumber}
            PaginationNumber={paginationNumber}
            IsSearch={isSearch}
            FilteredData={filteredData}
            Data={DataStock}
          />
        </>
      )}
      {designModal && (
        <>
          <div
            onClick={() => {
              setInput("");
              setShowConfirmModal(false);
              setDesignModal(false);
              setAction(0);
            }}
            className="fixed inset-0 z-40 bg-gray-300 opacity-80 "
          ></div>
          <div
            className={`fixed z-50 top-1/2 left-1/2 -translate-y-1/2 overflow-y-scroll no-scrollbar rounded-xl 
          -translate-x-1/2  w-fit ${
            showConfirmModal ? "h-fit" : " max-h-[41rem]"
          }`}
          >
            <StockForm
              handleModal={handleModel}
              initialFValues={{ quota: 0 }}
            />
          </div>
        </>
      )}
    </>
  );
}
