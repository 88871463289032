import { useEffect } from "react";
import { useGetDeviceMutation } from "../store/devicesApi";

const useDeviceData = ({ token, id, setConfirm, setAlert, setMessage }) => {
  const [
    getDevice,
    {
      data: deviceData,
      isLoading: deviceIsLoading,
      isError: deviceIsError,
      status: deviceStatus,
      error: deviceError,
    },
  ] = useGetDeviceMutation();

  const handleApiResponse = (
    isSuccess,
    isError,
    updateDeviceError,
    successMessage
  ) => {
    if (isError) {
      setConfirm(false);
      setAlert(2);
      setMessage(updateDeviceError);
    }
    if (isSuccess) {
      setConfirm(false);
      setAlert(1);
      setMessage(successMessage);
    }
  };

  useEffect(() => {
    handleApiResponse(false, deviceIsError, deviceError, "");
  }, [deviceIsError]);

  return { getDevice, deviceData, deviceIsLoading, deviceStatus, deviceError };
};

export default useDeviceData;
