import React from "react";
import { TiDeleteOutline } from "react-icons/ti";
import { colors } from "../../Config/Colors";
import FormatDateHourString from "../Utils/FormatDateHourString";
import { IoReceiptOutline } from "react-icons/io5";
import { FaUser } from "react-icons/fa";
import { BiPackage } from "react-icons/bi";
import { BsCalendarDate } from "react-icons/bs";

const CustomBillList = ({ data, onDelete }) => {
  const getBillTypeInfo = (type) => {
    switch (type) {
      case "Bill":
        return {
          color: "#2E7D32",
          text: "Ekleme",
          bgClass: "bg-[#4CAF50]/20",
        };
      case "FreeUpload":
        return {
          color: "#B45309",
          text: "Bedelsiz Yükleme",
          bgClass: "bg-[#F59E0B]/20",
        };
      case "GetReturn":
        return {
          color: "#9C27B0",
          text: "İade Alma",
          bgClass: "bg-[#9C27B0]/20",
        };
      default:
        return {
          color: "#C62828",
          text: "Silme",
          bgClass: "bg-[#EF4444]/20",
        };
    }
  };

  return (
    <div className="grid grid-cols-2 max-lg:grid-cols-1 gap-4">
      {data.map((bill) => {
        const billTypeInfo = getBillTypeInfo(bill.billType);

        return (
          <div
            key={bill._id}
            className="bg-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden"
          >
            <div
              className={`flex justify-between items-center p-4 border-b ${colors.background.main}`}
            >
              <div className="flex items-center gap-3 flex-wrap">
                <h3 className="text-lg font-semibold text-gray-800">
                  {bill.billNo}
                </h3>
                <div className="flex gap-2 items-center">
                  <span
                    className={`px-3 py-1 rounded-full text-lg font-semibold ${billTypeInfo.bgClass}`}
                    style={{ color: billTypeInfo.color }}
                  >
                    {billTypeInfo.text}
                  </span>
                </div>
              </div>

              <button
                onClick={() => onDelete(bill)}
                className={`p-2 rounded-lg text-red-500 hover:bg-red-50 transition-all duration-300`}
              >
                <TiDeleteOutline className="w-8 h-8" />
              </button>
            </div>

            <div className="p-4 grid grid-cols-2 gap-4">
              <div>
                <div
                  className={`${colors.background.light} p-4 rounded-lg h-full`}
                >
                  <h4
                    className={`${colors.primary.text} font-medium mb-3 text-lg`}
                  >
                    İşlem Bilgileri
                  </h4>
                  <div className="grid grid-cols-1 gap-3">
                    <div
                      className={`${colors.background.main} p-3 rounded-lg shadow-sm border ${colors.border.light}`}
                    >
                      <div className="flex items-center gap-2">
                        <FaUser className={`${colors.text.tertiary} w-5 h-5`} />
                        <span className={`text-lg ${colors.text.tertiary}`}>
                          İşlemi Yapan
                        </span>
                      </div>
                      <p
                        className={`text-lg mt-2 font-semibold ${colors.primary.text}`}
                      >
                        {bill.billerName}
                      </p>
                    </div>
                    <div
                      className={`${colors.background.main} p-3 rounded-lg shadow-sm border ${colors.border.light}`}
                    >
                      <div className="flex items-center gap-2">
                        <IoReceiptOutline
                          className={`${colors.text.tertiary} w-5 h-5`}
                        />
                        <span className={`text-lg ${colors.text.tertiary}`}>
                          Fatura No
                        </span>
                      </div>
                      <p
                        className={`text-lg mt-2 font-semibold ${colors.primary.text}`}
                      >
                        {bill.billNo}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div
                  className={`${colors.background.light} p-4 rounded-lg h-full`}
                >
                  <h4
                    className={`${colors.primary.text} font-medium mb-3 text-lg`}
                  >
                    Ürün Bilgileri
                  </h4>
                  <div className="grid grid-cols-1 gap-3">
                    <div
                      className={`${colors.background.main} p-3 rounded-lg shadow-sm border ${colors.border.light}`}
                    >
                      <div className="flex items-center gap-2">
                        <BiPackage
                          className={`${colors.text.tertiary} w-5 h-5`}
                        />
                        <span className={`text-lg ${colors.text.tertiary}`}>
                          Ürün ve Miktar
                        </span>
                      </div>
                      <p
                        className={`text-lg mt-2 font-semibold ${colors.primary.text}`}
                      >
                        {bill.productName} - {bill.quota}
                      </p>
                    </div>
                    <div
                      className={`${colors.background.main} p-3 rounded-lg shadow-sm border ${colors.border.light}`}
                    >
                      <div className="flex items-center gap-2">
                        <BsCalendarDate
                          className={`${colors.text.tertiary} w-5 h-5`}
                        />
                        <span className={`text-lg ${colors.text.tertiary}`}>
                          İşlem Tarihi
                        </span>
                      </div>
                      <p
                        className={`text-lg mt-2 font-semibold ${colors.primary.text}`}
                      >
                        {FormatDateHourString(bill.createdInfo)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CustomBillList;
