export const groupAndMergeLogsReport = (data, filterType) => {
  const grouped = {};

  data.forEach((item) => {
    if (!item.logs || item.logs.length === 0) return;

    const date = new Date(item.date);
    let key;

    switch (filterType) {
      case "Day":
        key = date.toISOString().split("T")[0]; // YYYY-MM-DD formatında
        break;
      case "Month":
        key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}`; // YYYY-MM formatında
        break;
      case "Year":
        key = `${date.getFullYear()}`; // YYYY formatında
        break;
      default:
        return;
    }

    // Eğer bu anahtara sahip bir grup zaten varsa, logs'ları birleştir
    if (!grouped[key]) {
      grouped[key] = [...item.logs];
    } else {
      grouped[key] = [...grouped[key], ...item.logs];
    }
  });

  // Sonuç olarak oluşturulan gruplanmış ve birleştirilmiş logs'ları bir diziye çevir
  return Object.keys(grouped).map((key) => ({
    date: key,
    logs: grouped[key],
  }));
};
