import React from "react";
import { TextField } from "@mui/material";
import { colors } from "../../Config/Colors";

export default function Input(props) {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    disabled = false,
  } = props;

  return (
    <TextField
      className="w-full"
      disabled={disabled}
      variant="outlined"
      label={label}
      name={name}
      value={value || ""}
      onChange={onChange}
      {...(error && { error: true, helperText: error })}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "12px",
          backgroundColor: "white",
          fontSize: "16px",
          "& fieldset": {
            borderColor: "#29A19C",
            borderWidth: "2px",
          },
          "&:hover fieldset": {
            borderColor: "#29A19C",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#29A19C",
            borderWidth: "2px",
          },
        },
        "& .MuiInputLabel-root": {
          color: "#29A19C",
          backgroundColor: "white",
          fontSize: "18px",
        },
        "& input": {
          padding: "16px 14px",
        },
      }}
    />
  );
}
