import { useState, useEffect } from "react";
import Alerts from "../../../../Components/Utils/Alert";
import useAuth from "../../../../Hooks/useAuth";
import styles from "../../../../CustomStyles";
import Confirm from "../../../../Components/Utils/Confirm";
import TopSearchInfo from "../../../../Components/SearchBar/TopSearchInfo";
import ListInfo from "../../../../Components/Table/ListInfo";
import { BsFillPencilFill, BsTrash } from "react-icons/bs";
import DesignModal from "../../../../Components/Utils/DesignModal";
import UnfindDataWarn from "../../../../Components/Utils/UnfindDataWarn";
import Loader from "../../../../Components/Utils/Loader";
import {
  useAddProductInfoMutation,
  useDeleteProductInfoMutation,
  useFetchProductInfoQuery,
  useUpdateProductInfoMutation,
} from "../../../../store";
import FormatDateHourString from "../../../../Components/Utils/FormatDateHourString";
import ProductInfoDesignTag from "./ProductInfoDesignTag";

export default function ProductInfoPage() {
  const { auth } = useAuth();
  const token = auth.accessToken;
  const [isSearch, setIsSearch] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [input, setInput] = useState("");
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [filteredData, setFilteredData] = useState("");
  const [inputFieldName, setInputFieldName] = useState({
    productName: "Ürün İsmi",
    productCode: "Ürün Kodu",
    productTypeName: "Ürün Tipi",
  });

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [designModal, setDesignModal] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [action, setAction] = useState(0);
  const [alert, setAlert] = useState(0);
  const [message, setMessage] = useState("");

  const ProductInfo = useFetchProductInfoQuery(token);

  const [Update, resultUpdate] = useUpdateProductInfoMutation();
  const [Delete, resultDelete] = useDeleteProductInfoMutation();
  const [Add, resultAdd] = useAddProductInfoMutation();
  const DataProductInfo = ProductInfo?.data?.data?.productInfoes || [];

  useEffect(() => {
    setIsSearch(false);
  }, []);

  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        setAlert(0);
        setMessage("");
      }, 5000);
    }
  }, [alert]);

  const handleApiResponse = (apiResponse, successMessage) => {
    setConfirm(false);
    if (apiResponse.isError) {
      setAlert(2);
      setMessage("İşlem başarısız tekrar deneyin !");
      setInput((prevInput) => ({
        ...prevInput,
        token: token,
      }));
    }
    if (apiResponse.isSuccess) {
      setAlert(1);
      setMessage(successMessage);
    }
  };

  useEffect(() => {
    handleApiResponse(resultDelete, "Silme başarılı !");
  }, [resultDelete.isSuccess, resultDelete.isError]);
  useEffect(() => {
    handleApiResponse(resultAdd, "Ekleme başarılı !");
  }, [resultAdd.isSuccess, resultAdd.isError]);

  useEffect(() => {
    handleApiResponse(resultUpdate, "Güncelleme başarılı !");
  }, [resultUpdate.isSuccess, resultUpdate.isError]);

  const HandleModal = ({ e, productTypeName, productCode, productName }) => {
    e.preventDefault();
    setInput({ ...input, productTypeName, productCode, productName });
    setShowConfirmModal(true);
    setDesignModal(false);
  };

  const handleCloseModel = (boolean) => {
    if (boolean) {
      setConfirm(true);

      if (action === 1) {
        Add(input);
        setIsSearch(false);
      } else if (action === 2) {
        Update(input);
        setIsSearch(false);
      } else if (action === 3) {
        Delete(input);
        setIsSearch(false);
      }
      setAction(0);
      setInput("");
      setIsSearch(false);
      setShowConfirmModal(false);
    } else {
      setShowConfirmModal(false);
      setIsSearch(false);
      if (action === 3) setAction(0);
    }
  };

  const handleSearch = (data, isSearch) => {
    setPaginationNumber(1);
    setFilteredData(data);
    setIsSearch(isSearch);
  };

  const config = [
    {
      class: "w-4",
      label: "Düzenle",
      render: (DataProductInfo) => (
        <div className="flex flex-row justify-center">
          <button
            onClick={() => {
              setInput({
                ...input,
                productCode: DataProductInfo.productCode,
                productName: DataProductInfo.productName,
                productTypeName: DataProductInfo.productTypeName,
                id: DataProductInfo._id,
                token: token,
              });
              setInfoText(
                "Değişiklikleri kaydetmek istediğinize emin misiniz ?"
              );
              setDesignModal(true);
              setAction(2);
            }}
            className={`${styles.tableButton}`}
          >
            <BsFillPencilFill className={`${styles.buttonIcon}`} />
          </button>
          <button
            className={`${styles.tableButton}`}
            onClick={() => {
              setInput({
                ...input,
                productCode: DataProductInfo.productCode,
                productName: DataProductInfo.productName,
                productTypeName: DataProductInfo.productTypeName,
                id: DataProductInfo._id,
                token: token,
              });
              setInfoText("Silmek istediğinize emin misiniz ?");
              setShowConfirmModal(true);
              setAction(3);
            }}
          >
            <BsTrash className={`${styles.buttonIcon}`} />
          </button>
        </div>
      ),
    },
    {
      label: "Ürün Kodu",
      render: (DataProductInfo) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Ürün Kodu</div>
          {DataProductInfo.productCode}
        </>
      ),
      sortValue: (DataProductInfo) => DataProductInfo.productTypeName,
    },
    {
      label: "Ürün İsmi",
      render: (DataProductInfo) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Ürün İsmi</div>
          {DataProductInfo.productName}
        </>
      ),
      sortValue: (DataProductInfo) => DataProductInfo.productName,
    },
    {
      label: "Ürün Tipi",
      render: (DataProductInfo) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">Ürün Tipi</div>
          {DataProductInfo.productType.productTypeName}
        </>
      ),
      sortValue: (DataProductInfo) =>
        DataProductInfo.productType.productTypeName,
    },
    {
      label: "Değiştirilme Tarihi",
      render: (DataProductInfo) => (
        <>
          <div className=" md:hidden opacity-40 font-Bold">
            Değiştirilme Tarihi
          </div>
          {FormatDateHourString(DataProductInfo.updatedInfo)}
        </>
      ),
      sortValue: (DataProductInfo) => DataProductInfo.updatedInfo,
    },
  ];

  const DesignTag = () => {
    return (
      <ProductInfoDesignTag
        HandleModal={HandleModal}
        input={input}
        Token={token}
      />
    );
  };

  return (
    <div className={` mb-20 flex flex-col md:mr-10 md:ml-0 mx-10 relative`}>
      {alert !== 0 && (
        <div className="fixed z-10 left-1/2 top-0 -translate-x-1/2">
          <Alerts AlertChoice={alert} message={message} />
        </div>
      )}

      <Confirm
        input={input}
        inputFieldName={inputFieldName}
        infoText={infoText}
        handleCloseModel={handleCloseModel}
        showConfirmModal={showConfirmModal}
      />

      <TopSearchInfo
        Length={DataProductInfo.length}
        TopInfo={"Ürün Tipi Listesi"}
        AddButton={() => {
          setAction(1);
          setInput({
            ...input,
            token: token,
            productTypeName: "",
            productName: "",
            productCode: "",
          });
          setInfoText("Oluşturma işlemini onaylıyor musunuz ?");
          setDesignModal(true);
        }}
        SearchBarData={DataProductInfo}
        HandleSearch={handleSearch}
        SearchInputFieldName={{
          productName: "Ürün İsmi",
          productCode: "Ürün Kodu",
          productTypeName: "Ürün Tipi",
        }}
      />
      {ProductInfo.isFetching || confirm ? (
        <div className=" flex w-full h-full justify-center items-center">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : ProductInfo.error ||
        DataProductInfo?.length === 0 ||
        (filteredData.length === 0 && isSearch) ? (
        <UnfindDataWarn Data={"Ürün bulunamadı"} />
      ) : (
        <ListInfo
          Config={config}
          Key={"_id"}
          SetPaginationNumber={setPaginationNumber}
          PaginationNumber={paginationNumber}
          IsSearch={isSearch}
          FilteredData={filteredData}
          Data={DataProductInfo}
        />
      )}

      <DesignModal
        onClose={() => {
          setInput("");
          setShowConfirmModal(false);
          setDesignModal(false);
          setAction(0);
        }}
        designModal={designModal}
        Tag={DesignTag}
      />
    </div>
  );
}
