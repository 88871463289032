import { BsCalendarWeekFill } from "react-icons/bs";
import CalendarContainer from "./CalendarContainer";
import { colors } from "../../../Config/Colors";
import { useState } from "react";

function CustomCalendar({ setDate, date }) {
  const [calendar, setCalendar] = useState(false);
  const [input, setInput] = useState("");

  const onChangeDate = (date) => {
    const day = date?.getDate().toString().padStart(2, "0");
    const month = (date?.getMonth() + 1).toString().padStart(2, "0");
    const year = date?.getFullYear();
    const formattedDate = `${day}.${month}.${year}`;
    setInput(formattedDate);
    setDate(date);
    setCalendar(false);
  };

  return (
    <div className="relative flex gap-4 items-center">
      <BsCalendarWeekFill
        onClick={() => setCalendar(!calendar)}
        className={`w-6 h-6 cursor-pointer transition-colors duration-300 text-[#29A19C] hover:${colors.text.secondary}`}
      />

      {calendar && (
        <>
          <div
            onClick={() => setCalendar(false)}
            className="fixed inset-0 bg-black/20 backdrop-blur-sm z-30"
          />
          <div
            className={`absolute z-40 top-10 -left-5 bg-white rounded-xl shadow-lg border ${colors.border.light} p-4`}
          >
            <CalendarContainer onChange={onChangeDate} value={date} />
          </div>
        </>
      )}

      {input && (
        <p
          className={`px-3 text-xl rounded-lg ${colors.background.lighter} ${colors.text.primary} font-medium`}
        >
          {input}
        </p>
      )}
    </div>
  );
}

export default CustomCalendar;
