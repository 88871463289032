import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { RxCross2 } from "react-icons/rx";
import { colors } from "../../Config/Colors";
import { useUploadManualQRMutation } from "../../store";
import useAuth from "../../Hooks/useAuth";
import { useUploadManualQuotaMutation } from "../../store/devicesApi";

export default function ManualQuota({ setIsOpen, isOpen }) {
  const { auth } = useAuth();
  const token = auth.accessToken;
  const [quota, setQuota] = useState(0);
  const [qrContent, setQrContent] = useState("");
  const [deviceInfo, setDeviceInfo] = useState();

  const [uploadManualQR, resultUploadManualQR] = useUploadManualQRMutation();
  const [uploadManualQuota, resultUploadManualQuota] =
    useUploadManualQuotaMutation();

  const handleInputChange = (event) => {
    setQuota(event.target.value);
  };

  useEffect(() => {
    if (resultUploadManualQR.data?.data) {
      setDeviceInfo(resultUploadManualQR.data.data);
    }
  }, [resultUploadManualQR]);

  useEffect(() => {
    if (resultUploadManualQuota.data?.data) {
      setDeviceInfo(resultUploadManualQuota.data.data);
    }
  }, [resultUploadManualQuota]);

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setQrContent("");
        setDeviceInfo();
        setIsOpen(false);
      }}
    >
      <div
        className={`
        absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
        bg-white rounded-xl shadow-xl
        w-full max-w-lg
        max-h-[90vh] overflow-y-auto
        outline-none
      `}
      >
        {/* Header */}
        <div
          className={`flex justify-between items-center p-6  border-b ${colors.border.light}`}
        >
          <p className={` text-xl font-medium ${colors.text.primary}`}>
            Manuel Kota Yükleme
          </p>
          <button
            onClick={() => {
              setQrContent("");
              setDeviceInfo();
              setIsOpen(false);
            }}
            className={`p-2 rounded-lg hover:${colors.background.light}`}
          >
            <RxCross2 className="w-8 h-8" />
          </button>
        </div>

        {/* Content */}
        <div className="p-6 space-y-6">
          {/* QR Input */}
          <div className="space-y-4">
            <input
              type="text"
              value={qrContent}
              onChange={(event) => setQrContent(event.target.value)}
              placeholder="QR Kod"
              className={`
                w-full px-4 py-3 
                rounded-lg text-sm
                border-2
                ${colors.border.main}
                ${colors.text.primary}
                outline-none
              `}
            />
            <button
              onClick={() => uploadManualQR({ token, data: qrContent })}
              className={`
                w-full px-4 py-3 
                rounded-lg text-sm font-medium
                ${colors.primary.bg} text-white
                hover:scale-105 transform transition-all
              `}
            >
              Kodu Yükle
            </button>
          </div>

          {/* Device Info */}
          {deviceInfo && (
            <div className={`rounded-lg p-4 ${colors.background.lighter}`}>
              <div className="space-y-3">
                {[
                  { label: "Ürün", value: deviceInfo.device.type },
                  { label: "Seri No", value: deviceInfo.device.serialNo },
                  {
                    label: "Bağlanmayan Makine Kota",
                    value: deviceInfo.unConnectedDevice.quota,
                  },
                  {
                    label: "Bağlanmayan Makine Sayaç",
                    value: deviceInfo.unConnectedDevice.totalCounter,
                  },
                  {
                    label: "Sistemdeki Kota",
                    value: deviceInfo.targetProductInfo.quota,
                  },
                  {
                    label: "Sistemdeki Sayaç",
                    value: deviceInfo.targetProductInfo.totalCounter,
                  },
                ].map((item, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center"
                  >
                    <span
                      className={`text-sm font-medium ${colors.text.primary}`}
                    >
                      {item.label}:
                    </span>
                    <span className={`text-sm ${colors.text.primary}`}>
                      {item.value}
                    </span>
                  </div>
                ))}
              </div>

              {/* Üretilen Kod Gösterimi */}
              {deviceInfo.encryptedKey && (
                <div className="mt-4">
                  <p
                    className={`text-sm font-medium mb-2 ${colors.text.primary}`}
                  >
                    Makine Yükleme Kodu:
                  </p>
                  <div
                    className={`
                    relative 
                    p-4 
                    rounded-lg 
                    ${colors.primary.bg}
                    group
                  `}
                  >
                    {/* Kod Metni */}
                    <p className="text-white text-sm break-all font-mono">
                      {deviceInfo.encryptedKey}
                    </p>

                    {/* Kopyalama Butonu */}
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(deviceInfo.encryptedKey);
                        // İsteğe bağlı: Kopyalandı bildirimi eklenebilir
                      }}
                      className={`
                        absolute top-2 right-2
                        p-2
                        rounded-lg
                        bg-white/10
                        hover:bg-white/20
                        transition-all duration-200
                        opacity-0 group-hover:opacity-100
                      `}
                    >
                      <svg
                        className="w-4 h-4 text-white"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
                        />
                      </svg>
                    </button>

                    {/* Kod Uzunluğu Göstergesi */}
                    <div
                      className={`
                      mt-2 
                      pt-2 
                      border-t 
                      border-white/20
                      flex 
                      justify-between 
                      items-center
                    `}
                    >
                      <span className="text-white/70 text-xs">
                        Kod Uzunluğu: {deviceInfo.encryptedKey.length} karakter
                      </span>
                      <span className="text-white/70 text-xs">
                        Kopyalamak için üzerine gelin
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Quota Input */}
          {deviceInfo && (
            <div className="space-y-4">
              <input
                type="number"
                value={quota}
                onChange={handleInputChange}
                placeholder="Makinenin Olması Gereken Kota"
                className={`
                    w-full px-4 py-3 
                    rounded-lg text-sm
                    border-2
                    ${colors.border.main}
                    ${colors.text.primary}
                    outline-none
                `}
              />
              <button
                onClick={() =>
                  quota && uploadManualQuota({ token, data: qrContent, quota })
                }
                disabled={!quota}
                className={`
                  w-full px-4 py-3 
                  rounded-lg text-sm font-medium
                  ${colors.primary.bg} text-white
                  ${
                    !quota
                      ? "opacity-50 cursor-not-allowed"
                      : `hover:${colors.primary.bgHover}`
                  }
                `}
              >
                Kod Üret
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
