import { useDeviceReportData } from "../../../Hooks/useDeviceReportData";
import { MagnifyingGlass } from "react-loader-spinner";
import UnfindDataWarn from "../../../Components/Utils/UnfindDataWarn";
import CustomDeviceReportTable from "../../../Components/Device/CustomDeviceReportTable";
import ReportSearchBar from "../../../Components/SearchBar/ReportSearchBar";
import { groupAndSumConsumptionsReport } from "../../../Components/Utils/Report/groupAndSumConsumptionsReport";
import { groupAndMergeLogsReport } from "../../../Components/Utils/Report/groupAndMergeLogsReport";
import SidebarList from "../../../Components/Utils/SidebarList";
import ExportExcel from "../../../Components/Utils/ExportExcel";
import FormatDateString from "../../../Components/Utils/Date/FormatDateString";
import FormatHourString from "../../../Components/Utils/Date/FormatHourString";
import { useLocation } from "react-router-dom";
import useAuth from "../../../Hooks/useAuth";
import { colors } from "../../../Config/Colors";

function ReportDevice() {
  const location = useLocation();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const id = location.state.id;

  const {
    info,
    setInfo,
    input,
    setInput,
    filterType,
    handleFilterType,
    date,
    setDate,
    dateSecond,
    setDateSecond,
    paginationNumber,
    setPaginationNumber,
    isFetching,
    isLoading,
    isError,
    Datas,
    selectedDate,
    setSelectedDate,
    handleGetLogData,
  } = useDeviceReportData(id, token);

  const reportTypeOptions = [
    { label: "Tüketim", value: "Consument" },
    { label: "Ayar Değişikliği", value: "UpdateSetting" },
    { label: "Bilgi Değişikliği", value: "UpdateInfo" },
    { label: "Manual Kota Yükleme", value: "UpdateManualQuota" },
  ];

  const ReportReturn = (item, date) => {
    let output = null;

    const fileName = {
      Consument: "Tüketim",
      UpdateSetting: "Ayar Değişikliği",
      UpdateInfo: "Bilgi Değişikliği",
      UpdateManualQuota: "Manual Kota Yükleme",
    };

    const reportTypeColors = {
      Consument: "from-blue-500 to-blue-700",
      UpdateSetting: "from-emerald-500 to-emerald-700",
      UpdateInfo: "from-violet-500 to-violet-700",
      UpdateManualQuota: "from-amber-500 to-amber-700",
    };

    if (date === "" || item.length === 0) {
      return null;
    }

    const filteredItems = item.find((entry) => entry.date === date);

    if (!filteredItems) {
      return null;
    }

    let excelData = [];
    const tableHeaderClass = `px-6 py-4 text-xl font-semibold text-white bg-gradient-to-r ${
      reportTypeColors[input.type]
    }`;
    const tableCellClass = `px-6 py-4 text-lg ${colors.text.primary} border-b`;
    const tableRowHoverClass = `hover:bg-gray-50 transition-colors duration-150`;

    const renderOutput = (data, filterType) => {
      const groupedData = groupAndSumConsumptionsReport(data.logs, filterType);

      return (
        <div className="overflow-x-auto">
          <table className="w-full border rounded-lg overflow-hidden">
            <thead>
              <tr>
                <th className={tableHeaderClass}>Tarih</th>
                {filterType === "Day" && (
                  <th className={tableHeaderClass}>Saat</th>
                )}
                <th className={tableHeaderClass}>Ürün</th>
                <th className={tableHeaderClass}>Tüketim</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(groupedData).map(([dateTime, products]) =>
                Object.entries(products).map(
                  ([productName, { consument }], index) => (
                    <tr key={index}>
                      <td className={tableCellClass}>
                        {dateTime.split(" ")[0]}
                      </td>
                      {filterType === "Day" && (
                        <td className={tableCellClass}>
                          {filterType === "Day" ? dateTime.split(" ")[1] : "—"}
                        </td>
                      )}
                      <td
                        className={`${tableCellClass} font-medium text-blue-600`}
                      >
                        {productName}
                      </td>
                      <td
                        className={`${tableCellClass} font-medium text-emerald-600`}
                      >
                        {consument}
                      </td>
                    </tr>
                  )
                )
              )}
            </tbody>
            {filterType === "Day" && (
              <tfoot className="bg-gray-50">
                <tr>
                  <td colSpan="2" className={`${tableCellClass} font-semibold`}>
                    Toplam Tüketimler:
                  </td>
                  <td colSpan="4" className={tableCellClass}>
                    <div className="grid grid-cols-2 gap-4">
                      {Object.entries(
                        data?.logs.reduce((acc, log) => {
                          if (acc[log.productName]) {
                            acc[log.productName] += log.consument;
                          } else {
                            acc[log.productName] = log.consument;
                          }
                          return acc;
                        }, {})
                      ).map(([product, total], index) => (
                        <div key={index} className="flex items-center gap-2">
                          <span className="font-medium text-blue-600">
                            {product}:
                          </span>
                          <span className="font-medium text-emerald-600">
                            {total}
                          </span>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      );
    };

    const renderExcelData = (data, filterType) => {
      return data?.logs.map((log) => ({
        İsim: log.name,
        Soyisim: log.lastName,
        Ürün: log.productName,
        Tüketim: log.consument,
        Saat: FormatHourString(log.createdInfo),
        Tarih: FormatDateString(log.createdInfo),
      }));
    };

    if (input.type === "Consument") {
      let productTotals = {};

      filteredItems?.logs.forEach((log) => {
        if (productTotals[log.productName]) {
          productTotals[log.productName] += log.consument;
        } else {
          productTotals[log.productName] = log.consument;
        }
      });

      output = renderOutput(filteredItems, filterType);
      excelData = renderExcelData(filteredItems, filterType);
    } else if (input.type === "UpdateSetting" || input.type === "UpdateInfo") {
      output = (
        <div className="overflow-x-auto">
          <table className="w-full border rounded-lg overflow-hidden">
            <thead>
              <tr>
                <th className={tableHeaderClass}>İsim</th>
                <th className={tableHeaderClass}>Soyisim</th>
                <th className={tableHeaderClass}>Bilgi</th>
                <th className={tableHeaderClass}>İlk Değer</th>
                <th className={tableHeaderClass}>Son Değer</th>
                <th className={tableHeaderClass}>Saat</th>
                <th className={tableHeaderClass}>Tarih</th>
              </tr>
            </thead>
            <tbody>
              {filteredItems?.logs.map((log, logIndex) =>
                log.info.map((info, infoIndex) => (
                  <tr
                    key={`${logIndex}-${infoIndex}`}
                    className={tableRowHoverClass}
                  >
                    <td className={tableCellClass}>{log.name}</td>
                    <td className={tableCellClass}>{log.lastName}</td>
                    <td className={tableCellClass}>{info.infoName}</td>
                    <td
                      className={`${tableCellClass} font-medium text-red-600`}
                    >
                      {info.valueFrom}
                    </td>
                    <td
                      className={`${tableCellClass} font-medium text-green-600`}
                    >
                      {info.valueTo}
                    </td>
                    <td className={tableCellClass}>
                      {FormatHourString(log.createdInfo)}
                    </td>
                    <td className={tableCellClass}>
                      {FormatDateString(log.createdInfo)}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      );
      excelData = filteredItems.logs.map((log) => ({
        İsim: log.name,
        Soyisim: log.lastName,
        Bilgi: log.info.infoName,
        İlk_Değer: log.info.valueFrom,
        Son_Değer: log.info.valueTo,
        Saat: FormatHourString(log.createdInfo),
        Tarih: FormatDateString(log.createdInfo),
      }));
    } else if (input.type === "UpdateManualQuota") {
      output = (
        <div className="overflow-x-auto">
          <table className="w-full border rounded-lg overflow-hidden">
            <thead>
              <tr>
                <th className={tableHeaderClass}>İsim</th>
                <th className={tableHeaderClass}>Soyisim</th>
                <th className={tableHeaderClass}>Firma</th>
                <th className={tableHeaderClass}>Ürün</th>
                <th className={tableHeaderClass}>Yüklenen</th>
                <th className={tableHeaderClass}>Varolan</th>
                <th className={tableHeaderClass}>Sayaç</th>
                <th className={tableHeaderClass}>Saat</th>
                <th className={tableHeaderClass}>Tarih</th>
              </tr>
            </thead>
            <tbody>
              {filteredItems?.logs.map((log, logIndex) =>
                log.info.map((info, infoIndex) => (
                  <tr
                    key={`${logIndex}-${infoIndex}`}
                    className={tableRowHoverClass}
                  >
                    <td className={tableCellClass}>{log.name}</td>
                    <td className={tableCellClass}>{log.lastName}</td>
                    <td className={tableCellClass}>{info.firmName}</td>
                    <td
                      className={`${tableCellClass} font-medium text-blue-600`}
                    >
                      {info.productInfo.productName}
                    </td>
                    <td
                      className={`${tableCellClass} font-medium text-green-600`}
                    >
                      {info.productInfo.quota}
                    </td>
                    <td
                      className={`${tableCellClass} font-medium text-amber-600`}
                    >
                      {info.productInfo.alreadyQuota}
                    </td>
                    <td
                      className={`${tableCellClass} font-medium text-violet-600`}
                    >
                      {info.productInfo.counter}
                    </td>
                    <td className={tableCellClass}>
                      {FormatHourString(log.createdInfo)}
                    </td>
                    <td className={tableCellClass}>
                      {FormatDateString(log.createdInfo)}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      );
      excelData = filteredItems.logs.map((log) => ({
        İsim: log.name,
        Soyisim: log.lastName,
        Firma: log.firmName,
        Yüklenen: log.productInfo.quota,
        Varolan: log.productInfo.alreadyQuota,
        Sayaç: log.productInfo.counter,
        Saat: FormatHourString(log.createdInfo),
        Tarih: FormatDateString(log.createdInfo),
      }));
    }

    return (
      <div className="space-y-6 bg-white ">
        <div
          className={`bg-gradient-to-r ${
            reportTypeColors[input.type]
          } rounded-lg p-6`}
        >
          <h2 className="text-2xl font-bold text-white text-center">
            {date} Tarihli {fileName[input.type]} Raporu
          </h2>
          <p className="text-white/80 text-xl text-center mt-2">
            Toplam {filteredItems.logs.length} kayıt bulundu
          </p>
        </div>

        <div className="flex justify-center gap-4">
          <ExportExcel excelData={excelData} fileName={fileName[input.type]} />
        </div>

        <div className="  overflow-hidden border">{output}</div>
      </div>
    );
  };

  return (
    <>
      <ReportSearchBar
        filterType={filterType}
        handleFilterType={handleFilterType}
        date={date}
        setDate={setDate}
        dateSecond={dateSecond}
        setDateSecond={setDateSecond}
        input={input}
        setInput={setInput}
        handleGetLogData={handleGetLogData}
        reportTypeOptions={reportTypeOptions}
      />
      <SidebarList
        info={info}
        onClick={() => {
          setInfo(false);
          setSelectedDate(null);
        }}
      >
        {ReportReturn(groupAndMergeLogsReport(Datas, filterType), selectedDate)}
      </SidebarList>
      {isFetching || isLoading ? (
        <div className="flex w-full h-full mt-48 justify-center items-center">
          <MagnifyingGlass
            visible={true}
            height="100"
            width="100"
            ariaLabel="MagnifyingGlass-loading"
            wrapperStyle={{}}
            wrapperClass="MagnifyingGlass-wrapper"
            glassColor="#c0efff"
            color="#004080"
          />
        </div>
      ) : isError || Datas?.length === 0 ? (
        <UnfindDataWarn Data={"Rapor bulunamadı"} />
      ) : (
        <div className="flex flex-col w-full items-center bg-white rounded-xl shadow-md mb-48">
          <CustomDeviceReportTable
            data={groupAndMergeLogsReport(Datas, filterType)}
            reportType={input.type}
            setInfo={setInfo}
            currentPage={paginationNumber}
            setCurrentPage={setPaginationNumber}
            setSelectedDate={setSelectedDate}
          />
        </div>
      )}
    </>
  );
}

export default ReportDevice;
