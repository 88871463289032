import { useEffect } from "react";
import { useDowloadSettingMutation } from "../store/devicesApi";

const useDeviceSettingData = ({ setConfirm, setAlert, setMessage }) => {
  const [
    getDeviceSetting,
    {
      data: deviceSettingData,
      isLoading: deviceSettingIsLoading,
      isError: deviceSettingIsError,
      status: deviceSettingStatus,
      error: deviceSettingError,
    },
  ] = useDowloadSettingMutation();

  const handleApiResponse = (
    isSuccess,
    isError,
    updateDeviceError,
    successMessage
  ) => {
    if (isError) {
      setConfirm(false);
      setAlert(2);
      setMessage(updateDeviceError.data.message);
    }
    if (isSuccess) {
      setConfirm(false);
      setAlert(1);
    }
  };

  useEffect(() => {
    handleApiResponse(false, deviceSettingIsError, deviceSettingError, "");
  }, [deviceSettingIsError]);

  return {
    getDeviceSetting,
    deviceSettingData,
    deviceSettingIsLoading,
    deviceSettingStatus,
    deviceSettingError,
  };
};

export default useDeviceSettingData;
