import styles from "../../CustomStyles";
import useAuth from "../../Hooks/useAuth";
import { useDashboard } from "../../Hooks/useDashboard";
import ProductConsumentCard from "./ProductConsumentCard";
import FilterButton from "./FilterButton";
import ConsumpitonGraph from "./ConsumptionGraph";
import UnfindDataWarn from "../../Components/Utils/UnfindDataWarn";
import BillInfoCard from "./Bills/BillInfoCard";
import DeviceInfoCard from "./Devices/DeviceInfoCard";
import FirmInfoCard from "./Firms/FirmInfoCard";
import IncomeInfoCard from "./Incomes/IncomeInfoCard";
import { colors } from "../../Config/Colors";
import teaCoffeeBackground from "../../Assets/icons/coffee-hand.png";

function DashBoardPage() {
  const { auth } = useAuth();
  const {
    dashboardType,
    activeConsumption,
    isLoading,
    isFetching,
    errors,
    data,
    handleClickConsumption,
    getConsumptionData,
  } = useDashboard(auth.accessToken);

  // Herhangi bir hata varsa
  const hasError = errors.consuments || errors.bills || errors.devices;

  return (
    <div className="container px-4 py-6 space-y-6">
      {hasError ? (
        <UnfindDataWarn Data={"Dashboard yüklenirken bir sorun oluştu !"} />
      ) : (
        <div className="space-y-6">
          {/* Üst Kartlar */}
          <div className="grid xl:grid-cols-2 gap-6">
            {/* Grafik Kartı */}
            <div
              className={`bg-white shadow-lg rounded-2xl overflow-hidden ${colors.border.light}`}
            >
              <div className={`p-6 border-b ${colors.border.light}`}>
                <h2 className={`text-2xl font-semibold ${colors.text.primary}`}>
                  {dashboardType.graphText}
                </h2>
              </div>
              <div className="px-6 mt-12">
                <ConsumpitonGraph
                  dashboardConsumentIsLoading={isLoading.consuments}
                  dashboardConsumentIsFetching={isFetching.consuments}
                  dashboardType={dashboardType}
                  warn={dashboardType.warn}
                  monthlyConsumption={data.consuments?.monthlyConsumption}
                />
              </div>
            </div>

            {/* Tüketim Kartı */}
            <div
              className={`relative bg-white rounded-2xl shadow-lg overflow-hidden ${colors.border.light}`}
            >
              <div className="absolute right-0 bottom-0 pointer-events-none opacity-5">
                <img
                  src={teaCoffeeBackground}
                  alt="background"
                  className="w-72 h-72 object-cover"
                />
              </div>
              <div className={`p-6 border-b ${colors.border.light}`}>
                <div className="flex max-md:flex-col max-md:gap-2 md:justify-between items-center">
                  <h2
                    className={`text-2xl font-semibold ${colors.text.primary}`}
                  >
                    {dashboardType.text}
                  </h2>
                  <FilterButton
                    active={activeConsumption}
                    handleClick={handleClickConsumption}
                    button={`px-4 py-2 rounded-lg text-lg font-medium transition-all duration-300 ${colors.border.main}`}
                  />
                </div>
              </div>
              <div className="p-6">
                <ProductConsumentCard
                  dashboardType={dashboardType}
                  consumption={getConsumptionData()}
                  warn={dashboardType.warn}
                  dashboardConsumentIsLoading={isLoading.consuments}
                  dashboardConsumentIsFetching={isFetching.consuments}
                />
              </div>
            </div>
          </div>

          {/* Alt Kartlar */}
          <div className="flex flex-col gap-6">
            {/* Firma ve Gelir Kartları */}
            <div className="grid xl:grid-cols-2 grid-cols-1 gap-6">
              <FirmInfoCard
                isLoading={isLoading.bills}
                isFetching={isFetching.bills}
                firms={data.bills?.notOrderedFirms}
                warn={"Fatura kesilmemiş bir firma henüz yok."}
              />
              <IncomeInfoCard
                isLoading={isLoading.bills}
                isFetching={isFetching.bills}
                incomes={data.bills?.revenue}
                warn={"Ciro bulunamadı"}
              />
            </div>

            {/* Fatura ve Cihaz Kartları */}
            <div className="flex max-xl:flex-col gap-6">
              <BillInfoCard
                isLoading={isLoading.bills}
                isFetching={isFetching.bills}
                bills={data.bills?.bills}
                warn={"Fatura bulunamadı"}
              />
              <DeviceInfoCard
                isLoading={isLoading.devices}
                isFetching={isFetching.devices}
                devices={data.devices?.devices}
                warn={"Cihaz bulunamadı"}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DashBoardPage;
