import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import { BsFillPencilFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import { useFetchUserQuery } from "../../store";
import styles from "../../CustomStyles";
import UnfindDataWarn from "../../Components/Utils/UnfindDataWarn";
import ListInfo from "../../Components/Table/ListInfo";
import Loader from "../../Components/Utils/Loader";
import TopSearchInfo from "../../Components/SearchBar/TopSearchInfo";
import CustomUserList2 from "../../Components/Table/CustomUserList2";

function UsersPage() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const responseDatas = useFetchUserQuery(token);
  const User = responseDatas.data?.data?.users || [];
  // Exclude current user from DataUser
  const DataUser = User?.filter((user) => user._id !== auth._id);
  const inputFieldName = {
    name: "İsim",
    lastName: "Soyisim",
    email: "Email",
  };
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [filteredData, setFilteredData] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  useEffect(() => {
    setIsSearch(false);
  }, []);

  const handleSearch = (data, isSearch) => {
    setPaginationNumber(1);
    setFilteredData(data);
    setIsSearch(isSearch);
  };

  return (
    <div className="">
      <div className="w-full inline-block align-middle">
        <TopSearchInfo
          Length={DataUser.length}
          TopInfo={"Kullanıcı Listesi"}
          AddButton={() => {
            navigate("Oluştur");
          }}
          SearchBarData={DataUser}
          HandleSearch={handleSearch}
          SearchInputFieldName={inputFieldName}
        />
        {responseDatas.isFetching ? (
          <div className="flex w-full h-full justify-center items-center">
            <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
          </div>
        ) : responseDatas.error || DataUser.length === 0 ? (
          <UnfindDataWarn Data={"Kullanıcı bulunamadı"} />
        ) : (
          <CustomUserList2 data={isSearch ? filteredData : DataUser} />
        )}
      </div>
    </div>
  );
}

export default UsersPage;
