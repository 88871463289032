import { useGetFirmsListBasedLocationQuery } from "../../../store/reportsApi";
import useAuth from "../../../Hooks/useAuth";
import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ReportLayout from "../../../Layout/Reports/ReportLayout";
import Loader from "../../../Components/Utils/Loader";
import {
  useFetchCityQuery,
  useFetchPlayMakerQuery,
  useFetchTownQuery,
} from "../../../store";

export default function ReportFirmListBasedLocationPage() {
  const { auth } = useAuth();
  const [playMakerId, setPlayMakerId] = useState("");
  const [city, setCity] = useState("");
  const [town, setTown] = useState("");
  const [activity, setActivity] = useState("");
  const [shouldFetch, setShouldFetch] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const token = auth.accessToken;

  const { data: playmakers, isLoading: isPlaymakersLoading } =
    useFetchPlayMakerQuery(token);
  const CityResult = useFetchCityQuery(token);
  const TownResult = useFetchTownQuery(token);

  const {
    data: firmListReport,
    isLoading,
    error,
  } = useGetFirmsListBasedLocationQuery(
    {
      token,
      playMakerId: playMakerId || "undefined",
      city: city || "undefined",
      town: town || "undefined",
      activity: activity || "undefined",
    },
    { skip: !shouldFetch }
  );

  useEffect(() => {
    if (firmListReport && firmListReport.data) {
      setParsedData(firmListReport.data);
      setShouldFetch(false);
    }
  }, [firmListReport]);

  const handleSearch = () => {
    setShouldFetch(true);
  };

  // Dosya adı oluşturma
  const fileName = `Firma_Listesi_Konum_Bazli_${
    new Date().toISOString().split("T")[0]
  }.xlsx`;

  // Excel verilerini hazırlama
  const excelData = parsedData.map((item) => ({
    "Firma Adı": item.firmName,
    "Ana Firma": item.mainFirmName,
    Şehir: item.city,
    İlçe: item.town,
    Adres: item.address,
    Telefon: item.phone,
    "E-posta": item.email,
    Durum: item.status,
    "Filtre Kahve Cihaz Sayısı": item.filterCoffeeDeviceCount,
    "Türk Kahvesi Cihaz Sayısı": item.turkishCoffeeDeviceCount,
    ...Object.entries(item.productQuotas).reduce((acc, [product, quota]) => {
      acc[`${product} Kotası`] = quota;
      return acc;
    }, {}),
  }));

  return (
    <ReportLayout
      title="Firma Listesi Konum Bazlı Raporu"
      fileName={fileName}
      excelData={excelData}
    >
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <Grid container spacing={3} sx={{ maxWidth: 800 }}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="playmaker-select-label">Plasiyer</InputLabel>
              <Select
                labelId="playmaker-select-label"
                id="playmaker-select"
                value={playMakerId}
                onChange={(e) => setPlayMakerId(e.target.value)}
                label="Plasiyer"
                disabled={isPlaymakersLoading}
              >
                <MenuItem value="">Tümü</MenuItem>
                {playmakers?.data?.playmakers?.map((playmaker) => (
                  <MenuItem key={playmaker.id} value={playmaker.id}>
                    {playmaker.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="city-select-label">Şehir</InputLabel>
              <Select
                labelId="city-select-label"
                id="city-select"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                  setTown(""); // Şehir değiştiğinde ilçeyi sıfırla
                }}
                label="Şehir"
                disabled={CityResult.isLoading}
              >
                <MenuItem value="">Tümü</MenuItem>
                {CityResult.data?.data?.cities?.map((city) => (
                  <MenuItem key={city._id} value={city.cityName}>
                    {city.cityName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="town-select-label">İlçe</InputLabel>
              <Select
                labelId="town-select-label"
                id="town-select"
                value={town}
                onChange={(e) => setTown(e.target.value)}
                label="İlçe"
                disabled={TownResult.isLoading || !city}
              >
                <MenuItem value="">Tümü</MenuItem>
                {TownResult.data?.data?.towns
                  ?.filter((t) => t.city.cityName === city)
                  .map((town) => (
                    <MenuItem key={town._id} value={town.townName}>
                      {town.townName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="activity-select-label">
                Aktivite Durumu
              </InputLabel>
              <Select
                labelId="activity-select-label"
                id="activity-select"
                value={activity}
                onChange={(e) => setActivity(e.target.value)}
                label="Aktivite Durumu"
              >
                <MenuItem value="">Tümü</MenuItem>
                <MenuItem value="active">Aktif</MenuItem>
                <MenuItem value="inactive">Pasif</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <Button
          variant="contained"
          onClick={handleSearch}
          sx={{ minWidth: 200, borderRadius: 2 }}
        >
          Raporu Getir
        </Button>
      </Box>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            marginTop: 4,
          }}
        >
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </Box>
      )}
      {error && (
        <Typography align="center" color="error">
          Hata: {error.message}
        </Typography>
      )}
      {(!isLoading || !error) && parsedData.length > 0 && (
        <Box sx={{ overflowX: "auto" }}>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>Firma Adı</th>
                <th style={tableHeaderStyle}>Ana Firma</th>
                <th style={tableHeaderStyle}>Şehir</th>
                <th style={tableHeaderStyle}>İlçe</th>
                <th style={tableHeaderStyle}>Adres</th>
                <th style={tableHeaderStyle}>Telefon</th>
                <th style={tableHeaderStyle}>E-posta</th>
                <th style={tableHeaderStyle}>Durum</th>
                <th style={tableHeaderStyle}>Filtre Kahve Cihaz Sayısı</th>
                <th style={tableHeaderStyle}>Türk Kahvesi Cihaz Sayısı</th>
                {Object.keys(parsedData[0].productQuotas).map((product) => (
                  <th
                    key={product}
                    style={tableHeaderStyle}
                  >{`${product} Kotası`}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {parsedData.map((item, index) => (
                <tr
                  key={index}
                  style={index % 2 === 0 ? {} : alternateRowStyle}
                >
                  <td style={tableCellStyle}>{item.firmName}</td>
                  <td style={tableCellStyle}>{item.mainFirmName}</td>
                  <td style={tableCellStyle}>{item.city}</td>
                  <td style={tableCellStyle}>{item.town}</td>
                  <td style={tableCellStyle}>{item.address}</td>
                  <td style={tableCellStyle}>{item.phone}</td>
                  <td style={tableCellStyle}>{item.email}</td>
                  <td style={tableCellStyle}>{item.status}</td>
                  <td style={tableCellStyle}>{item.filterCoffeeDeviceCount}</td>
                  <td style={tableCellStyle}>
                    {item.turkishCoffeeDeviceCount}
                  </td>
                  {Object.entries(item.productQuotas).map(
                    ([product, quota]) => (
                      <td key={product} style={tableCellStyle}>
                        {quota}
                      </td>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      )}
    </ReportLayout>
  );
}

const tableHeaderStyle = {
  backgroundColor: "#222831",
  color: "white",
  padding: "12px",
  textAlign: "center",
  fontWeight: "bold",
  borderBottom: "2px solid #ddd",
};

const tableCellStyle = {
  padding: "12px",
  borderBottom: "1px solid #ddd",
  textAlign: "center",
};

const alternateRowStyle = {
  backgroundColor: "#f0f0f0",
};
