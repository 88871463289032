import { colors } from "../../Config/Colors";

export default function UnfindDataWarn({ Data }) {
  return (
    <div
      className={`
      flex items-center justify-center p-8 
      bg-white rounded-xl shadow-md 
      ${colors.border.light}
    `}
    >
      <div className={`text-2xl font-medium ${colors.text.secondary}`}>
        {Data}
      </div>
    </div>
  );
}
