import React from "react";
import Controls from "../Components/Form/Controls";
import { useForm } from "../Components/Form/useForm";

export default function StockForm({ handleModal, initialFValues }) {
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("quota" in fieldValues)
      temp.quota = fieldValues.quota ? "" : "Kota değeri girin.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      handleModal({
        quota: values.quota,
      });
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  return (
    <form
      className=" grid gap-4 w-full border-main border-4 bg-white p-4 rounded-md shadow-md"
      onSubmit={handleSubmit}
    >
      <div className=" flex flex-col gap-4 w-full ">
        <Controls.Number
          label="Kota"
          name="quota"
          min="100"
          max="20000"
          value={values.quota}
          onChange={handleInputChange}
          error={errors.quota}
        />
      </div>

      <button className={` bg-main rounded-md p-4 text-white `}>
        <p>Kaydet</p>
      </button>
    </form>
  );
}
