import React, { useState, useEffect } from "react";
import Alerts from "../Utils/Alert";
import { TbEdit } from "react-icons/tb";
import useAuth from "../../Hooks/useAuth";
import { useLocation } from "react-router-dom";
import Confirm from "../Utils/Confirm";
import DeviceSyncronizationForm from "../../Forms/DeviceForm/DeviceSyncronizationForm";
import UnfindDataWarn from "../Utils/UnfindDataWarn";
import Loader from "../Utils/Loader";
import { colors } from "../../Config/Colors";
import useDeviceSyncData from "../../Hooks/useDeviceSyncData";
import useUpdateDeviceSyncData from "../../Hooks/useUpdateDeviceSyncData";

export default function Syncronization() {
  const location = useLocation();
  const { auth } = useAuth();
  const token = auth.accessToken;
  const id = location.state.id;
  const [deviceSync, setDeviceSync] = useState();

  const [alert, setAlert] = useState(0); // 1- success 2-error 3-warning
  const [message, setMessage] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [showSyncModal, setShowSyncModal] = useState(false);

  const [input, setInput] = useState({
    productName: "",
    quota: 0,
    quotaWarning: 0,
    quotaMax: 0,
    syncLevel: 0,
    quotaDistribution: false,
    id: id,
    token: token,
  });
  const inputFieldName = {
    productName: "Ürün İsmi",
    quota: "Kota",
    quotaWarning: "Kota Uyarı Seviyesi",
    quotaMax: "Maximum Kota",
    syncLevel: "Senkronizasyon Seviyesi",
    quotaDistribution: "Otomatik Dağıtım",
  };
  const {
    getDeviceSyncByID,
    deviceSyncData,
    deviceSyncIsLoading,
    deviceSyncError,
  } = useDeviceSyncData({ token, id, setAlert, setConfirm, setMessage });

  const { updateDeviceSync, updateDeviceSyncIsLoading } =
    useUpdateDeviceSyncData({
      getDeviceSyncByID,
      token,
      id,
      setAlert,
      setMessage,
    });

  useEffect(() => {
    if (deviceSyncData) {
      setDeviceSync(deviceSyncData?.data?.productInfoes);
    }
  }, [deviceSyncData]);

  useEffect(() => {
    getDeviceSyncByID({ id, token });
  }, []);

  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        setAlert(0);
        setMessage("");
      }, 5000);
    }
  }, [alert]);

  const handleModal = (e) => {
    setConfirm(true);
    setInput(e);
  };

  const handleCloseModel = (boolean) => {
    if (boolean) {
      updateDeviceSync({
        productName: input.productName,
        quotaWarning: Number(input.quotaWarning),
        quotaMax: Number(input.quotaMax),
        syncLevel: Number(input.syncLevel),
        quotaDistribution: input.quotaDistribution,
        token,
        id,
      });
      setInput({
        productName: "",
        quotaWarning: 0,
        quotaMax: 0,
        syncLevel: 0,
        quotaDistribution: false,
      });
      setConfirm(false);
      setShowSyncModal(false);
    } else {
      setConfirm(false);
      setShowSyncModal(false);
    }
  };

  const content = deviceSync?.map((Datas, index) => {
    return (
      <div
        key={index}
        className={`bg-gradient-to-r ${colors.primary.gradient} rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden`}
      >
        <div className={` p-4 relative`}>
          <h3
            className={`${colors.text.white} text-2xl font-semibold text-center`}
          >
            {Datas.productName}
          </h3>
          <p className={`${colors.text.whiteAlpha} text-xl text-center mt-1`}>
            Senkronizasyon Bilgileri
          </p>
        </div>
        <div className="p-6">
          <div className="space-y-4">
            <div
              className={`flex justify-between items-center p-3 ${colors.background.light} rounded-lg`}
            >
              <span className={` ${colors.primary.text} text-lg font-medium`}>
                Kota
              </span>
              <span className={`${colors.text.tertiary} text-lg font-bold`}>
                {parseInt(Datas.quota)}
              </span>
            </div>
            <div className="grid grid-cols-2 gap-3">
              <div className={`p-3 ${colors.background.light} rounded-lg`}>
                <p className={` ${colors.status.warning.text} text-lg mb-1`}>
                  Uyarı Seviyesi
                </p>
                <p className={`${colors.text.light} text-lg font-bold`}>
                  {Datas.quotaWarning}
                </p>
              </div>
              <div className={`p-3 ${colors.background.light} rounded-lg`}>
                <p className={`${colors.status.success.text} text-lg mb-1`}>
                  Maximum Seviye
                </p>
                <p className={`${colors.text.light}  text-lg font-bold`}>
                  {Datas.quotaMax}
                </p>
              </div>
            </div>
            <div className="space-y-3">
              <div
                className={`flex justify-between items-center p-3 ${colors.background.light} rounded-lg`}
              >
                <span className={`${colors.primary.text}  text-lg font-medium`}>
                  Senkronizasyon
                </span>
                <span className={`${colors.text.tertiary} text-lg font-bold`}>
                  {Datas.syncLevel}
                </span>
              </div>
              <div
                className={`flex justify-between items-center p-3 ${colors.background.light} rounded-lg`}
              >
                <span className={`${colors.text.tertiary} text-lg font-medium`}>
                  Otomatik Dağıtım
                </span>
                <span
                  className={`px-3 py-1 rounded-full text-lg font-medium ${
                    Datas.quotaDistribution
                      ? `${colors.status.success.bg} ${colors.status.success.text}`
                      : `${colors.status.error.bg} ${colors.status.error.text}`
                  }`}
                >
                  {Datas.quotaDistribution ? "Aktif" : "Pasif"}
                </span>
              </div>
            </div>
          </div>
          <div className="mt-6 text-center">
            <button
              onClick={() => {
                setInput({
                  ...input,
                  productName: Datas.productName,
                  quota: Datas.quota,
                  quotaWarning: Datas.quotaWarning,
                  quotaMax: Datas.quotaMax,
                  syncLevel: Datas.syncLevel,
                  quotaDistribution: Datas.quotaDistribution,
                  token: token,
                  id: id,
                });
                setShowSyncModal(true);
              }}
              className="flex items-center justify-center w-full gap-2 px-4 py-2 text-[#29A19C] bg-white border-2 border-[#29A19C] rounded-lg hover:bg-[#29A19C] hover:text-white transition-all duration-300"
            >
              <TbEdit className="w-8 h-8" />
              <span className="text-xl">Düzenle</span>
            </button>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className={` mb-20 flex flex-col md:mr-10 md:ml-0 `}>
      {alert !== 0 && (
        <div
          className="fixed z-50 left-1/2 top-0
  -translate-x-1/2"
        >
          <Alerts AlertChoice={alert} message={message} />
        </div>
      )}
      <Confirm
        input={input}
        inputFieldName={inputFieldName}
        handleCloseModel={handleCloseModel}
        showConfirmModal={confirm}
      />
      {deviceSyncIsLoading || updateDeviceSyncIsLoading ? (
        <div className=" flex w-full h-full justify-center items-center">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : deviceSyncError || deviceSync?.length === 0 ? (
        <div
          className={` fixed top-0 left-0 flex w-full h-fit justify-center items-center`}
        >
          <UnfindDataWarn Data={"Senkronizasyon ayarları bulunamadı !"} />
        </div>
      ) : (
        <div className=" grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 gap-8 ">
          <>{content}</>
        </div>
      )}
      <DeviceSyncronizationForm
        handleModal={handleModal}
        initialFValues={{
          productName: input.productName,
          quotaMax: input.quotaMax,
          quotaWarning: input.quotaWarning,
          syncLevel: input.syncLevel,
          quotaDistribution: input.quotaDistribution,
          quota: input.quota,
        }}
        showModal={showSyncModal}
        onClose={() => {
          setInput({
            productName: "",
            quotaWarning: 0,
            quotaMax: 0,
            syncLevel: 0,
            quotaDistribution: false,
            token: token,
          });
          setShowSyncModal(false);
        }}
      />
    </div>
  );
}
