import { Link } from "react-router-dom";

const GeneralReports = () => {
  const generalReports = [
    {
      title: "Tüketim Hareketleri Raporu",
      description: "2 Tarih arası çay ve kahve tüketim toplamlarını gösterir",
      path: "/Anasayfa/Rapor/GenelTüketim",
    },
    {
      title: "Satış Toplamları Raporu",
      description: "2 Tarih arası ürünlerin toplam satış miktarlarını gösterir",
    },
  ];

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4 flex items-center">
        <span className="mr-2">⚫</span> Genel Raporlar
      </h1>
      <ul className="grid grid-cols-2 gap-4 ">
        {generalReports.map((report, index) => (
          <Link to={report.path} key={index}>
            <li className="mb-4 p-4 border bg-white border-gray-200 rounded-lg hover:bg-gray-100 transition duration-200">
              <div className="text-lg font-semibold">{report.title}</div>
              <div className="text-gray-600">{report.description}</div>
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
};

const FirmReports = () => {
  const firmReports = [
    {
      title: "Bakiye Seviye Raporu",
      description: "Firmaların bakiye bilgisini gösterir",
      path: "/Anasayfa/Rapor/FirmaBakiyeSeviye",
    },
    {
      title: "Eklenenler Raporu",
      description: "2 Tarih arası eklenen firmaları gösterir",
      path: "/Anasayfa/Rapor/FirmaAramızaKatılan",
    },
    {
      title: "Fatura Hareketleri Raporu",
      description: "2 Tarih arası firmaların fatura hareketlerini gösterir",
      path: "/Anasayfa/Rapor/YüklenenFaturalar",
    },
    {
      title: "Firmalar (İl-İlçe) Raporu",
      description: "İl ve ilçe bazında firma raporu",
      path: "/Anasayfa/Rapor/FirmaLokasyonaGöre",
    },
    {
      title: "Tüketim (Aylık) Raporu",
      path: "/Anasayfa/Rapor/FirmaAylıkTüketim",
      description:
        "Yıl parametresine göre firmaların aylık tüketimlerini gösterir",
    },
    {
      title: "Tüketim (Günlük) Raporu",
      description:
        "Yıl&Ay parametrelerine göre firmaların günlük tüketimlerini gösterir",
      path: "/Anasayfa/Rapor/FirmaGünlükTüketim",
    },
    {
      title: "Tüketim (Toplam) Raporu",
      description: "2 Tarih arası firmaların toplam tüketimlerini gösterir",
      path: "/Anasayfa/Rapor/FirmaToplamTüketim",
    },
  ];

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4 flex items-center">
        <span className="mr-2">⚫</span> Firma Raporları
      </h1>
      <ul className="grid grid-cols-2 gap-4 ">
        {firmReports.map((report, index) => (
          <Link to={report.path} key={index}>
            <li className="mb-4 p-4 border bg-white border-gray-200 rounded-lg hover:bg-gray-100 transition duration-200">
              <div className="text-lg font-semibold">{report.title}</div>
              <div className="text-gray-600">{report.description}</div>
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
};

const DeviceReports = () => {
  const deviceReports = [
    {
      title: "Cihaz Bağlantı Bilgileri Raporu",
      description: "Belirtilen saatttir bağlanmayan cihazları gösterir",
    },
    {
      title: "Cihaz Genel Bilgileri Raporu",
      description: "Cihaz Genel Bilgileri Raporu",
    },
    {
      title: "Cihaz Performans Raporu",
      description: "2 Tarih arası cihaz tüketim performansını gösterir",
    },
    {
      title: "Eklenenler Raporu",
      description:
        "2 Tarih arası sisteme eklenen cihaz özet bilgilerini gösterir",
    },
    {
      title: "Gsm Modülü Bilgileri Raporu",
      description: "Cihazların gsm modülü bilgilerini gösterir",
    },
    {
      title: "Hareket Raporu",
      description: "2 Tarih arası cihaz üzerinde yapılan işlemleri gösterir",
    },
    {
      title: "Kullanım Raporu",
      description:
        "2 Tarih arası firmaların cihaz dağılımları ve tüketim istatistiklerini gösterir",
    },
    {
      title: "Servis Hareketleri Raporu",
      description: "2 Tarih arası cihazların servis hareketlerini gösterir",
    },
    {
      title: "Tüketim Raporu",
      description:
        "2 Tarih arası cihazların sunucuya gönderdikleri hareketleri gösterir",
    },
  ];

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4 flex items-center">
        <span className="mr-2">⚫</span> Cihaz Raporları
      </h1>
      <ul className="grid grid-cols-2 gap-4 ">
        {deviceReports.map((report, index) => (
          <li
            key={index}
            className="mb-4 p-4 border bg-white border-gray-200 rounded-lg hover:bg-gray-100 transition duration-200"
          >
            <div className="text-lg font-semibold">{report.title}</div>
            <div className="text-gray-600">{report.description}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default function ReportPage() {
  return (
    <div className="w-full inline-block align-middle">
      <GeneralReports />
      <FirmReports />
      <DeviceReports />
    </div>
  );
}
