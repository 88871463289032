import { useState, useMemo } from "react";
import { useGetBelongUsersByFirmIDQuery } from "../store/firmApi";

export function useFirmUsersData({ id, token }) {
  const [paginationNumber, setPaginationNumber] = useState(1);

  const {
    data: usersData,
    isLoading: usersIsLoading,
    isFetching: usersIsFetching,
    error: usersError,
  } = useGetBelongUsersByFirmIDQuery(
    { id, token },
    {
      refetchOnMountOrArgChange: true,
      skip: !token || !id,
    }
  );

  const users = useMemo(() => usersData?.data?.users || [], [usersData]);

  const isLoading = usersIsLoading || usersIsFetching;
  const hasNoData = usersData?.results === 0 || usersError;

  return {
    users,
    isLoading,
    hasNoData,
    usersError,
    paginationNumber,
    setPaginationNumber,
  };
}
