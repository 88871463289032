import CryptoJS from "crypto-js";

const createRequestData = (body) => {
  const timestamp = Date.now();
  const nonce = Math.floor(Math.random() * 10000).toString();
  const content = JSON.stringify({ ...body });

  // Parametreleri encode et
  const encodedContent = encodeURIComponent(content);

  // Parametreleri birleştir
  const params = {
    content: encodedContent,
    appKey: "12QDwtKG9u0tdZwXzCPhm0Ztrbjr71KT",
    timestamp: timestamp,
    nonce: nonce,
  };

  // Parametreleri ASCII sırasına göre dizip birleştir
  const sortedParams = Object.keys(params)
    .sort()
    .map((key) => `${key}=${params[key]}`)
    .join("&");
  const stringToSign = `${sortedParams}&appSecret=aYFtWFMDeIPuFJi1Ok1WFosGWmvpb2qn`;

  // MD5 ile hash al ve küçük harfe çevir
  const sign = CryptoJS.MD5(stringToSign).toString();

  // İstek verisi oluştur
  return {
    content: encodedContent,
    appKey: "12QDwtKG9u0tdZwXzCPhm0Ztrbjr71KT",
    timestamp: timestamp,
    nonce: nonce,
    sign: sign,
  };
};

export { createRequestData };
