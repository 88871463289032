import { RxCross2 } from "react-icons/rx";
import { colors } from "../../Config/Colors";

export default function SidebarList({ children, ...props }) {
  return (
    <>
      {props.info && (
        <div
          onClick={props.onClick}
          className="fixed inset-0 bg-black/40 backdrop-blur-sm z-40"
        />
      )}
      <div
        className={`
          fixed top-0 right-0 h-screen z-50
          bg-white shadow-xl
          transition-all duration-300 ease-in-out
          ${props.info ? "translate-x-0" : "translate-x-full"}
          min-w-[320px] max-w-[1200px]
        `}
      >
        <div className="relative h-full">
          <button
            onClick={props.onClick}
            className={`
              absolute top-4 left-4
              p-2 rounded-lg
              ${colors.background.lighter}
              ${colors.text.primary}
              hover:${colors.background.light}
              transition-colors duration-200
            `}
          >
            <RxCross2 className="w-6 h-6" />
          </button>

          {props.info && (
            <div
              className={`
              h-full overflow-y-auto
              pt-16 px-6 pb-6
              ${colors.background.main}
            `}
            >
              <div
                className={`
                bg-white rounded-xl shadow-sm
                p-6
                ${colors.border.light}
              `}
              >
                {children}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
