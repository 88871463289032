import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import URL from "../url";
import { createRequestData } from "./utils";

const devicesApi = createApi({
  reducerPath: "devices",
  baseQuery: fetchBaseQuery({
    baseUrl: `${URL.url}`,
    fetchFn: async (...args) => {
      return fetch(...args);
    },
  }),
  endpoints(builder) {
    return {
      uploadManualQuota: builder.mutation({
        query: (input) => {
          return {
            url: `/devices/uploadManualQuota`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
            body: {
              data: input.data,
              newQuota: input.quota,
            },
          };
        },
      }),
      uploadManualQR: builder.mutation({
        query: (input) => {
          return {
            url: `/devices/uploadManualQR`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
            body: {
              data: input.data,
            },
          };
        },
      }),

      updateDeviceQuota: builder.mutation({
        query: (input) => {
          return {
            url: `/devices/updateCounterQuota/${input.id}`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
            body: {
              ...input,
            },
          };
        },
      }),
      makeDeviceOperation: builder.mutation({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/devices/makeDeviceOperation`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
      }),
      updateSetting: builder.mutation({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/devices/uploadDeviceSetting`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
      }),
      dowloadSetting: builder.mutation({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/devices/downloadDeviceSetting`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${data.token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
      }),
      updateDeviceInformation: builder.mutation({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });

          return {
            url: `/devices/updateDevice`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
      }),
      deleteDevice: builder.mutation({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/devices/deleteDevice`,
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
      }),
      updateDeviceSync: builder.mutation({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/devices/updateDeviceSyncData`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
      }),
      getDeviceSyncByID: builder.mutation({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/devices/getDeviceSync`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
      }),
      getDevice: builder.mutation({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/devices/getDevice`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
      }),
      getDevices: builder.mutation({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: "/devices/getDevices",
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
      }),
      getQuotaToLoadedCode: builder.query({
        query: (input) => {
          return {
            url: `/devices/getQuotaCanLoadToDevice/${input.id}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
          };
        },
      }),
    };
  },
});

export const {
  useGetDevicesMutation,
  useGetDeviceMutation,
  useGetDeviceSyncByIDMutation,
  useMakeDeviceOperationMutation,
  useUpdateDeviceInformationMutation,
  useGetDevicesQuery,
  useUpdateSettingMutation,
  useDowloadSettingMutation,
  useDeleteDeviceMutation,
  useGetQuotaToLoadedCodeQuery,
  useUpdateDeviceQuotaMutation,
  useUploadManualQRMutation,
  useUploadManualQuotaMutation,
  useUpdateDeviceSyncMutation,
} = devicesApi;
export { devicesApi };
