import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useAuth from "../../../../Hooks/useAuth";
import DeviceSettingEditForm from "../../../../Forms/DeviceForm/DeviceSettingEditForm";
import SettingPageContainer from "./SettingPageContainer";
import useDeviceSettingData from "../../../../Hooks/useDeviceSettingData";
import useUpdateDeviceSettingData from "../../../../Hooks/useUpdateDeviceSettingData";

const generateInitialFValues = (settings) => {
  return Object.keys(settings).reduce((acc, key) => {
    acc[key] = settings[key].value;
    return acc;
  }, {});
};

const secondGenerateInitialFValues = (settings) => {
  return Object.keys(settings).reduce((acc, key) => {
    acc[key] = settings[key];
    return acc;
  }, {});
};

const getChangedValues = (original, initial, updated) => {
  const changes = { index: updated.index };
  const label = {};
  const lastValue = {};
  for (const key in initial) {
    if (updated.hasOwnProperty(key)) {
      if (initial[key] !== updated[key]) {
        if (original[key].type === "Number") {
          changes[key] = Number(updated[key]);
          lastValue[key] = Number(initial[key]);
        } else {
          changes[key] = updated[key];
          lastValue[key] = initial[key];
        }
        label[key] = original[key].label;
      }
    }
  }
  return { changes, label, lastValue };
};

export default function GeneralSetting() {
  const { auth } = useAuth();
  const token = auth.accessToken;
  const location = useLocation();
  const [GeneralSettings, setGeneralSettings] = useState({});
  const [DesignedGeneralSettings, setDesignedGeneralSettings] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [alert, setAlert] = useState(0);
  const [message, setMessage] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [inputFieldName, setInputFieldName] = useState({});
  const [initialFValues, setInitialFValues] = useState({});

  const {
    getDeviceSetting,
    deviceSettingData,
    deviceSettingIsLoading,
    deviceSettingStatus,
    deviceSettingError,
  } = useDeviceSettingData({ setConfirm, setAlert, setMessage });

  const { updateDeviceSetting, updateDeviceSettingIsSuccess } =
    useUpdateDeviceSettingData({ setConfirm, setAlert, setMessage });

  useEffect(() => {
    getDeviceSetting({
      id: location.state.id,
      token: token,
      type: "GS",
    });
  }, []);

  useEffect(() => {
    if (updateDeviceSettingIsSuccess && confirm) {
      setInitialFValues(secondGenerateInitialFValues(DesignedGeneralSettings));
    }
  }, [updateDeviceSettingIsSuccess]);

  const handleCloseModel = (boolean) => {
    if (boolean) {
      setConfirm(true);
      const changes = getChangedValues(
        GeneralSettings,
        initialFValues,
        DesignedGeneralSettings
      );
      updateDeviceSetting({
        id: location.state.id,
        token,
        key: "generalSetting",
        type: "GS",
        data: changes,
      });
    }
    setShowConfirmModal(false);
  };

  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        setAlert(0);
        setMessage("");
        setConfirm(false);
      }, 3000);
    }
  }, [alert]);

  useEffect(() => {
    if (deviceSettingStatus === "fulfilled") {
      const tempGeneralSetting = {
        ...deviceSettingData?.data?.settings,
      };
      setGeneralSettings(tempGeneralSetting);
      setInitialFValues(generateInitialFValues(tempGeneralSetting));
      setDesignedGeneralSettings(tempGeneralSetting);
    }
  }, [deviceSettingStatus]);

  return (
    <SettingPageContainer
      isLoading={deviceSettingIsLoading || confirm || alert !== 0}
      isError={deviceSettingError}
      alert={{
        show: alert !== 0,
        type: alert,
        message: message,
      }}
      confirmProps={{
        input: DesignedGeneralSettings,
        inputFieldName: inputFieldName,
        infoText: "Bilgileri kaydetmek istediğinize emin misiniz?",
        handleCloseModel: handleCloseModel,
        showConfirmModal: showConfirmModal,
      }}
      onRefresh={() =>
        getDeviceSetting({
          id: location.state.id,
          token: token,
          type: "GS",
        })
      }
    >
      {GeneralSettings && (
        <DeviceSettingEditForm
          Settings={GeneralSettings}
          setDesignedInput={setDesignedGeneralSettings}
          setInputFieldName={setInputFieldName}
          setShowConfirmModal={setShowConfirmModal}
          initialFValues={initialFValues}
        />
      )}
    </SettingPageContainer>
  );
}
