import React from "react";
import Controls from "../../Components/Form/Controls";
import { useForm } from "../../Components/Form/useForm";
import { colors } from "../../Config/Colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";

export default function DeviceSettingEditForm({
  Settings = {},
  setDesignedInput = () => {},
  setInputFieldName = () => {},
  setShowConfirmModal = () => {},
  initialFValues = {},
}) {
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setInputFieldName({});
      setShowConfirmModal(true);
      setDesignedInput(values);
    }
  };

  return (
    <div className="flex flex-col gap-6">
      {[
        { type: "SelectOptions", wrapperClass: "flex-1" },
        { type: "Input", wrapperClass: "flex-1" },
        { type: "Checkbox", wrapperClass: "flex-1" },
        { type: "Number", wrapperClass: "flex-1" },
      ].map(({ type, wrapperClass }) => {
        const filteredSettings = Object.keys(Settings).filter(
          (key) => Settings[key].type === type
        );

        return filteredSettings.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {filteredSettings.map((key) => {
              const setting = Settings[key];
              const ControlComponent = Controls[setting.type];
              return (
                <div
                  key={key}
                  className={`${colors.background.white} p-4 rounded-lg ${wrapperClass}`}
                >
                  <ControlComponent
                    label={setting.label}
                    name={key}
                    value={values[key]}
                    onChange={handleInputChange}
                    disabled={setting.disabled}
                    {...(setting.type === "Number" && {
                      min: setting.lowerBound,
                      max: setting.upperBound,
                    })}
                    {...(setting.type === "SelectOptions" && {
                      options: setting.options.map((option) => ({
                        title: option,
                      })),
                    })}
                  />
                </div>
              );
            })}
          </div>
        ) : null;
      })}

      <button
        onClick={handleSubmit}
        className={`
          fixed bottom-8 right-8 z-20
          flex items-center gap-2 px-6 py-3
          ${colors.primary.bg} text-white rounded-xl
          hover:shadow-lg transform hover:scale-[1.02]
          transition-all duration-300
        `}
      >
        <FontAwesomeIcon icon={faCloudArrowUp} className="w-6 h-6" />
        <span className="text-lg font-medium">Ayarları Kaydet</span>
      </button>
    </div>
  );
}
