import { TiTick } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";
import Pagination from "../Table/Pagination";

export default function BillList({
  sortData,
  isSearch,
  filteredData,
  resultCreate,
  resultControll,
  sortConfig,
  currentPage,
  pageSize,
  onPageChange,
  state,
}) {
  return (
    <div className="bg-white rounded-xl shadow-sm overflow-hidden mb-20">
      <>
        <div className="overflow-x-auto">
          <table className="w-full table-fixed">
            <thead className="bg-gray-50 border-b border-gray-200">
              <tr>
                <th className="w-2/12 px-3 py-4 text-left text-lg font-medium text-gray-500">
                  <p className="text-center">Fatura No</p>
                </th>
                <th className="w-2/12 px-3 py-4 text-left text-lg font-medium text-gray-500">
                  <p className="text-center">Firma</p>
                </th>
                <th className="w-2/12 px-3 py-4 text-left text-lg font-medium text-gray-500">
                  <p className="text-center">Ürün</p>
                </th>
                <th className="w-2/12 px-3 py-4 text-left text-lg font-medium text-gray-500">
                  <p className="text-center">Detaylar</p>
                </th>
                <th className="w-2/12 px-3 py-4 text-left text-lg font-medium text-gray-500">
                  <p className="text-center">Durum</p>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 text-lg">
              {sortData(
                isSearch
                  ? filteredData
                  : state === 2
                  ? resultCreate?.data?.bills || []
                  : state === 1
                  ? resultControll?.data?.bills || []
                  : [],
                sortConfig
              )
                .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                .map((item) => (
                  <tr key={item._id} className="hover:bg-gray-50">
                    <td className="px-3 py-4 text-gray-600 truncate">
                      <p className="text-center">{item.billNo}</p>
                    </td>
                    <td className="px-3 py-4 text-gray-600 whitespace-normal">
                      <p className="text-center">{item.firmApellation}</p>
                      <p className="text-center">{item.bayserNo}</p>
                    </td>
                    <td className="px-3 py-4 text-gray-600 whitespace-normal">
                      <p className="text-center">{item.productCode}</p>
                      <p className="text-center">{item.productName}</p>
                    </td>
                    <td className="px-3 py-4">
                      <div className="flex flex-col gap-1.5">
                        <div className="flex items-center justify-between px-3 py-1.5 bg-gray-50 rounded-lg">
                          <span className="text-xs font-medium text-gray-500">
                            Kota:
                          </span>
                          <span className="font-medium text-gray-700">
                            {item.quota}
                          </span>
                        </div>
                        <div className="flex items-center justify-between px-3 py-1.5 bg-blue-50 rounded-lg">
                          <span className="text-xs font-medium text-blue-500">
                            Fiyat:
                          </span>
                          <span className="font-medium text-blue-700">
                            {item.price} ₺
                          </span>
                        </div>
                        <div className="flex items-center justify-between px-3 py-1.5 bg-green-50 rounded-lg">
                          <span className="text-xs font-medium text-green-500">
                            Gelir:
                          </span>
                          <span className="font-medium text-green-700">
                            {item.income} ₺
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="px-3 py-4 whitespace-normal">
                      <div className="flex items-center justify-center gap-2">
                        {item.status ? (
                          <TiTick className="w-5 h-5 text-emerald-500 shrink-0" />
                        ) : (
                          <RxCross2 className="w-5 h-5 text-red-500 shrink-0" />
                        )}
                        <span className="text-gray-600">{item.message}</span>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <Pagination
          currentPage={currentPage}
          totalCount={
            (isSearch
              ? filteredData
              : (resultCreate?.isSuccess
                  ? resultCreate.data?.bills
                  : resultControll.data?.bills) || []
            ).length
          }
          pageSize={pageSize}
          onPageChange={onPageChange}
        />
      </>
    </div>
  );
}
