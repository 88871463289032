import { NavLink } from "react-router-dom";
import { BiLogOut } from "react-icons/bi";
import { SiBandsintown } from "react-icons/si";

import { BsGraphUp } from "react-icons/bs";
import { MdAddBusiness } from "react-icons/md";
import { GiModernCity } from "react-icons/gi";
import { FiType } from "react-icons/fi";
import LogoImage from "../../img/logo2.png";

import { MdCoffeeMaker, MdProductionQuantityLimits } from "react-icons/md";
import { TbReportMoney, TbReportSearch } from "react-icons/tb";
import { RxCross2 } from "react-icons/rx";
import { HiOutlineDesktopComputer, HiOutlineMenu } from "react-icons/hi";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { FiUsers } from "react-icons/fi";
import avatar from "../../img/Profile2.png";
import useAuth from "../../Hooks/useAuth";
import { useState } from "react";
import Sidebarlink from "./Sidebarlink";
import Modal from "@mui/material/Modal";
import { colors } from "../../Config/Colors";

const profileInfo = " text-xl ";
const profileImg = "w-14 h-14";
const styleLinkIcon = " w-8 h-8";
const linkInfo = " text-xl";

const links = [
  {
    icon: <BsGraphUp className={styleLinkIcon} />,
    label: "Dashboard",
    path: "/Anasayfa/Dashboard",
    roles: ["admin", "management"],
  },
  {
    icon: <MdAddBusiness className={styleLinkIcon} />,
    label: "Firmalar",
    path: "/Anasayfa/Firma",
    roles: ["admin", "management", "accounting"],
  },
  {
    icon: <MdCoffeeMaker className={styleLinkIcon} />,
    label: "Cihazlar",
    path: "/Anasayfa/Cihaz",
    roles: ["admin", "management", "manufacturer", "accounting"],
  },
  /**
  {
    icon: <MdChecklistRtl className={styleLinkIcon} />,
    label: "Stoklar",
    path: "/Anasayfa/Stok",
    roles: ["admin", "management", "manufacturer"],
  },*/
  {
    icon: <TbReportMoney className={styleLinkIcon} />,
    label: "Faturalar",
    path: "/Anasayfa/Fatura",
    roles: ["admin", "management", "accounting"],
  },
  {
    icon: <FiUsers className={styleLinkIcon} />,
    label: "Kullanıcılar",
    path: "/Anasayfa/Kullanıcı",
    roles: ["admin", "management", "accounting"],
  },
  {
    icon: <TbReportSearch className={styleLinkIcon} />,
    label: "Raporlar",
    path: "/Anasayfa/Rapor/Menu",
    roles: ["admin", "management", "accounting"],
  },
  {
    icon: <HiOutlineDesktopComputer className={styleLinkIcon} />,
    label: "Parametreler",
    roles: ["admin"],
    childrens: [
      {
        label: "Ürün Bilgisi",
        icon: <MdProductionQuantityLimits className={styleLinkIcon} />,
        path: "/Anasayfa/ÜrünBilgisi",
        roles: ["admin"],
      },
      {
        label: "Ürün Tipi",
        icon: <FiType className={styleLinkIcon} />,
        path: "/Anasayfa/ÜrünTipi",
        roles: ["admin"],
      },
      {
        label: "Şehir",
        icon: <GiModernCity className={styleLinkIcon} />,
        path: "/Anasayfa/Şehir",
        roles: ["admin"],
      },
      {
        label: "İlçe",
        icon: <SiBandsintown className={styleLinkIcon} />,
        path: "/Anasayfa/İlçe",
        roles: ["admin"],
      },
    ],
  },
  {
    icon: <BiLogOut className={styleLinkIcon} />,
    label: "Çıkış",
    path: "/",
    roles: ["admin", "management", "manufacturer", "accounting"],
  },
];

function Sidebar() {
  const [isHover, setIsHover] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const { auth } = useAuth();

  const handleClose = (val) => {
    setOpenMenu(val);
  };

  return (
    <>
      <button className="flex items-center md:hidden left-0 top-0 z-40 shadow-md fixed w-full bg-white">
        <HiOutlineMenu
          onClick={() => setOpenMenu(true)}
          className={`w-10 h-10 self-center ml-2 mt-2 ${colors.primary.text}`}
        />
      </button>

      <Modal open={openMenu} onClose={handleClose}>
        <div
          className={`
          z-[60] fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 
          ${colors.background.main} flex flex-col h-[36rem]
          rounded-xl items-center w-[20rem] ${colors.text.primary} 
          overflow-hidden border ${colors.primary.border} shadow-lg
        `}
        >
          <button
            onClick={() => handleClose(false)}
            className="absolute top-2 right-2 z-40"
          >
            <RxCross2 className="w-8 h-8 text-red-500 hover:text-red-600 transition-colors duration-300" />
          </button>

          <div
            className="fixed mt-4 cursor-pointer"
            onClick={() => {
              handleClose(false);
              window.location.href = "/#/Anasayfa/Profil";
            }}
          >
            <div
              className={`border-2 ${colors.primary.border} rounded-full hover:scale-110 transition-all duration-300`}
            >
              <img src={avatar} alt="Profile" className="w-28 h-28" />
            </div>
          </div>

          <div className="mt-36 h-[24rem] overflow-scroll">
            {links.map((link, index) => (
              <div key={index}>
                <Sidebarlink
                  link={link}
                  isHover={isHover}
                  roles={link.roles}
                  linkInfo={linkInfo}
                  handleClose={handleClose}
                />
              </div>
            ))}
          </div>
        </div>
      </Modal>

      <div className="md:top-0 md:left-0 md:fixed z-40 md:flex h-full hidden">
        <div
          className={`
            transition-all duration-500 transform h-screen
            ${isHover ? "w-[280px]" : "w-[88px]"}
            overflow-hidden xl:border-none
            bg-gray-100 text-gray-700
            shadow-xl
          `}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <div className="flex flex-col items-start pl-4 gap-3 w-fit">
            <div
              className={`flex flex-row w-52 justify-center items-center -ml-1 py-4 border-b border-gray-200`}
            >
              <NavLink to={"/Anasayfa/Profil"}>
                <div className="ml-1 w-fit rounded-full hover:scale-110 transition-all duration-300">
                  <img src={avatar} alt="Profile" className={profileImg} />
                </div>
              </NavLink>
              <div className="flex w-32 flex-col justify-around ml-5">
                <div>
                  <p className={profileInfo}>{auth?.name}</p>
                  <p className={profileInfo}>{auth?.lastName}</p>
                </div>
                <div>
                  <p
                    className={`${profileInfo} ${
                      isHover ? "flex px-4" : "hidden"
                    }`}
                  >
                    {auth?.firmName}
                  </p>
                </div>
              </div>
            </div>

            <div className="relative overflow-y-scroll no-scrollbar flex flex-col gap-4">
              <div className=" ">
                {links.map((link, index) => (
                  <div className=" mt-4" key={index}>
                    <Sidebarlink
                      link={link}
                      isHover={isHover}
                      roles={link.roles}
                      linkInfo={linkInfo}
                      handleClose={handleClose}
                    />
                  </div>
                ))}
              </div>

              <div
                className={`duration-300 transition-all ${
                  isHover
                    ? "self-center xl:w-[10rem] fixed bottom-0"
                    : "w-[5rem] fixed bottom-0 left-2 self-center"
                }`}
              >
                <img src={LogoImage} title="logo" alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
