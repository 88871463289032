import moment from "moment-timezone";

export default function FormatDateHourString(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}.${month}.${year} ${hours}:${minutes}`;
}

export const formatToTurkeyDate = (isoDate) => {
  return moment.utc(isoDate).tz("Europe/Istanbul").format("DD.MM.YYYY");
};

export const formatToTurkeyTime = (isoDate) => {
  return moment.utc(isoDate).tz("Europe/Istanbul").format("HH:mm");
};
