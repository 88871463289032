import React from "react";
import Controls from "../../Components/Form/Controls";
import { useForm } from "../../Components/Form/useForm";
import styles from "../../CustomStyles";
import { useFetchStorageFirmQuery } from "../../store/firmApi";
import { GrSend } from "react-icons/gr";
import { colors } from "../../Config/Colors";

export default function DevicePositionStorage({
  setDesignedInput,
  initialFValues,
  token,
}) {
  const responseStorageFirm = useFetchStorageFirmQuery(token);
  const Firms = responseStorageFirm.data?.data?.firms || [];
  const FirmsOptions = [
    ...Firms?.map((option) => {
      return { title: option.firmName };
    }),
  ];
  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("description" in fieldValues)
      temp.description = fieldValues.description
        ? ""
        : "Operasyon nedenini belirtiniz.";
    if ("firmName" in fieldValues)
      temp.firmName = fieldValues.firmName ? "" : "Firma seçiniz.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setDesignedInput({
        deviceStatusName: values.deviceStatusName,
        description: values.description,
        connect: values.connect,
        firmName: values.firmName,
      });
    }
  };

  return (
    <form className="grid gap-4 w-full " onSubmit={handleSubmit}>
      <div
        className={`flex items-center gap-2 p-4 border-b ${colors.background.main}`}
      >
        <GrSend className={`w-8 h-8 text-[${colors.primary.main}]`} />
        <h3 className="text-xl font-semibold text-gray-800">Depoya Gönder</h3>
      </div>
      <div className=" px-6 grid gap-4">
        <Controls.SelectOptions
          label="Firmalar Listesi"
          name="firmName"
          value={values.firmName}
          onChange={handleInputChange}
          options={FirmsOptions}
          error={errors.firmName}
        />
        <Controls.Input
          label="Açıklama"
          name="description"
          value={values.description}
          onChange={handleInputChange}
          error={errors.description}
        />
        <Controls.Checkbox
          label="Makine bağlantısı kurulsun mu ?"
          name="connect"
          value={values.connect}
          onChange={handleInputChange}
        />
      </div>

      <button
        className={` mb-4 z-20 md:rounded-md rounded-full w-fit justify-self-center
         ${styles.button}`}
      >
        <p>Kaydet</p>
      </button>
    </form>
  );
}
