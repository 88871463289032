import { NavLink } from "react-router-dom";
import { colors } from "../../Config/Colors";

export default function TopBar({ links, backLink }) {
  return (
    <div className="sticky top-0 z-40 bg-white shadow-md">
      <div
        className={`
        container mx-auto
        flex items-center justify-between
        border-b-2 ${colors.primary.border}
      `}
      >
        {/* Links */}
        <div className="flex-1 flex items-center overflow-x-auto no-scrollbar">
          <div className="flex gap-1 py-2 px-4">
            {links.map((link) => (
              <NavLink
                key={link.to}
                to={link.to}
                state={link.state}
                className={({ isActive }) => `
                  relative flex items-center gap-2 px-4 py-3
                  text-sm font-medium rounded-lg
                  transition-all duration-300 ease-in-out
                  ${
                    isActive
                      ? `${colors.primary.bg} text-white shadow-md`
                      : `text-gray-600 hover:bg-gray-100`
                  }
                `}
              >
                <span className="text-xl">{link.icon}</span>
                <span className="whitespace-nowrap">{link.label}</span>
              </NavLink>
            ))}
          </div>
        </div>

        {/* Back Button */}
        {backLink && (
          <div className="px-4">
            <NavLink
              to={backLink.path}
              className={`
                flex items-center justify-center
                w-10 h-10 rounded-full
                transition-all duration-300
                hover:bg-gray-100
                ${colors.text.secondary}
              `}
            >
              <span className="text-2xl">{backLink.icon}</span>
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
}
