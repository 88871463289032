import { Outlet, useLocation, NavLink } from "react-router-dom";
import { colors } from "../../Config/Colors";
import { IoSettingsOutline } from "react-icons/io5";
import { AiOutlineEye } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";

const UpdateDeviceSettingLayout = () => {
  const location = useLocation();

  const navLinks = [
    {
      to: "GenelAyar",
      icon: <IoSettingsOutline className="w-6 h-6" />,
      text: "Genel",
      subText: "Ayarı",
    },
    {
      to: "GözAyarı",
      icon: <AiOutlineEye className="w-6 h-6" />,
      text: "Göz",
      subText: "Ayarı",
    },
    {
      to: "ProfilAyarı",
      icon: <CgProfile className="w-6 h-6" />,
      text: "Profil",
      subText: "Ayarı",
    },
  ];

  return (
    <div className="flex flex-col gap-6">
      {/* Navigation Tabs */}
      <div className="flex overflow-x-auto no-scrollbar gap-4 p-2">
        {navLinks.map((link) => (
          <NavLink
            key={link.to}
            to={link.to}
            state={{
              id: location.state.id,
              serialNo: location.state.serialNo,
              deviceName: location.state.deviceName,
              createdInfo: location.state.createdInfo,
              updatedInfo: location.state.updatedInfo,
            }}
            className={({ isActive }) => `
              flex items-center gap-2
              px-6 py-3 rounded-xl
              border-2 ${colors.border.main}
              font-medium text-xl
              transition-all duration-300
              hover:shadow-md
              ${
                isActive
                  ? `${colors.primary.bg}  text-white border-transparent`
                  : `${colors.background.light} ${colors.text.primary}`
              }
            `}
          >
            {/* İkon */}
            {link.icon}

            {/* Metin */}
            <div className="flex whitespace-nowrap">
              <span>{link.text}</span>
              <span className="max-sm:hidden">&nbsp;{link.subText}</span>
            </div>
          </NavLink>
        ))}
      </div>

      {/* Content Area */}
      <div className={` mb-20`}>
        <Outlet />
      </div>
    </div>
  );
};

export default UpdateDeviceSettingLayout;
